@if(introduction && introduction.showSection){
<app-introduction [content]="introduction" [useCenter]="false"></app-introduction>
}

@if(historySection && historySection.showSection){
<app-history-section [content]="historySection" [useCenter]="false" [bgColor]="false"></app-history-section>
}

@if(bmiCalculator && historySection.showSection){
<app-bmi-calculator [content]="bmiCalculator" [useCenter]="false"></app-bmi-calculator>
}

@if(calculatorsInfo && calculatorsInfo.showSection){
<app-neo-calculators-info [content]="calculatorsInfo" [useCenter]="false"></app-neo-calculators-info>
}

<app-contact-section [id]="'contact'" (viewInitialized)="onChildViewInitialized()"
  [useCenter]="false"></app-contact-section>