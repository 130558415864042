import {PersondatenJson} from "./persondatenJson";
import {NeoRegionJson} from "../jsons/neo-region.json";
import {DoctorJson} from "../jsons/doctor.json";

export enum SortOrder {
    PREMIUM = 0,
    COVERAGE = 1
}

export class SuchparameterJson {
    tarifJahr: number;
    region: NeoRegionJson;
    nurKVG: boolean;
    hauptPerson: PersondatenJson;
    weitereVersichertePersonen?: PersondatenJson[];
    tariftypen: string[];
    selectedDoctor: DoctorJson;
    sortOrder: number;

    constructor() {
        this.sortOrder = SortOrder.PREMIUM;
    }
}

