import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {ContactFormTranslation} from "../models/contact-form.translation";
import {AnmeldungFormData} from "../models/anmeldung-form.data";
import {NeoInputDatepickerComponent} from "./neo-input-datepicker.component";
import {NeoInputTextComponent} from "./neo-input-text.component";
import {NeoCommonsUiConstants} from "../models/neo-ui.constants";
import { NeoSelectOptionComponent } from "./neo-select-option.component";
@Component({
    selector: "neo-anmeldung-contact",
    templateUrl: "./anmeldung-contact-form.component.html",
    styleUrls: ["./anmeldung-contact-form.component.scss"]
})
export class AnmeldungContactFormComponent {
    @Input()
    formTexts: ContactFormTranslation;
    @Input()
    showHeader = true;
    @Input()
    showText = true;
    @Input()
    showRequired = true;
    @Input()
    showSubmit = true;
    @Input()
    billingAddressOptions = {
        options: [],
        values: []
    };

    @Output()
    messageSubmit = new EventEmitter<AnmeldungFormData>();
    @Output()
    dataChanged = new EventEmitter<AnmeldungFormData>();

    @ViewChild("inputLastName")
    inputLastName: NeoInputTextComponent;
    @ViewChild("inputFirstName")
    inputFirstName: NeoInputTextComponent;
    @ViewChild("inputDate")
    inputDate: NeoInputDatepickerComponent;
    @ViewChild("inputZipCode")
    inputZipCode: NeoInputTextComponent;
    @ViewChild("inputCity")
    inputCity: NeoInputTextComponent;
    @ViewChild("inputEmail")
    inputEmail: NeoInputTextComponent;
    @ViewChild("inputPhone")
    inputPhone: NeoInputTextComponent;
    @ViewChild("inputAddress")
    inputAddress: NeoInputTextComponent;
    @Input()
    contactData: AnmeldungFormData;

    // new inputs
    @ViewChild("inputEmployer")
    inputEmployer: NeoInputTextComponent;
    @ViewChild("inputBillingAddress")
    inputBillingAddress: NeoSelectOptionComponent;

    @ViewChild("inputSupervisorEmailAddress")
    inputSupervisorEmailAddress: NeoInputTextComponent;
    @ViewChild("inputExperience")
    inputExperience: NeoInputTextComponent;

    @ViewChild("inputSupervisorPhone")
    inputSupervisorPhone: NeoInputTextComponent;
    



    showErrorDiv = false;

    public readonly contactMail = NeoCommonsUiConstants.CONTACT_MAIL;
    public readonly contactPhone = NeoCommonsUiConstants.CONTACT_PHONE;

    public constructor() {
        this.contactData = new AnmeldungFormData();
        this.formTexts = new ContactFormTranslation();
    }

    public setMessageData(value: any) {
        this.contactData.data = value;
    }

    public getContactData(): AnmeldungFormData {
        return this.contactData;
    }

    public sendMessage() {
        if (this.neoIsValid()) {
            this.showError(false);
            this.messageSubmit.emit(this.contactData);
        } else {
            this.showError(true);
        }
    }

    public showError(value: boolean) {
        this.showErrorDiv = value;
    }

    public firstNameChanged(value: string) {
        this.contactData.firstName = value;
        this.dataChanged.emit(this.contactData);
    }

    public lastNameChanged(value: string) {
        this.contactData.lastName = value;
        this.dataChanged.emit(this.contactData);
    }
    public adressChanged(value: string) {
        this.contactData.address = value;
        this.dataChanged.emit(this.contactData);
    }

    public zipChanged(value: string) {
        this.contactData.zip = value;
        this.dataChanged.emit(this.contactData);
    }

    public townChanged(value: string) {
        this.contactData.town = value;
        this.dataChanged.emit(this.contactData);
    }

    public emailChanged(value: string) {
        this.contactData.email = value;
        this.dataChanged.emit(this.contactData);
    }

    public phoneChanged(value: string) {
        this.contactData.phone = value;
        this.dataChanged.emit(this.contactData);
    }

    public vorgesetzterChanged(value: string) {
        this.contactData.supervisor = value;
        this.dataChanged.emit(this.contactData);
    }
    public birthdayChanged(value: Date) {
        this.contactData.birthday = value;
        this.dataChanged.emit(this.contactData);
    }
    public employerChanged(value: string) {
        this.contactData.employer = value;
        this.dataChanged.emit(this.contactData);
    }

    public billingAddressChanged(value: string) {
        this.contactData.billingAddress = value;
        this.dataChanged.emit(this.contactData);
    }
    public supervisorEmailAddressChanged(value: string) {
        this.contactData.supervisorEmailAddress = value;
        this.dataChanged.emit(this.contactData);
    }
    public experienceChanged(value: string) {
        this.contactData.experience = value;
        this.dataChanged.emit(this.contactData);
    }
    public supervisorPhoneChanged(value: string) {
        this.contactData.supervisorPhone = value;
        this.dataChanged.emit(this.contactData);
    }
    public neoIsValid(): boolean {
        let result: boolean;
        result = this.inputFirstName.neoIsValid();
        this.inputFirstName.neoShowError = this.inputFirstName.neoRequired && !this.inputFirstName.neoIsValid();

        result = result && this.inputLastName.neoIsValid();
        this.inputLastName.neoShowError = this.inputLastName.neoRequired && !this.inputLastName.neoIsValid();
       
        result = result && this.inputEmail.neoIsValid();
        this.inputEmail.neoShowError = this.inputEmail.neoRequired && !this.inputEmail.neoIsValid();

        result = result && this.inputPhone.neoIsValid();
        this.inputPhone.neoShowError = this.inputPhone.neoRequired && !this.inputPhone.neoIsValid();

        result = result && this.inputAddress.neoIsValid();
        this.inputAddress.neoShowError = this.inputAddress.neoRequired && !this.inputAddress.neoIsValid();
       
        result = result && this.inputZipCode.neoIsValid();
        this.inputZipCode.neoShowError = this.inputZipCode.neoRequired && !this.inputZipCode.neoIsValid();

        result = result && this.inputCity.neoIsValid();
        this.inputCity.neoShowError = this.inputCity.neoRequired && !this.inputCity.neoIsValid();
       
        result = result && this.inputDate.neoIsValid();
        this.inputDate.neoShowError = this.inputDate.neoRequired && !this.inputDate.neoIsValid();
       
        result = result && this.inputEmployer.neoIsValid();
        this.inputEmployer.neoShowError = this.inputEmployer.neoRequired && !this.inputEmployer.neoIsValid();

        result = result && this.inputBillingAddress.neoIsValid();
        this.inputBillingAddress.neoShowError = this.inputBillingAddress.neoRequired && !this.inputBillingAddress.neoIsValid();

        result = result && this.inputSupervisorEmailAddress.neoIsValid();
        this.inputSupervisorEmailAddress.neoShowError = this.inputSupervisorEmailAddress.neoRequired && !this.inputSupervisorEmailAddress.neoIsValid();

        result = result && this.inputExperience.neoIsValid();
        this.inputExperience.neoShowError = this.inputExperience.neoRequired && !this.inputExperience.neoIsValid();

        result = result && this.inputSupervisorPhone.neoIsValid();
        this.inputSupervisorPhone.neoShowError = this.inputSupervisorPhone.neoRequired && !this.inputSupervisorPhone.neoIsValid();


        return result;
    }
}
