import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {ContactFormTranslation} from "../models/contact-form.translation";
import {IJobData, NeoContactFormData} from "../models/neo-contact-form.data";
import {NeoInputCompleterItem} from "../models/neo-input-completer.item";
import {NeoRegion} from "../models/neo-region";
import {NeoInputDatepickerComponent} from "./neo-input-datepicker.component";
import {NeoInputTextComponent} from "./neo-input-text.component";
import {NeoInputCompleterComponent} from "./neo-input-completer.component";
import {NeoInputTimeComponent} from "./neo-input-time.component";
import {NeoCommonsUiConstants} from "../models/neo-ui.constants";
import { NeoDialogComponent } from "./neo-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { JobDetails } from "../models/job-details";
export type FormOutputType = 'contact' | 'jobApplication';

@Component({
    selector: "neo-contact-form",
    templateUrl: "./neo-contact-form.component.html",
    styleUrls: ["./neo-contact-form.component.scss"]
})
export class NeoContactFormComponent {
    fileFormat:any;
    files: any[] = [];
    errorMessage: string;
    isUploaded: boolean;

    @Input()
    formOutputType: FormOutputType = 'contact';    
    @Input()
    formTexts: ContactFormTranslation;
    @Input()
    useRegion = false;
    @Input()
    showHeader = true;
    @Input()
    showText = true;
    @Input()
    showRequired = true;
    @Input()
    showSubmit = true;
    @Input()
    showContactPlatform = true;
    @Input()
    showTime = true;
    @Input()
    showConcern = false;
    @Input()
    showNews = false;
    @Input()
    showBirthday = true;
    @Input()
    showStreetAndNumber = true;
    @Input()
    showLanguage = true;
    @Input()
    showAttachment = false;
    @Input()
    showPrivacyPolicy = false;
    @Input()
    showInterestedIn = true;
    @Input() 
    genderImages: string[] = [];
    @Input()
    public customCssClass : string = '';
    @Input()
    regionData: NeoInputCompleterItem;
    @Input()
    selectedLanguage : string = "de";
    @Output()
    messageSubmit = new EventEmitter<NeoContactFormData | IJobData>();
    @Output()
    dataChanged = new EventEmitter<NeoContactFormData>();
    image = ['assets/icons/gender0.svg','assets/icons/gender1.svg','assets/icons/gender2.svg'];

    @ViewChild("inputLastName")
    inputLastName: NeoInputTextComponent;
    @ViewChild("inputFirstName")
    inputFirstName: NeoInputTextComponent;
    @ViewChild("inputDate")
    inputDate: NeoInputDatepickerComponent;
    @ViewChild("inputStreet")
    inputStreet: NeoInputTextComponent;
    @ViewChild("inputHouseNo")
    inputHouseNo: NeoInputTextComponent;
    @ViewChild("inputRegion")
    inputRegion: NeoInputCompleterComponent;
    @ViewChild("inputZipCode")
    inputZipCode: NeoInputTextComponent;
    @ViewChild("inputCity")
    inputCity: NeoInputTextComponent;
    @ViewChild("inputEmail")
    inputEmail: NeoInputTextComponent;
    @ViewChild("inputPhone")
    inputPhone: NeoInputTextComponent;
    @ViewChild("inputInterest")
    inputInterest: NeoInputTextComponent;
    @ViewChild("inputConcern")
    inputConcern: NeoInputTextComponent;
    @ViewChild("inputNews")
    inputNews: NeoInputTextComponent;
    @ViewChild("inputTime")
    inputTime: NeoInputTimeComponent;
    @Input()
    contactData: NeoContactFormData;
    @Input()
    jobDetails: JobDetails = null;

    showErrorDiv = false;
    datepickerPlaceholder = {
        "de" : "tt.mm.jjjj",
        "en" : "dd.mm.yyyy",
        "fr" : "jj.mm.aaaa",
        "it" : "gg.mm.aaaa"
    }

    public readonly formOfAddressFemale = NeoCommonsUiConstants.FORM_OF_ADDRESS_FEMALE;
    public readonly formOfAddressMale = NeoCommonsUiConstants.FORM_OF_ADDRESS_MALE;
    public readonly languageGerman = NeoCommonsUiConstants.LANGUAGE_GERMAN;
    public readonly languageEnglish = NeoCommonsUiConstants.LANGUAGE_ENGLISH;
    public readonly languageFrench = NeoCommonsUiConstants.LANGUAGE_FRENCH;
    public readonly languageItalian = NeoCommonsUiConstants.LANGUAGE_ITALIAN;
    public readonly contactMail = NeoCommonsUiConstants.CONTACT_MAIL;
    public readonly contactWhatsApp = NeoCommonsUiConstants.CONTACT_WHATSAPP;
    public readonly contactPhone = NeoCommonsUiConstants.CONTACT_PHONE;

    public constructor(
        private dialog: MatDialog
    ) {
        this.contactData = new NeoContactFormData();
        this.formTexts = new ContactFormTranslation();
    }

    public setMessageData(value: any) {
        this.contactData.data = value;
    }

    public getContactData(): NeoContactFormData {
        return this.contactData;
    }

    public sendMessage() {
        if (this.neoIsValid()) {
            this.showError(false);
            if (this.formOutputType === 'contact') {
                this.messageSubmit.emit(this.contactData);
            } else {
                const data = new NeoContactFormData();
                const jobData = data.mapContactToJob(this.contactData, this.jobDetails, this.files);
                this.messageSubmit.emit(jobData);
            }
        } else {
            this.showError(true);
        }
    }

    public showError(value: boolean) {
        this.showErrorDiv = value;
    }

    public sexChanged(value: string) {
        this.contactData.salutation = value;
        this.dataChanged.emit(this.contactData);
    }

    public firstNameChanged(value: string) {
        this.contactData.firstName = value;
        this.dataChanged.emit(this.contactData);
    }

    public lastNameChanged(value: string) {
        this.contactData.lastName = value;
        this.dataChanged.emit(this.contactData);
    }

    public streetChanged(value: string) {
        this.contactData.street = value;
        this.dataChanged.emit(this.contactData);
    }

    public houseNumberChanged(value: string) {
        this.contactData.houseNumber = value;
        this.dataChanged.emit(this.contactData);
    }

    public zipChanged(value: string) {
        this.contactData.zip = value;
        this.dataChanged.emit(this.contactData);
    }

    public townChanged(value: string) {
        this.contactData.town = value;
        this.dataChanged.emit(this.contactData);
    }

    public emailChanged(value: string) {
        this.contactData.email = value;
        this.dataChanged.emit(this.contactData);
    }

    public phoneChanged(value: string) {
        this.contactData.phone = value;
        this.dataChanged.emit(this.contactData);
    }

    public languageChanged(value: string) {
        this.contactData.language = value;
        this.dataChanged.emit(this.contactData);
    }

    public interestedInChanged(pos: number, value: boolean) {
        if (pos === 0) {
            this.contactData.interestedInHealth = value;
        } else if (pos === 1) {
            this.contactData.interestedInMotor = value;
        } else if (pos === 2) {
            this.contactData.interestedInHousehold = value;
        } else if (pos === 3) {
            this.contactData.interestedInLife = value;
        }
        this.dataChanged.emit(this.contactData);
    }

    public interestOtherChanged(value: string) {
        this.contactData.interestedInOther = value;
        this.dataChanged.emit(this.contactData);
    }

    public concernChanged(value: string) {
        this.contactData.concern = value;
        this.dataChanged.emit(this.contactData);
    }
    public newsChanged(value: string) {
        this.contactData.news = value;
        this.dataChanged.emit(this.contactData);
    }

    public contactChanged(value: string) {
        this.contactData.contactKind = value;
        this.dataChanged.emit(this.contactData);
    }

    public timeChanged(value: string) {
        this.contactData.time = value;
        this.dataChanged.emit(this.contactData);
    }

    public birthdayChanged(value: Date) {
        this.contactData.birthday = value;
        this.dataChanged.emit(this.contactData);
    }

    public regionChanged(data: NeoRegion) {
        this.contactData.region = data;
        this.dataChanged.emit(this.contactData);
    }

    public neoIsValid(): boolean {
        let result: boolean;
        result = this.inputFirstName.neoIsValid();
        this.inputFirstName.neoShowError = this.inputFirstName.neoRequired && !this.inputFirstName.neoIsValid();

        result = result && this.inputLastName.neoIsValid();
        this.inputLastName.neoShowError = this.inputLastName.neoRequired && !this.inputLastName.neoIsValid();

        result = result && this.inputEmail.neoIsValid();
        this.inputEmail.neoShowError = this.inputEmail.neoRequired && !this.inputEmail.neoIsValid();

        result = result && this.inputPhone.neoIsValid();
        this.inputPhone.neoShowError = this.inputPhone.neoRequired && !this.inputPhone.neoIsValid();

        if (this.useRegion) {
            result = result && this.inputRegion.neoIsValid();
            this.inputRegion.neoShowError = this.inputRegion.neoRequired && !this.inputRegion.neoIsValid();
        } else if (!this.useRegion) {
            result = result && this.inputCity.neoIsValid();
            this.inputCity.neoShowError = this.inputCity.neoRequired && !this.inputCity.neoIsValid();
        }
        return result;
    }

      /**
   * on file drop handler
   */
   onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.isUploaded = true;
    this.errorMessage = this.formTexts.attachmentError;

    for (const item of files) {
      item.progress = 0;
      this.fileFormat = item.name.slice(-3);
      if (item.size < 100000 && this.fileFormat == "pdf" || this.fileFormat == "jpg" || this.fileFormat == "png"){
        this.files.push(item);
      } else {
          this.isUploaded = false;
          this.openDialog('ERROR', this.errorMessage);
          break;
      }
    }
}

    /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
     formatBytes(bytes, decimals) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      
    }

    openDialog(infoTitle, infoDescription): void {
        const dialogRef = this.dialog.open(NeoDialogComponent, {
          maxWidth: '100%',
          minWidth: '20%',
          panelClass: 'neo-vvg-dialog',
          data: {'title': infoTitle, 'text': infoDescription},
        });
      dialogRef.backdropClick().subscribe(() => {
        // Close the dialog
        dialogRef.close();
      });
    }
    
}
