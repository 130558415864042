import {PersondatenJson} from "../jsons/persondatenJson";
import {NeoClass} from "./neo-class";
import {NeoCommonsUiConstants} from "../models/neo-ui.constants";
import {LeistungFilter} from "./leistung-filter";
import {SuchmaskeProvider} from "../provider/suchmaske-provider";

export class Persondaten implements NeoClass<PersondatenJson> {
    geschlecht = NeoCommonsUiConstants.GENDER_MALE;
    franchise = -1;
    jahrgang: number;
    minTarif: string;
    maxTarif: string;
    neoRecommendation = true;
    _unfalldeckung = undefined;
    leistungFilter: Array<LeistungFilter> = [];

    get unfalldeckung(): boolean {
        if (this._unfalldeckung === undefined) {
            this._unfalldeckung = this.jahrgang &&
                SuchmaskeProvider.getAltersklasseForYear(this.jahrgang) === 0 &&
                SuchmaskeProvider.getAccidentCoverageForYear(this.jahrgang);
        }
        return this._unfalldeckung;
    }

    public constructor(pd?: PersondatenJson) {
        if (pd) {
            this.geschlecht = pd.geschlecht;
            this.franchise = pd.franchise;
            this.jahrgang = pd.jahrgang;
            this.neoRecommendation = pd.neoRecommendation;
            this._unfalldeckung = pd.unfalldeckung;
            this.leistungFilter = (pd.leistungFilter ? LeistungFilter.fromJson((pd.leistungFilter)) : []);
        }
    }

    public static fromJson(response: PersondatenJson): Persondaten {
        const result = new Persondaten();
        result.jahrgang = response.jahrgang;
        result.franchise = response.franchise;
        result.geschlecht = response.geschlecht;
        result.neoRecommendation = response.neoRecommendation;
        result._unfalldeckung = response.unfalldeckung;
        result.leistungFilter = (response.leistungFilter ? LeistungFilter.fromJson(response.leistungFilter) : []);
        return result;
    }

    public static toJsonArray(value: Persondaten[]): PersondatenJson[] | undefined {
        if (value) {
            return value.map(pd => new PersondatenJson(pd));
        }
    }

    public toJson(): PersondatenJson {
        const result = new PersondatenJson();
        result.jahrgang = this.jahrgang;
        result.franchise = this.franchise;
        result.geschlecht = this.geschlecht;
        result.neoRecommendation = this.neoRecommendation;
        result.unfalldeckung = this.unfalldeckung;
        result.leistungFilter = (this.leistungFilter ? LeistungFilter.toJsonArray(this.leistungFilter) : []);
        return result;
    }
}


