export class NeoVgrTranslation {
    neoVgrButtonNext = "COMMON.BUTTON.NEXT";
    neoVgrText = "COMMON.TEXT";
    neoVgrButtonBack = "COMMON.BUTTON.BACK";
    neoVgrButtonReset = "COMMON.BUTTON.RESET";
    neoVgrButtonNoDoctor = "VGR.SEARCH.BUTTON.NODOCTOR";
    neoVgrButtonFreeDoctor = "VGR.SEARCH.BUTTON.FREEDOCTOR";
    neoVgrButtonStandardDoctor = "VGR.SEARCH.BUTTON.STANDARDDOCTOR";
    neoVgrButtonSelectDoctor = "VGR.SEARCH.BUTTON.SELECTDOCTOR";
    neoVgrButtonResultOverview = "VGR.SEARCH.BUTTON.RESULT.OVERVIEW";
    neoVgrButtonResultDetail = "VGR.SEARCH.BUTTON.RESULT.DETAIL";
    neoVgrSorting = "VGR.SORTING.TITLE";
    neoVgrSortingPremium = "VGR.SORTING.PRICE";
    neoVgrSortingCoverage = "VGR.SORTING.RELEVANCE";
    neoVgrButtonRecommendation = "VGR.SEARCH.BUTTON.RECOMMENDATION";
    neoVgrButtonIndividually = "VGR.SEARCH.BUTTON.INDIVIDUALLY";
    neoVgrInfoRecommendationText = "VGR.VVG.INFO.RECOMMENDATION.TEXT";
    neoVgrInfoRecommendationTitle = "VGR.VVG.INFO.RECOMMENDATION.TITLE";
    neoVgrDoctorSearchTitle = "VGR.DOCTOR.SEARCH.TITLE";
    neoVgrDoctorSearchLabel = "VGR.DOCTOR.SEARCH.LABEL";
    neoVgrInfoDoctorText = "VGR.DOCTOR.INFO.TEXT";
    neoVgrInfoDoctorTitle = "VGR.DOCTOR.INFO.TITLE";
    neoVgrStepperLabelTitle = "VGR.STEPPER.TITLE";
    neoVgrStepperLabelDescription = "VGR.STEPPER.DESCRIPTION";
    neoVgrStepperLabelInfoboxTitle = "VGR.STEPPER.INFOBOXTITLE"; 
    neoVgrStepperLabelInfoboxCheck1 = "VGR.STEPPER.INFOBOXCHECK1"; 
    neoVgrStepperLabelInfoboxCheck2 = "VGR.STEPPER.INFOBOXCHECK2"; 
    neoVgrStepperLabelInfoboxCheck3 = "VGR.STEPPER.INFOBOXCHECK3"; 
    neoVgrStepperLabelInfoboxCheck4 = "VGR.STEPPER.INFOBOXCHECK4"; 
    neoVgrStepperLabelInfoboxCheck5 = "VGR.STEPPER.INFOBOXCHECK5"; 
    neoVgrStepperLabelInfoboxCheck6 = "VGR.STEPPER.INFOBOXCHECK6"; 
    neoVgrStepperLabelInfoboxCheck7 = "VGR.STEPPER.INFOBOXCHECK7"; 
    neoVgrStepperLabelInfoboxCheck8 = "VGR.STEPPER.INFOBOXCHECK8";
    neoVgrStepperLabelInfoboxText = "VGR.STEPPER.INFOBOXTEXT";

    neoVgrStepperLoading = "VGR.STEPPER.LOADING";
    neoVgrDonutAdviseTitle = "VGR.DONUT.ADVISE.TITLE";
    neoVgrDonutAdviseText = "VGR.DONUT.ADVISE.TEXT";
    neoVgrMinMaxHigh = "VGR.MINMAX.HIGH";
    neoVgrMinMaxLow = "VGR.MINMAX.LOW";
    neoVgrMinMaxErrorBounds = "VGR.MINMAX.ERROR.BOUNDS";
    neoVgrMinMaxErrorSearch = "VGR.MINMAX.ERROR.SEARCH";
    neoVgrSearchYear = "VGR.SEARCH.YEAR";
    neoVgrSearchPLZ = "VGR.SEARCH.PLZ";
    neoVgrSearchAddPersonText = "VGR.SEARCH.BUTTON.ADDPERSON.TEXT";
    neoVgrSearchErrorNoTarifs = "VGR.SEARCH.ERROR.NOTARIFS";
    neoVgrSearchErrorNoDoctor = "VGR.SEARCH.ERROR.NODOCTOR";
    neoVgrSearchDeletePersonText = "VGR.SEARCH.BUTTON.DELETEPERSON.TEXT";
    neoVgrDetailDescriptionText = "VGR.DETAIL.DESCRIPTION.TEXT";
    neoVgrCommonMale = "VGR.COMMON.MALE";
    neoVgrCommonShortcutMale = "VGR.COMMON.SHORTCUT.MALE";
    neoVgrCommonFemale = "VGR.COMMON.FEMALE";
    neoVgrCommonShortcutFemale = "VGR.COMMON.SHORTCUT.FEMALE";
    neoVgrCommonUnborn = "VGR.COMMON.UNBORN";
    neoVgrCommonShortcutUnborn = "VGR.COMMON.SHORTCUT.UNBORN";
    neoVgrCommonKVGOnly = "VGR.COMMON.KVGONLY";
    neoVgrCommonErrorTitle = "VGR.COMMON.ERROR.TITLE";
    neoVgrCommonHome = "VGR.COMMON.HOME";
    neoVgrTarifListElementPremium = "VGR.TARIFLISTELEMENT.PREMIUM";
    neoVgrTarifListElementRelevance = "VGR.TARIFLISTELEMENT.RELEVANCE";
    neoVgrTarifAnzeigeFranchise = "VGR.TARIFANZEIGE.FRANCHISE";
    neoVgrPersonenEingabeTitle = "VGR.PERSONENEINGABE.TITLE";
    neoVgrPersonenEingabeSex = "VGR.PERSONENEINGABE.SEX";
    neoVgrVvgFeedbackMax = "VGR.VVG.FEEDBACK.MAX";
    neoVgrVvgFeedbackMiddle = "VGR.VVG.FEEDBACK.MIDDLE";
    neoVgrVvgFeedbackNone = "VGR.VVG.FEEDBACK.NONE";
    neoVgrVvgTitle = "VGR.VVG.TITLE";
    neoVgrVvgDescription = "VGR.VVG.DESCRIPTION";
    neoVgrTarifAnzeigeButtonOffer = "VGR.TARIFANZEIGE.BUTTONOFFER";
    neoVgrTarifAnzeigeButtonDetail =  "VGR.TARIFANZEIGE.BUTTONDETAIL";
    neoVgrContactTitle = "KONTAKTFORMULAR.TITEL.TITEL";
    neoVgrContactSubmit = "KONTAKTFORMULAR.BUTTON.ABSENDEN";
    neoVgrContactTitleTariff = "KONTAKTFORMULAR.TITEL.TARIFF";
    neoVgrContactModalOkTitel = "KONTAKTFORMULAR.MODAL_OK.TITEL";
    neoVgrContactModalOkText = "KONTAKTFORMULAR.MODAL_OK.BESCHREIBUNG";
    neoVgrContactModalErrTitel = "KONTAKTFORMULAR.MODAL_ERR.TITEL";
    neoVgrContactModalErrText = "KONTAKTFORMULAR.MODAL_ERR.BESCHREIBUNG";
    neoVgrTariffListInsurer = "VGR.TARIFANZEIGE.INSURER";
    neoVgrTariffListTotalSum = "VGR.TARIFANZEIGE.TOTAL_SUM";
    neoVgrTariffListPerson = "VGR.TARIFANZEIGE.PERSON";
    neoVgrSmallSearchTitle = "VGR.SMALLSEARCH.TITLE";
    neoVgrSmallSearchNext = "VGR.SMALLSEARCH.NEXT";
    neoVgrMediumSearchTitle = "VGR.SEARCH.MEDIUM.TITLE";
    neoVgrMediumSearchSupplementaryTitle = "VGR.SEARCH.MEDIUM.SUPPLEMENTARY";
    neoVgrMediumSearchAccidentCoverage = "VGR.SEARCH.MEDIUM.ACCIDENT_COVERAGE";
    neoVgrMediumSearchAccidentCoverageInfoTitle = "VGR.SEARCH.MEDIUM.ACCIDENT_COVERAGE_INFO.TITLE";
    neoVgrMediumSearchAccidentCoverageInfoText = "VGR.SEARCH.MEDIUM.ACCIDENT_COVERAGE_INFO.TEXT";
    neoVgrMediumSearchYes = "COMMON.LABEL.YES";
    neoVgrMediumSearchNo = "COMMON.LABEL.NO";
    neoVgrSmallSearchPramieBerechnen = "VGR.SMALLSEARCH.PRAMIEBERECHNEN";
}
