import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-vision-section',
  standalone: true,
  imports: [],
  templateUrl: './vision-section.component.html',
  styleUrl: './vision-section.component.scss'
})
export class VisionSectionComponent {
  @Output() viewInitialized: EventEmitter<void> = new EventEmitter<void>();

  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    this.viewInitialized.emit();
  }

  redirectTo(link: string) {
  }
}
