<div class="mdc-layout-grid neo-banner-grid">
  <div class="mdc-layout-grid__inner neo-banner-grid-inner" >
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-banner-card">
      <div [ngClass]="customCssClass">
        <div class="neo-banner-title">
          <div *ngIf="image" class="neo-grid-component-wrapper-img">
            <img [src]="image" alt="{{alt}}" width="100%" class="neo-banner-img" />
        </div>
        <h2 class="neo-banner-title-header">{{bannerTitle}}</h2>
        </div>
        <div class="neo-banner-description">
          <h3 class="neo-banner-description-header">{{bannerTitleDescription}}</h3>
          <p class="neo-banner-description-p" [innerHTML]="bannerDescription"></p>
        </div>
       
          <div *ngIf="showInnerGrid" class="mdc-layout-grid">
            <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-12-phone  neo-markdown" *ngFor="let data of innerGridData" >
              <p [innerHTML]="data"></p>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>