<div *ngIf="neoLabel && neoLabel.length > 0" class="neo-vgr-input-margin neo-input-text-height">
    {{neoLabel}}
    <span *ngIf="neoRequired === true && neoShowRequired === true" class="neo-contact-form-required">
        *
    </span>
</div>
<mat-form-field class="neo-input-datepicker-form">
    <input matInput
           class="neo-vgr-input-field neo-vgr-input-margin neo-vgr-input-field-text neo-helper-user-select-auto"
           ngClass="{{ showErrorCss ? 'neo-message-error-input':'' }}"
           [matDatepicker]="dp3"
           [placeholder]="neoPlaceholder"
           [value]="neoValue"
           (dateChange)="dateChanged('change', $event)">
    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
    <mat-datepicker #dp3 disabled="false" [startView]="neoStartView" [startAt]="neoValue"></mat-datepicker>
</mat-form-field>