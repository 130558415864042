<div [ngClass]="customCssClass">
<div class="{{elementAsLink === true ? 'neo-grid-component neo-isquare-link' : 'neo-grid-component'}} neo-grid-component{{getColor()}}"
     (click)="openLink($event)">
            <div *ngIf="image" class="neo-grid-component-wrapper-img">
                <img [src]="image" alt="{{alt}}" loading="lazy" width="100%" class="neo-icon" />
            </div>
            <div *ngIf="title" class="neo-grid-component-wrapper-txt">
                <h3 class="neo-grid-component-wrapper-txt-title">{{title}}</h3>
            </div>
            <div *ngIf="description" class="neo-grid-component-wrapper-txt">
                <p class="neo-grid-component-wrapper-txt-p"  [innerHTML]="description"></p>
            </div>  
            <div class="neo-grid-component-wrapper-icon">
                <mat-icon width="15%" class="neo-icon">exit_to_app</mat-icon>
            </div>
</div></div>