import { inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private meta = inject(Meta);
  private titleService = inject(Title);

  constructor() {}

  /**
   * initializes SEO by parsing the provided SEO data
   * @param seoData - structured SEO data from CMS
   */
  public initializeSeo(seoData: any): void {
    if (!seoData || seoData.length === 0) {
        console.warn('No SEO data provided');
        return;
    }

    const pluginSeo = seoData;
    if (pluginSeo) {
        this.updateMetaTags(pluginSeo);
        this.updateSocialTags(pluginSeo.metaSocial);
        this.updateStructuredData(pluginSeo.structuredData);
        if (pluginSeo?.Google) {
            this.setupGoogleAnalytics(pluginSeo?.Google?.googleAnalyticsId);
        }
    } else {
        console.warn('No valid SEO configuration found in pluginSeo');
    }
}


private updateMetaTags(tags: any): void {
  this.titleService.setTitle(tags?.metaTitle || 'Neo Swiss');
  this.meta.updateTag({ name: 'description', content: tags?.metaDescription || 'Default description' });
  this.meta.updateTag({ name: 'keywords', content: tags?.keywords || 'Default keywords' });
  this.meta.updateTag({ name: 'robots', content: tags?.metaRobots || 'index, follow' });
  this.meta.updateTag({ name: 'viewport', content: tags?.metaViewport || 'width=device-width, initial-scale=1' });
  
  if (tags?.canonicalURL) {
      this.meta.updateTag({ name: 'canonical', content: tags.canonicalURL });
  }
  if(tags?.Google?.verificationToken){
    this.meta.updateTag({name: 'google-site-verification', content: tags?.Google?.verificationToken})
  }
}

  private updateSocialTags(metaSocial: any[]): void {
    if (!Array.isArray(metaSocial)) return;

    metaSocial.forEach(social => {
      const network = social.socialNetwork.toLowerCase();
      const imageUrl = social?.image?.data?.attributes?.url || '/default-image.png';
      const fullImageUrl = `${environment.CMS_URL}${imageUrl}`;

      
      this.meta.updateTag({ property: `og:${network}:title`, content: social.title });
      this.meta.updateTag({ property: `og:${network}:description`, content: social.description });
      this.meta.updateTag({ property: `og:${network}:image`, content: fullImageUrl });
    });
  }

  private updateStructuredData(data: any): void {
    if (!data) return;
    const scriptTag = document.createElement('script');
    scriptTag.type = 'application/ld+json';
    scriptTag.text = JSON.stringify(data);
    const existingScript = document.querySelector('script[type="application/ld+json"]');
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    document.head.appendChild(scriptTag);
  }

  private setupGoogleAnalytics(gaId: string): void {
    if (gaId) {
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      document.head.appendChild(gaScript);

      const configScript = document.createElement('script');
      configScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gaId}', {
          'anonymize_ip': true,
          'cookie_flags': 'SameSite=None;Secure'
        });
      `;
      document.head.appendChild(configScript);
    }
  }
}
