<div class="mdc-layout-grid">
    <div class="mdc-layout-grid__inner neo-helper-grid-gap-10">
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <h1 *ngIf="showHeader === true">{{formTexts.titleTitle}}</h1>
        </div>
        <div *ngIf="showText === true"
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            {{formTexts.titleText}}
        </div>
        <div *ngIf="showRequired === true"
            class="neo-contact-form-required mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            * {{formTexts.required}}
        </div>
        <!-- <div
            class="neo-contact-form-inputs mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #vorgesetzter [neoValue]="contactData.supervisor" [neoLabel]="formTexts.supervisor"
                [neoRequired]="true" [neoShowRequired]="true" (neoValueEmitter)="vorgesetzterChanged($event)">
            </neo-input-text>
        </div> -->
       

            <div
                class="neo-contact-form-inputs mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
                <neo-input-text #inputFirstName [neoValue]="contactData.firstName" [neoLabel]="formTexts.name"
                    [neoRequired]="true" [neoShowRequired]="true" (neoValueEmitter)="firstNameChanged($event)">
                </neo-input-text>
            </div>
            <div
                class="neo-contact-form-inputs mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
                <neo-input-text #inputLastName [neoValue]="contactData.lastName" [neoLabel]="formTexts.surname"
                    [neoRequired]="true" [neoShowRequired]="true"
                    (neoValueEmitter)="lastNameChanged($event)">
                </neo-input-text>
            </div>

        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-datepicker #inputDate class="neo-input-datepicker" [neoValue]="contactData.birthday" [neoPlaceholder]="'tt.mm.jjjj'"
                [neoLabel]="formTexts.birthday"
                [neoRequired]="true" [neoShowRequired]="true"
                (neoValueEmitter)="birthdayChanged($event)">
            </neo-input-datepicker>
           
        </div>
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputPhone [neoValue]="contactData.phone" [neoLabel]="formTexts.telefon"
                [neoRequired]="true" [neoShowRequired]="true" (neoValueEmitter)="phoneChanged($event)">
            </neo-input-text>
        </div>
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputEmail [neoValue]="contactData.email" [neoLabel]="formTexts.mail" [neoRequired]="true"
                [neoShowRequired]="true" (neoValueEmitter)="emailChanged($event)">
            </neo-input-text>
        </div>
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputAddress [neoValue]="contactData.address" [neoLabel]="formTexts.address"
                [neoRequired]="true" [neoShowRequired]="true" (neoValueEmitter)="adressChanged($event)">
            </neo-input-text>
        </div>
   
    <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <neo-input-text #inputZipCode [neoValue]="contactData.zip" [neoLabel]="formTexts.zip"
        [neoRequired]="true" [neoShowRequired]="true" (neoValueEmitter)="zipChanged($event)" >
        </neo-input-text>
    </div>
    <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <neo-input-text #inputCity [neoValue]="contactData.town" [neoLabel]="formTexts.city" [neoRequired]="true"
            [neoShowRequired]="true" (neoValueEmitter)="townChanged($event)">
        </neo-input-text>
    </div>
    <div
    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
    <neo-input-text #inputEmployer [neoValue]="contactData.employer" [neoLabel]="formTexts.employer" [neoRequired]="true"
        [neoShowRequired]="true" (neoValueEmitter)="employerChanged($event)">
    </neo-input-text>
    </div>
    <div
    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
    <neo-select-option #inputBillingAddress [neoValue]="contactData.billingAddress" [neoLabel]="formTexts.billingAddress" [neoRequired]="true"
        [neoShowRequired]="true" 
        [neoSelectOptions]="billingAddressOptions.options"
        [neoSelectValues]="billingAddressOptions.values"
        (neoValueEmitter)="billingAddressChanged($event)">
    </neo-select-option>
    </div>
    <div
    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
    <neo-input-text #inputSupervisorEmailAddress [neoValue]="contactData.supervisorEmailAddress" [neoLabel]="formTexts.supervisorEmailAddress" [neoRequired]="true"
        [neoShowRequired]="true" (neoValueEmitter)="supervisorEmailAddressChanged($event)">
    </neo-input-text>
    </div>
    <div
    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
    <neo-input-text #inputSupervisorPhone [neoValue]="contactData.supervisorPhone" [neoLabel]="formTexts.supervisorPhone" [neoRequired]="true"
        [neoShowRequired]="true" (neoValueEmitter)="supervisorPhoneChanged($event)">
    </neo-input-text>
    </div>

    <div
    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
    <neo-input-radio-group #inputExperience [neoValue]="contactData.experience" [neoLabel]="formTexts.experience" [neoRequired]="true"
        [neoShowRequired]="true" [neoRadioValues]="['Ja', 'Nein']" [neoRadioLabels]="[formTexts.yes, formTexts.no]" (neoValueEmitter)="experienceChanged($event)">
    </neo-input-radio-group>
    </div>

    <div *ngIf="showErrorDiv"
    class="neo-message-error neo-contact-form-error mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
   {{formTexts.errorRequiredMissing}}
</div>
<div *ngIf="showSubmit"
    class="neo-contact-form-submit mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
   <neo-input-button [neoLabel]="formTexts.send"
                     (neoValueEmitter)="sendMessage()"
                     [neoButtonCssClasses]="'neo-btn-input neo-btn-input-primary neo-btn-input-end'">
   </neo-input-button>
</div>
</div>
</div>