import {TarifartDescriptionJson} from "../jsons/tarifart-description.json";

export class TarifartDescription {
    id: number;
    key: string;
    data: { [lang: string]: string; } = {};

    constructor() {
    }

    public static fromJson(value: TarifartDescriptionJson): TarifartDescription {
        const result = new TarifartDescription();
        result.id = value.id;
        result.key = value.key;
        value.data.forEach(v => {
            result.data[v.key] = v.value;
        });
        return result;
    }
}
