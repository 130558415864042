<!-- <div class="overlay RotateScreen" *ngIf="ShowRotateScreen">
  <h1 class="rotatescreen-text">
    {{localTranslationClass.getTranslationTexts().VorsorgerechnerROTATE}}
  </h1>
</div> -->

<!-- All page -->

<div class="neo-vsr-container">

  <div class="neo-vsr-main-left-side">
    <div class=" neo-vsr-left-side-height">

      <div class="neo-vsr-content-img">
        <h1 class="neo-vsr-intro neo-vsr-left-title" *ngIf="leftText.introTitle"> {{
          leftText?.introTitle[selectedLanguage] }}</h1>
        <p class="neo-vsr-display-flex" *ngIf="leftText.backgroundImgAlt">
          <span class="neo-vsr-span-content" *ngIf="leftText.introContent">
            <markdown class="neo-markdown" [data]="leftText.introContent[selectedLanguage]"></markdown>
          </span>
        </p>
      </div>
    </div>
  </div>

  <div class="neo-vsr-right">
    <button [ngClass]="{
          neo_vsr_accordion: shouldShow,
          neo_vsr_accordion_active: !shouldShow
        }" id="button1" class="neo_vsr_accordion" *ngIf="vsrStep1.title" (click)="
          updateUrl();
          shouldShow = !shouldShow;
          Step1 = !Step1;
          Step2 = false;
          Step3 = false;
          Step4 = false;
          Step5 = false;
          shouldShow1 = shouldShow2 = shouldShow3 = shouldShow4 = true;
          ShowChart = true;
          ShowDoughnutChart = true;
          ShowChart = true;
          ShowDoughnutChart = true
        ">
      {{ vsrStep1.title[this.selectedLanguage] }}
    </button>

    <!-- Schritt 1: Person -->
    <div *ngIf="Step1 == true">
      <div class="mdc-layout-grid neo-pd0">
        <div class="mdc-layout-grid__inner">
          <div class="
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-12-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
                neo-back-color
              ">
            <div class="mdc-layout-grid neo-vsr-content-step">
              <div class="mdc-layout-grid__inner">
                <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-12-desktop
                      mdc-layout-grid__cell--span-12-tablet
                      mdc-layout-grid__cell--span-12-phone
                    ">
                  <div class="
                        mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                        mdc-layout-grid__cell--span-12-tablet
                        mdc-layout-grid__cell--span-12-phone
                        neo-vsr-schritt1
                      ">
                    <!-- Schritt 1- alter -->
                    <div class="
                          mdc-layout-grid__cell
                          mdc-layout-grid__cell--span-4-desktop
                          mdc-layout-grid__cell--span-4-tablet
                          mdc-layout-grid__cell--span-12-phone
                          neo-vsr-alter
                        ">
                      <div class="neo-pdtop-10">
                        <!-- TODO this could be a bug -->
                        <label for="staticEmail" *ngIf="vsrStep1.yearsText">{{
                          vsrStep1.yearsText[this.selectedLanguage]
                          }}</label>
                      </div>
                      <div class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-4-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-pdtop-10
                          ">
                        <neo-input-number min="18" max="99" (neoValueEmitter)="alterChanged($event)"
                          [neoValue]="data.person.age">
                        </neo-input-number>
                      </div>
                    </div>

                    <!-- Schritt 1- jahreseinkommen -->
                    <div class="
                          mdc-layout-grid__cell
                          mdc-layout-grid__cell--span-4-desktop
                          mdc-layout-grid__cell--span-4-tablet
                          mdc-layout-grid__cell--span-12-phone
                          neo-mg-0 neo-pdtop-10 neo-vsr-jahreseinkommen
                        ">
                      <div class="neo-vsr-jahreseinkommen-title neo-vsr-jahreseinkommen-title-step-one neo-pd0">
                        <label class="neo-vsr-jahreseinkommen-title-label" *ngIf="vsrStep1.avgyearIncomeText">{{
                          vsrStep1.avgyearIncomeText[this.selectedLanguage]
                          }}</label>

                        <i class="material-icons neo-recommendation-info" (click)="
                              openDialog(vsrStep1.dialogInfoTitle[this.selectedLanguage], vsrStep1.dialogInfoDescription[this.selectedLanguage]
                              )
                            ">info_outline</i>
                      </div>
                      <div class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-4-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-pdtop-10
                          ">
                        <neo-input-text min="10" (neoValueEmitter)="yearlyIncomeChanged($event)"
                          [neoValue]="PersonYearlyIncomeTmp">
                        </neo-input-text>

                        <label *ngIf="data.person.avgYearlyIncome < 14220" class="">{{
                          vsrStep1.avgyearIncomeUnderlimitText[this.selectedLanguage]
                          }}</label>

                        <!-- 21330 -->
                        <!-- TODO this could be a bug -->
                        <mat-slider type="range" min="14300" max="200000" step="100"
                          [(ngModel)]="data.person.avgYearlyIncome" class="neo-service-slider-track neo-mat-slider"
                          id="myRange" ngDefaultControl>
                          <input matSliderThumb [value]="data.person.avgYearlyIncome"
                            (input)="setPersonYearlyIncome($event.target.value); updateUrl()" (change)="CheckRequired1(); setPersonYearlyIncome($event.target.value);
                            updateUrl()" />
                        </mat-slider>
                      </div>
                    </div>

                    <!-- Schritt 1- familienstand -->
                    <div class="
                          mdc-layout-grid__cell
                          mdc-layout-grid__cell--span-4-desktop
                          mdc-layout-grid__cell--span-4-tablet
                          mdc-layout-grid__cell--span-12-phone
                          neo-vsr-familienstand neo-vsr-width
                        ">
                      <div class="neo-pdtop-10 ">
                        <!-- TODO this could be a bug -->
                        <label for="inputPassword" *ngIf="vsrStep1.martialStatusText">{{
                          vsrStep1.martialStatusText[selectedLanguage]
                          }}</label>
                      </div>
                      <div class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-4-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-pdtop-10
                          ">
                        <mat-select disableOptionCentering class="neo-vsr-dropdown"
                          [(ngModel)]="data.person.maritalStatus" (selectionChange)="updateUrl()">
                          <mat-select-trigger class="neo-vsr-dropdown-select-trigger">
                            {{ data.person.maritalStatus }}
                          </mat-select-trigger>

                          <mat-option [value]="vsrStep1.singleText[this.selectedLanguage]"
                            *ngIf="vsrStep1.singleText">{{
                            vsrStep1.singleText[this.selectedLanguage]
                            }}</mat-option>
                          <mat-option [value]="vsrStep1.marriedText[this.selectedLanguage]"
                            *ngIf="vsrStep1.marriedText">{{
                            vsrStep1.marriedText[this.selectedLanguage]
                            }}</mat-option>
                          <mat-option [value]="vsrStep1.widowerText[this.selectedLanguage]"
                            *ngIf="vsrStep1.widowerText">{{
                            vsrStep1.widowerText[this.selectedLanguage]
                            }}</mat-option>
                        </mat-select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Schritt-1 Kinder -->

                <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-12-desktop
                      mdc-layout-grid__cell--span-12-tablet
                      mdc-layout-grid__cell--span-12-phone
                      neo-vsr-kinder
                    ">
                  <table class="
                        mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                        mdc-layout-grid__cell--span-12-tablet
                        mdc-layout-grid__cell--span-12-phone
                        neo-vsr-kinder neo-vsr-kinder-table
                      ">
                    <tbody>
                      <tr class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-vsr-kinder neo-vsr-kinder-table-trow
                          ">
                        <td>
                          <label class="neo-vsr-kinder-text" *ngIf="vsrStep1.childrenText">{{
                            vsrStep1.childrenText[this.selectedLanguage]
                            }}</label>
                        </td>
                        <td class="neo-vsr-flex">
                          <div class="neo-vsr-flex">

                            <div class="
                                  mdc-layout-grid
                                  neo-vsr-grid-layout neo-pd0
                                ">
                              <div class="
                                    mdc-layout-grid__inner
                                    neo-vsr-grid-inner-layout neo-vsr-grid-kinder
                                  ">
                                <table class="

                                      mdc-layout-grid__cell--span-12-desktop
                                      mdc-layout-grid__cell--span-12-tablet
                                      mdc-layout-grid__cell--span-12-phone
                                      neo-vsr-kinder neo-vsr-child-table
                                    ">
                                  <tbody>
                                    <tr class="

                                          mdc-layout-grid__cell--span-4-desktop
                                          mdc-layout-grid__cell--span-12-tablet
                                          mdc-layout-grid__cell--span-12-phone
                                          neo-vsr-child-table-trow
                                        " *ngFor="
                                          let child of data.children;
                                          index as i
                                        ">
                                      <div class="neo-vsr-child">
                                        <td class="neo-vsr-child-td" *ngIf="vsrStep1.ChildText">
                                          {{
                                          vsrStep1.ChildText[
                                          this.selectedLanguage
                                          ]
                                          }}
                                          {{ i + 1 }}
                                        </td>
                                        <td>
                                          <mat-icon _ngcontent-c32="" (click)="RemoveChildren(child)" class="
                                                neo-vertical neo-mat-icon
                                                material-icons
                                                neo-mat-icon-close
                                                neo-margin-right-30
                                              " role="img" aria-hidden="true">close
                                          </mat-icon>
                                        </td>
                                      </div>
                                      <div class="neo-vsr-kinder-alter">
                                        <td class="neo-vsr-kinder-alter-td">

                                          <neo-input-number min="0" max="18" (neoValueEmitter)="
                                                childChanged($event, i)
                                              " [neoValue]="child.years"
                                            [neoPlaceholder]="vsrStep1.yearsText[this.selectedLanguage]"></neo-input-number>
                                        </td>
                                      </div>
                                    </tr>
                                    <div class="neo-vsr-child-add">
                                      <mat-icon _ngcontent-c32="" (click)="AddtoChildren()"
                                        aria-label="Example icon-button with a heart icon" class="
                                            neo-mat-icon
                                            material-icons
                                            neo-person-add neo-float-right
                                          " role="img" aria-hidden="true">person_add
                                      </mat-icon>
                                      <label (click)="AddtoChildren()" class="neo-float-right neo-person-add-text"
                                        *ngIf="vsrStep1.addChildText">
                                        {{
                                        vsrStep1.addChildText[
                                        this.selectedLanguage
                                        ]
                                        }}
                                      </label>
                                    </div>

                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Schritt-2: Partner -->
    <i class=""></i><button [ngClass]="{
          neo_vsr_accordion: shouldShow1,
          neo_vsr_accordion_active: !shouldShow1
        }" class="neo_vsr_accordion" (click)="
          updateUrl();
          shouldShow1 = !shouldShow1;
          Step1 = false;
          Step2 = !Step2;
          Step3 = false;
          Step4 = false;
          Step5 = false;
          shouldShow = shouldShow2 = shouldShow3 = shouldShow4 = true;
          ShowChart = true;
          ShowDoughnutChart = true
        " *ngIf="
        data.person.maritalStatus == 'Married' || data.person.maritalStatus == 'Sposato' || data.person.maritalStatus == 'Marié' ||
          (data.person.maritalStatus == 'Verheiratet' && vsrStep2.title)
        ">
      {{ vsrStep2?.title[this.selectedLanguage] }}
    </button>
    <div *ngIf="Step2 == true">
      <div class="mdc-layout-grid neo-pd0">
        <div class="mdc-layout-grid__inner">
          <div class="
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-12-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
                mdc-layout-grid
                neo-back-color
              ">
            <div class="mdc-layout-grid neo-vsr-content-step">
              <div class="mdc-layout-grid__inner neo-vsr-partner-grid">
                <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-9-desktop
                      mdc-layout-grid__cell--span-12-tablet
                      mdc-layout-grid__cell--span-12-phone
                      neo-vsr-schritt-partner
                    ">
                  <div class="
                        mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-4-desktop
                        mdc-layout-grid__cell--span-4-tablet
                        mdc-layout-grid__cell--span-12-phone
                        neo-pdtop-10 neo-vsr-partner
                      ">
                    <!-- TODO this could be a bug -->
                    <div class="neo-vsr-partner-alter">
                      <label for="staticEmail" *ngIf="vsrStep2.yearsText">{{
                        vsrStep2.yearsText[this.selectedLanguage]
                        }}</label>
                    </div>
                    <neo-input-number min="18" max="99" (neoValueEmitter)="alterpartnerChanged($event)"
                      [neoValue]="data.partner.age"></neo-input-number>
                  </div>
                  <div class="
                        mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-4-desktop
                        mdc-layout-grid__cell--span-4-tablet
                        mdc-layout-grid__cell--span-12-phone
                        neo-vsr-partner
                      ">
                    <div class="neo-vsr-jahreseinkommen-title">
                      <!-- TODO this could be a bug -->
                      <label class="neo-vsr-jahreseinkommen-title-label" for="staticEmail"
                        *ngIf="vsrStep2.avgyearIncomeText">{{
                        vsrStep2.avgyearIncomeText[this.selectedLanguage]
                        }}</label>

                      <i class="material-icons neo-recommendation-info" (click)="
                          openDialog(vsrStep2.dialogInfoTitle[this.selectedLanguage], vsrStep2.dialogInfoDescription[this.selectedLanguage]
                            )
                          ">info_outline</i>
                    </div>
                    <neo-input-text min="10" (neoValueEmitter)="partnerYearylyIncomeChanged($event)"
                      [neoValue]="PartnerYearlyIncomeTmp"></neo-input-text>

                    <mat-slider type="range" min="0" max="200000" step="100" [(ngModel)]="data.partner.avgYearlyIncome"
                      class="neo-service-slider-track neo-mat-slider" id="myRange" ngDefaultControl>
                      <input matSliderThumb [value]="data.partner.avgYearlyIncome" (input)="
                          setPartnerYearlyIncomeThousand($event.target.value);
                          updateUrl()" (change)="
                          CheckRequired2();
                          setPartnerYearlyIncomeThousand($event.target.value);
                          updateUrl()" />
                    </mat-slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Schritt 3 -->
    <button [ngClass]="{
          neo_vsr_accordion: shouldShow2,
          neo_vsr_accordion_active: !shouldShow2
        }" class="neo_vsr_accordion" (click)="
          updateUrl();
          shouldShow2 = !shouldShow2;
          Step1 = false;
          Step2 = false;
          Step3 = !Step3;
          Step4 = false;
          Step5 = false;
          shouldShow = shouldShow1 = shouldShow3 = shouldShow4 = true;
          ShowChart = true;
          ShowDoughnutChart = true;
          ExecuteFunctions.tod_durch_unfall =
          ExecuteFunctions.tod_durch_krankheit =
          ExecuteFunctions.invalid_durch_unfall =
          ExecuteFunctions.invalid_durch_krankheit =
          ExecuteFunctions.Alter =
          false;
          SelectAllSelected = false;
          this.TDUD = this.TDKD = this.IDUD = this.IDKD = this.ALTERD = false;
        " *ngIf="vsrStep3.title">
      {{ vsrStep3.title[this.selectedLanguage] }}
    </button>
    <div *ngIf="Step3 == true">
      <div class="mdc-layout-grid neo-pd0">
        <div class="mdc-layout-grid__inner">
          <div class="
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-12-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
                mdc-layout-grid
                neo-pd0
              ">
            <div class="mdc-layout-grid neo-vsr-schritt3">
              <div class="mdc-layout-grid__inner neo-vsr-schritt3-mat-card">
                <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-12-desktop
                      mdc-layout-grid__cell--span-12-tablet
                      mdc-layout-grid__cell--span-12-phone
                    ">
                  <p *ngIf="vsrStep3.introHeadline">
                    {{ vsrStep3.introHeadline[selectedLanguage] }}
                  </p>
                  <div class="
                        mdc-layout-grid
                        neo-pd-left-0 neo-padding-right-0 neo-pd-bottom-0
                      ">
                    <div class="mdc-layout-grid__inner neo-vsr-mat-card">
                      <div class="
                          mdc-layout-grid__cell
                          mdc-layout-grid__cell--span-4-desktop
                          mdc-layout-grid__cell--span-4-tablet
                          mdc-layout-grid__cell--span-12-phone
                        ">
                        <mat-card appearance="outlined" class="neo-mat-card">
                          <mat-card-header class="neo-mat-card-header">
                            <mat-card-title>
                              <p class="neo-titleschrite">
                                {{ vsrMatCard[5].title[selectedLanguage] }}
                              </p>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <p class="neo-mat-card-content">
                              {{ vsrMatCard[5].content[selectedLanguage] }}
                            </p>
                          </mat-card-content>
                          <mat-card-actions class="neo-mat-card-actions">
                            <button class="neo-btn-options-unactive" *ngIf="
                                !SelectAllSelected ||
                                ExecuteFunctions.tod_durch_krankheit ==
                                  false ||
                                  ExecuteFunctions.invalid_durch_unfall ==
                                    false ||
                                  ExecuteFunctions.invalid_durch_krankheit ==
                                    false ||
                                  ExecuteFunctions.Alter == false
                              " [hidden]="
                                SelectAllSelected ||
                                (ExecuteFunctions.tod_durch_krankheit ==
                                  true &&
                                  ExecuteFunctions.invalid_durch_unfall ==
                                    true &&
                                  ExecuteFunctions.invalid_durch_krankheit ==
                                    true &&
                                  ExecuteFunctions.Alter == true)
                              " (click)="
                                ExecuteFunctions.tod_durch_unfall =
                                  ExecuteFunctions.tod_durch_krankheit =
                                  ExecuteFunctions.invalid_durch_unfall =
                                  ExecuteFunctions.invalid_durch_krankheit =
                                  ExecuteFunctions.Alter =
                                    true;
                                SelectAllSelected = !SelectAllSelected;
                                Step4 = true
                              ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>

                            <button class="neo-btn-options-active" *ngIf="
                                SelectAllSelected ||
                                (ExecuteFunctions.tod_durch_krankheit ==
                                  true &&
                                  ExecuteFunctions.invalid_durch_unfall ==
                                    true &&
                                  ExecuteFunctions.invalid_durch_krankheit ==
                                    true &&
                                  ExecuteFunctions.Alter == true)
                              " (click)="
                                ExecuteFunctions.tod_durch_unfall =
                                  ExecuteFunctions.tod_durch_krankheit =
                                  ExecuteFunctions.invalid_durch_unfall =
                                  ExecuteFunctions.invalid_durch_krankheit =
                                  ExecuteFunctions.Alter =
                                    false;
                                SelectAllSelected = false;
                                Step4 = false
                              ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                          </mat-card-actions>
                        </mat-card>
                      </div>
                      <div class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-4-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-divbutton
                          ">
                        <mat-card appearance="outlined" class="neo-mat-card">
                          <mat-card-header class="neo-mat-card-header">
                            <mat-card-title>
                              <p class="neo-titleschrite" *ngIf="vsrMatCard[0].title">
                                {{ vsrMatCard[0].title[selectedLanguage] }}
                              </p>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <p class="neo-mat-card-content">
                              {{ vsrMatCard[0].content[selectedLanguage] }}
                            </p>
                          </mat-card-content>
                          <mat-card-actions class="neo-mat-card-actions">
                            <button class="neo-btn-options-unactive" *ngIf="!ExecuteFunctions.tod_durch_unfall" (click)="
                                  ExecuteFunctions.tod_durch_unfall =
                                    !ExecuteFunctions.tod_durch_unfall;
                                  Step4 = true
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>

                            <button class="neo-btn-options-active" *ngIf="ExecuteFunctions.tod_durch_unfall" (click)="
                                  ExecuteFunctions.tod_durch_unfall =
                                    !ExecuteFunctions.tod_durch_unfall;
                                  SelectAllSelected = false
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                          </mat-card-actions>
                        </mat-card>
                      </div>
                      <div class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-4-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-divbutton
                          ">
                        <mat-card appearance="outlined" class="neo-mat-card">
                          <mat-card-header class="neo-mat-card-header">
                            <mat-card-title>
                              <p class="neo-titleschrite">
                                {{ vsrMatCard[1].title[selectedLanguage] }}
                              </p>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <p class="neo-mat-card-content">
                              {{ vsrMatCard[1].content[selectedLanguage] }}
                            </p>
                          </mat-card-content>
                          <mat-card-actions class="neo-mat-card-actions">
                            <button class="neo-btn-options-unactive" *ngIf="!ExecuteFunctions.tod_durch_krankheit"
                              (click)="
                                  ExecuteFunctions.tod_durch_krankheit =
                                    !ExecuteFunctions.tod_durch_krankheit;
                                  Step4 = true
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                            <button class="neo-btn-options-active" *ngIf="ExecuteFunctions.tod_durch_krankheit" (click)="
                                  ExecuteFunctions.tod_durch_krankheit =
                                    !ExecuteFunctions.tod_durch_krankheit;
                                  SelectAllSelected = false
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                          </mat-card-actions>
                        </mat-card>
                      </div>
                      <div class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-4-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-divbutton
                          ">
                        <mat-card appearance="outlined" class="neo-mat-card">
                          <mat-card-header class="neo-mat-card-header">
                            <mat-card-title>
                              <p class="neo-titleschrite">
                                {{ vsrMatCard[2].title[selectedLanguage] }}
                              </p>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <p class="neo-mat-card-content">
                              {{ vsrMatCard[2].content[selectedLanguage] }}
                            </p>
                          </mat-card-content>
                          <mat-card-actions class="neo-mat-card-actions">
                            <button class="neo-btn-options-unactive" *ngIf="!ExecuteFunctions.invalid_durch_unfall"
                              (click)="
                                  ExecuteFunctions.invalid_durch_unfall =
                                    !ExecuteFunctions.invalid_durch_unfall;
                                  Step4 = true
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                            <button class="neo-btn-options-active" *ngIf="ExecuteFunctions.invalid_durch_unfall"
                              (click)="
                                  ExecuteFunctions.invalid_durch_unfall =
                                    !ExecuteFunctions.invalid_durch_unfall;
                                  SelectAllSelected = false
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                          </mat-card-actions>
                        </mat-card>
                      </div>
                      <div class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-4-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-divbutton
                          ">
                        <mat-card appearance="outlined" class="neo-mat-card">
                          <mat-card-header class="neo-mat-card-header">
                            <mat-card-title>
                              <p class="neo-titleschrite">
                                {{ vsrMatCard[3].title[selectedLanguage] }}
                              </p>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <p class="neo-mat-card-content">
                              {{ vsrMatCard[3].content[selectedLanguage] }}
                            </p>
                          </mat-card-content>
                          <mat-card-actions class="neo-mat-card-actions">
                            <button class="neo-btn-options-unactive" *ngIf="
                                  !ExecuteFunctions.invalid_durch_krankheit
                                " (click)="
                                  ExecuteFunctions.invalid_durch_krankheit =
                                    !ExecuteFunctions.invalid_durch_krankheit;
                                  Step4 = true
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                            <button class="neo-btn-options-active" *ngIf="ExecuteFunctions.invalid_durch_krankheit"
                              (click)="
                                  ExecuteFunctions.invalid_durch_krankheit =
                                    !ExecuteFunctions.invalid_durch_krankheit;
                                  SelectAllSelected = false
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                          </mat-card-actions>
                        </mat-card>
                      </div>
                      <div class="
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-4-tablet
                            mdc-layout-grid__cell--span-12-phone
                            neo-divbutton
                          ">
                        <mat-card appearance="outlined" class="neo-mat-card">
                          <mat-card-header class="neo-mat-card-header">
                            <mat-card-title>
                              <p class="neo-titleschrite">
                                {{ vsrMatCard[4].title[selectedLanguage] }}
                              </p>
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <p class="neo-mat-card-content">
                              {{ vsrMatCard[4].content[selectedLanguage] }}
                            </p>
                          </mat-card-content>
                          <mat-card-actions class="neo-mat-card-actions">
                            <button class="neo-btn-options-unactive" *ngIf="!ExecuteFunctions.Alter" (click)="
                                  ExecuteFunctions.Alter =
                                    !ExecuteFunctions.Alter;
                                  Step4 = true
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                            <button class="neo-btn-options-active" *ngIf="ExecuteFunctions.Alter" (click)="
                                  ExecuteFunctions.Alter =
                                    !ExecuteFunctions.Alter;
                                  SelectAllSelected = false
                                ">
                              <div class="neo-text-button" *ngIf="vsrStep3.chooseButton">
                                {{
                                vsrStep3.chooseButton[this.selectedLanguage]
                                }}
                              </div>
                            </button>
                          </mat-card-actions>
                        </mat-card>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-10-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
                mdc-layout-grid
                neo-pd0 neo-float-right
              "></div>
          <div class="
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-2-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
                mdc-layout-grid
                neo-pd0 neo-float-right
              ">
            <button class="neo-btn-ausfuhren-active" *ngIf="
                  ExecuteFunctions.tod_durch_unfall == true ||
                  ExecuteFunctions.tod_durch_krankheit == true ||
                  ExecuteFunctions.invalid_durch_unfall == true ||
                  ExecuteFunctions.invalid_durch_krankheit == true ||
                  ExecuteFunctions.Alter == true
                " (click)="ExecuteButton()" mat-button>
              <span class="neovsr-executeButton" *ngIf="vsrStep3.executeButton">{{
                vsrStep3.executeButton[this.selectedLanguage] }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button [ngClass]="{
          neo_vsr_accordion_schritt4: shouldShow3,
          neo_vsr_accordion_schritt4_active: !shouldShow3
        }" class="neo_vsr_accordion_schritt4" *ngIf="
          ExecuteFunctions.tod_durch_unfall == true ||
          ExecuteFunctions.tod_durch_krankheit == true ||
          ExecuteFunctions.invalid_durch_unfall == true ||
          ExecuteFunctions.invalid_durch_krankheit == true ||
          (ExecuteFunctions.Alter == true && vsrStep4.title)
        ">
      {{ vsrStep4.title[this.selectedLanguage] }}
    </button>

    <div *ngIf="
          (ExecuteFunctions.tod_durch_unfall == true ||
            ExecuteFunctions.tod_durch_krankheit == true ||
            ExecuteFunctions.invalid_durch_unfall == true ||
            ExecuteFunctions.invalid_durch_krankheit == true ||
            ExecuteFunctions.Alter == true) &&
          Step4 == true
        ">
      <div class="mdc-layout-grid neo-pdtop-0 neo-padding-right-0 neo-pd-left-0">
        <div class="mdc-layout-grid__inner">
          <div class="
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-10-desktop
                mdc-layout-grid__cell--span-4-tablet
                mdc-layout-grid__cell--span-12-phone
                fright
              "></div>
          <div *ngIf="!ShowTwoButtons" class="
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-2-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
                fright
              "></div>
          <div *ngIf="ShowTwoButtons" class="
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-12-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
                fright
                neo-mg-top-20
              ">
            <div class="mdc-layout-grid neo-pd0">
              <div class="mdc-layout-grid__inner neo-vsr-showtwobuttons">
                <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-12-desktop
                      mdc-layout-grid__cell--span-12-tablet
                      mdc-layout-grid__cell--span-12-phone
                      neo-vsr-showtwobuttons-toggle
                    ">
                  <div>
                    <h2 class="neo-vsr-showtwobuttons-toggle-txt" *ngIf="!isToggled && vsrStep4.togglebuttonCharts">
                      {{ vsrStep4.togglebuttonCharts[this.selectedLanguage] }}
                    </h2>
                    <h2 class="neo-vsr-showtwobuttons-toggle-txt" *ngIf="isToggled && vsrStep4.togglebuttonOverview">
                      {{
                      vsrStep4.togglebuttonOverview[this.selectedLanguage]
                      }}
                    </h2>
                  </div>
                  <div>
                    <button class="neo-vsr-showtwobuttons-toggle-click" (click)="toggleButton()" color="accent">
                      <mat-slide-toggle [checked]="true" color="primary" class="neo-charts-toggle-button">
                      </mat-slide-toggle>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Bar Charts-->
      <div class="neo-dsp-grid neo-vsr-barchart" [ngStyle]="{ height: ShowDoughnutChart == true ? '10px' : '100%' }">
        <div class="
              mdc-layout-grid
              neo-pdtop-10
              neo-padding-right-0
              neo-pd-left-0
              neo-pd0
            ">
          <div class="mdc-layout-grid__inner neo-pd0">
            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10 neo-pd0
                ">
              <!-- TODO this could be a bug -->

              <div [ngClass]="!shouldShowTDUTITLE ? 'height-chart-deactive' : 'height-chart-active'" id="content"
                *ngIf="ExecuteFunctions.tod_durch_unfall == true">
                <button [ngClass]="{
                      neo_vsr_accordion_title_active: shouldShowTDUTITLE,
                      neo_vsr_accordion_title: !shouldShowTDUTITLE
                    }" *ngIf="TDUD" (click)="
                      TDUCHART = !TDUCHART;
                      shouldShowTDUTITLE = !shouldShowTDUTITLE
                    ">
                  {{ vsrStep4.chartTitle[0][this.selectedLanguage] }}
                </button>


                <div [ngClass]="TDUCHART ? 'showChart' : 'hideChart'" class="chart-container">
                  <p *ngIf="TDUD && TDUCHART == true" class="neo-vsr-chart-des">
                    {{this.vsrStep4.chartHeadline[this.selectedLanguage]}} </p>
                  <canvas class="canvo" #TDU [hidden]="ShowDoughnutChart"></canvas>
                  <div class="neo-vsr-btn-contact" *ngIf="TDUD && TDUCHART == true">
                    <button routerLink="/vorsorgerechner/contact/{{
                          urlProvider.getCompressedData()
                        }}" class="neo-btn-MehrInformationen-chart">
                      <span class="neo-vsr-span-button neovsr-executeButton"
                        *ngIf="vsrStep4.contactButtonText">{{vsrStep4.contactButtonText[this.selectedLanguage]
                        }}</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="TDUD && TDUCHART == true" [ngClass]="{
                      neo_vsr_accordion_tdud: shouldShowTDUMD,
                      neo_vsr_accordion_tdud_active: !shouldShowTDUMD
                    }" class="neo_vsr_accordion_tdud">
                  <div class="neo_vsr_accordion_mehr" (click)="
                        shouldShowTDUMD = !shouldShowTDUMD;
                        TDUMEHR = !TDUMEHR;
                        TDUMD = !TDUMD
                      ">
                    <p class="neo-mehr-details-header" *ngIf="TDUMD">
                      {{
                      vsrStep4.moredetailsButtonText[this.selectedLanguage]
                      }}
                    </p>
                  </div>

                  <div class="neo-weniger-details" *ngIf="!TDUMD">
                    <div class="neo-weniger-details-header">
                      <div class="neo-mehr-weniger-details-txt" (click)="
                            shouldShowTDUMD = !shouldShowTDUMD;
                            TDUMEHR = !TDUMEHR;
                            TDUMD = !TDUMD
                          ">
                        <label class="neo-vsr-label-less-details">{{
                          vsrStep4.lessdetailsButtonText[
                          this.selectedLanguage
                          ]
                          }}<img src="/assets/images/up.svg" /></label>
                      </div>
                      <div>
                        <mat-form-field class="neo-vsr-mat-form-field">
                          <mat-select [(value)]="neovsrMatFormFieldTduSelect"
                            placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                            (selectionChange)="compareTDU(neovsrMatFormFieldTduSelect)">
                            <mat-option *ngFor="let item of result1TDU; let i = index" [value]="i">
                              {{ item }}</mat-option>
                          </mat-select>
                        </mat-form-field>

                      </div>

                    </div>

                    <div *ngIf="TDUMEHR">
                      <div class="neo-mehr-list">
                        <label class="mehrlist neo-bd-red" *ngIf="
                              result2TDU.aktuellesinkomen !== undefined &&
                              result2TDU.aktuellesinkomen !== null
                            ">{{ namedatasets.aktuellesname
                          }}<label>{{
                            result2TDU.aktuellesinkomen
                            }}</label></label>
                        <label class="mehrlist neo-bd-white" *ngIf="
                              result2TDU.ahv !== undefined &&
                              result2TDU.ahv !== null
                            ">
                          {{ namedatasets.ahvname
                          }}<label>{{ result2TDU.ahv }}</label></label>

                        <label class="mehrlist neo-bd-lightblue" *ngIf="
                              result2TDU.bvg !== undefined &&
                              result2TDU.bvg !== null
                            ">
                          {{ namedatasets.bvgname
                          }}<label>{{ result2TDU.bvg }}</label></label>
                        <label class="mehrlist neo-bd-grey" *ngIf="
                              result2TDU.uvg !== undefined &&
                              result2TDU.uvg !== null
                            ">{{ namedatasets.uvgname
                          }}<label>{{ result2TDU.uvg }}</label></label>
                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2TDU.einkommen !== undefined &&
                              result2TDU.einkommen !== null
                            ">{{ namedatasets.einkommen
                          }}<label>{{ result2TDU.einkommen }}</label></label>

                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2TDU.ktg !== undefined &&
                              result2TDU.ktg !== null
                            ">{{ namedatasets.ktg
                          }}<label>{{ result2TDU.ktg }}</label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="
              mdc-layout-grid
              neo-pdtop-10 neo-padding-right-0 neo-pd-left-0
            ">
          <div class="mdc-layout-grid__inner">
            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10
                ">
              <!-- TODO this could be a bug -->
              <div [ngClass]="!shouldShowTDKTITLE ? 'height-chart-deactive' : 'height-chart-active'" id="content"
                *ngIf="ExecuteFunctions.tod_durch_krankheit == true">
                <button [ngClass]="{
                      neo_vsr_accordion_title_active: shouldShowTDKTITLE,
                      neo_vsr_accordion_title: !shouldShowTDKTITLE
                    }" *ngIf="TDUD" (click)="
                      TDKCHART = !TDKCHART;
                      shouldShowTDKTITLE = !shouldShowTDKTITLE
                    ">
                  {{ vsrStep4.chartTitle[1][this.selectedLanguage] }}
                </button>

                <div [ngClass]="TDKCHART ? 'showChart' : 'hideChart'" class="chart-container">
                  <p *ngIf="TDKD && TDKCHART == true" class="neo-vsr-chart-des">
                    {{this.vsrStep4.chartHeadline[this.selectedLanguage]}} </p>
                  <canvas class="canvo" #TDK [hidden]="ShowDoughnutChart"></canvas>
                  <div class="neo-vsr-btn-contact" *ngIf="TDKD && TDKCHART == true">
                    <button routerLink="/vorsorgerechner/contact/{{
                          urlProvider.getCompressedData()
                        }}" class="neo-btn-MehrInformationen-chart">
                      <span class="neo-vsr-span-button neovsr-executeButton"
                        *ngIf="vsrStep4.contactButtonText">{{vsrStep4.contactButtonText[this.selectedLanguage]
                        }}</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="TDKD && TDKCHART == true" [ngClass]="{
                      neo_vsr_accordion_tdud: shouldShowTDKMD,
                      neo_vsr_accordion_tdud_active: !shouldShowTDKMD
                    }" class="neo_vsr_accordion_tdud">
                  <div class="neo_vsr_accordion_mehr" (click)="
                        shouldShowTDKMD = !shouldShowTDKMD;
                        TDKMEHR = !TDKMEHR;
                        TDKMD = !TDKMD
                      ">
                    <p class="neo-mehr-details-header" *ngIf="TDKMD">
                      {{
                      vsrStep4.moredetailsButtonText[this.selectedLanguage]
                      }}
                    </p>
                  </div>

                  <div class="neo-weniger-details" *ngIf="!TDKMD">
                    <div class="neo-weniger-details-header">
                      <div class="neo-mehr-weniger-details-txt" (click)="
                            shouldShowTDKMD = !shouldShowTDKMD;
                            TDKMEHR = !TDKMEHR;
                            TDKMD = !TDKMD
                          ">
                        <label class="neo-vsr-label-less-details">{{
                          vsrStep4.lessdetailsButtonText[
                          this.selectedLanguage
                          ]
                          }}<img src="/assets/images/up.svg" /></label>
                      </div>
                      <div>
                        <mat-form-field class="neo-vsr-mat-form-field">
                          <mat-select [(value)]="neovsrMatFormFieldTdkSelect"
                            placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                            (selectionChange)="compareTDK(neovsrMatFormFieldTdkSelect)">
                            <mat-option *ngFor="let item of result1TDK; let i = index" [value]="i">
                              {{ item }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="TDKMEHR">
                      <div class="neo-mehr-list">
                        <label class="mehrlist neo-bd-red" *ngIf="
                              result2TDK.aktuellesinkomen !== undefined &&
                              result2TDK.aktuellesinkomen !== null
                            ">{{ namedatasets.aktuellesname
                          }}<label>{{
                            result2TDK.aktuellesinkomen
                            }}</label></label>
                        <label class="mehrlist neo-bd-white" *ngIf="
                              result2TDK.ahv !== undefined &&
                              result2TDK.ahv !== null
                            ">
                          {{ namedatasets.ahvname
                          }}<label>{{ result2TDK.ahv }}</label></label>

                        <label class="mehrlist neo-bd-lightblue" *ngIf="
                              result2TDK.bvg !== undefined &&
                              result2TDK.bvg !== null
                            ">
                          {{ namedatasets.bvgname
                          }}<label>{{ result2TDK.bvg }}</label></label>
                        <label class="mehrlist neo-bd-grey" *ngIf="
                              result2TDK.uvg !== undefined &&
                              result2TDK.uvg !== null
                            ">{{ namedatasets.uvgname
                          }}<label>{{ result2TDK.uvg }}</label></label>
                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2TDK.einkommen !== undefined &&
                              result2TDK.einkommen !== null
                            ">{{ namedatasets.einkommen
                          }}<label>{{ result2TDK.einkommen }}</label></label>

                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2TDK.ktg !== undefined &&
                              result2TDK.ktg !== null
                            ">{{ namedatasets.ktg
                          }}<label>{{ result2TDK.ktg }}</label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="
              mdc-layout-grid
              neo-pdtop-10 neo-padding-right-0 neo-pd-left-0
            ">
          <div class="mdc-layout-grid__inner">
            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10
                ">
              <!-- TODO this could be a bug -->
              <div [ngClass]="!shouldShowIDUTITLE ? 'height-chart-deactive' : 'height-chart-active'" id="content"
                *ngIf="ExecuteFunctions.invalid_durch_unfall == true">
                <button [ngClass]="{
                      neo_vsr_accordion_title_active: shouldShowIDUTITLE,
                      neo_vsr_accordion_title: !shouldShowIDUTITLE
                    }" *ngIf="IDUD" (click)="
                      IDUCHART = !IDUCHART;
                      shouldShowIDUTITLE = !shouldShowIDUTITLE
                    ">
                  {{ vsrStep4.chartTitle[2][this.selectedLanguage] }}
                </button>

                <div [ngClass]="IDUCHART ? 'showChart' : 'hideChart'" class="chart-container">
                  <p *ngIf="IDUD && IDUCHART == true" class="neo-vsr-chart-des">
                    {{this.vsrStep4.chartHeadline[this.selectedLanguage]}} </p>
                  <canvas class="canvo" #IDU [hidden]="ShowDoughnutChart"></canvas>
                  <div class="neo-vsr-btn-contact" *ngIf="IDUD && IDUCHART == true">
                    <button routerLink="/vorsorgerechner/contact/{{
                          urlProvider.getCompressedData()
                        }}" class="neo-btn-MehrInformationen-chart">
                      <span class="neo-vsr-span-button neovsr-executeButton"
                        *ngIf="vsrStep4.contactButtonText">{{vsrStep4.contactButtonText[this.selectedLanguage]
                        }}</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="IDUD && IDUCHART == true" [ngClass]="{
                      neo_vsr_accordion_tdud: shouldShowIDUMD,
                      neo_vsr_accordion_tdud_active: !shouldShowIDUMD
                    }" class="neo_vsr_accordion_tdud">
                  <div class="neo_vsr_accordion_mehr" (click)="
                        shouldShowIDUMD = !shouldShowIDUMD;
                        IDUMEHR = !IDUMEHR;
                        IDUMD = !IDUMD
                      ">
                    <p class="neo-mehr-details-header" *ngIf="IDUMD">
                      {{
                      vsrStep4.moredetailsButtonText[this.selectedLanguage]
                      }}
                    </p>
                  </div>

                  <div class="neo-weniger-details" *ngIf="!IDUMD">
                    <div class="neo-weniger-details-header">
                      <div class="neo-mehr-weniger-details-txt" (click)="
                            shouldShowIDUMD = !shouldShowIDUMD;
                            IDUMEHR = !IDUMEHR;
                            IDUMD = !IDUMD
                          ">
                        <label class="neo-vsr-label-less-details">{{
                          vsrStep4.lessdetailsButtonText[
                          this.selectedLanguage
                          ]
                          }}<img src="/assets/images/up.svg" /></label>
                      </div>
                      <div>
                        <mat-form-field class="neo-vsr-mat-form-field">
                          <mat-select [(value)]="neovsrMatFormFieldIduSelect"
                            placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                            (selectionChange)="compareIDU(neovsrMatFormFieldIduSelect)">
                            <mat-option *ngFor="let item of result1IDU; let i = index" [value]="i">
                              {{ item }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="IDUMEHR">
                      <div class="neo-mehr-list">
                        <label class="mehrlist neo-bd-red" *ngIf="
                              result2IDU.aktuellesinkomen !== undefined &&
                              result2IDU.aktuellesinkomen !== null
                            ">{{ namedatasets.aktuellesname
                          }}<label>{{
                            result2IDU.aktuellesinkomen
                            }}</label></label>
                        <label class="mehrlist neo-bd-white" *ngIf="
                              result2IDU.ahv !== undefined &&
                              result2IDU.ahv !== null
                            ">
                          {{ namedatasets.ahvname
                          }}<label>{{ result2IDU.ahv }}</label></label>

                        <label class="mehrlist neo-bd-lightblue" *ngIf="
                              result2IDU.bvg !== undefined &&
                              result2IDU.bvg !== null
                            ">
                          {{ namedatasets.bvgname
                          }}<label>{{ result2IDU.bvg }}</label></label>
                        <label class="mehrlist neo-bd-grey" *ngIf="
                              result2IDU.uvg !== undefined &&
                              result2IDU.uvg !== null
                            ">{{ namedatasets.uvgname
                          }}<label>{{ result2IDU.uvg }}</label></label>
                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2IDU.einkommen !== undefined &&
                              result2IDU.einkommen !== null
                            ">{{ namedatasets.einkommen
                          }}<label>{{ result2IDU.einkommen }}</label></label>

                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2IDU.ktg !== undefined &&
                              result2IDU.ktg !== null
                            ">{{ namedatasets.ktg
                          }}<label>{{ result2IDU.ktg }}</label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="
              mdc-layout-grid
              neo-pdtop-10 neo-padding-right-0 neo-pd-left-0
            ">
          <div class="mdc-layout-grid__inner">
            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10
                ">
              <!-- TODO this could be a bug -->
              <div [ngClass]="!shouldShowIDKTITLE ? 'height-chart-deactive' : 'height-chart-active'" id="content"
                *ngIf="ExecuteFunctions.invalid_durch_krankheit == true">
                <button [ngClass]="{
                      neo_vsr_accordion_title_active: shouldShowIDKTITLE,
                      neo_vsr_accordion_title: !shouldShowIDKTITLE
                    }" *ngIf="IDKD" (click)="
                      IDKCHART = !IDKCHART;
                      shouldShowIDKTITLE = !shouldShowIDKTITLE
                    ">
                  {{ vsrStep4.chartTitle[3][this.selectedLanguage] }}
                </button>

                <div [ngClass]="IDKCHART ? 'showChart' : 'hideChart'" class="chart-container">
                  <p *ngIf="IDKD && IDKCHART == true" class="neo-vsr-chart-des">
                    {{this.vsrStep4.chartHeadline[this.selectedLanguage]}} </p>
                  <canvas class="canvo" #IDK [hidden]="ShowDoughnutChart"></canvas>
                  <div class="neo-vsr-btn-contact" *ngIf="IDKD && IDKCHART == true">
                    <button routerLink="/vorsorgerechner/contact/{{
                          urlProvider.getCompressedData()
                        }}" class="neo-btn-MehrInformationen-chart">
                      <span class="neo-vsr-span-button neovsr-executeButton"
                        *ngIf="vsrStep4.contactButtonText">{{vsrStep4.contactButtonText[this.selectedLanguage]
                        }}</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="IDKD && IDKCHART == true" [ngClass]="{
                      neo_vsr_accordion_tdud: shouldShowIDKMD,
                      neo_vsr_accordion_tdud_active: !shouldShowIDKMD
                    }" class="neo_vsr_accordion_tdud">
                  <div class="neo_vsr_accordion_mehr" (click)="
                        shouldShowIDKMD = !shouldShowIDKMD;
                        IDKMEHR = !IDKMEHR;
                        IDKMD = !IDKMD
                      ">
                    <p class="neo-mehr-details-header" *ngIf="IDKMD">
                      {{
                      vsrStep4.moredetailsButtonText[this.selectedLanguage]
                      }}
                    </p>
                  </div>

                  <div class="neo-weniger-details" *ngIf="!IDKMD">
                    <div class="neo-weniger-details-header">
                      <div class="neo-mehr-weniger-details-txt" (click)="
                            shouldShowIDKMD = !shouldShowIDKMD;
                            IDKMEHR = !IDKMEHR;
                            IDKMD = !IDKMD
                          ">
                        <label class="neo-vsr-label-less-details">{{
                          vsrStep4.lessdetailsButtonText[
                          this.selectedLanguage
                          ]
                          }}<img src="/assets/images/up.svg" /></label>
                      </div>
                      <div>
                        <mat-form-field class="neo-vsr-mat-form-field">
                          <mat-select [(value)]="neovsrMatFormFieldIdkSelect"
                            placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                            (selectionChange)="compareIDK(neovsrMatFormFieldIdkSelect)">
                            <mat-option *ngFor="let item of result1IDK; let i = index" [value]="i">
                              {{ item }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="IDKMEHR">
                      <div class="neo-mehr-list">
                        <label class="mehrlist neo-bd-red" *ngIf="
                              result2IDK.aktuellesinkomen !== undefined &&
                              result2IDK.aktuellesinkomen !== null
                            ">{{ namedatasets.aktuellesname
                          }}<label>{{
                            result2IDK.aktuellesinkomen
                            }}</label></label>
                        <label class="mehrlist neo-bd-white" *ngIf="
                              result2IDK.ahv !== undefined &&
                              result2IDK.ahv !== null
                            ">
                          {{ namedatasets.ahvname
                          }}<label>{{ result2IDK.ahv }}</label></label>

                        <label class="mehrlist neo-bd-lightblue" *ngIf="
                              result2IDK.bvg !== undefined &&
                              result2IDK.bvg !== null
                            ">
                          {{ namedatasets.bvgname
                          }}<label>{{ result2IDK.bvg }}</label></label>
                        <label class="mehrlist neo-bd-grey" *ngIf="
                              result2IDK.uvg !== undefined &&
                              result2IDK.uvg !== null
                            ">{{ namedatasets.uvgname
                          }}<label>{{ result2IDK.uvg }}</label></label>
                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2IDK.einkommen !== undefined &&
                              result2IDK.einkommen !== null
                            ">{{ namedatasets.einkommen
                          }}<label>{{ result2IDK.einkommen }}</label></label>

                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2IDK.ktg !== undefined &&
                              result2IDK.ktg !== null
                            ">{{ namedatasets.ktg
                          }}<label>{{ result2IDK.ktg }}</label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="
              mdc-layout-grid
              neo-pdtop-10 neo-padding-right-0 neo-pd-left-0
            ">
          <div class="mdc-layout-grid__inner">
            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10
                ">
              <!-- TODO this could be a bug -->
              <div [ngClass]="!shouldShowALTERTITLE ? 'height-chart-deactive' : 'height-chart-active'" id="content"
                *ngIf="ExecuteFunctions.Alter == true">
                <button [ngClass]="{
                      neo_vsr_accordion_title_active: shouldShowALTERTITLE,
                      neo_vsr_accordion_title: !shouldShowALTERTITLE
                    }" *ngIf="ALTERD" (click)="
                      ALTERCHART = !ALTERCHART;
                      shouldShowALTERTITLE = !shouldShowALTERTITLE
                    ">
                  {{ vsrStep4.chartTitle[4][this.selectedLanguage] }}
                </button>

                <div [ngClass]="ALTERCHART ? 'showChart' : 'hideChart'" class="chart-container">
                  <p *ngIf="ALTERD && ALTERCHART == true" class="neo-vsr-chart-des">
                    {{this.vsrStep4.chartHeadline[this.selectedLanguage]}} </p>
                  <canvas #Alter [hidden]="ShowDoughnutChart" class="canvo"></canvas>
                  <div class="neo-vsr-btn-contact" *ngIf="ALTERD && ALTERCHART == true">
                    <button routerLink="/vorsorgerechner/contact/{{
                          urlProvider.getCompressedData()
                        }}" class="neo-btn-MehrInformationen-chart">
                      <span class="neo-vsr-span-button neovsr-executeButton"
                        *ngIf="vsrStep4.contactButtonText">{{vsrStep4.contactButtonText[this.selectedLanguage]
                        }}</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="ALTERD && ALTERCHART == true" [ngClass]="{
                      neo_vsr_accordion_tdud: shouldShowALTERMD,
                      neo_vsr_accordion_tdud_active: !shouldShowALTERMD
                    }" class="neo_vsr_accordion_tdud">
                  <div class="neo_vsr_accordion_mehr" (click)="
                        shouldShowALTERMD = !shouldShowALTERMD;
                        ALTERMEHR = !ALTERMEHR;
                        ALTERMD = !ALTERMD
                      ">
                    <p class="neo-mehr-details-header" *ngIf="ALTERMD">
                      {{
                      vsrStep4.moredetailsButtonText[this.selectedLanguage]
                      }}
                    </p>
                  </div>

                  <div class="neo-weniger-details" *ngIf="!ALTERMD">
                    <div class="neo-weniger-details-header">
                      <div class="neo-mehr-weniger-details-txt" (click)="
                            shouldShowALTERMD = !shouldShowALTERMD;
                            ALTERMEHR = !ALTERMEHR;
                            ALTERMD = !ALTERMD
                          ">
                        <label class="neo-vsr-label-less-details">{{
                          vsrStep4.lessdetailsButtonText[
                          this.selectedLanguage
                          ]
                          }}<img src="/assets/images/up.svg" /></label>
                      </div>
                      <div>
                        <mat-form-field class="neo-vsr-mat-form-field">
                          <mat-select [(value)]="neovsrMatFormFieldAlterSelect"
                            placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                            (selectionChange)="compareALTER(neovsrMatFormFieldAlterSelect)">
                            <mat-option *ngFor="let item of result1ALTER; let i = index" [value]="i">
                              {{ item }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="ALTERMEHR">
                      <div class="neo-mehr-list">
                        <label class="mehrlist neo-bd-red" *ngIf="
                              result2ALTER.aktuellesinkomen !== undefined &&
                              result2ALTER.aktuellesinkomen !== null
                            ">{{ namedatasets.aktuellesname
                          }}<label>{{
                            result2ALTER.aktuellesinkomen
                            }}</label></label>
                        <label class="mehrlist neo-bd-white" *ngIf="
                              result2ALTER.ahv !== undefined &&
                              result2ALTER.ahv !== null
                            ">
                          {{ namedatasets.ahvname
                          }}<label>{{ result2ALTER.ahv }}</label></label>

                        <label class="mehrlist neo-bd-lightblue" *ngIf="
                              result2ALTER.bvg !== undefined &&
                              result2ALTER.bvg !== null
                            ">
                          {{ namedatasets.bvgname
                          }}<label>{{ result2ALTER.bvg }}</label></label>

                        <label class="mehrlist neo-bd-grey" *ngIf="
                              result2ALTER.uvg !== undefined &&
                              result2ALTER.uvg !== null
                            ">{{ namedatasets.uvgname
                          }}<label>{{ result2ALTER.uvg }}</label></label>
                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2ALTER.einkommen !== undefined &&
                              result2ALTER.einkommen !== null
                            ">{{ namedatasets.einkommen
                          }}<label>{{ result2ALTER.einkommen }}</label></label>

                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2ALTER.ktg !== undefined &&
                              result2ALTER.ktg !== null
                            ">{{ namedatasets.ktg
                          }}<label>{{ result2ALTER.ktg }}</label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Doughnut Charts-->
      <div class="neo-dsp-grid neo-vsr-doughnutchart" [ngStyle]="{ height: ShowChart == true ? '10px' : '100%' }">
        <div class="
              mdc-layout-grid
              neo-pdtop-10 neo-padding-right-0 neo-pd-left-0
            ">
          <div class="mdc-layout-grid__inner neo-vsr-doughnutchart-grid">
            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-4-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10 neo-vsr-width
                ">
              <div class="neo-chart-width"
                [ngClass]="!shouldShowTDUDoughnutTitle ? 'height-chart-deactive' : 'height-chart-active'" id="content"
                *ngIf="ExecuteFunctions.tod_durch_unfall == true">
                <button [ngClass]="{
                      neo_vsr_accordion_title_active:
                        shouldShowTDUDoughnutTitle,
                      neo_vsr_accordion_title: !shouldShowTDUDoughnutTitle
                    }" *ngIf="TDUDo" (click)="
                      TDUDCHART = !TDUDCHART;
                      shouldShowTDUDoughnutTitle = !shouldShowTDUDoughnutTitle
                    ">
                  {{ vsrStep4.chartTitle[0][this.selectedLanguage] }}
                </button>

                <table [ngClass]="TDUDCHART ? 'showChart' : 'hideChart'" class="chart-container" style="
                      border-top: 0px;
                      border-left: 5px solid #cfc2ab;
                      border-right: 1px solid #d9dadb;
                      border-bottom: 1px solid #d9dadb;
                    ">
                  <tr class="neo-vsr-doughnut-chart-tr">
                    <p *ngIf="TDUDo && TDUDCHART == true" class="neo-vsr-doughnut-chart-header-des">
                      {{this.vsrStep4.overviewHeadline[this.selectedLanguage]}} </p>
                    <td>
                      <canvas #TDU_D class="neo-vsr-doughnut-chart-canvas"></canvas>
                      <p class="neo-vsr-doughnut-chart-des" *ngIf="vsrStep4.overviewDescription">
                        {{
                        vsrStep4.overviewDescription[
                        this.selectedLanguage
                        ]
                        }}
                      </p>
                    </td>

                    <td class="neo-vsr-doughnut-chart-td">
                      <button routerLink="/vorsorgerechner/contact/{{
                            urlProvider.getCompressedData()
                          }}" *ngIf="ShowOnlyDoughnutCharts" class="neo-btn-MehrInformationen" mat-button>
                        <span class="neo-vsr-span-button neovsr-executeButton"
                          *ngIf="vsrStep4.contactButtonText">{{vsrStep4.contactButtonText[this.selectedLanguage]
                          }}</span>
                      </button>
                    </td>
                  </tr>
                </table>
                <div *ngIf="TDUDo && TDUDCHART == true" [ngClass]="{
                      neo_vsr_accordion_tdud: shouldShowTDUMD,
                      neo_vsr_accordion_tdud_active: !shouldShowTDUMD
                    }" class="neo_vsr_accordion_tdud">
                  <div class="neo_vsr_accordion_mehr" (click)="
                        shouldShowTDUMD = !shouldShowTDUMD;
                        TDUMEHR = !TDUMEHR;
                        TDUMD = !TDUMD
                      ">
                    <p class="neo-mehr-details-header" *ngIf="TDUMD">
                      {{
                      vsrStep4.moredetailsButtonText[this.selectedLanguage]
                      }}
                    </p>
                  </div>

                  <div class="neo-weniger-details" *ngIf="!TDUMD">
                    <div class="neo-weniger-details-header">
                      <div class="neo-mehr-weniger-details-txt" (click)="
                            shouldShowTDUMD = !shouldShowTDUMD;
                            TDUMEHR = !TDUMEHR;
                            TDUMD = !TDUMD
                          ">
                        <label class="neo-vsr-label-less-details">{{
                          vsrStep4.lessdetailsButtonText[
                          this.selectedLanguage
                          ]
                          }}<img src="/assets/images/up.svg" /></label>
                      </div>
                      <div>
                        <mat-form-field class="neo-vsr-mat-form-field">
                          <mat-select [(value)]="neovsrMatFormFieldTduSelect"
                            placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                            (selectionChange)="compareTDU(neovsrMatFormFieldTduSelect)">
                            <mat-option *ngFor="let item of result1TDU; let i = index" [value]="i">
                              {{ item }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="TDUMEHR">
                      <div class="neo-mehr-list">
                        <label class="mehrlist neo-bd-red" *ngIf="
                              result2TDU.aktuellesinkomen !== undefined &&
                              result2TDU.aktuellesinkomen !== null
                            ">{{ namedatasets.aktuellesname
                          }}<label>{{
                            result2TDU.aktuellesinkomen
                            }}</label></label>
                        <label class="mehrlist neo-bd-white" *ngIf="
                              result2TDU.ahv !== undefined &&
                              result2TDU.ahv !== null
                            ">
                          {{ namedatasets.ahvname
                          }}<label>{{ result2TDU.ahv }}</label></label>

                        <label class="mehrlist neo-bd-lightblue" *ngIf="
                              result2TDU.bvg !== undefined &&
                              result2TDU.bvg !== null
                            ">
                          {{ namedatasets.bvgname
                          }}<label>{{ result2TDU.bvg }}</label></label>
                        <label class="mehrlist neo-bd-grey" *ngIf="
                              result2TDU.uvg !== undefined &&
                              result2TDU.uvg !== null
                            ">{{ namedatasets.uvgname
                          }}<label>{{ result2TDU.uvg }}</label></label>
                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2TDU.einkommen !== undefined &&
                              result2TDU.einkommen !== null
                            ">{{ namedatasets.einkommen
                          }}<label>{{ result2TDU.einkommen }}</label></label>

                        <label class="mehrlist neo-bd-blue" *ngIf="
                              result2TDU.ktg !== undefined &&
                              result2TDU.ktg !== null
                            ">{{ namedatasets.ktg
                          }}<label>{{ result2TDU.ktg }}</label></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-4-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10 neo-vsr-width
                " [ngClass]="!shouldShowTDKDoughnutTitle ? 'height-chart-deactive' : 'height-chart-active'"
              *ngIf="ExecuteFunctions.tod_durch_krankheit == true">
              <button [ngClass]="{
                    neo_vsr_accordion_title_active: shouldShowTDKDoughnutTitle,
                    neo_vsr_accordion_title: !shouldShowTDKDoughnutTitle
                  }" *ngIf="TDKDo" (click)="
                    TDKDCHART = !TDKDCHART;
                    shouldShowTDKDoughnutTitle = !shouldShowTDKDoughnutTitle
                  ">
                {{ vsrStep4.chartTitle[1][this.selectedLanguage] }}
              </button>

              <table [ngClass]="TDKDCHART ? 'showChart' : 'hideChart'" class="chart-container" style="
                    border-top: 0px;
                    border-left: 5px solid #cfc2ab;
                    border-right: 1px solid #d9dadb;
                    border-bottom: 1px solid #d9dadb;
                  ">
                <tbody>
                  <tr class="neo-vsr-doughnut-chart-tr">
                    <p *ngIf="TDKDo && TDKDCHART == true" class="neo-vsr-doughnut-chart-header-des">
                      {{this.vsrStep4.overviewHeadline[this.selectedLanguage]}} </p>
                    <td>
                      <canvas #TDK_D class="neo-vsr-doughnut-chart-canvas"></canvas>
                      <p class="neo-vsr-doughnut-chart-des" *ngIf="vsrStep4.overviewDescription">
                        {{
                        vsrStep4.overviewDescription[this.selectedLanguage]
                        }}
                      </p>
                    </td>
                    <td class="neo-vsr-doughnut-chart-td">
                      <button routerLink="/vorsorgerechner/contact/{{
                            urlProvider.getCompressedData()
                          }}" *ngIf="ShowOnlyDoughnutCharts" class="neo-btn-MehrInformationen" mat-button>
                        <span class="neo-vsr-span-button neovsr-executeButton" *ngIf="vsrStep4.contactButtonText">
                          {{vsrStep4.contactButtonText[this.selectedLanguage]
                          }}
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="TDKDo && TDKDCHART == true" [ngClass]="{
                    neo_vsr_accordion_tdud: shouldShowTDKMD,
                    neo_vsr_accordion_tdud_active: !shouldShowTDKMD
                  }" class="neo_vsr_accordion_tdud">
                <div class="neo_vsr_accordion_mehr" (click)="
                      shouldShowTDKMD = !shouldShowTDKMD;
                      TDKMEHR = !TDKMEHR;
                      TDKMD = !TDKMD
                    ">
                  <p class="neo-mehr-details-header" *ngIf="TDKMD">
                    {{
                    vsrStep4.moredetailsButtonText[this.selectedLanguage]
                    }}
                  </p>
                </div>

                <div class="neo-weniger-details" *ngIf="!TDKMD">
                  <div class="neo-weniger-details-header">
                    <div class="neo-mehr-weniger-details-txt" (click)="
                          shouldShowTDKMD = !shouldShowTDKMD;
                          TDKMEHR = !TDKMEHR;
                          TDKMD = !TDKMD
                        ">
                      <label>{{
                        vsrStep4.lessdetailsButtonText[
                        this.selectedLanguage
                        ]
                        }}<img src="/assets/images/up.svg" /></label>
                    </div>
                    <div>
                      <mat-form-field class="neo-vsr-mat-form-field">
                        <mat-select [(value)]="neovsrMatFormFieldTdkSelect"
                          placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                          (selectionChange)="compareTDK(neovsrMatFormFieldTdkSelect)">
                          <mat-option *ngFor="let item of result1TDK; let i = index" [value]="i">
                            {{ item }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="TDKMEHR">
                    <div class="neo-mehr-list">
                      <label class="mehrlist neo-bd-red" *ngIf="
                            result2TDK.aktuellesinkomen !== undefined &&
                            result2TDK.aktuellesinkomen !== null
                          ">{{ namedatasets.aktuellesname
                        }}<label>{{
                          result2TDK.aktuellesinkomen
                          }}</label></label>
                      <label class="mehrlist neo-bd-white" *ngIf="
                            result2TDK.ahv !== undefined &&
                            result2TDK.ahv !== null
                          ">
                        {{ namedatasets.ahvname
                        }}<label>{{ result2TDK.ahv }}</label></label>

                      <label class="mehrlist neo-bd-lightblue" *ngIf="
                            result2TDK.bvg !== undefined &&
                            result2TDK.bvg !== null
                          ">
                        {{ namedatasets.bvgname
                        }}<label>{{ result2TDK.bvg }}</label></label>
                      <label class="mehrlist neo-bd-grey" *ngIf="
                            result2TDK.uvg !== undefined &&
                            result2TDK.uvg !== null
                          ">{{ namedatasets.uvgname
                        }}<label>{{ result2TDK.uvg }}</label></label>
                      <label class="mehrlist neo-bd-blue" *ngIf="
                            result2TDK.einkommen !== undefined &&
                            result2TDK.einkommen !== null
                          ">{{ namedatasets.einkommen
                        }}<label>{{ result2TDK.einkommen }}</label></label>

                      <label class="mehrlist neo-bd-blue" *ngIf="
                            result2TDK.ktg !== undefined &&
                            result2TDK.ktg !== null
                          ">{{ namedatasets.ktg
                        }}<label>{{ result2TDK.ktg }}</label></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-4-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10 neo-vsr-width
                " [ngClass]="!shouldShowIDUDoughnutTitle ? 'height-chart-deactive' : 'height-chart-active'"
              *ngIf="ExecuteFunctions.invalid_durch_unfall == true">
              <button [ngClass]="{
                    neo_vsr_accordion_title_active: shouldShowIDUDoughnutTitle,
                    neo_vsr_accordion_title: !shouldShowIDUDoughnutTitle
                  }" *ngIf="IDUDo" (click)="
                    IDUDCHART = !IDUDCHART;
                    shouldShowIDUDoughnutTitle = !shouldShowIDUDoughnutTitle
                  ">
                {{ vsrStep4.chartTitle[2][this.selectedLanguage] }}
              </button>

              <table [ngClass]="IDUDCHART ? 'showChart' : 'hideChart'" class="chart-container" style="
                    border-top: 0px;
                    border-left: 5px solid #cfc2ab;
                    border-right: 1px solid #d9dadb;
                    border-bottom: 1px solid #d9dadb;
                  ">
                <tbody>
                  <tr class="neo-vsr-doughnut-chart-tr">
                    <p *ngIf="IDUDo && IDUDCHART == true" class="neo-vsr-doughnut-chart-header-des">
                      {{this.vsrStep4.overviewHeadline[this.selectedLanguage]}} </p>
                    <td>
                      <canvas #IDU_D class="neo-vsr-doughnut-chart-canvas"></canvas>
                      <p class="neo-vsr-doughnut-chart-des" *ngIf="vsrStep4.overviewDescription">
                        {{
                        vsrStep4.overviewDescription[this.selectedLanguage]
                        }}
                      </p>
                    </td>
                    <td class="neo-vsr-doughnut-chart-td">
                      <button routerLink="/vorsorgerechner/contact/{{
                            urlProvider.getCompressedData()
                          }}" *ngIf="ShowOnlyDoughnutCharts" class="neo-btn-MehrInformationen" mat-button>
                        <span class="neo-vsr-span-button neovsr-executeButton" *ngIf="vsrStep4.contactButtonText">
                          {{vsrStep4.contactButtonText[this.selectedLanguage]
                          }}
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="IDUDo && IDUDCHART == true" [ngClass]="{
                    neo_vsr_accordion_tdud: shouldShowIDUMD,
                    neo_vsr_accordion_tdud_active: !shouldShowIDUMD
                  }" class="neo_vsr_accordion_tdud">
                <div class="neo_vsr_accordion_mehr" (click)="
                      shouldShowIDUMD = !shouldShowIDUMD;
                      IDUMEHR = !IDUMEHR;
                      IDUMD = !IDUMD
                    ">
                  <p class="neo-mehr-details-header" *ngIf="IDUMD">
                    {{
                    vsrStep4.moredetailsButtonText[this.selectedLanguage]
                    }}
                  </p>
                </div>

                <div class="neo-weniger-details" *ngIf="!IDUMD">
                  <div class="neo-weniger-details-header">
                    <div class="neo-mehr-weniger-details-txt" (click)="
                          shouldShowIDUMD = !shouldShowIDUMD;
                          IDUMEHR = !IDUMEHR;
                          IDUMD = !IDUMD
                        ">
                      <label class="neo-vsr-label-less-details">{{
                        vsrStep4.lessdetailsButtonText[
                        this.selectedLanguage
                        ]
                        }}<img src="/assets/images/up.svg" /></label>
                    </div>
                    <div>
                      <mat-form-field class="neo-vsr-mat-form-field">
                        <mat-select [(value)]="neovsrMatFormFieldIduSelect"
                          placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                          (selectionChange)="compareIDU(neovsrMatFormFieldTdkSelect)">
                          <mat-option *ngFor="let item of result1IDU; let i = index" [value]="i">
                            {{ item }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="IDUMEHR">
                    <div class="neo-mehr-list">
                      <label class="mehrlist neo-bd-red" *ngIf="
                            result2IDU.aktuellesinkomen !== undefined &&
                            result2IDU.aktuellesinkomen !== null
                          ">{{ namedatasets.aktuellesname
                        }}<label>{{
                          result2IDU.aktuellesinkomen
                          }}</label></label>
                      <label class="mehrlist neo-bd-white" *ngIf="
                            result2IDU.ahv !== undefined &&
                            result2IDU.ahv !== null
                          ">
                        {{ namedatasets.ahvname
                        }}<label>{{ result2IDU.ahv }}</label></label>

                      <label class="mehrlist neo-bd-lightblue" *ngIf="
                            result2IDU.bvg !== undefined &&
                            result2IDU.bvg !== null
                          ">
                        {{ namedatasets.bvgname
                        }}<label>{{ result2IDU.bvg }}</label></label>
                      <label class="mehrlist neo-bd-grey" *ngIf="
                            result2IDU.uvg !== undefined &&
                            result2IDU.uvg !== null
                          ">{{ namedatasets.uvgname
                        }}<label>{{ result2IDU.uvg }}</label></label>
                      <label class="mehrlist neo-bd-blue" *ngIf="
                            result2IDU.einkommen !== undefined &&
                            result2IDU.einkommen !== null
                          ">{{ namedatasets.einkommen
                        }}<label>{{ result2IDU.einkommen }}</label></label>

                      <label class="mehrlist neo-bd-blue" *ngIf="
                            result2IDU.ktg !== undefined &&
                            result2IDU.ktg !== null
                          ">{{ namedatasets.ktg
                        }}<label>{{ result2IDU.ktg }}</label></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="
              mdc-layout-grid
              neo-pdtop-10 neo-padding-right-0 neo-pd-left-0
            ">
          <div class="mdc-layout-grid__inner neo-vsr-doughnutchart-grid">
            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-4-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10 neo-vsr-width
                " [ngClass]="!shouldShowIDKDoughnutTitle ? 'height-chart-deactive' : 'height-chart-active'"
              *ngIf="ExecuteFunctions.invalid_durch_krankheit == true">
              <button [ngClass]="{
                    neo_vsr_accordion_title_active: shouldShowIDKDoughnutTitle,
                    neo_vsr_accordion_title: !shouldShowIDKDoughnutTitle
                  }" *ngIf="IDKDo" (click)="
                    IDKDCHART = !IDKDCHART;
                    shouldShowIDKDoughnutTitle = !shouldShowIDKDoughnutTitle
                  ">
                {{ vsrStep4.chartTitle[3][this.selectedLanguage] }}
              </button>

              <table [ngClass]="IDKDCHART ? 'showChart' : 'hideChart'" class="chart-container" style="
                      border-top: 0px;
                      border-left: 5px solid #cfc2ab;
                      border-right: 1px solid #d9dadb;
                      border-bottom: 1px solid #d9dadb;
                    ">
                <tbody>
                  <tr class="neo-vsr-doughnut-chart-tr">
                    <p *ngIf="IDKDo && IDKDCHART == true" class="neo-vsr-doughnut-chart-header-des">
                      {{this.vsrStep4.overviewHeadline[this.selectedLanguage]}} </p>
                    <td>
                      <canvas #IDK_D class="neo-vsr-doughnut-chart-canvas"></canvas>
                      <p class="neo-vsr-doughnut-chart-des" *ngIf="vsrStep4.overviewDescription">
                        {{
                        vsrStep4.overviewDescription[this.selectedLanguage]
                        }}
                      </p>
                    </td>
                    <td class="neo-vsr-doughnut-chart-td">
                      <button routerLink="/vorsorgerechner/contact/{{
                            urlProvider.getCompressedData()
                          }}" *ngIf="ShowOnlyDoughnutCharts" class="neo-btn-MehrInformationen" mat-button>
                        <span class="neo-vsr-span-button neovsr-executeButton" *ngIf="vsrStep4.contactButtonText">
                          {{
                          vsrStep4.contactButtonText[this.selectedLanguage]
                          }}
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="IDKDo && IDKDCHART == true" [ngClass]="{
                    neo_vsr_accordion_tdud: shouldShowIDKMD,
                    neo_vsr_accordion_tdud_active: !shouldShowIDKMD
                  }" class="neo_vsr_accordion_tdud">
                <div class="neo_vsr_accordion_mehr" (click)="
                      shouldShowIDKMD = !shouldShowIDKMD;
                      IDKMEHR = !IDKMEHR;
                      IDKMD = !IDKMD
                    ">
                  <p class="neo-mehr-details-header" *ngIf="IDKMD">
                    {{
                    vsrStep4.moredetailsButtonText[this.selectedLanguage]
                    }}
                  </p>
                </div>

                <div class="neo-weniger-details" *ngIf="!IDKMD">
                  <div class="neo-weniger-details-header">
                    <div class="neo-mehr-weniger-details-txt" (click)="
                          shouldShowIDKMD = !shouldShowIDKMD;
                          IDKMEHR = !IDKMEHR;
                          IDKMD = !IDKMD
                        ">
                      <label class="neo-vsr-label-less-details">{{
                        vsrStep4.lessdetailsButtonText[
                        this.selectedLanguage
                        ]
                        }}<img src="/assets/images/up.svg" /></label>
                    </div>
                    <div>
                      <mat-form-field class="neo-vsr-mat-form-field">
                        <mat-select [(value)]="neovsrMatFormFieldIdkSelect"
                          placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                          (selectionChange)="compareIDK(neovsrMatFormFieldIdkSelect)">
                          <mat-option *ngFor="let item of result1IDK; let i = index" [value]="i">
                            {{ item }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="IDKMEHR">
                    <div class="neo-mehr-list">
                      <label class="mehrlist neo-bd-red" *ngIf="
                            result2IDK.aktuellesinkomen !== undefined &&
                            result2IDK.aktuellesinkomen !== null
                          ">{{ namedatasets.aktuellesname
                        }}<label>{{
                          result2IDK.aktuellesinkomen
                          }}</label></label>
                      <label class="mehrlist neo-bd-white" *ngIf="
                            result2IDK.ahv !== undefined &&
                            result2IDK.ahv !== null
                          ">
                        {{ namedatasets.ahvname
                        }}<label>{{ result2IDK.ahv }}</label></label>

                      <label class="mehrlist neo-bd-lightblue" *ngIf="
                            result2IDK.bvg !== undefined &&
                            result2IDK.bvg !== null
                          ">
                        {{ namedatasets.bvgname
                        }}<label>{{ result2IDK.bvg }}</label></label>
                      <label class="mehrlist neo-bd-grey" *ngIf="
                            result2IDK.uvg !== undefined &&
                            result2IDK.uvg !== null
                          ">{{ namedatasets.uvgname
                        }}<label>{{ result2IDK.uvg }}</label></label>
                      <label class="mehrlist neo-bd-blue" *ngIf="
                            result2IDK.einkommen !== undefined &&
                            result2IDK.einkommen !== null
                          ">{{ namedatasets.einkommen
                        }}<label>{{ result2IDK.einkommen }}</label></label>

                      <label class="mehrlist neo-bd-blue" *ngIf="
                            result2IDK.ktg !== undefined &&
                            result2IDK.ktg !== null
                          ">{{ namedatasets.ktg
                        }}<label>{{ result2IDK.ktg }}</label></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-4-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                  fright
                  neo-pdtop-10 neo-vsr-width
                " [ngClass]="!shouldShowALTERDoughnutTitle ? 'height-chart-deactive' : 'height-chart-active'"
              *ngIf="ExecuteFunctions.Alter == true">
              <button [ngClass]="{
                    neo_vsr_accordion_title_active:
                      shouldShowALTERDoughnutTitle,
                    neo_vsr_accordion_title: !shouldShowALTERDoughnutTitle
                  }" *ngIf="ALTERDo" (click)="
                    ALTERDCHART = !ALTERDCHART;
                    shouldShowALTERDoughnutTitle = !shouldShowALTERDoughnutTitle
                  ">
                {{ vsrStep4.chartTitle[4][this.selectedLanguage] }}
              </button>

              <table [ngClass]="ALTERDCHART ? 'showChart' : 'hideChart'" class="chart-container" style="
                    border-top: 0px;
                    border-left: 5px solid #cfc2ab;
                    border-right: 1px solid #d9dadb;
                    border-bottom: 1px solid #d9dadb;
                  ">
                <tbody>
                  <tr class="neo-vsr-doughnut-chart-tr">
                    <p *ngIf="ALTERDo && ALTERDCHART == true" class="neo-vsr-doughnut-chart-header-des">
                      {{this.vsrStep4.overviewHeadline[this.selectedLanguage]}} </p>
                    <td>
                      <canvas #Alter_D class="neo-vsr-doughnut-chart-canvas"></canvas>
                      <p class="neo-vsr-doughnut-chart-des" *ngIf="vsrStep4.overviewDescription">
                        {{
                        vsrStep4.overviewDescription[this.selectedLanguage]
                        }}
                      </p>
                    </td>
                    <td class="neo-vsr-doughnut-chart-td">
                      <button routerLink="/vorsorgerechner/contact/{{
                            urlProvider.getCompressedData()
                          }}" *ngIf="ShowOnlyDoughnutCharts" class="neo-btn-MehrInformationen" mat-button>
                        <span class="neo-vsr-span-button neovsr-executeButton" *ngIf="vsrStep4.contactButtonText">{{
                          vsrStep4.contactButtonText[this.selectedLanguage]
                          }}</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="ALTERDo && ALTERDCHART == true" [ngClass]="{
                    neo_vsr_accordion_tdud: shouldShowALTERMD,
                    neo_vsr_accordion_tdud_active: !shouldShowALTERMD
                  }" class="neo_vsr_accordion_tdud">
                <div class="neo_vsr_accordion_mehr" (click)="
                      shouldShowALTERMD = !shouldShowALTERMD;
                      ALTERMEHR = !ALTERMEHR;
                      ALTERMD = !ALTERMD
                    ">
                  <p class="neo-mehr-details-header" *ngIf="ALTERMD">
                    {{
                    vsrStep4.moredetailsButtonText[this.selectedLanguage]
                    }}
                  </p>
                </div>

                <div class="neo-weniger-details" *ngIf="!ALTERMD">
                  <div class="neo-weniger-details-header">
                    <div class="neo-mehr-weniger-details-txt" (click)="
                          shouldShowALTERMD = !shouldShowALTERMD;
                          ALTERMEHR = !ALTERMEHR;
                          ALTERMD = !ALTERMD
                        ">
                      <label class="neo-vsr-label-less-details">{{
                        vsrStep4.lessdetailsButtonText[
                        this.selectedLanguage
                        ]
                        }}<img src="/assets/images/up.svg" /></label>
                    </div>
                    <div>
                      <mat-form-field class="neo-vsr-mat-form-field">
                        <mat-select [(value)]="neovsrMatFormFieldAlterSelect"
                          placeholder="{{localTranslationClass.getTranslationTexts().VorsorgerechnerSELECTYEAR}}"
                          (selectionChange)="compareALTER(neovsrMatFormFieldAlterSelect)">
                          <mat-option *ngFor="let item of result1ALTER; let i = index" [value]="i">
                            {{ item }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div *ngIf="ALTERMEHR">
                    <div class="neo-mehr-list">
                      <label class="mehrlist neo-bd-red" *ngIf="
                            result2ALTER.aktuellesinkomen !== undefined &&
                            result2ALTER.aktuellesinkomen !== null
                          ">{{ namedatasets.aktuellesname
                        }}<label>{{
                          result2ALTER.aktuellesinkomen
                          }}</label></label>
                      <label class="mehrlist neo-bd-white" *ngIf="
                            result2ALTER.ahv !== undefined &&
                            result2ALTER.ahv !== null
                          ">
                        {{ namedatasets.ahvname
                        }}<label>{{ result2ALTER.ahv }}</label></label>

                      <label class="mehrlist neo-bd-lightblue" *ngIf="
                            result2ALTER.bvg !== undefined &&
                            result2ALTER.bvg !== null
                          ">
                        {{ namedatasets.bvgname
                        }}<label>{{ result2ALTER.bvg }}</label></label>
                      <label class="mehrlist neo-bd-grey" *ngIf="
                            result2ALTER.uvg !== undefined &&
                            result2ALTER.uvg !== null
                          ">{{ namedatasets.uvgname
                        }}<label>{{ result2ALTER.uvg }}</label></label>
                      <label class="mehrlist neo-bd-blue" *ngIf="
                            result2ALTER.einkommen !== undefined &&
                            result2ALTER.einkommen !== null
                          ">{{ namedatasets.einkommen
                        }}<label>{{ result2ALTER.einkommen }}</label></label>

                      <label class="mehrlist neo-bd-blue" *ngIf="
                            result2ALTER.ktg !== undefined &&
                            result2ALTER.ktg !== null
                          ">{{ namedatasets.ktg
                        }}<label>{{ result2ALTER.ktg }}</label></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vors-swiper-container">
      <!-- <swiper [config]="neoSwiperCareerConfig" class="swiper-el">
          <neo-grid-component
          *ngFor="let items of filteredToolsComponent"
          class="neo-grid-flex"
          customCssClass = "bmi-tools-swiper"
          [tinyRepresentation]="true"
          [color]="items?.colorSchema"
          [elementAsLink]="true"
          [title]="items?.title[selectedLanguage]"
          [link]="items?.toolsLink[selectedLanguage]"
          [description]="(items?.description[selectedLanguage].length>100)?(items?.description[selectedLanguage] | slice: 0:100)+'...':(items?.description[selectedLanguage])">
        </neo-grid-component>
      </swiper> -->
    </div>
  </div>
</div>