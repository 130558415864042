import { CommonModule } from '@angular/common';
import { HypothekenrechnerLibraryComponent } from './neo-hypothekenrechner-lib.component';
import {MatButtonModule } from '@angular/material/button';
import {MatSliderModule} from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import {DialogOverviewExampleDialogComponent} from './dialog-overview-example-dialog';
import {MatIconModule} from '@angular/material/icon';
import { MatCheckboxModule } from "@angular/material/checkbox";
// import { CommonsUiModule } from '@neo/commons-ui';
// import { SwiperModule } from 'ngx-swiper-wrapper';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
    declarations: [
        HypothekenrechnerLibraryComponent,
        DialogOverviewExampleDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSliderModule,
        MatDialogModule,
        MatIconModule,
        // CommonsUiModule,
        // SwiperModule,
        MarkdownModule.forRoot()
    ],
    exports: [
        HypothekenrechnerLibraryComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatSliderModule,
        DialogOverviewExampleDialogComponent
    ]
})

export class NeoHypothekenrechnerLibModule { }
