import gql from "graphql-tag";

const FC_DIETIKON_QUERY: any = gql`
query FcDietikon{
  fcDietikon{
    title{de en fr it}
    welcomeText{de en fr it}
    icon{url}
    image{url}
    beforeContactFormText {de en fr it}
    afterContactFormText {de en fr it}
    contactFormContent {
      title{de en fr it}
      description {de en fr it}
      childFormDescription {de en fr it}
    }
  }
}`;
export default FC_DIETIKON_QUERY
