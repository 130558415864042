import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TitleSectionComponent } from "../title-section/title-section.component";
import { StandortComponent } from "../standort/standort.component";

@Component({
  selector: 'app-location',
  standalone: true,
  templateUrl: './location.component.html',
  styleUrl: './location.component.scss',
  imports: [TitleSectionComponent, StandortComponent]
})
export class LocationComponent {
  @Output() viewInitialized: EventEmitter<void> = new EventEmitter<void>();
  
  @Input('content') content: any;
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.viewInitialized.emit();
  }
}
