<mat-radio-group class="neo-gender-select" 
                 [(ngModel)]="neoValue"
                 (change)="neoValueChanged($event)">
        
    <mat-radio-button *ngFor="let t of neoRadioLabels; let i = index;"
                      value="{{neoRadioValues[i]}}"
                      disableRipple = "true"
                      class="neo-gender-select-hidden">
                         <img *ngIf="images && images.length > 0" 
                         [attr.width]="width" [attr.height]="height"
                         width="55px" height="60px"
                         alt="{{images[i]?.alt}}"
                         [ngClass]="neoValue === neoRadioValues[i] ? 'opacity-1' : 'opacity-0'"
                         [src]="images[i]?.img"/>
    </mat-radio-button>
</mat-radio-group>