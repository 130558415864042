import {NeoInputCompleterItem} from "./neo-input-completer.item";
import {NeoClass} from "./neo-class";
import {NeoRegionJson} from "../jsons/neo-region.json";

export class NeoRegion implements NeoInputCompleterItem, NeoClass<NeoRegionJson> {
    public Region: number;
    public PLZ: number;
    public Kanton: string;
    public Ort: string;
    public Gemeinde: string;
    public key: string;

    constructor(zip?: number,
                kanton?: string,
                regionszahl?: number,
                ort?: string,
                gemeinde?: string) {
        this.Region = regionszahl;
        this.PLZ = zip;
        this.Kanton = kanton;
        this.Ort = ort;
        this.Gemeinde = gemeinde;
    }

    public static fromJson(region: NeoRegionJson): NeoRegion {
        const result = new NeoRegion();
        result.Gemeinde = region.Gemeinde;
        result.Kanton = region.Kanton;
        result.PLZ = region.PLZ;
        result.Ort = region.Ort;
        result.Region = region.Region;
        return result;
    }

    public static fromRegion(v: NeoRegionJson) {
        return new NeoRegion(v.PLZ, v.Kanton, v.Region, v.Ort, v.Gemeinde);
    }

    public toJson(): NeoRegionJson {
        const result = new NeoRegionJson();
        result.Gemeinde = this.Gemeinde;
        result.Kanton = this.Kanton;
        result.Ort = this.Ort;
        result.PLZ = this.PLZ;
        result.Region = this.Region;
        return result;
    }

    get value(): string {
        return `${this.PLZ.toString()} - ${this.Ort} (${this.Gemeinde})`;
    }

    public toString(): string {
        return this.value;
    }
}
