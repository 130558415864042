@if(content){
<div class="mb-50">
    <app-title-section [content]="content?.blogHeader" [useCenter]="true"></app-title-section>
</div>

<div class="blog-cards">
    @for(blog of content?.blogs.data; track blog){
    <div class="blog-card">

        <app-card [content]="blog"></app-card>
    </div>
    }
</div>

}