import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {StaticDataProvider} from "../provider/static-data-provider";
import {environment} from "../../environments/environment";
import {NeoVgrTranslation} from "../models/neo-vgr-translation.model";
import {NeoVgrTranslationLanguage} from "../models/neo-vgr-translation-language.model";
import {Suchparameter} from "../models/suchparameter";
import {log} from "./neo.logger";

@Component({
    selector: "neo-vgr-x-entry",
    templateUrl: "./vgr-x-entry.component.html",
    styleUrls: ["./vgr-x-entry.component.css"]
})
export class VGRXEntryComponent implements OnInit {
    @Input()
    dataURL: string;
    @Input()
    tarifYear: number;
    @Input()
    production: boolean;
    @Input()
    ga: boolean;
    @Input()
    translationTexts: NeoVgrTranslation;
    @Input()
    queryString = "";
    @Input()
    translationLanguage: NeoVgrTranslationLanguage;
    @Input()
    logLevel = "NONE";
    @Input()
    genderImages: string []=[];
    @Input() 
    neoSearchSmallClass = '';
    @Input()
    tooltipTitle = 'tooltipTitle';
    @Input()
    tooltipDescription = 'tooltipDescription';
    @Output()
    emitOpen = new EventEmitter<Suchparameter>();

    public constructor(public staticDataProvider: StaticDataProvider) {
        this.translationTexts = new NeoVgrTranslation();
        this.translationLanguage = new NeoVgrTranslationLanguage();
    }

    ngOnInit() {
        if (this.dataURL !== undefined) {
            environment.settings.BASE_URL = this.dataURL;
        }
        if (this.tarifYear !== undefined) {
            environment.settings.TARIFJAHR = this.tarifYear;
        }
        if (this.production !== undefined) {
            environment.settings.production = this.production;
        }
        if (this.logLevel !== undefined) {
            environment.settings.LOG_LEVEL = this.logLevel;
        }
        if (this.ga !== undefined) {
            environment.settings.USE_GOOGLE_ANALYTICS = this.ga;
        }
        log.setLogLevel(environment.settings.LOG_LEVEL);
        this.staticDataProvider.setTranslationTexts(this.translationTexts);
        this.staticDataProvider.setTranslationLang(this.translationLanguage);
    }

    openVGR(value: Suchparameter) {
        this.emitOpen.emit(value);
    }
}
