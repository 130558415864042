import {ErrorHandler, EventEmitter, Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class NeoErrorProvider implements ErrorHandler {

    public onError: EventEmitter<string> = new EventEmitter<string>();

    private _errorMessage: string = null;

    get errorMessage(): string {
        return this._errorMessage;
    }

    buildError(error: Response | any) {

        if (error instanceof Response) {
            const err = error || "";
            this._errorMessage = `${error.status} - ${error.statusText || ""} ${err}`;
        } else if (error instanceof HttpErrorResponse && error.error != null) {
            this._errorMessage = `${error.message} - ${error.error.code} - ${error.error.message}`;
        } else {
            this._errorMessage = error.message ? error.message : error.toString();
        }
        this.onError.emit(this._errorMessage);
        console.error(this._errorMessage);

    }

    handleError(error: any): void {
        this.buildError(error);
    }
}
