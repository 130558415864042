import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NeoHypothekenrechnerLibService {
  data: any = {};
  constructor(private http: HttpClient) {}

  use(lang: string): Promise<{}> {
    // console.log("use...");
    return new Promise<{}>((resolve, reject) => {
      // console.log("lang:"+lang);
      const langPath = `src/assets/hypothekenrechner-assets/i18n/${lang}.json`;//`src/assets/bmi-assets/i18n/${lang}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }
}
