<div *ngIf="neoLabel && neoLabel.length > 0" class="neo-vgr-input-margin ">
    {{neoLabel}}
    <span *ngIf="neoRequired === true && neoShowRequired === true" class="neo-contact-form-required">
        *
    </span>
</div>
<!-- <input type="time"
       ngClass="{{ showErrorCss ? 'neo-input-error':'' }}"
       class="neo-vgr-input-field neo-vgr-input-margin neo-vgr-input-field-text neo-helper-user-select-auto"
       placeholder="{{neoPlaceholder}}"
       [(ngModel)]="neoValue"
       (ngModelChange)="neoTimeChange($event)"
       matTooltip="{{neoErrorTooltipText}}"
       [matTooltipDisabled]=[!showErrorCss]
       [matTooltipPosition]="'right'"
/> -->
<div ngClass="{{ showErrorCss ? 'neo-input-error':'' }}" class="neo-input-time-dropdowns">
    <mat-form-field class="neo-input-time-dropdowns-hour">
        <mat-label>Hours</mat-label>
        <mat-select name="hours" placeholder="{{neoPlaceholder}}" [(ngModel)]="hoursValue"
            (ngModelChange)="neoTimeChange($event)" matTooltip="{{neoErrorTooltipText}}"
            [matTooltipDisabled]=[!showErrorCss] [matTooltipPosition]="'right'">
            <mat-option *ngFor="let item of hours" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="neo-input-time-dropdowns-minutes">
        <mat-label>Minutes</mat-label>
        <mat-select name="minutes" placeholder="{{neoPlaceholder}}" [(ngModel)]="minutesValue"
            (ngModelChange)="neoTimeChange($event)" matTooltip="{{neoErrorTooltipText}}"
            [matTooltipDisabled]=[!showErrorCss] [matTooltipPosition]="'right'">
            <mat-option *ngFor="let item of minutes" [value]="item">
                {{item}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>