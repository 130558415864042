import { Component } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { TranslateService } from '@ngx-translate/core';
import { IntroductionComponent } from '../../components/introduction/introduction.component';
import { HistorySectionComponent } from '../../components/history-section/history-section.component';
import { ContactSectionComponent } from '../../components/contact-section/contact-section.component';
import { NeoCalculatorsInfoComponent } from '../../components/neo-calculators-info/neo-calculators-info.component';
import { BmiCalculatorComponent } from '../../components/bmi-calculator/bmi-calculator.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-neo-calculator',
  standalone: true,
  imports: [IntroductionComponent, HistorySectionComponent, ContactSectionComponent, NeoCalculatorsInfoComponent, BmiCalculatorComponent],
  templateUrl: './neo-calculator.component.html',
  styleUrl: './neo-calculator.component.scss'
})
export class NeoCalculatorComponent {
  calculatorData: any;
  introduction: any;
  calculatorsInfo: any;
  historySection: any;
  contactSection: any;
  selectedLanguage!: string;
  bmiCalculator: any;
  currentPage: string | undefined;
  childViewInitialized: boolean = false;
  fragment: string[] = [];
  allMenuItems: any;


  constructor(private cmsService: CmsService, private translate: TranslateService, private route: ActivatedRoute, private router: Router,
    private seo: SeoService) {
    this.currentPage = this.route.snapshot.routeConfig?.path
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe((res) => {
      this.selectedLanguage = res.lang;
      this.getCalculatorData();
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const fragment = this.route.snapshot.fragment;
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView();
        }
      }
    });
  }

  onChildViewInitialized(): void {
    this.childViewInitialized = true;
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      const element = document.getElementById(fragment);
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  getMenuItems() {
    this.cmsService.allMenuItems.subscribe(menuItems => {
      if (menuItems.length > 0) {

        this.allMenuItems = menuItems;
        const aboutMenuItems = this.allMenuItems.filter((item: any) => item.redirectLink === this.currentPage);
        aboutMenuItems.forEach((aboutMenuItem: any) => {
          const subMenuItem = aboutMenuItem.subMenuItem;
          subMenuItem.forEach((item: any) => {
            const fragment = item.fragment;

            this.fragment.push(fragment);
          });
        });
      }
    });
  }

  getCalculatorData() {
    const page = 'neo-calculator';
    const populate = 'deep';
    const locale = this.selectedLanguage;;
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;

    this.cmsService.singlePageContent(page, populateAndLocale).subscribe((data: any) => {
      this.calculatorData = data;
      this.introduction = data.Introduction;
      this.calculatorsInfo = data.CalculatorsInfoSection;
      this.historySection = data.NeoHistory;
      this.contactSection = data.ContactSection;
      this.bmiCalculator = data.BMITexts;

      const seoData = data?.pluginSeo;
      this.seo.initializeSeo(seoData);
    });
  }
}
