import {LeistungFilterJson} from "../jsons/leistungfilterJson";
import {NeoClass} from "./neo-class";

export class LeistungFilter implements NeoClass<LeistungFilterJson> {
    public key: string;
    public wertung: number;
    public id: number;

    constructor(lf?: LeistungFilterJson) {
        if (lf) {
            this.key = lf.key;
            this.wertung = lf.wertung;
        }
    }

    public static fromJson(response: LeistungFilterJson[]): LeistungFilter[] | undefined {
        if (response) {
            return response.map(lf => new LeistungFilter(lf));
        }
    }

    public static toJsonArray(value: LeistungFilter[]): LeistungFilterJson[] | undefined {
        if (value) {
            return value.map(lf => new LeistungFilterJson(lf));
        }
    }

    public toJson() {
        const temp = new LeistungFilterJson();
        temp.key = this.key;
        temp.wertung = this.wertung;
        return temp;
    }
}


