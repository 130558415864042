@if(content){
<div class="mb-50">

    <app-title-section [content]="content?.calculatorHeader" [useCenter]="true"></app-title-section>
</div>
<div class="buttons">
    @for(button of content?.calculatorButton; track button){

    <div>

        <app-calculator-button [content]="button"></app-calculator-button>
    </div>
    }
</div>
}