import { NeoInputCompleterItem } from './neo-input-completer.item';

import {AgencyJson} from "../jsons/agency-find.json";

export class Agency implements NeoInputCompleterItem {
    public city: string;
    public postalCode: number;
    public key: string;

    constructor(city?: string, postalCode?: number) {
        this.city = city;
        this.postalCode = postalCode;
        
    }

    public static fromAgency(v: Agency) {
        return new Agency(v.city, v.postalCode);
    }

    public static fromJson(value: AgencyJson): Agency {
        const result = new Agency();
        result.city = value.city;
        result.postalCode = value.postalCode;
        return result;
    }

    public toJson(): AgencyJson {
        const result = new AgencyJson();
        result.city = this.city;
        result.postalCode = this.postalCode;
        return result;
    }

    public get value(): string {
        return `${this.city} in ${this.postalCode}`;
    }

    public toString(): string {
        return this.value;
    }
}
