<!-- agencyfind -->
<div class="neo-agencyfind-height">
  <div class="neo-agencyfind-left">
    <!-- Agencies -->
    @if(!showDetails){
    <div class="search-filter">
      <div class="neo-agencyfind-left-search">
        @if(standortPlaces?.HeaderText){
        <h3 class="neo-agencyfind-left-search-header ">
          {{ standortPlaces.HeaderText }}
        </h3>
        }
        <input type="text" [(ngModel)]="searchAgency" class="neo-agencyfind-left-search-input " id="search"
          placeholder="{{ standortPlaces?.searchText }}" name="searchAgency" />
      </div>
      <div class="neo-agencyfind-left-content">
        @for(place of FindPlaces | filter : searchAgency; track trackByFn){

        <div class="neo-agencyfind-left-list" (click)="setSelectedAgencyDetails(place)">
          <button class="neo-agencyfind-left-button">
            @if(place?.PlaceName){
            <span class="neo-agencyfind-left-list-text f-big">{{ place.PostalCode + ' - ' + place.PlaceName}} </span>

            }
          </button>

          <button class="neo-agencyfind-left-button-slide">
            @if(place.PlaceName){
            <span class="neo-agencyfind-left-list-text">{{ place.PostalCode + ' - ' + place.PlaceName}}</span>
            }
          </button>
          @if(FindPlaces.length === 0){
          <p>No Result</p>
          }
        </div>
        }
      </div>
    </div>
    }
    @if(showDetails && selectedAgency){
    <div class="neo-agencyfind-left-details-card">
      <div class="w-full gap-40 flex flex-col items-start self-stretch">
        <div class="text-[#2D2D2D] font-[700] gap-2 flex items-center self-stretch">
          <div class="w-4 h-4 cursor-pointer" (click)="toggleDetails(); clearSelectedAgency(); agencySearchClear()">
            <svg width="100%" height="100%" preserve-aspect-ratio="none" view-box="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M 14.428 7.321 H 3.98 L 10.234 1.893 C 10.334 1.805 10.273 1.643 10.141 1.643 H 8.56 C 8.491 1.643 8.425 1.668 8.373 1.712 L 1.625 7.568 C 1.563 7.621 1.513 7.688 1.479 7.762 C 1.445 7.836 1.428 7.917 1.428 7.999 C 1.428 8.081 1.445 8.162 1.479 8.236 C 1.513 8.311 1.563 8.377 1.625 8.43 L 8.412 14.321 C 8.439 14.345 8.471 14.357 8.505 14.357 H 10.139 C 10.271 14.357 10.332 14.193 10.232 14.107 L 3.98 8.679 H 14.428 C 14.507 8.679 14.571 8.614 14.571 8.536 V 7.464 C 14.571 7.386 14.507 7.321 14.428 7.321 Z"
                fill="#737387" />
            </svg>
          </div>
          <p class="[flex-grow:1] w-[296px] text-base leading-6 header-postal-place-name ">
            {{selectedAgency.PostalCode + ' - ' + selectedAgency.PlaceName}}
          </p>
        </div>
        <div class="font-[400] gap-40 flex flex-col items-start items-info">
          <div class="w-80 flex flex-col items-start">
            <div class="text-[#737387] pr-4 gap-2 flex justify-center items-center">
              <p class="tracking-[0.08em] w-[59px] text-xs leading-5 subheader">
                {{"STANDORT.DETAILS.ADRESS" | translate}}
              </p>
            </div>
            <div class="text-[#2D2D2D] pr-4 gap-2 flex justify-center items-center py-1">
              <p class="text-base leading-6 subheader-value">{{selectedAgency.StandortDetails.Address}}</p>
            </div>
          </div>
          <div class="flex flex-col items-start">
            <div class="text-[#737387] gap-2">
              <p class="tracking-[0.08em] w-[104px] text-xs leading-5 subheader">
                {{"STANDORT.DETAILS.PHONE-NUMBER" | translate}}

              </p>
            </div>
            <div class="text-[#2D2D2D] gap-2 flex justify-center items-center py-1">
              <p class="text-base leading-6 subheader-value">{{selectedAgency.StandortDetails.Phone}}</p>
            </div>
          </div>
          <div class="w-80 flex flex-col items-start">
            <div class="text-[#737387] pr-4 gap-2 flex justify-center items-center">
              <p class="tracking-[0.08em] w-[72px] text-xs leading-5 subheader">
                {{"STANDORT.DETAILS.DAYS-OPEN" | translate}}

              </p>
            </div>
            <div class="text-[#2D2D2D] pr-4 gap-2 flex justify-center items-center py-1">
              <p class="text-base leading-6 subheader-value">{{selectedAgency.StandortDetails.WorkingDays}}</p>
            </div>
          </div>
          <div class="w-80 flex flex-col items-start">
            <div class="text-[#737387] pr-4 gap-2 flex justify-center items-center">
              <p class="tracking-[0.08em] text-xs leading-5 subheader">
                {{"STANDORT.DETAILS.HOURS-OPEN" | translate}}

              </p>
            </div>
            <div class="text-[#2D2D2D] pr-4 gap-2 flex justify-center items-center py-1">
              <p class="text-base leading-6 subheader-value">
                {{selectedAgency.StandortDetails.WorkingHours}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <button
          class="open-map-btn"
        > -->
      @if(standortPlaces.OpenInMapText){
      <a class="open-map-btn" rel="noopener noreferrer" href="{{selectedAgency.StandortDetails.LinkInMaps}}"
        target="_blank">{{ standortPlaces.OpenInMapText }}</a>
      }
      <!-- </button> -->
    </div>
    }
  </div>

  <!-- Agency details -->

  <div class="neo-agencyfind-right">
    @if(apiLoaded){
    <div class="neo-google-maps">
      <div [ngClass]="{ 'map-full-height': isScreenLarge, 'map-reduced-height': !isScreenLarge }">
      <google-map [options]="options" 
      width="auto" height="100%">

        @for(marker of markers; let i =$index; track marker){
        <map-marker [position]="marker.position" [label]="marker.label" [title]="marker.title"
          [options]="marker.options" (mapClick)="setSelectedAgencyDetails(marker)">
          <map-info-window>
            {{ marker.title }}
          </map-info-window>
        </map-marker>
        }
      </google-map>
      </div>
    </div>

    }
  </div>
</div>