import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
// import {NeoDialogData} from "../models/neo-dialog.data";

export interface NeoDialogData {
    title: string;
    text: string;
}

@Component({
    templateUrl: './dialog-overview-example-dialog.html',
    styleUrls: ['./dialog-overview-example-dialog.scss']
})
export class DialogOverviewExampleDialogComponent {
    constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
