import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AgenciesComponent } from './pages/agencies/agencies.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { NeoCalculatorComponent } from './pages/neo-calculator/neo-calculator.component';
import { KrankenkassenComponent } from './pages/services/krankenkassen/krankenkassen.component';
import { RechtsschutzversicherungComponent } from './pages/services/rechtsschutzversicherung/rechtsschutzversicherung.component';
import { AutoversicherungComponent } from './pages/services/autoversicherung/autoversicherung.component';
import { HausratversicherungComponent } from './pages/services/hausratversicherung/hausratversicherung.component';
import { LebensversicherungComponent } from './pages/services/lebensversicherung/lebensversicherung.component';
import { UnternehmenskundenComponent } from './pages/services/unternehmenskunden/unternehmenskunden.component';
import { HypothekenrechnerComponent } from './components/hypothekenrechner/hypothekenrechner.component';
import { FcDietikonComponent } from './pages/fc-dietikon/pages/fc-dietikon.component';
import { VorsorgerechnerPageComponent } from './components/vorsorgerechner/vorsorgerechner.component';
import { VsrContactFormComponent } from 'vorsorgerechner-lib';
import { MessageComponent } from './components/message/message.component';
import { TranslationResolver } from './TranslationResolver';
import { ErrorPageComponent } from './components/error-page/error-page.component';

export const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: "fcdietikon", component: FcDietikonComponent },
    { path: "about", component: AboutUsComponent },
    { path: 'agencies', component: AgenciesComponent },
    { path: "krankenkassen", component: KrankenkassenComponent },
    { path: "rechtsschutzversicherung", component: RechtsschutzversicherungComponent },
    { path: "hausratversicherung", component: HausratversicherungComponent },
    { path: "autoversicherung", component: AutoversicherungComponent },
    { path: "lebensversicherung", component: LebensversicherungComponent },
    { path: "unternehmenskunden", component: UnternehmenskundenComponent },
    { path: "calculator", component: NeoCalculatorComponent },
    { path: "message-success", component: MessageComponent },
    { path: "vorsorgerechner", component: VorsorgerechnerPageComponent },
    { path: "vorsorgerechner/contact", component: VsrContactFormComponent },
    { path: 'vorsorgerechner/contact/:data', component: VsrContactFormComponent },
    { path: "hypothekenrechner", component: HypothekenrechnerComponent },
    { path: 'error', component: ErrorPageComponent },
    { path: ":footerRedirectLink", component: ImpressumComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            scrollOffset: [0, 50],
            // relativeLinkResolution: 'legacy',
        }
    )],
    exports: [RouterModule],

})

export class AppRoutingModule { }
