<div *ngIf="neoLabel && neoLabel.length > 0" class="neo-vgr-input-margin ">
    {{neoLabel}}
    <span *ngIf="neoRequired === true && neoShowRequired === true" class="neo-contact-form-required">
        *
    </span>
</div>
<mat-select 
    ngClass="{{ showErrorCss ? 'neo-message-error-input':'' }}"
    class="c neo-vgr-input-field neo-vgr-input-margin neo-vgr-input-field-text neo-helper-user-select-auto"
    placeholder="{{neoPlaceholder}}"
    [(ngModel)]="neoValue"
    (ngModelChange)="neoValueChange($event)"
    matTooltip="{{neoErrorTooltipText}}"
    matTooltipClass="neo-message-error-tooltip"
    [matTooltipDisabled]=[!showErrorCss]
    [matTooltipPosition]="'right'"
    >
        <mat-option *ngFor="let option of neoSelectOptions; let i=index" [value]="neoSelectValues[i]">
            {{option}}
        </mat-option>
 </mat-select>
