@if(content && content.showSection){
<div class="button-component" [style.--btnBgColor]="content?.bgColor" [style.--color]="content?.buttonTextColor">
  <button class="component-btn" (click)="redirectTo(content?.redirectLink, content?.redirectFragment)">
    {{content?.buttonText}}
    <!-- <img class="icon"  [style.color]="content?.buttonTextColor" src="{{  content?.btnSvg.data.attributes.url }}" alt="{{ content?.buttonText }}" /> -->

    <img class="icon" [src]="sanitizeSVG(content?.btnSvg?.data.attributes.url)" [style.fill]="content?.buttonTextColor"
      alt="{{ content?.btnSvg?.data.attributes.alternativeText }}" (mouseover)="onHover()" (mouseout)="onMouseOut()" />

  </button>
</div>
}
