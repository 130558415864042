import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { ButtonComponent } from "../button/button.component";

@Component({
    selector: 'app-title-section',
    standalone: true,
    templateUrl: './title-section.component.html',
    styleUrl: './title-section.component.scss',
    imports: [ButtonComponent]
})
export class TitleSectionComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;

  constructor(private translate: TranslateService, private router: Router) {
  }
  
  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
        this.selectedLanguage = res.lang;
      // }
    })
  }
}
