import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";

@Component({
    selector: "neo-grid-component",
    templateUrl: "./neo-grid.component.html",
    styleUrls: ["./neo-grid.component.scss"]
})
export class NeoGridComponent implements OnInit {
    @Input()
    public color:string;
    @Input()
    public title:string;
    @Input()
    public tinyRepresentation = false;
    @Input()
    public headline: string;
    @Input()
    public textContent: string;
    @Input()
    public link: string;
    @Input()
    public linkText: string;
    @Input()
    public showLink = false;
    @Input()
    public elementAsLink = false;
    @Input()
    public centerHeader = false;
    @Input()
    public iconHeadline: string;
    @Input()
    public image: string;
    @Input()
    public icon: string;
    @Input()
    public description: string;
    @Output()
    public linkEmitter = new EventEmitter<string>(true);
    @Input()
    public alt:string;
   @Input() 
   public customCssClass = "";
   @Input()
   public lazy:boolean;
    
    constructor() {}

    ngOnInit() {
    }
    openLink(event: Event) {
        if (this.elementAsLink === true) {
            event.stopPropagation();
        }
        if (this.link && this.link.length > 0) {
            if (this.link.startsWith("http") || this.link.startsWith("www")) {
                window.open(this.link, "_self");
            } else {
                this.linkEmitter.emit(this.link);
            }
        }
    }

    getColor() {
        if (this.color && this.color.length > 0) {
            return this.color;
        }
        return "--color-primary";
    }
}

export interface NeoGridComponentItems {
    colorSchema: string;
    title: string;
    imageHeadline: {url:string;}
    elementAsLink_summary: boolean;
    linkTarget: string;
    linkText_summary: string;
    description: string;
    alt:string;
}
