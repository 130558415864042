import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "neo-icon-square",
    templateUrl: "./neo-icon-square.component.html",
    styleUrls: ["./neo-icon-square.component.scss"]
})
export class NeoIconSquareComponent implements OnInit {
    @Input()
    width = '100%';
    @Input()
    height = '100%';
    @Input()
    public tinyRepresentation = false;
    @Input()
    public color: string;
    @Input()
    public headline: string;
    @Input()
    public textContent: string;
    @Input()
    public link: string;
    @Input()
    public linkText: string;
    @Input()
    public showLink = false;
    @Input()
    public elementAsLink = false;
    @Input()
    public centerHeader = false;
    @Input()
    public iconHeadline: string;
    @Input()
    public icon: string;
    @Input()
    public iconHover: string;
    @Input()
    public description: string;
    @Input()
    public alt:string;
    @Input()
    public customCssClass = '';
    @Output()
    public linkEmitter = new EventEmitter<string>(true);

    changeImg: boolean = false;
   
    constructor(public router: Router ){}
    ngOnInit(): void {
    }
    
    openLink(event: Event) {
        if (this.elementAsLink === true) {
            event.stopPropagation();
        }
        if (this.link && this.link.length > 0) {
            if (this.link.startsWith("http") || this.link.startsWith("www")) {
                window.open(this.link, "_self");
            } else {
                this.linkEmitter.emit(this.link);
            }
        }
    }

    getColor() {
        if (this.color && this.color.length > 0) {
            return this.color;
        }
        return "--color-primary";
    }
}
