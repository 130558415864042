<div class="neo-vsr-container">
  <div class="neo-vsr-main-left-side">
    <div class=" neo-vsr-left-side-height">
    <div class="neo-vsr-content-img">
        <h1 class="neo-vsr-intro neo-vsr-left-title" *ngIf="leftText.introTitle"> {{ leftText?.introTitle[selectedLanguage] }}</h1>
        <p class="neo-vsr-display-flex"  *ngIf="leftText.backgroundImgAlt">
          <img class="neo-vsr-img-sticky neo-vsr-background-logo" src="{{cmsUrl}}{{leftText?.backgroundImg.data.attributes.url}}" alt="{{leftText?.backgroundImgAlt[this.selectedLanguage]}}">
          <span class="neo-vsr-span-content" *ngIf="leftText.introContent"><markdown [data]="leftText.introContent[selectedLanguage]"></markdown>
        
          </span></p>
    </div>
  </div>
  </div>
  <div
  class="neo-vsr-right neo-vsr-right-contact">
<div class="neo-vgr-wrapper">
  <app-vsr-selected-data [vsrData]="vsrData"></app-vsr-selected-data>
    <div class="mdc-layout-grid neo-vsr-right-contact-grid">
      <form>
      <div class="mdc-layout-grid__inner">
          <div class="neo-vgr-contact-form mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
           <h2>{{'KONTAKTFORMULAR.TITEL.TITEL' | translate}}</h2>
          </div>
          <div class="neo-vgr-contact-form mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
              <neo-contact-form [formTexts]="formData"
                                [contactData]="contactData"
                                [regionData]="regions"
                                [useRegion]="true"
                                [genderImages]="images"
                                [showHeader]="false"
                                [showText]="false"
                                [showBirthday]="false"
                                [showStreetAndNumber]="false"
                                [showInterestedIn]="false"
                                [showConcern]="false"
                                [showSubmit]="false"
                                customCssClass="neo-vsr-contact-form"
                                (dataChanged)="dataChanged()"
                                >
              </neo-contact-form>
          </div>
    </div>
    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-12-phone neo-helper-text-end">
              <neo-input-button
              [neoLabel]="'KONTAKTFORMULAR.BUTTON.ABSENDEN' | translate"
              (neoValueEmitter)="sendMessage()"
              [neoButtonCssClasses]="'neo-btn-input neo-btn-input-primary neo-btn-input-navigation neo-btn-input-end'">
      </neo-input-button>

            </div>
        </div>
    </div>
    </form>
  </div>
</div>
</div>