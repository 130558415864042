import {environment} from "../../environments/environment";
import {Persondaten} from "./persondaten";
import {SortOrder, SuchparameterJson} from "../jsons/suchparameterJson";
import {NeoClass} from "./neo-class";
import {NeoRegion} from "../models/neo-region";
import {MinMaxPraemieJson} from "../jsons/minMaxPraemieJson";
import {NeoComponent} from "../components/neo.component";
import {Doctor} from ".//doctor";

export class Suchparameter implements NeoClass<SuchparameterJson> {
    tarifJahr: number;
    region: NeoRegion;
    nurKVG: boolean;
    tariftypen: Array<string>;
    selectedDoctor: Doctor;
    alleVersichertePersonen: Array<Persondaten>;
    sortOrder: number;

    constructor(createFirstPerson = true) {
        this.sortOrder = SortOrder.PREMIUM;
        this.nurKVG = true;
        this.tarifJahr = environment.settings.TARIFJAHR;
        this.selectedDoctor = undefined;
        this.alleVersichertePersonen = [];
        if (createFirstPerson) {
            this.alleVersichertePersonen.push(new Persondaten());
        }
    }

    get hauptPerson(): Persondaten {
        return this.alleVersichertePersonen.length > 0 ? this.alleVersichertePersonen[0] : null;
    }

    get weitereVersichertePersonen(): Array<Persondaten> {
        return this.alleVersichertePersonen.slice(1);
    }

    public static fromJson(response: SuchparameterJson): Suchparameter {
        const result = new Suchparameter();
        result.alleVersichertePersonen[0] = response.hauptPerson ? Persondaten.fromJson(response.hauptPerson) : null;
        result.tariftypen = response.tariftypen || null;

        if (response.weitereVersichertePersonen) {
            response.weitereVersichertePersonen.forEach(v => result.alleVersichertePersonen.push(Persondaten.fromJson(v)));
        }

        result.sortOrder = response.sortOrder || SortOrder.PREMIUM;
        result.nurKVG = response.nurKVG || false;
        result.region = response.region ? NeoRegion.fromJson(response.region) : null;
        result.tarifJahr = response.tarifJahr || 0;
        result.selectedDoctor = response.selectedDoctor ? Doctor.fromJson(response.selectedDoctor) : null;
        return result;
    }

    public toJson(): SuchparameterJson {
        const result = new SuchparameterJson();
        result.hauptPerson = this.hauptPerson ? this.hauptPerson.toJson() : null;
        result.region = this.region ? this.region.toJson() : null;
        result.weitereVersichertePersonen = Persondaten.toJsonArray(this.weitereVersichertePersonen);
        result.sortOrder = this.sortOrder;
        result.nurKVG = this.nurKVG;
        result.tarifJahr = this.tarifJahr;
        result.tariftypen = this.tariftypen;
        result.selectedDoctor = this.selectedDoctor ? this.selectedDoctor.toJson() : null;
        return result;
    }

    setMinMaxPraemien(praemien: MinMaxPraemieJson[]) {
        const allePersonen: Persondaten[] = this.getAllePersonen();
        for (let i = 0; i < allePersonen.length; i++) {
            allePersonen[i].minTarif = NeoComponent.formatMoney(praemien[i].min ? praemien[i].min : 0);
            allePersonen[i].maxTarif = NeoComponent.formatMoney(praemien[i].max ? praemien[i].max : 0);
        }
    }

    setMinMaxError(error: string) {
        const allePersonen: Persondaten[] = this.getAllePersonen();
        for (const person of allePersonen) {
            person.minTarif = error;
            person.maxTarif = error;
        }
    }

    getAllePersonen(): Persondaten[] {
        const tempArray: Persondaten[] = this.weitereVersichertePersonen.slice(0);
        tempArray.unshift(this.hauptPerson);
        return tempArray;
    }
}

