import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { CommonModule, ViewportScroller } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { TitleSectionComponent } from '../title-section/title-section.component';

@Component({
  selector: 'app-insurance',
  standalone: true,
  imports: [CommonModule, ButtonComponent, TitleSectionComponent],
  templateUrl: './insurance.component.html',
  styleUrl: './insurance.component.scss'
})
export class InsuranceComponent implements OnInit {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;

  @Input('content') content: any;
  @Input('reverse') reverse!: boolean;
  label: string = '';
  underLineColor: any;
  title: string = '';
  description: string = '';
  readMore: string = '';
  button: any;
  image: any;
  redirectLink: any;
  fullText: boolean = false;

  constructor(private router: Router, private translate: TranslateService, private scroller: ViewportScroller) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.selectedLanguage = res.lang;
    })
    this.label = this.content?.insuranceHeader[0]?.label;
    this.underLineColor = this.content?.insuranceHeader[0]?.underLineColor;
    this.title = this.content?.insuranceHeader[0]?.title;
    this.description = this.content?.description[0]?.children[0]?.text;
    this.readMore = this.content?.readMore;
    this.image = this.content?.image?.data?.attributes?.url;
    this.button = this.content?.button;
    this.redirectLink = this.content?.button?.redirectLink;
  }

  showMore() {
    this.fullText = !this.fullText;
  }

  scrollTo(element: string) {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  goTo(link: string) {
    this.router.navigate([link]);
  }
}
