<div class="container-fluid my-md-5 py-md-5">
    <div class="row mb-5">
        <div class="col-12 col-lg-4 mt-4 px-4">
            <div class="label" [style.--color]="content?.underLineColor">
                {{content?.label}}
                <div class="mtb-5 underline"></div>
            </div>
            <p class="mt-2 blue-30">{{faqTitle}}</p>
            <p class="font-14 grey-15">{{faqDescription}}</p>
        </div>
        <div class="col-12 col-lg-8">
            <div class="accordion" id="accordionExample">
              @for(faq of faqs; track $index){
                <div class="accordion-item">
                  <h2 class="accordion-header" id="heading{{$index}}">
                    <button class="accordion-button" [style.--bg-color]="content?.underLineColor" [class.collapsed]="$index != 0" data-bs-toggle="collapse" 
                            [attr.data-bs-target]="'#collapse' + $index" aria-expanded="false" [attr.aria-controls]="'collapse' + $index">
                      {{faq.question}}
                    </button>
                  </h2>
                  <div id="collapse{{$index}}" class="accordion-collapse collapse" [class.show]="$index == 0" 
                      [attr.aria-labelledby]="'heading' + $index" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div *ngFor="let answer of faq.answer.split('\n')">
                        <span class="font-14">{{answer}}</span>
                      </div>
                    </div>
                  </div>
                </div> 
              }      
            </div>
        </div>
    </div>
  </div>