import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {NeoInputBase} from "./neo-input.abstract.component";

@Component({
    selector: "neo-input-text",
    templateUrl: "neo-input-text.component.html",
    styleUrls: ["neo-input-text.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NeoInputTextComponent extends NeoInputBase<String> implements OnInit {
    @Input()
    disabled = false;
    @Input()
    useTextarea = false;
    @Input()
    rows = 4;
    @Input() 
    public customCss: {};

    constructor() {
        super(String);
    }

    ngOnInit() {
    }

    public neoValueChange(value: string) {
        this.neoValueEmitter.emit(value);
        this.showErrorCss = !this.neoIsValid();
        this.neoValueIsValid.emit(this.neoIsValid());
    }

    public neoIsValid(): boolean {
        if (!this.neoValue || this.neoValue.toString().length === 0) {
            if (this.neoRequired) {
                return false;
            }
            return true;
        }
        return true;
    }
}
