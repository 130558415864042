 import { AfterViewInit, Component, HostListener, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
 import { ActivatedRoute, Router } from '@angular/router';
 import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
 import { environment } from '../../../environments/environment';
 import { ApolloModule } from 'apollo-angular';
 import { GraphQLModule } from '../../graphql/graphql.module';
 import { CmsService } from '../../services/cms.service';
 import { IntroductionComponent } from '../introduction/introduction.component';
 import { CommonModule } from '@angular/common';
 import { ContactSectionComponent } from '../contact-section/contact-section.component';
 import { VorsorgerechnerClass, VorsorgerechnerModule, VorsorgerechnerComponent } from 'vorsorgerechner-lib';

 @Component({
   selector: 'app-vorsorgerechner',
   standalone: true,
   imports: [VorsorgerechnerModule, ApolloModule, GraphQLModule, IntroductionComponent, CommonModule, ContactSectionComponent, ],
   templateUrl: './vorsorgerechner.component.html',
   styleUrl: './vorsorgerechner.component.scss',
   encapsulation: ViewEncapsulation.None
 })
 export class VorsorgerechnerPageComponent implements OnInit{
   public VorsorgerechnerTranslationTexts: VorsorgerechnerClass;
  
   public VorsorgerechnerCurrentLanguage: string = "";
   selectedLanguage: string = "";
   introduction: any;
   calculatorData: any;
   contactSection: any;

   firstSegment= 'vorsorgerechner';
   VGR_BASE_URL = environment.VGR_BASE_URL;
   production = environment.production;

   constructor(public router: Router, 
     private translate: TranslateService, 
     private route: ActivatedRoute,
     private cmsService: CmsService,
     // private langService: SharedLangServiceService
   )
      {
     this.VorsorgerechnerTranslationTexts = new VorsorgerechnerClass();
     this.translateModule();
   }

   ngOnInit(): void {
     this.translate.onLangChange.subscribe((res: any) => {

       this.selectedLanguage = res.lang;
       this.getCalculatorData();
    });
     this.getCalculatorData();

     this.selectedLanguage = 'de';
     this.translateModule();
   }

   private translateModule() {
     const tempData = new VorsorgerechnerClass();
     const keys = Object.keys(tempData) as Array<keyof VorsorgerechnerClass>;
     this.VorsorgerechnerTranslationTexts = {} as VorsorgerechnerClass;
    keys.forEach(key => {
       const translatedValue = this.doTranslate(tempData[key]);
       this.VorsorgerechnerTranslationTexts[key] = translatedValue;
     });
     this.VorsorgerechnerCurrentLanguage = this.translate.currentLang;
   }
  
   private doTranslate(key: string): string {
     let result = "UNKNOWN";
     this.translate.get(key, { value: "world" }).subscribe((res: string) => {
       result = res;
     }
     );
     return result;
   }

   getCalculatorData() {
     const page = 'vsr';
     const populate = 'deep';
     const locale = 'de';
     const populateAndLocale = `?populate=${populate}&locale=${locale}`;

     this.cmsService.singlePageContent(page, populateAndLocale).subscribe((data: any) => {
        console.log(data, 'data')
       this.calculatorData = data;
       this.introduction = data.Introduction;
       // this.calculatorsInfo = data.CalculatorsInfoSection;
       // this.historySection = data.NeoHistory;
       this.contactSection = data.Contact;
     });
   }

 }
