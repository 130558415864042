import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FormsModule } from '@angular/forms';
import { BmiCalculatorService } from '../../services/bmi-calculator.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bmi-calculator',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './bmi-calculator.component.html',
  styleUrl: './bmi-calculator.component.scss'
})
export class BmiCalculatorComponent {
  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;
  screenWidth: number = window.innerWidth;

  cmsUrl = environment.CMS_URL;
  private subscriptions: Subscription[] = [];

  GENDER_MALE = "Mann";
  GENDER_FEMALE = "Frau";

  public readonly genderMale = this.GENDER_MALE;
  public readonly genderFemale = this.GENDER_FEMALE;

  maleGradient = {
    'background': 'linear-gradient(90deg, rgb(241, 126, 128) 15%,  rgb(237, 73, 78) 0%, rgb(237, 73, 78) 23.5%, rgb(138, 210, 58) 0%, rgb(138, 210, 58) 57%,  rgb(234, 0, 24) 0%, rgb(234, 0, 24) 83.5%, rgb(210, 0, 20) 0%)'
  };

  femaleGradient = {
    'background': 'linear-gradient(90deg, rgb(241, 126, 128) 15%,  rgb(237, 73, 78) 0%, rgb(237, 73, 78) 23.5%, rgb(138, 210, 58) 0%, rgb(138, 210, 58) 57%,  rgb(234, 0, 24) 0%, rgb(234, 0, 24) 83.5%, rgb(210, 0, 20) 0%)'
  };

  isMaleSelected: boolean = true;

  gender = 'm';
  calculated: any = {};
  minAge = 0;
  maxAge = 100;
  minCm = 0;
  maxCm = 250;
  minKg = 0;
  maxKg = 250;

  BMI: number = 15;
  MinSlider = 0;
  MaxSlider: number = 200;

  cm: number = 174;
  kg: number = 71;
  age: number = 25;
  results: any = {};
  data: any = {};
  datas: any = {};
  outputs = { id: 0, age: 0, sex: '', bmifrom: 0, bmito: 0, subdivision_id: 0 };
  heightThumbPosition: number = 0;
  weightThumbPosition: number = 0;
  ageThumbPosition: number = 0;
  subdivisionID: string = '';


  constructor(
    readonly api: BmiCalculatorService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        this.screenWidth = window.innerWidth;
      });
    } else {
      this.screenWidth = 0;
    }
  }

  ngOnInit() {
    this.BMI = 16;
    this.CalcMinSlider();
    this.CalcMaxSlider();
    this.calculateBMI();
    this.heightGradient();
  }

  getContentForSubdivisionID(subdivisionID: string): any {

    const content = this.content.BMIWeightTexts.data.find((item: any) => item.attributes.BMIWeightTexts.subdivision_id === subdivisionID);
    if (content) {
      return content;
    } else {
      return { attributes: { Title: '', Content: '' } };
    }
  }

  getHealthTextFirstCard(subdivisionID: string) {
    const filteredData = this.content.BMIHealthTexts.data.find((item: any) => item.attributes.BMIHealthTexts.subdivision_id === subdivisionID);

    if (filteredData && filteredData.attributes.BMIHealthTexts.HealthText && filteredData.attributes.BMIHealthTexts.HealthText.length > 0) {
      return {
        Header: filteredData.attributes.BMIHealthTexts.HealthText[0]?.Header || '',
        Content: filteredData.attributes.BMIHealthTexts.HealthText[0]?.Content || ''
      };
    } else {
      return null;
    }
  }

  getHealthTextSecondCard(subdivisionID: string) {
    const filteredData = this.content.BMIHealthTexts.data.find((item: any) => item.attributes.BMIHealthTexts.subdivision_id === subdivisionID);

    if (filteredData && filteredData.attributes.BMIHealthTexts.HealthText && filteredData.attributes.BMIHealthTexts.HealthText.length > 1) {
      return {
        Header: filteredData.attributes.BMIHealthTexts.HealthText[1]?.Header || '',
        Content: filteredData.attributes.BMIHealthTexts.HealthText[1]?.Content || ''
      };
    } else {
      return null;
    }
  }

  toggleGenderSelection(isMale: boolean): void {
    this.isMaleSelected = isMale;
    this.gender = isMale ? 'm' : 'w';

    this.calculateBMI();
    this.CalcMinSlider();
    this.CalcMaxSlider();
  }

  calculateDotPosition(): string {
    const positionPercentage = (this.BMI / (this.MaxSlider - this.MinSlider)) * 100;
    return `calc(${positionPercentage}% - 18.5px)`;
  }

  calculateBMI() {
    try {
      if (this.kg !== null && this.age !== null && this.cm !== null) {
        const data = {
          age: Number(this.age) > 20 ? 20 : Number(this.age),
          kg: Number(this.kg),
          cm: Number(this.cm),
          gender: this.gender,
        };

        const res = this.api.postBmi(data);
        this.BMI = res.calculateBMI;
        this.subdivisionID = res.outputs[0].subdivision_id.toString();

        this.outputs = res['outputs'][0];
      }

    } catch { }
  }

  CalcMinSlider() {
    try {

      this.MinSlider = this.api.tbl_outputs.filter(x => x.age === (this.age > 20 ? 20 : this.age) && x.sex === this.gender)[0].bmito - 2;

    } catch (ex) { }
  }

  CalcMaxSlider() {
    try {
      this.MaxSlider = this.api.tbl_outputs.filter(x => x.age === (this.age > 20 ? 20 : this.age) && x.sex === this.gender)
      [this.api.tbl_outputs.filter(x => x.age === (this.age > 20 ? 20 : this.age) && x.sex === this.gender).length - 1].bmifrom + 2;

    } catch (ex) { }
  }

  GetKg(valueRange: number) {
    const data = {
      bmi: valueRange,
      cm: Number(this.cm),
    };

    this.kg = Number(this.api.GetKg(data));
    this.cdr.detectChanges();
  }

  ageChanged(age: number): void {
    if (age !== 0 && age != null) {
      this.age = age;
      this.calculateBMI();
      this.CalcMinSlider();
      this.CalcMaxSlider();
    }
  }

  cmChanged(cm: number): void {
    if (cm !== 0 && cm != null) {
      this.cm = cm;
      this.calculateBMI();
      this.CalcMinSlider();
      this.CalcMaxSlider();
    }
  }

  kgChanged(kg: number): void {
    if (kg !== 0 && kg != null) {
      this.kg = kg;
      this.calculateBMI();
      this.CalcMinSlider();
      this.CalcMaxSlider();
    }
  }

  AgeClear() {
    this.age = 0;
  }

  CmClear() {
    this.cm = 0;
  }

  KgClear() {
    this.kg = 0;
  }

  heightGradient(): string {
    const sliderMin = this.minCm;
    const sliderMax = this.maxCm;
    const gradientStop1 = (100 * this.cm / (sliderMax - sliderMin));
    const gradientStop2 = 100 * (this.cm - sliderMin) / (sliderMax - sliderMin);
    this.heightThumbPosition = gradientStop1;
    return `linear-gradient(to right, #5BC9E9 0%, #5BC9E9 ${gradientStop1}%, #2F3F60 ${gradientStop2}%, #2F3F60 100%)`;
  }

  weightGradient(): string {
    const sliderMin = this.minKg;
    const sliderMax = this.maxKg;
    const gradientStop1 = (100 * this.kg / (sliderMax - sliderMin));
    const gradientStop2 = 100 * (this.kg - sliderMin) / (sliderMax - sliderMin);
    this.weightThumbPosition = gradientStop1;

    return `linear-gradient(to right, #5BC9E9 0%, #5BC9E9 ${gradientStop1}%, #2F3F60 ${gradientStop2}%, #2F3F60 100%)`;
  }

  ageGradient(): string {
    const sliderMin = this.minAge;
    const sliderMax = this.maxAge;
    const gradientStop1 = (100 * this.age / (sliderMax - sliderMin));
    const gradientStop2 = 100 * (this.age - sliderMin) / (sliderMax - sliderMin);
    this.ageThumbPosition = gradientStop1;

    return `linear-gradient(to right, #5BC9E9 0%, #5BC9E9 ${gradientStop1}%, #2F3F60 ${gradientStop2}%, #2F3F60 100%)`;
  }

  resetTallWeightAge() {
    this.cm = 0;
    this.kg = 0;
    this.age = 0;
    this.BMI = 0;
  }

  redirectTo(link: string, fragment: string) {
    let url = `${link}#${fragment}`;
    if (fragment) {
      window.location.href = url;

    }
    else if (link) {

      window.location.href = link;
    }
    else {
      return;
    }
  }

}
