import gql from "graphql-tag";

const VSR_DATA = gql`
  query VsrText {
    vsr {
      data {
        id
        attributes {
          VsrLeftText {
            introTitle {
              de
              en
              fr
              it
            }
            introContent {
              de
              en
              fr
              it
            }
            backgroundImg {
              data {
                attributes {
                  url
                }
              }
            }
            backgroundImgAlt {
              de
              en
              fr
              it
            }
          }
          VsrStep1 {
            title {
              de
              en
              fr
              it
            }
            yearsText {
              de
              en
              fr
              it
            }
            martialStatusText {
              de
              en
              fr
              it
            }
            childrenText {
              de
              en
              fr
              it
            }
            ChildText {
              de
              en
              fr
              it
            }
            addChildText {
              de
              en
              fr
              it
            }
            avgyearIncomeText {
              de
              en
              fr
              it
            }
            avgyearIncomeUnderlimitText {
              de
              en
              fr
              it
            }
            dialogInfoTitle {
              de
              en
              fr
              it
            }
            dialogInfoDescription {
              de
              en
              fr
              it
            }
            singleText {
              de
              en
              fr
              it
            }
            marriedText {
              de
              en
              fr
              it
            }
            widowerText {
              de
              en
              fr
              it
            }
          }
          VsrStep2 {
            title {
              de
              en
              fr
              it
            }
            yearsText {
              de
              en
              fr
              it
            }
            avgyearIncomeText {
              de
              en
              fr
              it
            }
            dialogInfoTitle {
              de
              en
              fr
              it
            }
            dialogInfoDescription {
              de
              en
              fr
              it
            }
          }
          VsrStep3 {
            title {
              de
              en
              fr
              it
            }
            introHeadline {
              de
              en
              fr
              it
            }
            chooseButton {
              de
              en
              fr
              it
            }
            executeButton {
              de
              en
              fr
              it
            }
          }
          VsrMatCard {
            title {
              de
              en
              fr
              it
            }
            content {
              de
              en
              fr
              it
            }
          }
          VsrStep4 {
            title {
              de
              en
              fr
              it
            }
            chartTitle {
              de
              en
              fr
              it
            }
            chartHeadline {
              de
              en
              fr
              it
            }
            moredetailsButtonText {
              de
              en
              fr
              it
            }
            contactButtonText {
              de
              en
              fr
              it
            }
            lessdetailsButtonText {
              de
              en
              fr
              it
            }
            selectYearText {
              de
              en
              fr
              it
            }
            overviewHeadline {
              de
              en
              fr
              it
            }
            overviewDescription {
              de
              en
              fr
              it
            }
            togglebuttonCharts {
              de
              en
              fr
              it
            }
            togglebuttonOverview {
              de
              en
              fr
              it
            }
          }
          Seo {
            title {
              de
              en
              fr
              it
            }
            description {
              de
              en
              fr
              it
            }
            keywords {
              de
              en
              fr
              it
            }
          }
        }
      }
    }
    
    selectGender {
      data {
        id
        attributes {
          images {
            img {
              data {
                attributes {
                  url
                }
              }
            }
            alt {
              de
              en
              fr
              it
            }
          }
        }
      }
    }
  
  }
`;

// tools{
//   colorSchema
//   toolsLink{ de en fr it }
//   description{de en fr it}
//   title{de en fr it}
// }
// homePageText{
//   Tools{
//     headline{de en fr it}
//     textContent{de en fr it}
//   }
// }
export default VSR_DATA;

