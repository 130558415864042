import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from "@angular/core";
import {NeoInputButtonComponent} from "./components/neo-input-button.component";
import {NeoInputRadioGroupComponent} from "./components/neo-input-radio-group.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AngularMaterialModule} from "./angularmaterial.module";
import {NeoInputCheckboxComponent} from "./components/neo-input-checkbox.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NeoInputTextComponent} from "./components/neo-input-text.component";
import {NeoInputTimeComponent} from "./components/neo-input-time.component";
import {NeoContactFormComponent} from "./components/neo-contact-form.component";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {NeoInputDatepickerComponent} from "./components/neo-input-datepicker.component";
import {NeoDialogComponent} from "./components/neo-dialog.component";
import {NeoRestProvider} from "./provider/neo-rest-provider";
import {NeoErrorProvider} from "./provider/neo-error-provider";
import {NeoInputCompleterComponent} from "./components/neo-input-completer.component";
import {NeoLogger} from "./provider/neo-logger.provider";
import {NeoEnvironmentProvider} from "./provider/neo-environment.provider";
// import {SwiperModule} from "ngx-swiper-wrapper";
import {NeoLoaderAnimationService} from "./services/neo-loader-animation.service";
import {NumbersOnlyDirective} from "./directives/numbers-only.directive";
import {StickyThingDirective} from "./directives/sticky-thing.directive";
import {NeoGoogleAnalyticsProvider} from "./provider/neo-google-analytics.provider";
import { AnmeldungContactFormComponent } from "./components/anmeldung-contact-form.component";
import { NeoHtmlDialogComponent } from "./components/neo-html-dialog.component";
import { NeoSelectOptionComponent } from "./components/neo-select-option.component";
import { NeoGenderSelectComponent } from "./components/neo-gender-select.component";
import { NeoIconSquareComponent } from "./components/neo-icon-square.component";
import { NeoSwiperIconComponent } from "./components/neo-swiper-icon.component";
import { NeoGridComponent } from "./components/neo-grid.component";
import { NeoInputNumberComponent } from "./components/neo-input-number.component";
import { NeoBannerComponent } from "./components/neo-banner.component";

import { AgencyFindComponent } from "./components/agency-find.component";
import { CommonModule } from "@angular/common";
import { VGRXEntryComponent } from "./components/vgr-x-entry.component";
import { StaticDataProvider } from "./provider/static-data-provider";
import { SearchSmallComponent } from "./components/search-small.component";
import { NeoInputIntegerComponent } from "./components/neo-input-integer.component";
import { NeoToolTipComponent } from "./components/neo-tooltip.component";
import { ToolTipRendererDirective } from "./directives/tooltiprenderer.directive";
import { DragFileDirective } from "./directives/dragfile.directive";
@NgModule({
    declarations: [
        NeoInputRadioGroupComponent,
        NeoInputButtonComponent,
        NeoInputCheckboxComponent,
        NeoInputTextComponent,
        NeoInputTimeComponent,
        NeoInputCompleterComponent,
        NeoInputDatepickerComponent,
        NeoContactFormComponent,
        AnmeldungContactFormComponent,
        NeoDialogComponent,
        NeoHtmlDialogComponent,
        NumbersOnlyDirective,
        StickyThingDirective,
        NeoSelectOptionComponent,
        NeoGenderSelectComponent,
        NeoIconSquareComponent,
        NeoSwiperIconComponent,
        NeoGridComponent,
        NeoInputNumberComponent,
        NeoBannerComponent,
        AgencyFindComponent,
        VGRXEntryComponent,
        SearchSmallComponent,
        NeoInputIntegerComponent,
        NeoToolTipComponent,
        ToolTipRendererDirective,
        DragFileDirective
    ],
    imports: [
        // SwiperModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AngularMaterialModule
        // AutocompleteLibModule
    ],
    exports: [
        NeoInputRadioGroupComponent,
        NeoInputButtonComponent,
        NeoInputCheckboxComponent,
        NeoInputTextComponent,
        NeoInputTimeComponent,
        NeoInputCompleterComponent,
        NeoInputDatepickerComponent,
        NeoContactFormComponent,
        NeoDialogComponent,
        NeoHtmlDialogComponent,
        NumbersOnlyDirective,
        AnmeldungContactFormComponent,
        StickyThingDirective,
        NeoGenderSelectComponent,
        NeoIconSquareComponent,
        NeoSwiperIconComponent,
        NeoGridComponent,
        NeoInputNumberComponent,
        NeoBannerComponent,
        AgencyFindComponent,
        VGRXEntryComponent,
        SearchSmallComponent,
        NeoInputIntegerComponent,
        NeoToolTipComponent,
        ToolTipRendererDirective,
        DragFileDirective
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        NeoRestProvider,
        NeoErrorProvider,
        NeoLogger,
        NeoEnvironmentProvider,
        NeoLoaderAnimationService,
        NeoGoogleAnalyticsProvider,
        StaticDataProvider
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonsUiModule {

}
