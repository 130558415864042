import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CmsService } from '../../services/cms.service';
import { TranslateService } from '@ngx-translate/core';
import { IntroductionComponent } from "../../components/introduction/introduction.component";
import { TitleSectionComponent } from "../../components/title-section/title-section.component";
import { ContactSectionComponent } from "../../components/contact-section/contact-section.component";
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-impressum',
  standalone: true,
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
  imports: [IntroductionComponent, TitleSectionComponent, ContactSectionComponent]
})
export class ImpressumComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  content: any;

  constructor(
    private cmsService: CmsService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private seo: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const footerRedirectLink = params['footerRedirectLink'];

      if (footerRedirectLink !== 'impressum' && footerRedirectLink !== 'datenschutz') {
        this.router.navigate(['/error']);
        return;
      }

      this.selectedLanguage = this.translate.currentLang;
      this.translate.onLangChange.subscribe(res => {
        this.selectedLanguage = res.lang;

        this.getCmsContent(footerRedirectLink);
      });
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.route.params.subscribe(params => {
        const footerRedirectLink = params['footerRedirectLink'];
        if (footerRedirectLink) {
          (window as any).isImpressumActive = true;
        } else {
          (window as any).isImpressumActive = false;
        }
      });
    }
  }

  getCmsContent(footerRedirectLink: string) {
    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;
    this.cmsService.singlePageContent(footerRedirectLink, populateString).subscribe(
      (res: any) => {
        this.content = res?.content;
        
        const seoData = res?.pluginSeo;
        this.seo.initializeSeo(seoData);
      });
  }
}
