import {Injectable} from "@angular/core";
import {NeoLogLevel} from "../models/neo-loglevel.types";

@Injectable()
export class NeoLogger {
    private static readonly MODULE_MAX_LENGTH = 20;
    private _module = "";
    private logLevel: NeoLogLevel;

    constructor() {
        this.initLogLevel();
    }

    public set module(m: string) {
        while (m.length < NeoLogger.MODULE_MAX_LENGTH) {
            m += " ";
        }
        this._module = m;
    }

    private convertLoglevel(value: string): NeoLogLevel {
        if (value && value === "INFO") {
            return NeoLogLevel.info;
        } else if (value && value === "ERROR") {
            return NeoLogLevel.error;
        } else if (value && value === "DEBUG") {
            return NeoLogLevel.debug;
        } else if (value && value === "WARNING") {
            return NeoLogLevel.warning;
        } else if (value && value === "NONE") {
            return NeoLogLevel.none;
        } else {
            return NeoLogLevel.all;
        }
    }

    private initLogLevel() {
        this.setLogLevel();
        this.module = "DEFAULT";
    }

    public setLogLevel(level = "NONE") {
        if (level && level.length > 0) {
            this.logLevel = this.convertLoglevel(level);
        } else {
            this.logLevel = NeoLogLevel.all;
        }
    }

    private addLogPrefix(prefix: string, msg: string): string {
        return `${this._module} | ${prefix} | ${msg}`;
    }

    public log(message: any) {
        if (this.logLevel >= NeoLogLevel.info) {
            window.console.log(message);
        }
    }

    public info(message: any, ...obj: any[]) {
        if (this.logLevel >= NeoLogLevel.info) {
            const msg = this.addLogPrefix("INFO ", message);
            this.logOnConsole("info", msg, ...obj);
        }
    }

    public warn(message: any, ...obj: any[]) {
        if (this.logLevel >= NeoLogLevel.warning) {
            const msg = this.addLogPrefix("WARN ", message);
            this.logOnConsole("warn", msg, ...obj);
        }
    }

    public error(message: any, ...obj: any[]) {
        if (this.logLevel >= NeoLogLevel.error) {
            const msg = this.addLogPrefix("ERROR", message);
            this.logOnConsole("error", msg, ...obj);
        }
    }

    public debug(message, ...obj: any[]) {
        if (this.logLevel >= NeoLogLevel.debug) {
            const msg = this.addLogPrefix("DEBUG", message);
            this.logOnConsole("debug", msg, ...obj);
        }
    }

    private logOnConsole(where: string, message, ...obj: any[]) {
        if (obj && obj.length) {
            if (where === "debug") {
                window.console.debug(message, ...obj);
            } else if (where === "info") {
                window.console.info(message, ...obj);
            } else if (where === "error") {
                window.console.error(message, ...obj);
            } else if (where === "warn") {
                window.console.warn(message, ...obj);
            }
        } else {
            if (where === "debug") {
                window.console.debug(message);
            } else if (where === "info") {
                window.console.info(message);
            } else if (where === "error") {
                window.console.error(message);
            } else if (where === "warn") {
                window.console.warn(message);
            }
        }
    }

    public createLogger(module: string): NeoLogger {
        const retVal = new NeoLogger();
        retVal.module = module;
        return retVal;
    }
}
