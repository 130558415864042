export namespace NeoVgrConstants {
    export const SERVICE_SELECTION_INDIVIDUALLY = "neo_individually";
    export const SERVICE_SELECTION_RECOMMENDATION = "neo_recommendation";
    export const FRANCHISE_DEFAULT_DATA = ["0", "100", "200", "300", "400", "500", "600", "1000", "1500", "2000", "2500"];
    export const DOCTOR_VALUE_NO_SELECTION = "noDoctor";
    export const DOCTOR_VALUE_FREE_CHOICE = "freeDoctor";
    export const DOCTOR_VALUE_SPECIFIED = "chooseDoctor";
    export const DOCTOR_VALUE_STANDARD = "standardDoctor";
    export const TARIFF_SELECTION_OVERVIEW = "overview";
    export const TARIFF_SELECTION_DETAIL = "detail";
    export const STEPPER_STEP_PERSONS = "step_1";
    export const STEPPER_STEP_SERVICES_AND_DOCTOR = "step_2";
    export const STEPPER_STEP_TARIFFS = "step_3";
    export const STEPPER_STEP_CONTACT = "step_4";
    export const STEPPER_DIALOG = "neo-vvg-dialog";

    export const TARIFF_SELECTION_NAVIGATION_DETAIL = "detail";
    export const TARIFF_SELECTION_NAVIGATION_CONTACT = "contact";

    export const RATING_TOP = "TOP";
    export const RATING_BEST = "BEST";
    export const RATING_GOOD = "GOOD";

    export const GA_KVG_ONLY = "KVG: ";
    export const GA_NUMBER_OF_PERSONS = "#P: ";
    export const GA_MAIN_PERSON_BIRTHYEAR = "JG: ";
    export const GA_MAIN_PERSON_SEX = "SEX: ";
    export const GA_ACTION_STEPPER = "stepper_";
    export const GA_ACTION_OVERVIEW_DETAILS = "overview_details";
    export const GA_CATEGORY_STATUS = "status";
    export const GA_CATEGORY_CLICK = "click";
    export const GA_LABEL_STEPPER = "Stepper: ";
    export const GA_LABEL_OVERVIEW_DETAILS_CLICK = "Overview details clicked";

    export const TARIFF_TYPE_HMO = "HMO";
    export const TARIFF_TYPE_HAM = "HAM";
    export const TARIFF_TYPE_BASE = "BASE";
    export const TARIFF_TYPE_DIV = "DIV";

    export const URL_PART_SEARCH = "suche";
    export const URL_PART_CONTACTS = "contacts";
    export const URL_PART_CONFIGS = "configs";
    export const URL_PART_REGIONS = "regionen";
    export const URL_PART_DOCTORS = "aerzte";
    export const URL_PART_SEARCH_DATA = "suchdaten";
    export const URL_PART_FILTER = "filter";

    export const URL_REST_ENDPOINT_TARIFFSV2 = "tarifeV2";
}
