import { Component } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { IntroductionComponent } from '../../components/introduction/introduction.component';
import { VisionSectionComponent } from '../../components/vision-section/vision-section.component';
import { HistorySectionComponent } from '../../components/history-section/history-section.component';
import { TeamSectionComponent } from '../../components/team-section/team-section.component';
import { ContactSectionComponent } from '../../components/contact-section/contact-section.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [IntroductionComponent, VisionSectionComponent, HistorySectionComponent, TeamSectionComponent, ContactSectionComponent],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent {
  aboutUsData: any;
  introduction: any;
  visionSection: any;
  historySection: any;
  teamSection: any;
  selectedLanguage!: string;
  childViewInitialized: boolean = false;
  currentPage: string | undefined;
  allMenuItems: any;
  fragment: string[] = [];

  constructor(private cmsService: CmsService, private translate: TranslateService, private route: ActivatedRoute,private seo: SeoService) {
    this.currentPage = this.route.snapshot.routeConfig?.path;
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe((res) => {
      this.selectedLanguage = res.lang;
      this.getAboutUsData();
    });
    // this.getAboutUsData();
    this.getMenuItems();
  }

  onChildViewInitialized(): void {
    this.childViewInitialized = true;
    const fragment = this.route.snapshot.fragment;

    if (fragment) {
      setTimeout(() => {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 10);
    }
  }

  getMenuItems() {
    this.cmsService.allMenuItems.subscribe(menuItems => {
      if (menuItems.length > 0) {

        this.allMenuItems = menuItems;
        const aboutMenuItems = this.allMenuItems.filter((item: any) => item.redirectLink === this.currentPage);
        aboutMenuItems.forEach((aboutMenuItem: any) => {
          const subMenuItem = aboutMenuItem.subMenuItem;
          subMenuItem.forEach((item: any) => {
            const fragment = item.fragment;
            this.fragment.push(fragment);
          });
        });
      }
    });
  }

  getAboutUsData() {
    const page = 'about-us';
    const populate = 'deep';
    const locale = this.selectedLanguage;;
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;

    this.cmsService.singlePageContent(page, populateAndLocale).subscribe((data: any) => {
      this.aboutUsData = data;
      this.introduction = data.introduction;
      this.visionSection = data.VisionSection;
      this.historySection = data.HistorySection;
      this.teamSection = data.TeamSection;
      const seoData = data?.pluginSeo;
      this.seo.initializeSeo(seoData);
    });
  }
}