@if(content?.introduction && content.introduction.showSection){
    <app-introduction [content]="content?.introduction" [useCenter]="false"></app-introduction>
}

@if(content?.historySection && content.historySection.showSection){
    <div class="px-100">
        <app-history-section (viewInitialized)="onChildViewInitialized()" [content]="content?.historySection" [useCenter]="false" [marginBottom]="false"></app-history-section>
    </div>
}

@if(content?.location && content.location.showSection){
    <div class="pb-60" >
        <app-location [id]="fragment[0]" (viewInitialized)="onChildViewInitialized()" [content]="content?.location"></app-location>
      </div>
}

<app-contact-section [id]="fragment[1]" (viewInitialized)="onChildViewInitialized()" [useCenter]="false"></app-contact-section>
