import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { CmsService } from '../../../services/cms.service';
import { IntroductionComponent } from '../../../components/introduction/introduction.component';
import { FaqComponent } from '../../../components/faq/faq.component';
import { KvgCardComponent } from '../../../components/kvg-card/kvg-card.component';
import { InsuranceExpertComponent } from '../../../components/insurance-expert/insurance-expert.component';
import { FranchiseComponent } from '../../../components/franchise/franchise.component';
import { InsuranceComponent } from '../../../components/insurance/insurance.component';
import { ContactSectionComponent } from '../../../components/contact-section/contact-section.component';
import { TeamSectionComponent } from '../../../components/team-section/team-section.component';
import { BenefitSectionComponent } from '../../../components/benefit-section/benefit-section.component';
import { SeoService } from '../../../services/seo.service';

@Component({
  selector: 'app-krankenkassen',
  standalone: true,
  imports: [IntroductionComponent, KvgCardComponent, InsuranceComponent, BenefitSectionComponent, InsuranceExpertComponent,
    FranchiseComponent, FaqComponent, TeamSectionComponent, ContactSectionComponent],
  templateUrl: './krankenkassen.component.html',
  styleUrl: './krankenkassen.component.scss'
})
export class KrankenkassenComponent implements OnInit {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  introduction: any;
  kvgSection: any;
  insuranceAdditional: any;
  backArrow: any;
  forwardArrow: any;
  benefitSection: any;
  insuranceCompanies: any;
  insuranceExpert: any;
  franchise: any;
  faq: any;
  contactSection: any;

  constructor(public route: ActivatedRoute, private cmsService: CmsService, private translate: TranslateService,
    private seo: SeoService) { }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.selectedLanguage = res.lang;
      this.getCmsContent();
    })
    this.getCmsContent();
  }

  getCmsContent() {
    const route = this.route.snapshot.routeConfig?.path;
    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;

    this.cmsService.collectionService('services', populateString).pipe(
      map((result: any) => result.data.filter((page: any) => page.attributes.name.toLowerCase() == route)))
      .subscribe((page: any) => {
        const data = page[0]?.attributes;

        this.introduction = data?.introduction;
        this.kvgSection = data?.kvgSection;
        this.insuranceAdditional = data?.insuranceAdditional;
        this.insuranceCompanies = data?.insuranceCompanies;

        this.backArrow = data?.backArrow?.data?.attributes?.url;
        this.forwardArrow = data?.forwardArrow?.data?.attributes?.url;
        this.benefitSection = data?.benefitSection;
        this.insuranceExpert = data?.insuranceExpert;
        this.franchise = data?.franchise;
        this.faq = data?.faq;
        this.contactSection = data?.ContactSection;

        const seoData = data?.pluginSeo;
        this.seo.initializeSeo(seoData);
      })

  }
}
