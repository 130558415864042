import {Persondaten} from "../models/persondaten";
import {LeistungFilterJson} from "./leistungfilterJson";
import {LeistungFilter} from "../models/leistung-filter";

export class PersondatenJson {
    geschlecht: string;
    franchise: number;
    jahrgang: number;
    neoRecommendation: boolean;
    unfalldeckung: boolean;
    leistungFilter: Array<LeistungFilterJson>;

    constructor(data?: Persondaten) {
        if (data) {
            this.geschlecht = data.geschlecht;
            this.franchise = data.franchise;
            this.jahrgang = data.jahrgang;
            this.neoRecommendation = data.neoRecommendation;
            this.unfalldeckung = data.unfalldeckung;
            this.leistungFilter = (data.leistungFilter ? LeistungFilter.toJsonArray(data.leistungFilter) : null);
        }
    }
}


