import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NeoDialogData} from "../models/neo-dialog.data";

@Component({
    templateUrl: "./neo-html-dialog.component.html",
    styleUrls: ["./neo-html-dialog.component.scss"]
})
export class NeoHtmlDialogComponent {
    constructor(public dialogRef: MatDialogRef<NeoHtmlDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: NeoDialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
