export class AnmeldungFormData {
    supervisor: string;
    firstName: string;
    lastName: string;
    birthday: Date;
    address: string;
    zip: string;
    town: string;
    email: string;
    phone: string;
    contactKind = "neo_academy";
    data: any;
    employer: string;
    billingAddress: string;
    supervisorEmailAddress: string;
    experience: string;
    supervisorPhone: string;
}
