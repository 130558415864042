import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousand'
})
export class ThousandPipe implements PipeTransform {

  transform(value: number): string {
    return value.toString().replace(/`/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1`')
  }

}
