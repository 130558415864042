import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {NeoInputBase} from "./neo-input.abstract.component";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {MomentDateAdapter} from "@angular/material-moment-adapter";

@Component({
    selector: "neo-input-datepicker",
    templateUrl: "neo-input-datepicker.component.html",
    styleUrls: ["neo-input-datepicker.component.scss"],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]}
    ]
})

export class NeoInputDatepickerComponent extends NeoInputBase<Date> implements OnInit, OnChanges {
    @Input()
    neoStartView = "year";

    @Input()
    public selectedLanguage: string = "de";

    constructor(public adapter: DateAdapter<any>) {
        super(Date);
        this.adapter.setLocale(this.selectedLanguage);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('selectedLanguage' in changes) {
        this.adapter.setLocale(this.selectedLanguage);
        }
    }
    ngOnInit() {
        // this.neoValue = undefined;
        // if (this.neoRequired && (!this.neoValue || this.neoValue.toString() === "")) {
        //     this.neoValue = new Date(1990,0,1);
        // }
    }

    public neoIsValid(): boolean {
        return (this.neoValue && this.adapter.isValid(this.neoValue));
    }

    public dateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
        this.neoValue = event.value;
        this.showErrorCss = !this.neoIsValid();
        this.neoValueEmitter.emit(this.neoValue);
    }
}
