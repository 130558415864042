import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Region } from '../models/regions';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class BmiCalculatorService {
  data: any = {};

  constructor(readonly http: HttpClient) { }
  private readonly VGR_URL = environment.VGR_BASE_URL;
  public regions: Region[] = [];

  public getMinMax(searchParams: string) {
    return this.http.get<[{ min: number, max: number }]>(`${this.VGR_URL}/minMaxTarife?filter=${searchParams}`);
  }

  public loadRegions(): Observable<Array<Region>> {
    return this.http.get<Region[]>(`${this.VGR_URL}/regionen`);
  }

  tbl_outputs = [
    { id: 1, age: 1, sex: 'm', bmifrom: 0, bmito: 15.4, subdivision_id: 1 },
    { id: 2, age: 1, sex: 'm', bmifrom: 15.5, bmito: 16.7, subdivision_id: 2 },
    { id: 3, age: 1, sex: 'm', bmifrom: 16.8, bmito: 18.2, subdivision_id: 3 },
    { id: 4, age: 1, sex: 'm', bmifrom: 18.3, bmito: 19.5, subdivision_id: 4 },
    { id: 5, age: 1, sex: 'm', bmifrom: 19.6, bmito: 99, subdivision_id: 5 },
    { id: 6, age: 2, sex: 'm', bmifrom: 0, bmito: 14.4, subdivision_id: 1 },
    { id: 7, age: 2, sex: 'm', bmifrom: 14.5, bmito: 15.6, subdivision_id: 2 },
    { id: 8, age: 2, sex: 'm', bmifrom: 15.7, bmito: 17, subdivision_id: 3 },
    { id: 9, age: 2, sex: 'm', bmifrom: 17.1, bmito: 18.2, subdivision_id: 4 },
    { id: 10, age: 2, sex: 'm', bmifrom: 18.3, bmito: 99, subdivision_id: 5 },
    { id: 11, age: 3, sex: 'm', bmifrom: 0, bmito: 14.3, subdivision_id: 1 },
    { id: 12, age: 3, sex: 'm', bmifrom: 14.4, bmito: 15.5, subdivision_id: 2 },
    { id: 13, age: 3, sex: 'm', bmifrom: 15.6, bmito: 16.9, subdivision_id: 3 },
    { id: 14, age: 3, sex: 'm', bmifrom: 17, bmito: 18.1, subdivision_id: 4 },
    { id: 15, age: 3, sex: 'm', bmifrom: 18.2, bmito: 99, subdivision_id: 5 },
    { id: 16, age: 4, sex: 'm', bmifrom: 0, bmito: 14, subdivision_id: 1 },
    { id: 17, age: 4, sex: 'm', bmifrom: 14.1, bmito: 15.2, subdivision_id: 2 },
    { id: 18, age: 4, sex: 'm', bmifrom: 15.3, bmito: 16.6, subdivision_id: 3 },
    { id: 19, age: 4, sex: 'm', bmifrom: 16.7, bmito: 17.9, subdivision_id: 4 },
    { id: 20, age: 4, sex: 'm', bmifrom: 18, bmito: 99, subdivision_id: 5 },
    { id: 21, age: 5, sex: 'm', bmifrom: 0, bmito: 13.8, subdivision_id: 1 },
    { id: 22, age: 5, sex: 'm', bmifrom: 13.9, bmito: 15.1, subdivision_id: 2 },
    { id: 23, age: 5, sex: 'm', bmifrom: 15.2, bmito: 16.6, subdivision_id: 3 },
    { id: 24, age: 5, sex: 'm', bmifrom: 16.7, bmito: 18, subdivision_id: 4 },
    { id: 25, age: 5, sex: 'm', bmifrom: 18.1, bmito: 99, subdivision_id: 5 },
    { id: 26, age: 6, sex: 'm', bmifrom: 0, bmito: 13.9, subdivision_id: 1 },
    { id: 27, age: 6, sex: 'm', bmifrom: 14, bmito: 15.2, subdivision_id: 2 },
    { id: 28, age: 6, sex: 'm', bmifrom: 15.3, bmito: 16.7, subdivision_id: 3 },
    { id: 29, age: 6, sex: 'm', bmifrom: 16.8, bmito: 18.2, subdivision_id: 4 },
    { id: 30, age: 6, sex: 'm', bmifrom: 18.3, bmito: 99, subdivision_id: 5 },
    { id: 31, age: 7, sex: 'm', bmifrom: 0, bmito: 14.1, subdivision_id: 1 },
    { id: 32, age: 7, sex: 'm', bmifrom: 14.2, bmito: 15.4, subdivision_id: 2 },
    { id: 33, age: 7, sex: 'm', bmifrom: 15.5, bmito: 17, subdivision_id: 3 },
    { id: 34, age: 7, sex: 'm', bmifrom: 17.1, bmito: 18.7, subdivision_id: 4 },
    { id: 35, age: 7, sex: 'm', bmifrom: 18.8, bmito: 99, subdivision_id: 5 },
    { id: 36, age: 8, sex: 'm', bmifrom: 0, bmito: 14.3, subdivision_id: 1 },
    { id: 37, age: 8, sex: 'm', bmifrom: 14.4, bmito: 15.6, subdivision_id: 2 },
    { id: 38, age: 8, sex: 'm', bmifrom: 15.7, bmito: 17.4, subdivision_id: 3 },
    { id: 39, age: 8, sex: 'm', bmifrom: 17.5, bmito: 19.3, subdivision_id: 4 },
    { id: 40, age: 8, sex: 'm', bmifrom: 19.4, bmito: 99, subdivision_id: 5 },
    { id: 41, age: 9, sex: 'm', bmifrom: 0, bmito: 14.5, subdivision_id: 1 },
    { id: 42, age: 9, sex: 'm', bmifrom: 14.6, bmito: 15.9, subdivision_id: 2 },
    { id: 43, age: 9, sex: 'm', bmifrom: 16, bmito: 17.9, subdivision_id: 3 },
    { id: 44, age: 9, sex: 'm', bmifrom: 18, bmito: 20, subdivision_id: 4 },
    { id: 45, age: 9, sex: 'm', bmifrom: 20.1, bmito: 99, subdivision_id: 5 },
    { id: 46, age: 10, sex: 'm', bmifrom: 0, bmito: 14.8, subdivision_id: 1 },
    { id: 47, age: 10, sex: 'm', bmifrom: 14.9, bmito: 16.3, subdivision_id: 2 },
    { id: 48, age: 10, sex: 'm', bmifrom: 16.4, bmito: 18.5, subdivision_id: 3 },
    { id: 49, age: 10, sex: 'm', bmifrom: 18.6, bmito: 20.9, subdivision_id: 4 },
    { id: 50, age: 10, sex: 'm', bmifrom: 21, bmito: 99, subdivision_id: 5 },
    { id: 51, age: 11, sex: 'm', bmifrom: 0, bmito: 15.2, subdivision_id: 1 },
    { id: 52, age: 11, sex: 'm', bmifrom: 15.3, bmito: 16.8, subdivision_id: 2 },
    { id: 53, age: 11, sex: 'm', bmifrom: 16.9, bmito: 19.2, subdivision_id: 3 },
    { id: 54, age: 11, sex: 'm', bmifrom: 19.3, bmito: 21.9, subdivision_id: 4 },
    { id: 55, age: 11, sex: 'm', bmifrom: 22, bmito: 99, subdivision_id: 5 },
    { id: 56, age: 12, sex: 'm', bmifrom: 0, bmito: 15.6, subdivision_id: 1 },
    { id: 57, age: 12, sex: 'm', bmifrom: 15.7, bmito: 17.4, subdivision_id: 2 },
    { id: 58, age: 12, sex: 'm', bmifrom: 17.5, bmito: 20, subdivision_id: 3 },
    { id: 59, age: 12, sex: 'm', bmifrom: 20.1, bmito: 23, subdivision_id: 4 },
    { id: 60, age: 12, sex: 'm', bmifrom: 23.1, bmito: 99, subdivision_id: 5 },
    { id: 61, age: 13, sex: 'm', bmifrom: 0, bmito: 16.2, subdivision_id: 1 },
    { id: 62, age: 13, sex: 'm', bmifrom: 16.3, bmito: 18.1, subdivision_id: 2 },
    { id: 63, age: 13, sex: 'm', bmifrom: 18.2, bmito: 20.8, subdivision_id: 3 },
    { id: 64, age: 13, sex: 'm', bmifrom: 20.9, bmito: 24.1, subdivision_id: 4 },
    { id: 65, age: 13, sex: 'm', bmifrom: 24.2, bmito: 99, subdivision_id: 5 },
    { id: 66, age: 14, sex: 'm', bmifrom: 0, bmito: 16.8, subdivision_id: 1 },
    { id: 67, age: 14, sex: 'm', bmifrom: 16.9, bmito: 18.9, subdivision_id: 2 },
    { id: 68, age: 14, sex: 'm', bmifrom: 19, bmito: 21.8, subdivision_id: 3 },
    { id: 69, age: 14, sex: 'm', bmifrom: 21.9, bmito: 25.2, subdivision_id: 4 },
    { id: 70, age: 14, sex: 'm', bmifrom: 25.3, bmito: 99, subdivision_id: 5 },
    { id: 71, age: 15, sex: 'm', bmifrom: 0, bmito: 17.5, subdivision_id: 1 },
    { id: 72, age: 15, sex: 'm', bmifrom: 17.6, bmito: 19.7, subdivision_id: 2 },
    { id: 73, age: 15, sex: 'm', bmifrom: 19.8, bmito: 22.7, subdivision_id: 3 },
    { id: 74, age: 15, sex: 'm', bmifrom: 22.8, bmito: 26.3, subdivision_id: 4 },
    { id: 75, age: 15, sex: 'm', bmifrom: 26.4, bmito: 99, subdivision_id: 5 },
    { id: 76, age: 16, sex: 'm', bmifrom: 0, bmito: 18.1, subdivision_id: 1 },
    { id: 77, age: 16, sex: 'm', bmifrom: 18.2, bmito: 20.4, subdivision_id: 2 },
    { id: 78, age: 16, sex: 'm', bmifrom: 20.5, bmito: 23.6, subdivision_id: 3 },
    { id: 79, age: 16, sex: 'm', bmifrom: 23.7, bmito: 27.2, subdivision_id: 4 },
    { id: 80, age: 16, sex: 'm', bmifrom: 27.3, bmito: 99, subdivision_id: 5 },
    { id: 81, age: 17, sex: 'm', bmifrom: 0, bmito: 18.6, subdivision_id: 1 },
    { id: 82, age: 17, sex: 'm', bmifrom: 18.7, bmito: 21, subdivision_id: 2 },
    { id: 83, age: 17, sex: 'm', bmifrom: 21.1, bmito: 24.3, subdivision_id: 3 },
    { id: 84, age: 17, sex: 'm', bmifrom: 24.4, bmito: 27.9, subdivision_id: 4 },
    { id: 85, age: 17, sex: 'm', bmifrom: 28, bmito: 99, subdivision_id: 5 },
    { id: 86, age: 18, sex: 'm', bmifrom: 0, bmito: 19.1, subdivision_id: 1 },
    { id: 87, age: 18, sex: 'm', bmifrom: 19.2, bmito: 21.6, subdivision_id: 2 },
    { id: 88, age: 18, sex: 'm', bmifrom: 21.7, bmito: 24.9, subdivision_id: 3 },
    { id: 89, age: 18, sex: 'm', bmifrom: 25, bmito: 28.5, subdivision_id: 4 },
    { id: 90, age: 18, sex: 'm', bmifrom: 28.6, bmito: 99, subdivision_id: 5 },
    { id: 91, age: 19, sex: 'm', bmifrom: 0, bmito: 19.4, subdivision_id: 1 },
    { id: 92, age: 19, sex: 'm', bmifrom: 19.5, bmito: 22.1, subdivision_id: 2 },
    { id: 93, age: 19, sex: 'm', bmifrom: 22.2, bmito: 25.5, subdivision_id: 3 },
    { id: 94, age: 19, sex: 'm', bmifrom: 25.6, bmito: 29, subdivision_id: 4 },
    { id: 95, age: 19, sex: 'm', bmifrom: 29.1, bmito: 99, subdivision_id: 5 },
    { id: 96, age: 1, sex: 'w', bmifrom: 0, bmito: 14.9, subdivision_id: 1 },
    { id: 97, age: 1, sex: 'w', bmifrom: 15, bmito: 16.3, subdivision_id: 2 },
    { id: 98, age: 1, sex: 'w', bmifrom: 16.4, bmito: 17.8, subdivision_id: 3 },
    { id: 99, age: 1, sex: 'w', bmifrom: 17.9, bmito: 19.3, subdivision_id: 4 },
    { id: 100, age: 1, sex: 'w', bmifrom: 19.4, bmito: 99, subdivision_id: 5 },
    { id: 101, age: 2, sex: 'w', bmifrom: 0, bmito: 14.3, subdivision_id: 1 },
    { id: 102, age: 2, sex: 'w', bmifrom: 14.4, bmito: 15.6, subdivision_id: 2 },
    { id: 103, age: 2, sex: 'w', bmifrom: 15.7, bmito: 17.1, subdivision_id: 3 },
    { id: 104, age: 2, sex: 'w', bmifrom: 17.2, bmito: 18.4, subdivision_id: 4 },
    { id: 105, age: 2, sex: 'w', bmifrom: 18.5, bmito: 99, subdivision_id: 5 },
    { id: 106, age: 3, sex: 'w', bmifrom: 0, bmito: 14, subdivision_id: 1 },
    { id: 107, age: 3, sex: 'w', bmifrom: 14.1, bmito: 15.3, subdivision_id: 2 },
    { id: 108, age: 3, sex: 'w', bmifrom: 15.4, bmito: 16.8, subdivision_id: 3 },
    { id: 109, age: 3, sex: 'w', bmifrom: 16.9, bmito: 18.1, subdivision_id: 4 },
    { id: 110, age: 3, sex: 'w', bmifrom: 18.2, bmito: 99, subdivision_id: 5 },
    { id: 111, age: 4, sex: 'w', bmifrom: 0, bmito: 13.8, subdivision_id: 1 },
    { id: 112, age: 4, sex: 'w', bmifrom: 13.9, bmito: 15.2, subdivision_id: 2 },
    { id: 113, age: 4, sex: 'w', bmifrom: 15.3, bmito: 16.7, subdivision_id: 3 },
    { id: 114, age: 4, sex: 'w', bmifrom: 16.8, bmito: 18.2, subdivision_id: 4 },
    { id: 115, age: 4, sex: 'w', bmifrom: 18.3, bmito: 99, subdivision_id: 5 },
    { id: 116, age: 5, sex: 'w', bmifrom: 0, bmito: 13.7, subdivision_id: 1 },
    { id: 117, age: 5, sex: 'w', bmifrom: 13.8, bmito: 15.2, subdivision_id: 2 },
    { id: 118, age: 5, sex: 'w', bmifrom: 15.3, bmito: 16.9, subdivision_id: 3 },
    { id: 119, age: 5, sex: 'w', bmifrom: 17, bmito: 18.5, subdivision_id: 4 },
    { id: 120, age: 5, sex: 'w', bmifrom: 18.6, bmito: 99, subdivision_id: 5 },
    { id: 121, age: 6, sex: 'w', bmifrom: 0, bmito: 13.7, subdivision_id: 1 },
    { id: 122, age: 6, sex: 'w', bmifrom: 13.8, bmito: 15.2, subdivision_id: 2 },
    { id: 123, age: 6, sex: 'w', bmifrom: 15.3, bmito: 17, subdivision_id: 3 },
    { id: 124, age: 6, sex: 'w', bmifrom: 17.1, bmito: 18.8, subdivision_id: 4 },
    { id: 125, age: 6, sex: 'w', bmifrom: 18.9, bmito: 99, subdivision_id: 5 },
    { id: 126, age: 7, sex: 'w', bmifrom: 0, bmito: 13.8, subdivision_id: 1 },
    { id: 127, age: 7, sex: 'w', bmifrom: 13.9, bmito: 15.3, subdivision_id: 2 },
    { id: 128, age: 7, sex: 'w', bmifrom: 15.4, bmito: 17.3, subdivision_id: 3 },
    { id: 129, age: 7, sex: 'w', bmifrom: 17.4, bmito: 19.3, subdivision_id: 4 },
    { id: 130, age: 7, sex: 'w', bmifrom: 19.4, bmito: 99, subdivision_id: 5 },
    { id: 131, age: 8, sex: 'w', bmifrom: 0, bmito: 14, subdivision_id: 1 },
    { id: 132, age: 8, sex: 'w', bmifrom: 14.1, bmito: 15.6, subdivision_id: 2 },
    { id: 133, age: 8, sex: 'w', bmifrom: 15.7, bmito: 17.7, subdivision_id: 3 },
    { id: 134, age: 8, sex: 'w', bmifrom: 17.8, bmito: 20.1, subdivision_id: 4 },
    { id: 135, age: 8, sex: 'w', bmifrom: 20.2, bmito: 99, subdivision_id: 5 },
    { id: 136, age: 9, sex: 'w', bmifrom: 0, bmito: 14.3, subdivision_id: 1 },
    { id: 137, age: 9, sex: 'w', bmifrom: 14.4, bmito: 16, subdivision_id: 2 },
    { id: 138, age: 9, sex: 'w', bmifrom: 16.1, bmito: 18.3, subdivision_id: 3 },
    { id: 139, age: 9, sex: 'w', bmifrom: 18.4, bmito: 21, subdivision_id: 4 },
    { id: 140, age: 9, sex: 'w', bmifrom: 21.1, bmito: 99, subdivision_id: 5 },
    { id: 141, age: 10, sex: 'w', bmifrom: 0, bmito: 14.7, subdivision_id: 1 },
    { id: 142, age: 10, sex: 'w', bmifrom: 14.8, bmito: 16.5, subdivision_id: 2 },
    { id: 143, age: 10, sex: 'w', bmifrom: 16.6, bmito: 19, subdivision_id: 3 },
    { id: 144, age: 10, sex: 'w', bmifrom: 19.1, bmito: 22, subdivision_id: 4 },
    { id: 145, age: 10, sex: 'w', bmifrom: 22.1, bmito: 99, subdivision_id: 5 },
    { id: 146, age: 11, sex: 'w', bmifrom: 0, bmito: 15.2, subdivision_id: 1 },
    { id: 147, age: 11, sex: 'w', bmifrom: 15.3, bmito: 17.1, subdivision_id: 2 },
    { id: 148, age: 11, sex: 'w', bmifrom: 17.2, bmito: 19.9, subdivision_id: 3 },
    { id: 149, age: 11, sex: 'w', bmifrom: 20, bmito: 23.1, subdivision_id: 4 },
    { id: 150, age: 11, sex: 'w', bmifrom: 23.2, bmito: 99, subdivision_id: 5 },
    { id: 151, age: 12, sex: 'w', bmifrom: 0, bmito: 15.8, subdivision_id: 1 },
    { id: 152, age: 12, sex: 'w', bmifrom: 15.9, bmito: 17.9, subdivision_id: 2 },
    { id: 153, age: 12, sex: 'w', bmifrom: 18, bmito: 20.8, subdivision_id: 3 },
    { id: 154, age: 12, sex: 'w', bmifrom: 20.9, bmito: 24.3, subdivision_id: 4 },
    { id: 155, age: 12, sex: 'w', bmifrom: 24.4, bmito: 99, subdivision_id: 5 },
    { id: 156, age: 13, sex: 'w', bmifrom: 0, bmito: 16.4, subdivision_id: 1 },
    { id: 157, age: 13, sex: 'w', bmifrom: 16.5, bmito: 18.8, subdivision_id: 2 },
    { id: 158, age: 13, sex: 'w', bmifrom: 18.9, bmito: 21.8, subdivision_id: 3 },
    { id: 159, age: 13, sex: 'w', bmifrom: 21.9, bmito: 25.5, subdivision_id: 4 },
    { id: 160, age: 13, sex: 'w', bmifrom: 25.6, bmito: 99, subdivision_id: 5 },
    { id: 161, age: 14, sex: 'w', bmifrom: 0, bmito: 17.1, subdivision_id: 1 },
    { id: 162, age: 14, sex: 'w', bmifrom: 17.2, bmito: 19.5, subdivision_id: 2 },
    { id: 163, age: 14, sex: 'w', bmifrom: 19.6, bmito: 22.8, subdivision_id: 3 },
    { id: 164, age: 14, sex: 'w', bmifrom: 22.9, bmito: 26.6, subdivision_id: 4 },
    { id: 165, age: 14, sex: 'w', bmifrom: 26.7, bmito: 99, subdivision_id: 5 },
    { id: 166, age: 15, sex: 'w', bmifrom: 0, bmito: 17.6, subdivision_id: 1 },
    { id: 167, age: 15, sex: 'w', bmifrom: 17.7, bmito: 20.1, subdivision_id: 2 },
    { id: 168, age: 15, sex: 'w', bmifrom: 20.2, bmito: 23.6, subdivision_id: 3 },
    { id: 169, age: 15, sex: 'w', bmifrom: 23.7, bmito: 27.5, subdivision_id: 4 },
    { id: 170, age: 15, sex: 'w', bmifrom: 27.6, bmito: 99, subdivision_id: 5 },
    { id: 171, age: 16, sex: 'w', bmifrom: 0, bmito: 18, subdivision_id: 1 },
    { id: 172, age: 16, sex: 'w', bmifrom: 18.1, bmito: 20.6, subdivision_id: 2 },
    { id: 173, age: 16, sex: 'w', bmifrom: 20.7, bmito: 24.1, subdivision_id: 3 },
    { id: 174, age: 16, sex: 'w', bmifrom: 24.2, bmito: 28.1, subdivision_id: 4 },
    { id: 175, age: 16, sex: 'w', bmifrom: 28.2, bmito: 99, subdivision_id: 5 },
    { id: 176, age: 17, sex: 'w', bmifrom: 0, bmito: 18.2, subdivision_id: 1 },
    { id: 177, age: 17, sex: 'w', bmifrom: 18.3, bmito: 20.9, subdivision_id: 2 },
    { id: 178, age: 17, sex: 'w', bmifrom: 21, bmito: 24.6, subdivision_id: 3 },
    { id: 179, age: 17, sex: 'w', bmifrom: 24.7, bmito: 28.5, subdivision_id: 4 },
    { id: 180, age: 17, sex: 'w', bmifrom: 28.6, bmito: 99, subdivision_id: 5 },
    { id: 181, age: 18, sex: 'w', bmifrom: 0, bmito: 18.4, subdivision_id: 1 },
    { id: 182, age: 18, sex: 'w', bmifrom: 18.5, bmito: 21.2, subdivision_id: 2 },
    { id: 183, age: 18, sex: 'w', bmifrom: 21.3, bmito: 24.8, subdivision_id: 3 },
    { id: 184, age: 18, sex: 'w', bmifrom: 24.9, bmito: 28.8, subdivision_id: 4 },
    { id: 185, age: 18, sex: 'w', bmifrom: 28.9, bmito: 99, subdivision_id: 5 },
    { id: 186, age: 19, sex: 'w', bmifrom: 0, bmito: 18.5, subdivision_id: 1 },
    { id: 187, age: 19, sex: 'w', bmifrom: 18.6, bmito: 21.3, subdivision_id: 2 },
    { id: 188, age: 19, sex: 'w', bmifrom: 21.4, bmito: 25, subdivision_id: 3 },
    { id: 189, age: 19, sex: 'w', bmifrom: 25.1, bmito: 28.9, subdivision_id: 4 },
    { id: 190, age: 19, sex: 'w', bmifrom: 29, bmito: 99, subdivision_id: 5 },
    { id: 191, age: 20, sex: 'm', bmifrom: 0, bmito: 16.99, subdivision_id: 1 },
    { id: 192, age: 20, sex: 'm', bmifrom: 17, bmito: 18.49, subdivision_id: 2 },
    { id: 193, age: 20, sex: 'm', bmifrom: 18.5, bmito: 24.99, subdivision_id: 3 },
    { id: 194, age: 20, sex: 'm', bmifrom: 25, bmito: 29.9, subdivision_id: 4 },
    { id: 195, age: 20, sex: 'm', bmifrom: 30, bmito: 99, subdivision_id: 5 },
    { id: 196, age: 20, sex: 'w', bmifrom: 0, bmito: 15.99, subdivision_id: 1 },
    { id: 197, age: 20, sex: 'w', bmifrom: 16, bmito: 17.49, subdivision_id: 2 },
    { id: 198, age: 20, sex: 'w', bmifrom: 17.5, bmito: 23.99, subdivision_id: 3 },
    { id: 199, age: 20, sex: 'w', bmifrom: 24, bmito: 28.99, subdivision_id: 4 },
    { id: 200, age: 20, sex: 'w', bmifrom: 29, bmito: 99, subdivision_id: 5 }
  ];

  GetKg(data: any) {
    const calculateKg = (data.bmi * (Math.pow(data.cm / 100, 2))).toFixed(0);
    return calculateKg;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  getLocationInfo(url: string) {
    return this.http.get(url);
  }
  postBmi(data: any) {
    const calculateBMI = Number((data.kg / (Math.pow(data.cm / 100, 2))).toFixed(1));
    const outputsFiltered = this.tbl_outputs.filter(x => x.age === data.age &&
      x.sex === data.gender &&
      x.bmifrom <= calculateBMI &&
      x.bmito >= calculateBMI);

    return { outputs: outputsFiltered, calculateBMI: calculateBMI };

  }

}
