import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {

  transform(places: any, searchAgency: any): any {
    if (searchAgency)
    {
      const filterValue = searchAgency?.toString().toLowerCase();
      var agencies = places.filter((agency: any) => agency.PlaceName.toLowerCase().indexOf(filterValue) === 0);
      if (agencies.length === 0) {
        agencies = places.filter((agency: any) => agency.PostalCode && agency.PostalCode?.toString().indexOf(filterValue) === 0);
      }
      if (agencies.length === 0) {
        agencies = places.reverse().find((x: any) => x.PostalCode <= filterValue);
      }
      return agencies;
    }
    return places;

  }

}
