import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NeoInputBase } from "./neo-input.abstract.component";

@Component({
    selector: "neo-input-time",
    templateUrl: "neo-input-time.component.html",
    styleUrls: ["neo-input-time.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NeoInputTimeComponent extends NeoInputBase<String> implements OnInit {
    constructor() {
        super(String);
    }

    hoursValue : any;
    minutesValue : any;

    ngOnInit() {
    }
    minutes = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", '39', "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]
    hours = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"]
    public neoTimeChange(value: String) {
        this.neoValue = `${this.hoursValue}:${this.minutesValue}`;
        this.neoValueEmitter.emit(this.neoValue);
        this.showErrorCss = !this.neoIsValid();
        this.neoValueIsValid.emit(this.neoIsValid());
    }

    public neoIsValid(): boolean {
        return true;
    }
}
