import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CmsService } from '../../services/cms.service';
import { TranslateService, } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { ButtonComponent } from "../../components/button/button.component";

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  imports: [CommonModule, ButtonComponent]
})
export class FooterComponent {

  allMenuItems: any;
  selectedLanguage: string = 'de';
  isLanguageDropdownOpen: boolean = false;
  content: any;
  cmsUrl = environment.CMS_URL;
  openMenuItemIndex: number | null = null;

  constructor(
    private router: Router,
    private cmsService: CmsService,
    public translate: TranslateService

  ) { }

  ngOnInit() {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe((res) => {
      this.selectedLanguage = res.lang;
      this.getFooterItems();
      this.getMenuItems();

    });
  }

  getMenuItems() {
    this.cmsService.allMenuItems.subscribe(menuItems => {
      this.allMenuItems = menuItems;
      this.allMenuItems.forEach((item: any) => item.isOpen = false);
    });
  }

  toggleSubMenu(index: number): void {
    if (this.openMenuItemIndex === index) {
      this.openMenuItemIndex = null;
    } else {
      this.openMenuItemIndex = index;
    }
  }

  getFooterItems() {
    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;
    this.cmsService.singlePageContent('footer', populateString).subscribe((res: any) => {
      if (res) {
        this.content = res;
      }
    });

  }

  footerRedirectTo(link: string, fragment:string) {
    let url = `${link}#${fragment}`;
    if (fragment) {
      window.location.href=url;
    }
    else if(link){
      window.location.href=link;
    }
    else{
      return;
    }
  }

  redirectTo(link: string) {
    if (link) {
      window.location.href=link;
    }
  }

  handleMenuItemClick(i: number, menuItem: any) {
    if (menuItem.subMenuItem.length < 1) {
      this.redirectTo(menuItem.redirectLink);
      this.toggleSubMenu(i);
      window.scrollTo(0, 0);

    } else {
      this.toggleSubMenu(i);

    }
  }
  handleSubMenuItemClick(mainMenuItem:string, fragment: string, subMenuLink: string) {
    if(fragment) {
      window.location.href = `${mainMenuItem}#${fragment}`;
    } else {
      window.location.href = `${subMenuLink}`;
    }
  }

  open(url: string) {
    window.open(url, '_blank');
  }
}
