import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperDirective } from '../../../lib/swiper.directive';
import { TitleSectionComponent } from '../title-section/title-section.component';

@Component({
  selector: 'app-benefit-section',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [FormsModule, CommonModule, SwiperDirective, TitleSectionComponent],
  templateUrl: './benefit-section.component.html',
  styleUrl: './benefit-section.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BenefitSectionComponent {
  cmsUrl = environment.CMS_URL;
  benefitCard: any;
  activeIndex: any = 0;
  
  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;
  @Input('back') back: any;
  @Input('forward') forward: any;

  @ViewChild('swiper', { static: true }) swiperContainer: any;

  public config: SwiperOptions = {}

  constructor() { }

  ngOnInit(): void {
    
    this.config = {
      navigation: {
        prevEl: '.button-prev',
        nextEl: '.button-next'
      },

      slidesPerView: 'auto',
      spaceBetween: 50,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
          grid: {
            rows: 1,
            fill: 'row'
          }
        },
        650: {
          slidesPerView: 2,
          spaceBetween: 20,
          grid: {
            rows: 1,
            fill: 'row'
          }
        },
        1300: {
          slidesPerView: this.content?.benefitCard.length % 4 == 0 ? 4 : 3,
          spaceBetween: 30,
          grid: {
            rows: 1,
            fill: 'row'
          }
        }
      },
      injectStyles: [
        `
        `,
      ],
      on: { slideChange: index => { this.activeIndex = index.snapIndex; console.log('index', this.activeIndex) }, }
    };

  }
}
