import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
export interface Tag {
  name: string;
  content: string;
}
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private titleService: Title, private metaService: Meta) { }
  public setTitle(title: string): void {
    this.titleService.setTitle(title);
  }
  public updateTags(tags: Tag[]){
    tags.forEach(tag => {
      this.metaService.updateTag({
        name: tag.name, content: tag.content
      });
    });
  }
}