export const AgeBasedZins = [
    {
      yearFrom: 1985,
      yearTo: 2003,
      zins: 0.04
    },
    {
      yearFrom:2003,
      yearTo: 2004,
      zins: 0.0225
    },
    {
      yearFrom:2004,
      yearTo: 2005,
      zins: 0.0225
    },
    {
      yearFrom:2005,
      yearTo: 2008,
      zins:  0.025
    },
    {
      yearFrom:2008,
      yearTo: 2009,
      zins:  0.0275
    },
    {
      yearFrom:2009,
      yearTo: 2012,
      zins:  0.02
    },
    {
      yearFrom:2012,
      yearTo: 2014,
      zins:  0.015
    },
    {
      yearFrom:2014,
      yearTo: 2017,
      zins:  0.0125
    }
  ];
