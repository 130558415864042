import {Component, Input, OnChanges, OnInit,  SimpleChanges, ViewEncapsulation} from "@angular/core";
import {NeoInputBase} from "./neo-input.abstract.component";

@Component({
    selector: "neo-input-number",
    templateUrl: "neo-input-number.component.html",
    styleUrls: ["neo-input-number.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NeoInputNumberComponent extends NeoInputBase<Number> implements OnInit, OnChanges {
    @Input()
    disabled = false;
    @Input()
    rows = 4;
    @Input()
    min:number;
    @Input()
    max:Number;

    constructor() {
        super(Number);
    }

    ngOnInit() {   }

    onKeyup(): boolean {
        let zeroNo: Number = 0;
        if(this.neoValue > this.max){ 
             this.neoValue = this.max;
            return false;
        } else if(this.neoValue<zeroNo){this.neoValue = this.neoValue as number * -1}
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('neoValue' in changes) {
            if (changes.neoValue.currentValue === null) {
                this.neoValueEmitter.emit(0);
            }
        }
    }

    public neoValueChange(value: number) {
        this.neoValueEmitter.emit(value);
        this.showErrorCss = !this.neoIsValid();
        this.neoValueIsValid.emit(this.neoIsValid());
    }

    public neoIsValid(): boolean {
        if (!this.neoValue || this.neoValue.toString().length === 0) {
            if (this.neoRequired) {
                return false;
            }
            return true;
        }
        return true;
    }
}
