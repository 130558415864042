import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    isDevMode,
    OnDestroy,
    OnInit,
    PLATFORM_ID
} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";
import {combineLatest, Observable, Subject} from "rxjs";
import {animationFrame} from "rxjs/internal/scheduler/animationFrame";
import {map, share, startWith, takeUntil, throttleTime} from "rxjs/operators";
import { BreakpointObserver } from "@angular/cdk/layout";

export interface StickyPositions {
    offsetY: number;
    bottomBoundary: number | null;
}

export interface StickyStatus {
    isSticky: boolean;
    reachedLowerEdge: boolean;
}

/**
 * Mainly this source is taken from:
 *
 * https://w11k.github.io/angular-sticky-things/
 *
 * It was modified to meet our expectations
 */
@Directive({
    selector: "[neoSticky]"
})
export class StickyThingDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input("spacer") spacerElement: HTMLElement | undefined;
    @Input("boundary") boundaryElement: HTMLElement | undefined;

    @HostBinding("class.is-sticky")
    public sticky = false;

    @HostBinding("class.boundary-reached")
    public boundaryReached = false;

    /**
     * The field represents some position values in normal (not sticky) mode.
     * If the browser size or the content of the page changes, this value must be recalculated.
     * */
    public normalPosition$: Observable<StickyPositions>;
    public scroll$ = new Subject<any>();
    public scrollThrottled$: Observable<number>;

    public resize$ = new Subject<void>();
    public resizeThrottled$: Observable<void>;
    public status$: Observable<StickyStatus>;
    public componentDestroyed = new Subject<void>();

    constructor(public stickyElement: ElementRef, @Inject(PLATFORM_ID) public platformId: string, public breakpointObserver: BreakpointObserver) {
    }

    initValues() {
        /**
         * Throttle the scroll to animation frame (around 16.67ms) */
        this.scrollThrottled$ = this.scroll$
            .pipe(
                throttleTime(0, animationFrame),
                map(() => window.pageYOffset),
                share()
            );

        /**
         * Throttle the resize to animation frame (around 16.67ms) */
        this.resizeThrottled$ = this.resize$
            .pipe(
                throttleTime(0, animationFrame),
                share()
            );

        /**
         * Start with initial value (1 since void doesn't work) so that
         * the original position gets set during view init.*/
        this.normalPosition$ = this.resize$.pipe(startWith(1), map(_ => this.determineElementOffsets()));

        this.status$ = combineLatest(this.normalPosition$, this.scrollThrottled$)
            .pipe(
                map(([originalVals, pageYOffset]) => this.determineStatus(originalVals, pageYOffset)),
                share(),
                takeUntil(this.componentDestroyed),
            );
    }

    ngAfterViewInit(): void {
        // we must use a very short timeout here since this directive could be called
        // when the element is not visible yet and therefor use the timeout to get correct top
        setTimeout(() => {
            this.initValues();
            this.status$.subscribe(status => {
                if (status.isSticky) {
                    this.makeSticky(status.reachedLowerEdge);
                } else {
                    this.removeSticky();
                }
            });
        }, 10);
    }

    @HostListener("window:resize", [])
    onWindowResize(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.resize$.next();
        }
    }

    @HostListener("window:scroll", [])
    adapter(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.scroll$.next(null);
        }
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next();
    }

    ngOnInit(): void {
        this.checkSetup();
    }

    getComputedStyle(el: HTMLElement): ClientRect | DOMRect {
        return el.getBoundingClientRect();
    }

    private determineStatus(originalVals: StickyPositions, pageYOffset: number): StickyStatus {
        const stickyElementHeight = this.getComputedStyle(this.stickyElement.nativeElement).height;
        const reachedLowerEdge = this.boundaryElement && window.pageYOffset + stickyElementHeight >= originalVals.bottomBoundary;
        return {
            isSticky: pageYOffset > originalVals.offsetY,
            reachedLowerEdge
        };
    }

    /**
     * Gets the offset for element. If the element
     * currently is sticky, it will get removed
     * to access the original position. Other
     * wise this would just be 0 for fixed elements. */
    private determineElementOffsets(): StickyPositions {
        if (this.sticky) {
            this.removeSticky();
        }
        let bottomBoundary: number | null = null;
        if (this.boundaryElement) {
            const boundaryElementHeight = this.getComputedStyle(this.boundaryElement).height;
            const boundaryElementOffset = getPosition(this.boundaryElement).y;
            bottomBoundary = boundaryElementHeight + boundaryElementOffset;
        }
        return {offsetY: getPosition(this.stickyElement.nativeElement).y, bottomBoundary};
    }

    private makeSticky(boundaryReached = false): void {
        this.boundaryReached = boundaryReached;
        // do this before setting it to pos:fixed
        const {width, height, left} = this.getComputedStyle(this.stickyElement.nativeElement);
        const offSet =
            boundaryReached ?
                (this.getComputedStyle(this.boundaryElement).bottom - this.getComputedStyle(this.stickyElement.nativeElement).height) :
                10;
        this.sticky = true;
        this.stickyElement.nativeElement.style.top = offSet + "px";
        this.stickyElement.nativeElement.style.left = left + "px";
        this.breakpointObserver.observe('(max-width: 959px)').subscribe(res => {
            if (res.matches) {
                this.stickyElement.nativeElement.style.position = "unset";
            } else {
                this.stickyElement.nativeElement.style.position = "fixed";
            }
          })
        // this.stickyElement.nativeElement.style.width = `${width}px`;
        if (this.spacerElement) {
            this.spacerElement.style.height = `${height}px`;
        }
    }

    private checkSetup() {
        if (isDevMode() && !this.spacerElement) {
            console.warn("Please add a spacer element. That the page wouldn't jump. For Example look at " +
                "https://w11k.github.io/angular-sticky-things/");
        }
    }

    private removeSticky(): void {
        this.boundaryReached = false;
        this.sticky = false;
        this.stickyElement.nativeElement.style.position = "";
        // this.stickyElement.nativeElement.style.width = "auto";
        this.stickyElement.nativeElement.style.left = "auto";
        this.stickyElement.nativeElement.style.top = "auto";
        if (this.spacerElement) {
            this.spacerElement.style.height = "0";
        }
    }

}

// Thanks to https://stanko.github.io/javascript-get-element-offset/
// if this shouldn't work somehow take the other function from above link
function getPosition(el) {
    const rect = el.getBoundingClientRect();
    return {
        x: rect.left,
        y: rect.top,
    };
}
