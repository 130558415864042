@if(introduction && introduction.showSection){
    <app-introduction [content]="introduction" [useCenter]="false"></app-introduction>
}
@if(aboutUS && aboutUS.showSection){
    <div class="about-us">

        <app-discover-section  [content]="aboutUS" [useCenter]="false"></app-discover-section>
    </div>
}

@if(services){
    <div>
        @for(service of services; track service){
            <app-services-section  [content]="service" [useCenter]="false">
            </app-services-section>
        }
    </div>
}
@if(review && review.showSection){
    <div class="review">
        <app-review-section  [content]="review" [useCenter]="false"></app-review-section>
    </div>
}

@if(calculator && calculator.showSection){
    <div class="padding-bottom-150">
        <app-calculator-section [content]="calculator" [useCenter]="false"></app-calculator-section>
    </div>
}

@if(content?.location && content?.location.showSection){
    <div class="padding-bottom-100" id="location">
        <app-location [content]="content?.location"></app-location>
      </div>
}

@if(content?.blogSection && content?.blogSection.showSection){
    <div class="padding-bottom-100">
        <app-blogs [content]="content?.blogSection"></app-blogs>
    </div>
}

