import {Doctor} from "../models/doctor";

export class DoctorJson {
    GPV: number;
    HAV: number;
    Name: string;
    Strasse: string;
    PLZ: number;
    Ort: string;
    Kanton: string;
    Neu: number;
    GPVName: string;
    key: string;

    constructor(data?: Doctor) {
        if (data) {
            this.GPV = data.GPV;
            this.GPVName = data.GPVName;
            this.HAV = data.HAV;
            this.Name = data.Name;
            this.Neu = data.Neu;
            this.Strasse = data.Strasse;
            this.PLZ = data.PLZ;
            this.Ort = data.Ort;
            this.Kanton = data.Kanton;
            this.key = data.key;
        }
    }
}
