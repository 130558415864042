import { SeoService } from './../static/seo.service';
import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VorsorgerechnerClass } from './VorsorgerechnerClass';
import {  UntypedFormGroup } from '@angular/forms';
// import {NeoDialogComponent} from '@neo/commons-ui';
import Chart from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import { VsrData } from '../model/VsrData';
import { LocalTranslateService } from '../providers/local-translate.service';
import {skala44} from '../static/skala44';
import { UrlProvider } from '../providers/url-provider';
import { AgeBasedZins } from '../static/ageBasedZins';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import  VSR_DATA from "./query/vsr.js"
import { Apollo } from 'apollo-angular';
import { NeoDialogComponent } from '@neo/commons-ui';
// import { SwiperConfigInterface } from "ngx-swiper-wrapper";

@Component({
  selector: 'neo-vorsorgerechner-lib',
  templateUrl: './vorsorgerechner.component.html',
  styleUrls: ['./vorsorgerechner.component.scss'],
  host: { ngSkipHydration: 'true' }
})
export class VorsorgerechnerComponent implements OnInit {

  selectedLanguage = '';

  toolsComponent: any;
  toolsTitle: any;
  filteredToolsComponent : Array<any> = [];

  cmsUrl = environment.CMS_URL;
  mehrDetailsData: any = [];
  mehrDetailsLabels: any = [];
  result1TDU: any = [];
  result2TDU: any = {};

  result1TDK: any = [];
  result2TDK: any = {};


  result1IDU: any = [];
  result2IDU: any = {};


  result1IDK: any = [];
  result2IDK: any = {};

  result1ALTER: any = [];
  result2ALTER: any = {};

  namedatasets: any = {};
  isToggled = false;
  isschritte4 = false;
  private subscriptions: Subscription[] = [];
  vsrText: any = [];
  leftText: any = [];
  vsrStep1: any = [];
  vsrStep2: any = [];
  vsrStep3: any = [];
  vsrMatCard: any = [];
  vsrStep4: any = [];
neovsrMatFormFieldTduSelect: any;
neovsrMatFormFieldTdkSelect: any;
neovsrMatFormFieldIduSelect: any;
neovsrMatFormFieldIdkSelect: any;
neovsrMatFormFieldAlterSelect: any;
  // @Input() localTranslationClass.getTranslationTexts(): VorsorgerechnerClass;
  data: VsrData = {
    person: {
      age: 30,
      avgYearlyIncome: 60000,
      maritalStatus: "Verheiratet"
    },
    partner: {
      age: 27,
      avgYearlyIncome: 40000
    },
    children: []
  };

  public shouldShow = false;
  public shouldShow1 = true;
  public shouldShow2 = true;
  public shouldShow3 = true;
  public shouldShow4 = true;
  // tod_durch_unfall
  @ViewChild('TDU')
  public TDUrefChart: ElementRef;
  // tod_durch_unfall doughnut
  @ViewChild('TDU_D')
  public TDU_DrefChart: ElementRef;

  public TDUchartData: any;
  public TDUAHVData = [];
  public TDUBVGData = [];
  public TDUUVGData = [];
  public TDUEinkommenData = [];
  public TDUKTGData = [];

  // tod_durch_krankheit
  @ViewChild('TDK')
  public TDKrefChart: ElementRef;

  // tod_durch_krankheit
  @ViewChild('TDK_D')
  public TDK_DrefChart: ElementRef;

  public TDKchartData: any;
  public TDKAHVData = [];
  public TDKBVGData = [];
  public TDKUVGData = [];
  public TDKEinkommenData = [];
  public TDKKTGData = [];

  // invalid_durch_unfall
  @ViewChild('IDU')
  public IDUrefChart: ElementRef;

  // invalid_durch_unfall
  @ViewChild('IDU_D')
  public IDU_DrefChart: ElementRef;

  public IDUchartData: any;
  public IDUAHVData = [];
  public IDUBVGData = [];
  public IDUUVGData = [];
  public IDUEinkommenData = [];
  public IDUKTGData = [];

  // invalid_durch_krankheit
  @ViewChild('IDK')
  public IDKrefChart: ElementRef;

  // invalid_durch_krankheit
  @ViewChild('IDK_D')
  public IDK_DrefChart: ElementRef;

  public IDKchartData: any;
  public IDKAHVData = [];
  public IDKBVGData = [];
  public IDKUVGData = [];
  public IDKEinkommenData = [];
  public IDKKTGData = [];

  // Alter
  @ViewChild('Alter')
  public AlterrefChart: ElementRef;

  // Alter
  @ViewChild('Alter_D')
  public Alter_DrefChart: ElementRef;

  public AlterchartData: any;
  public AlterAHVData = [];
  public AlterBVGData = [];
  public AlterUVGData = [];
  public AlterEinkommenData = [];
  public AlterKTGData = [];

  // Doughnut

  // tod_durch_unfall
  @ViewChild('DoughnutTDU')
  public DoughnutTDUrefChart: ElementRef;

  public DoughnutTDUchartData: any;
  public DoughnutTDUAHVData = [];
  public DoughnutTDUBVGData = [];
  public DoughnutTDUUVGData = [];
  public DoughnutTDUEinkommenData = [];
  public DoughnutTDUKTGData = [];


  TDUCHART = false;
  TDKCHART = false;
  IDUCHART = false;
  IDKCHART = false;
  ALTERCHART = false;

  Step1 = true;
  Step2 = false;
  Step3 = false;
  Step4 = false;
  Step5 = false;

  //mehrdetailstdu
public shouldShowTDUMD = false;
public shouldShowTDUTITLE = false
public shouldShowTDUDoughnutTitle=false;
public shouldShowTDUDoughnutMD = false;
TDUD = false;
TDUDo = false;
TDUDCHART = false;
TDUMD = true;
TDUMEHR = false;

//mehrdetailstdk
public shouldShowTDKMD = false;
public shouldShowTDKTITLE = false;
public shouldShowTDKDoughnutTitle=false;
public shouldShowTDKDoughnutMD = false;
TDKD = false;
TDKDo = false;
TDKDCHART = false;
TDKMD = true;
TDKMEHR = false;

//mehrdetailsidu
public shouldShowIDUMD = false;
public shouldShowIDUTITLE = false;
public shouldShowIDUDoughnutTitle=false;
public shouldShowIDUDoughnutMD = false;
IDUD = false;
IDUDo = false;

IDUDCHART = false;
IDUMD = true;
IDUMEHR = false;

//mehrdetailsidk
public shouldShowIDKMD = false;
public shouldShowIDKTITLE = false;
public shouldShowIDKDoughnutTitle=false;
public shouldShowIDKDoughnutMD = false;
IDKD = false;
IDKDo = false;
IDKDCHART = false;
IDKMD = true;
IDKMEHR = false;

//mehrdetailsalter
public shouldShowALTERMD = false;
public shouldShowALTERTITLE = false;
public shouldShowALTERDoughnutTitle=false;
public shouldShowALTERDoughnutMD = false;
ALTERD = false;
ALTERDo = false;
ALTERDCHART = false;
ALTERMD = true;
ALTERMEHR = false;


  DoughnutTDU;
  DoughnutTDK;
  DoughnutIDU;
  DoughnutIDK;
  DoughnutAlter;

  ShowOnlyBarCharts = false;
  ShowOnlyDoughnutCharts = false;


// chart data
datasetsTDUDATA: any = [];
labelsTDUDATA: any = [];


  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;

  title = 'assicurations';
  // PersonStatus = ''; // Marital status of the person

  // Person's years old
  PersonBirthplace;
  PartnerBirthplace;
  KidGender = 'Male';
  KidYears;

  PersonYearlyIncomeTmp: string;
  PartnerYearlyIncomeTmp: string;
  BVGverslohn;
  BVGInvalidenrente; // = 1000;
  BVGAltersrenteMitZins; // = 1000;

  Partner_cbAHVIV = 14100;

  Partner_BVGInvalidenrente; // = 1000;
  Partner_BVGAltersrenteMitZins; // = 1000;

  cbAHVIV = 14100;
  KTGSelect = '0.8'; // the value of KTG combobox

  waisenrente = 0; // Orphan's pension
  waisenrente2 = 0; // Orphan's pension
  waisenrentebvg = 0; // Orphan's pension bvg
  waisenrentebvg2 = 0; // Orphan's pension bvg
  witwer_rente = 0; // Widow's pension
  witwer_rentebvg = 0; // Widow's pension BVG
  einkommen_max = 0; // Max Income
  einkommen_p = 0; // Yearly income of the Partner
  Alter = 0; // Years of the Person
  Alter_p = 0; // Years of the Partner
  Alters_differenz = 0; // How many years are missing till the pension (65 years)
  Alters_differenz_p = 0; // How many years are missing till the pension of the partner(65 years)
  Alterhaupt = 0; //  'old master' according to Google Translate
  waisenrentejahr = 0; // how many years the orphan will get money
  waisenrentejahr2 = 0; // how many years the orphan will get money
  BVG = 0;
  UVG = 0;
  waisenrentejahrbvg = 0;
  waisenrentejahrbvg2 = 0;
  witwerrentejahr = 0; // Widower pension year
  AHV_gesamt = 0; // AHV total
  BVG_gesamt = 0;
  UVG_gesamt = 0;
  Jahr = 0;
  Differenz = 0;
  witwen_witwer = 0;
  waisenrenteuvg = 0;
  witwer_renteuvg = 0;
  waisenrenteuvg2 = 0;
  waisenrentejahruvg = 0;
  waisenrentejahruvg2 = 0;

  ExecuteFunctions = {
    'tod_durch_unfall' : false,
    'tod_durch_krankheit' : false,
    'invalid_durch_unfall' : false,
    'invalid_durch_krankheit' : false,
    'Alter' : false,

  };

  GoTo2 = false;
  GoTo3 = false;

  SelectAllSelected = false;

  myDoughnutChart: any;

  LastTDUAHV = [];
  LastTDUBVG = [];
  LastTDUKTG = [];
  LastTDUUVG = [];
  LastTDUEinkommen = [];
  LabelsTDU = 0;

  LastTDKAHV = [];
  LastTDKBVG = [];
  LastTDKKTG = [];
  LastTDKUVG = [];
  LastTDKEinkommen = [];
  LabelsTDK = 0;

  LastIDKAHV = [];
  LastIDKBVG = [];
  LastIDKKTG = [];
  LastIDKUVG = [];
  LastIDKEinkommen = [];
  LabelsIDK = 0;

  LastIDUAHV = [];
  LastIDUBVG = [];
  LastIDUKTG = [];
  LastIDUUVG = [];
  LastIDUEinkommen = [];
  LabelsIDU = 0;
  LastAlterAHV = [];
  LastAlterBVG = [];
  LastAlterKTG = [];
  LastAlterUVG = [];
  LastAlterEinkommen = [];
  LabelsAlter = 0;

  ShowTwoButtons = false;
  RedLineTDU = [];
  RedLineTDK = [];
  RedLineIDU = [];
  RedLineIDK = [];
  RedLineAlter = [];

  highestVal = 0;

  HeightChart = '100%';
  WidthChart = '100%';

  ShowChart = false;
  ShowDoughnutChart = false;
  ShowRotateScreen = false;

  gaugeType = 'arch';
  gaugeLabel = 'label';
  gaugeValue = 44;

  TDU_DoughnutgaugeValue;
  TDU_gaugeLabel = '';

  TDK_DoughnutgaugeValue;
  TDK_gaugeLabel = '';

  IDU_DoughnutgaugeValue;
  IDU_gaugeLabel = '';

  IDK_DoughnutgaugeValue;
  IDK_gaugeLabel = '';

  Alter_DoughnutgaugeValue;
  Alter_gaugeLabel = '';

  gaugeAppendText = '%';

  thresholdConfig = {
    '0' : {color: '#70c9e6'}
};

flat = 'flat';
BackgroundColor = 'rgb(235,106,49)';

myChartTDU;
myDoughnutChartTDU;

myChartTDK;
myDoughnutChartTDK;

myChartIDU;
myDoughnutChartIDU;
myChartIDK;
myDoughnutChartIDK;
myChartAlter;
myDoughnutChartAlter;

MaxIDU = 0;
MaxIDK = 0;
MaxAlter = 0;
GroupAfter = 5;

@Input() translations: VorsorgerechnerClass;
@Input() firstSegment: string;
@Input() VGR_API: string;
@Input() production: boolean;

public avgYearlyIncomeThousandAsString(value): string {
  return value.toString().replace(/`/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1`');
}
public avgYearlyIncomeThousandAsNumber(value): number {
  return Number(value.replace(/`/g, ''));
}

public setPersonYearlyIncome(value) {
this.PersonYearlyIncomeTmp = this.avgYearlyIncomeThousandAsString(value);
this.data.person.avgYearlyIncome = this.avgYearlyIncomeThousandAsNumber(this.PersonYearlyIncomeTmp);
}

public setPartnerYearlyIncomeThousand(value) {
  this.PartnerYearlyIncomeTmp = this.avgYearlyIncomeThousandAsString(value);
  this.data.partner.avgYearlyIncome = this.avgYearlyIncomeThousandAsNumber(this.PartnerYearlyIncomeTmp);
  }


  openDialog(infoTitle, infoDescription): void {
    const dialogRef = this.dialog.open(NeoDialogComponent, {
      maxWidth: '100%',
      minWidth: '20%',
      panelClass: 'neo-vvg-dialog',
      data: {'title': infoTitle, 'text': infoDescription},
    });
  dialogRef.backdropClick().subscribe(() => {
    // Close the dialog
    dialogRef.close();
  }); }


  constructor(public dialog: MatDialog,
    private translate: TranslateService,
    public localTranslationClass: LocalTranslateService,
    public urlProvider: UrlProvider,
    private apollo: Apollo,
    public route: ActivatedRoute,
    private seo: SeoService
   ) {
    this.translations = new VorsorgerechnerClass();
    this.setPersonYearlyIncome(this.data.person.avgYearlyIncome);
    this.setPartnerYearlyIncomeThousand(this.data.partner.avgYearlyIncome);
    Chart.defaults.global.defaultFontColor = '#00254D'; // 'black';
    Chart.defaults.global.defaultFontFamily = 'UniviaPro-Bold'; // 'UniviaPro-Book'; // 'arial black';
  }

  // selectedLanguage = 'de';

  // public neoSwiperCareerConfig: SwiperConfigInterface = {
  //   observer: false,
  //   observeParents: false,
  //   direction: "horizontal",
  //   watchOverflow: false,
  //   setWrapperSize: false,
  //   centeredSlides: false,
  //   slideToClickedSlide: true,
  //   freeModeSticky: true,
  //   keyboard: true,
  //   mousewheel: false,
  //   loop: false,
  //   navigation: false,
  //   scrollbar: false,
  //   height: 400,
  //   pagination: {
  //     el: ".swiper-pagination",
  //     type: "bullets",
  //     clickable: true
  //   },


  //   // if there are more then 3 in the future remove Media query from neo.component.bmi.scss
  //   // This the code that sshould be removed
  //   // @media (min-width: 1204px)
  //   // {
  //   //   .swiper-el .s-wrapper .swiper-pagination{
  //   //     display: none;
  //   //   }
  //   // }

  //   // @media screen and (max-width: 959px) and (min-width: 842px)
  //   // {
  //   //   .swiper-el .s-wrapper .swiper-pagination{
  //   //     display: none;
  //   //   }
  //   // }



  //   breakpoints: {

  //     1204: {
  //       slidesPerView: 3,
  //       slidesPerGroup: 3,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },

  //     959: {
  //       slidesPerView: 2,
  //       slidesPerGroup: 2,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
  //     842: {
  //       slidesPerView: 3,
  //       slidesPerGroup: 3,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
  //     588: {
  //       slidesPerView: 2,
  //       slidesPerGroup: 2,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
  //     375: {
  //       slidesPerView: 1,
  //       slidesPerGroup: 1,
  //       spaceBetween: 15,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
  //     300: {
  //       slidesPerView: 1,
  //       slidesPerGroup: 1,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     }
  //   }
  // };

  assignLang(lang: string): void {
    this.selectedLanguage = lang;
  }

  ngOnInit() {

    this.selectedLanguage = localStorage.getItem('lang');

    // Add event listener for storage changes
    window.addEventListener('storage', this.onStorageChange.bind(this));
  
    // this.selectedLanguage = this.translate.currentLang;
    if(this.VGR_API != undefined) {
      environment.VGR_API = this.VGR_API;
    }
    if (this.production != undefined) {
      environment.production = this.production;
    }
    this.translateStuff();
    this.translate.onLangChange.subscribe(() => {
      this.ChartsButton()
      this.translateStuff()
    }
  );
    this.route.paramMap.subscribe(params => {
      const paramData = params.get("searchData");
      if (paramData && paramData.length > 0) {
          this.urlProvider.decompressData(paramData).then((data => {
              this.data = data;
            this.setPersonYearlyIncome(data.person.avgYearlyIncome);
            this.setPartnerYearlyIncomeThousand(data.partner.avgYearlyIncome);
          }));
      }
  });

  // this.selectedLanguage = this.translate.currentLang;

  this.subscriptions.push(this.translate.onLangChange.subscribe(res => {

      if(this.selectedLanguage !== res.lang){
          this.selectedLanguage = res.lang;
          this.getText();
       }
       this.getText();
   }));






    this.getText();
    this.CheckRequired1(); // check the required for the step 1
    this.CheckRequired2(); // check the required for the step 2



  }

  onStorageChange(event: StorageEvent) {
    if (event.key === 'lang') {
      this.selectedLanguage = event.newValue;
    }
  }


  DefineCharts() {

    this.RedLineTDU = [];
    this.RedLineTDK = [];
    this.RedLineIDU = [];
    this.RedLineIDK = [];
    this.RedLineAlter = [];

    const AHVColor = '#ecebea';
    const BVGColor = '#70c9e6';
    const UVGColor = '#cccccc';
    const EinkommenColor = '#00254d';
    const KTGColor = '#815496';
    // tod_durch_unfall
    this.TDUAHVData = [];
    this.TDUBVGData = [];
    this.TDUUVGData = [];
    this.TDUEinkommenData = [];
    this.TDUKTGData = [];

    this.TDUchartData = {
      labels: [],
      datasets: [
        {
          type: 'line',
          label: this.translations.VorsorgerechnerCURRENTINCOME,
          data: this.RedLineTDU,
          backgroundColor: 'red', //  yellow
          borderColor: 'red',
          borderWidth: 2,
          fill: false,
          pointRadius: 0
        },
        {
          label: this.translations.VorsorgerechnerAHV,
          data: this.TDUAHVData,
          backgroundColor: AHVColor, //  yellow
          borderColor: AHVColor,
          borderWidth: 1
        }, {
          label: this.translations.VorsorgerechnerBVG,
          data: this.TDUBVGData,
          borderWidth: 1,
          backgroundColor: BVGColor, //  yellow
          borderColor: BVGColor
        }, {
          label: this.translations.VorsorgerechnerUVG,
          data: this.TDUUVGData,
          borderWidth: 1,
          backgroundColor: UVGColor, //  yellow
          borderColor: UVGColor
        }, {
          label: this.translations.VorsorgerechnerINCOMEPARTNER,
          data: this.TDUEinkommenData,
          borderWidth: 1,
          backgroundColor: EinkommenColor, //  yellow
          borderColor: EinkommenColor
        }, {
          label: 'KTG',
          data: this.TDUKTGData,
          borderWidth: 1,
          backgroundColor: KTGColor, //  yellow
          borderColor: KTGColor
        }
      ],
      options: {
        responsive: true,
        lineHeight: 5,
        hover: {
           mode: 'nearest',
           intersect: true
         },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes : [{stacked: true ,
          ticks: {
            lineHeight: 5,
          }}]

        }
      },
    };

    // tod_durch_krankheit
    this.TDKAHVData = [];
    this.TDKBVGData = [];
    this.TDKUVGData = [];
    this.TDKEinkommenData = [];
    this.TDKKTGData = [];

    this.TDKchartData = {
      labels: [],
      datasets: [
        {
          type: 'line',
          label: this.translations.VorsorgerechnerCURRENTINCOME,
          data: this.RedLineTDK,
          backgroundColor: 'red', //  yellow
          borderColor: 'red',
          borderWidth: 2,
          fill: false,
          pointRadius: 0
        },
        {
          label: this.translations.VorsorgerechnerAHV,
          data: this.TDKAHVData,
          backgroundColor: AHVColor, //  yellow
          borderColor: AHVColor,
          borderWidth: 1
        }, {
          label: this.translations.VorsorgerechnerBVG,
          data: this.TDKBVGData,
          borderWidth: 1,
          backgroundColor: BVGColor, //  yellow
          borderColor: BVGColor
        }, {
          label: this.translations.VorsorgerechnerUVG,
          data: this.TDKUVGData,
          borderWidth: 1,
          backgroundColor: UVGColor, //  yellow
          borderColor: UVGColor
        }, {
          label: this.translations.VorsorgerechnerINCOMEPARTNER,
          data: this.TDKEinkommenData,
          borderWidth: 1,
          backgroundColor: EinkommenColor,
          borderColor: EinkommenColor
        }, {
          label: 'KTG',
          data: this.TDKKTGData,
          borderWidth: 1,
          backgroundColor: KTGColor, //  yellow
          borderColor: KTGColor
        }
      ],
      options: {
        hover: {
           mode: 'nearest',
           intersect: true
         },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    };

    // invalid_durch_unfall
    this.IDUAHVData = [];
    this.IDUBVGData = [];
    this.IDUUVGData = [];
    this.IDUEinkommenData = [];
    this.IDUKTGData = [];

    this.IDUchartData = {
      labels: [],
      datasets: [
        {
          type: 'line',
          label: this.translations.VorsorgerechnerCURRENTINCOME,
          data: this.RedLineIDU,
          backgroundColor: 'red', //  yellow
          borderColor: 'red',
          borderWidth: 2,
          fill: false,
          pointRadius: 0
        },
        {
          label: this.translations.VorsorgerechnerAHV,
          data: this.IDUAHVData,
          backgroundColor: AHVColor, //  yellow
          borderColor: AHVColor,
          borderWidth: 1
        }, {
          label: this.translations.VorsorgerechnerBVG,
          data: this.IDUBVGData,
          borderWidth: 1,
          backgroundColor: BVGColor, //  yellow
          borderColor: BVGColor
        }, {
          label: this.translations.VorsorgerechnerUVG,
          data: this.IDUUVGData,
          borderWidth: 1,
          backgroundColor: UVGColor, //  yellow
          borderColor: UVGColor
        }, {
          label: this.translations.VorsorgerechnerINCOMEPARTNER,
          data: this.IDUEinkommenData,
          borderWidth: 1,
          backgroundColor: EinkommenColor, //  yellow
          borderColor: EinkommenColor
        }, {
          label: 'KTG',
          data: this.IDUKTGData,
          borderWidth: 1,
          backgroundColor: KTGColor, //  yellow
          borderColor: KTGColor
        }
      ],
      options: {
        hover: {
           mode: 'nearest',
           intersect: true
         },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    };

    // invalid_durch_krankheit
    this.IDKAHVData = [];
    this.IDKBVGData = [];
    this.IDKUVGData = [];
    this.IDKEinkommenData = [];
    this.IDKKTGData = [];

    this.IDKchartData = {
      labels: [],
      datasets: [
        {
          type: 'line',
          label: this.translations.VorsorgerechnerCURRENTINCOME,
          data: this.RedLineIDK,
          backgroundColor: 'red', //  yellow
          borderColor: 'red',
          borderWidth: 2,
          fill: false,
          pointRadius: 0
        },
        {
          label: this.translations.VorsorgerechnerAHV,
          data: this.IDKAHVData,
          backgroundColor: AHVColor, //  yellow
          borderColor: AHVColor,
          borderWidth: 1
        }, {
          label: this.translations.VorsorgerechnerBVG,
          data: this.IDKBVGData,
          borderWidth: 1,
          backgroundColor: BVGColor, //  yellow
          borderColor: BVGColor
        }, {
          label: this.translations.VorsorgerechnerUVG,
          data: this.IDKUVGData,
          borderWidth: 1,
          backgroundColor: UVGColor, //  yellow
          borderColor: UVGColor
        }, {
          label: this.translations.VorsorgerechnerINCOMEPARTNER,
          data: this.IDKEinkommenData,
          borderWidth: 1,
          backgroundColor: EinkommenColor, //  yellow
          borderColor: EinkommenColor
        }, {
          label: 'KTG',
          data: this.IDKKTGData,
          borderWidth: 1,
          backgroundColor: KTGColor, //  yellow
          borderColor: KTGColor
        }
      ],
      options: {
        hover: {
           mode: 'nearest',
           intersect: true
         },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    };


    // Alter
    this.AlterAHVData = [];
    this.AlterBVGData = [];
    this.AlterUVGData = [];
    this.AlterEinkommenData = [];
    this.AlterKTGData = [];


    this.AlterchartData = {
      labels: [],
      datasets: [
        {
          type: 'line',
          label: this.translations.VorsorgerechnerCURRENTINCOME,
          data: this.RedLineAlter,
          backgroundColor: 'red', //  yellow
          borderColor: 'red',
          borderWidth: 2,
          fill: false,
          pointRadius: 0
        },
        {
        label: this.translations.VorsorgerechnerAHV,
        data: this.AlterAHVData,
        backgroundColor: AHVColor, //  yellow
        borderColor: AHVColor,
        borderWidth: 1
      },
      {
        label: this.translations.VorsorgerechnerBVG,
        data: this.AlterBVGData,
        borderWidth: 1,
        backgroundColor: BVGColor, //  yellow
        borderColor: BVGColor
      }, {
        label: this.translations.VorsorgerechnerUVG,
        data: this.AlterUVGData,
        borderWidth: 1,
        backgroundColor: UVGColor, //  yellow
        borderColor: UVGColor
      }, {
        label: this.translations.VorsorgerechnerINCOMEPARTNER,
        data: this.AlterEinkommenData,
        borderWidth: 1,
        backgroundColor: EinkommenColor, //  yellow
        borderColor: EinkommenColor
      },
      {
        label: 'KTG',
        data: this.AlterKTGData,
        borderWidth: 1,
        backgroundColor: KTGColor, //  yellow
        borderColor: KTGColor
      }
      ],
      options: {
        hover: {
           mode: 'nearest',
           intersect: true
         },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    };

  }

  AddtoChildren() {

    this.data.children.push({
       id: this.data.children.length + 1,
       gender: 'Male',
       years: this.KidYears,
       YearsTillAdult: 18 - this.KidYears });
      this.updateUrl();
    this.KidGender = 'Male';
    this.KidYears;
  }


  RemoveChildren(Children: any) {
    this.data.children = this.data.children.filter(item => item.id !== Children.id);
    this.updateUrl();
  }


  tod_durch_unfall() {
    this.LastTDUAHV = [];
    this.LastTDUUVG = [];
    let AHVChart: any[] = [];
    let BVGChart: any[] = [];
    let UVGChart: any[] = [];
    let EinkommenChart: any[] = [];

    const AHVChartTmp: any[] = [];
    const BVGChartTmp: any[] = [];
    const UVGChartTmp: any[] = [];
    const EinkommenChartTmp: any[] = [];

    AHVChart = [];
    AHVChart.length = 0;
    BVGChart = [];
    BVGChart.length = 0;
    EinkommenChart = [];
    EinkommenChart.length = 0;
    UVGChart = [];
    UVGChart.length = 0;

    let Jahr;
    let Alter = 0; // Years of the Person
    let Alters_differenz = 0;
    let Alter_p = 0;
    let Alters_differenz_p = 0;
    let Alterhaupt = 0;
    let einkommen_max = 0;
    let waisenrenteuvg = 0;
    let AHV_gesamt = 0; // AHV total
    let witwer_renteuvg = 0;
    let waisenrente2 = 0;
    let waisenrente = 0;
    let waisenrenteuvg2 = 0;
    let waisenrentejahr = 0;
    let waisenrentejahr2 = 0;
    let waisenrentejahruvg = 0;
    let waisenrentejahruvg2 = 0;
    let witwerrentejahr = 0;
    let witwer_rente = 0;
    let einkommen_p = 0;
    let UVG = 0;
    let UVG_gesamt = 0;
    let Differenz = 0;

    // Witwenrente Skala44
    witwer_rente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
    [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].witwen_witwer;

    // Wenn Person Verweitwet dan aus Sakal44 die waisenrente60 benutzen ansonsten waisenrente
    if (this.data.person.maritalStatus === 'Widower' || this.data.person.maritalStatus === 'Verwitwet') {
      waisenrente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
      [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].waisenrente60;
    } else {
      waisenrente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
      [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].waisenrente;
    }
    // Alter Person
    Alter = this.data.person.age;
    // Altersdifferenz
    Alters_differenz = 65 - Alters_differenz;

    // Wenn Verheiratet dann Differenz Altersdifferenz zum Ehepartner
    if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
      Alter_p = this.data.partner.age;
      Alters_differenz_p = 65 - Alter_p;
      Alterhaupt = Alters_differenz_p;
    } else {
      Alterhaupt = Alters_differenz;
    }

    // Maximales Einkommen
    einkommen_max = this.data.person.avgYearlyIncome * 0.9; // 90% of the yearly income will be the max income in the assicuration

    // UVG Maximal Versicherter Lohn
    if (einkommen_max > 133380) {
      einkommen_max = 133380;
    }

    // Einkommen Partner
    einkommen_p = this.data.partner.avgYearlyIncome;

    if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
      Alterhaupt = Alters_differenz_p;
    } else {
      Alterhaupt = Alters_differenz;
    }

    // Wenn Verwitet wenn Vollweisenrente aus dem UVG ansonsten Halbweisenrente
    if (this.data.person.maritalStatus === 'Widower' || this.data.person.maritalStatus === 'Verwitwet') {
      waisenrenteuvg = this.data.person.avgYearlyIncome * 0.25;
    } else {
      waisenrenteuvg = this.data.person.avgYearlyIncome * 0.15;
    }

    // UVG Witwerrente
    witwer_renteuvg = this.data.person.avgYearlyIncome * 0.4;

    // Anzahl Kinder wird zur AHV und UVG dazu gerechnet (z.b. eine Kinderrente 200 bei zwei Kinder 400)
    if (this.data.children.length > 0) {
      waisenrente2 = waisenrente * this.data.children.length;
      waisenrenteuvg2 = waisenrenteuvg * this.data.children.length;
      for (let i = 0; i < this.data.children.length; i++) {
        this.data.children[i].YearsTillAdult = 18 - this.data.children[i].years;
      }
    }

    // Waisenrente AHV * 12 für 12 Monate
    waisenrentejahr = waisenrente * 12;
    waisenrentejahr2 = waisenrente2 * 12;

    // Übergabe
    waisenrentejahruvg = waisenrenteuvg;
    waisenrentejahruvg2 = waisenrenteuvg2;

    // Witwenrente * 12 Monate
    witwerrentejahr = witwer_rente * 12;



    if (this.data.children.length > 0) {
       // Wenn Verheiratet dann witwenrente + waisenrente ansonsten nur weisen rente
      if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
        AHV_gesamt = witwer_rente + waisenrente2;
        UVG_gesamt = witwer_renteuvg + waisenrenteuvg2;
      } else {
        AHV_gesamt = waisenrente2;
        UVG_gesamt = waisenrenteuvg2;
      }
    } else {// Childrens= 0
      // Wenn Verheiratet dann witwenrente + waisenrente ansonsten 0
      if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
        AHV_gesamt = witwer_rente;
        UVG_gesamt = witwer_renteuvg;
      } else {
        AHV_gesamt = 0;
        UVG_gesamt = 0;
      }
    }

    // AHV_gesamt = this.calc_ahv(AHV_gesamt, Alter);
    // Gesamtbetrag * 12
    AHV_gesamt = AHV_gesamt * 12;

    UVG = UVG_gesamt;
    Jahr = new Date().getFullYear() + 1; // .Now.Year + 1
    for (let j = 0; j <= Alterhaupt; j++) {
      const Datechart = String(Jahr + j);
      // Wenn AHV + UVG Grösser als das Max einkommen sind dann Kürzen ansonsten 0
      if (AHV_gesamt + UVG >= einkommen_max) {
        Differenz = (AHV_gesamt + UVG) - einkommen_max;
      } else {
        Differenz = 0;
      }
      // Wenn Differenz kleine als 0 dann 0
      if (Differenz <= 0) {
        Differenz = 0;
      }
      // Wenn UVG - Differenz kleiner als  -10 ist dann AHV Kürzen ansonsten AHV nicht kürzen.
      if (UVG - Differenz < -10) {
        this.TDUAHVData.push(Math.round(AHV_gesamt) - ((AHV_gesamt) - (einkommen_max)));
      } else {
        this.TDUAHVData.push(Math.round(AHV_gesamt));
      }
      // this.TDUAHVData.push(Math.round(AHV_gesamt) > 0 ? Math.round(AHV_gesamt) : 0);
      this.TDUchartData.labels.push(Number(Datechart));


      this.RedLineTDU.push(this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome);

      this.LastTDUAHV.push(Math.round(AHV_gesamt) > 0 ? Math.round(AHV_gesamt) : 0);
      this.LabelsTDU = this.TDUchartData.labels.length;

      // Wenn UVG - Differenz kleiner als 1 dann AHV begrenzen ansonsten UVG
      if (UVG - Differenz < 1) {
        this.TDUUVGData.push(0);
        this.LastTDUUVG.push(0);

        this.LastTDUAHV.push(Math.round((AHV_gesamt) - ((AHV_gesamt) - (einkommen_max))) > 0 ?
        Math.round((AHV_gesamt) - ((AHV_gesamt) - (einkommen_max))) : 0);
        this.LabelsTDU = this.TDUchartData.labels.length;

      } else {
        this.TDUUVGData.push(Math.round(UVG - Differenz));
        this.LastTDUUVG.push(Math.round(UVG - Differenz));
      this.LastTDUAHV.push(Math.round(AHV_gesamt) > 0 ? Math.round(AHV_gesamt) : 0);
      this.LabelsTDU = this.TDUchartData.labels.length;

      }
      AHVChartTmp.push(AHV_gesamt);
      UVGChart.push(UVG);
      // Wenn Kinder 18 Werden dann waisenrente vom Total abziehen
      for (let i = 0; i < this.data.children.filter(x => x.YearsTillAdult <= Alterhaupt).length; i++) {
        if (this.data.children[i].YearsTillAdult === j) {
          AHV_gesamt = AHV_gesamt - waisenrentejahr;
          UVG -= waisenrentejahruvg;
        }
      }
    }
    // Wenn Verheiratet dann das Einkommen des Partners Anzeigen
    if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
      this.LastTDUEinkommen = [];
      for (let i = 0; i <= Alterhaupt; i++) {
        const Datechart = String(Jahr + i);
        EinkommenChartTmp.push(einkommen_p);

        this.TDUEinkommenData.push(Math.round(einkommen_p));
        this.LastTDUEinkommen.push(Math.round(einkommen_p));

      }
    }
  }
  compareTDU(i: any){
    // this.tod_durch_unfall();

  this.result1TDU = this.TDUchartData.labels;
  this.namedatasets = {
    aktuellesname: this.TDUchartData.datasets[0].label,
    ahvname: this.TDUchartData.datasets[1].label,
    bvgname: this.TDUchartData.datasets[2].label,
    uvgname: this.TDUchartData.datasets[3].label,
    einkommen: this.TDUchartData.datasets[4].label,
    ktg: this.TDUchartData.datasets[5].label,
  }

    this.result2TDU  =
      {
        aktuellesinkomen: this.TDUchartData.datasets[0].data[i],
      ahv: this.TDUchartData.datasets[1].data[i],
      bvg: this.TDUchartData.datasets[2].data[i],
      uvg: this.TDUchartData.datasets[3].data[i],
      einkommen: this.TDUchartData.datasets[4].data[i],
      ktg: this.TDUchartData.datasets[5].data[i]

      };
    }

  // -----------------------------------------------------------------------------
  tod_durch_krankheit() {
    this.LastTDKAHV = [];
    this.LastTDKBVG  = [];
    this.LastTDKEinkommen  = [];
    let AHVChart: any[] = [];
    let BVGChart: any[] = [];
    let UVGChart: any[] = [];
    let EinkommenChart: any[] = [];

    const AHVChartTmp: any[] = [];
    const BVGChartTmp: any[] = [];
    const UVGChartTmp: any[] = [];
    const EinkommenChartTmp: any[] = [];

    //  this.randomize1(this.barChartData_tod_durch_krankheit);


    AHVChart = [];
    AHVChart.length = 0;
    BVGChart = [];
    BVGChart.length = 0;
    EinkommenChart = [];
    EinkommenChart.length = 0;
    UVGChart = [];
    UVGChart.length = 0;

    let Jahr;
    let waisenrente = 0;
    let Alter = 0;
    let Alters_differenz = 0;
    let Alter_p = 0;
    let Alters_differenz_p = 0;
    let Alterhaupt = 0;
    let waisenrentebvg = 0;
    let witwer_rentebvg = 0;
    let einkommen_max = 0;
    let einkommen_p = 0;
    let waisenrente2 = 0;
    let waisenrentebvg2 = 0;
    let waisenrentejahr = 0;
    let waisenrentejahr2 = 0;
    let waisenrentejahrbvg = 0;
    let waisenrentejahrbvg2 = 0;
    let witwerrentejahr = 0;
    let witwer_rente = 0;
    let AHV_gesamt = 0;
    let BVG_gesamt = 0;
    // let witwen_witwer = 0;
    let BVG = 0;
    let Differenz = 0;


    witwer_rente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
    [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].witwen_witwer;

    if (this.data.person.maritalStatus === 'Widower' || this.data.person.maritalStatus === 'Verwitwet') {
      waisenrente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
      [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].waisenrente60;

    } else {
      waisenrente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
      [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].waisenrente;

    }

    Alter = this.data.person.age;
    Alters_differenz = 65 - Alter;

    if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
      Alter_p = this.data.partner.age;
      Alters_differenz_p = 65 - Alter_p;
      Alterhaupt = Alters_differenz_p;
    } else {
      Alterhaupt = Alters_differenz;
    }

    waisenrentebvg = this.calc_bvg_rente(this.data.partner.avgYearlyIncome) * 0.2; // this.BVGInvalidenrente * 0.2;
    witwer_rentebvg = this.calc_bvg_rente(this.data.person.avgYearlyIncome) * 0.6; // this.BVGInvalidenrente * 0.6;

    einkommen_max = Number((this.data.person.avgYearlyIncome * 0.9).toFixed(0));
    // 90% of the yearly income will be the max income in the assicuration
    einkommen_p = this.data.partner.avgYearlyIncome;

    if (this.data.children.length > 0) {
      waisenrente2 = waisenrente * this.data.children.length;
      waisenrentebvg2 = waisenrentebvg * this.data.children.length;
      for (let i = 0; i < this.data.children.length; i++) {
        this.data.children[i].YearsTillAdult = 18 - this.data.children[i].years;
      }
    }
    waisenrentejahr = waisenrente * 12;
    waisenrentejahr2 = waisenrente2 * 12;

    waisenrentejahrbvg = waisenrentebvg;
    waisenrentejahrbvg2 = waisenrentebvg2;

    witwerrentejahr = witwer_rente * 12;

    //  '--------------------new code------------------------------
    // waisenrentejahr = this.calc_ahv(waisenrentejahr, Alter)
    // waisenrentejahr2 = this.calc_ahv(waisenrentejahr2, Alter)
    // witwerrentejahr = this.calc_ahv(witwerrentejahr, Alter)
    //  '--------------------new code------------------------------

    if (this.data.children.length > 0) {
      if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
        AHV_gesamt = witwer_rente + waisenrente2;
        BVG_gesamt = witwer_rentebvg + waisenrentebvg2;
      } else {
        AHV_gesamt = waisenrente2;
        BVG_gesamt = waisenrentebvg2;
      }
    } else {// Childrens= 0
      if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
        AHV_gesamt = witwer_rente;
        BVG_gesamt = witwer_rentebvg;
      } else {
        AHV_gesamt = 0;
        BVG_gesamt = 0;
      }
    }
    //  '---------------------------------------NEW CODE----------------------------------------------------
    // AHV_gesamt = this.calc_ahv(AHV_gesamt, Alter)
    //  '---------------------------------------NEW CODE----------------------------------------------------

    AHV_gesamt = AHV_gesamt * 12;
    BVG = BVG_gesamt;

    Jahr = new Date().getFullYear() + 1; // .Now.Year + 1

    for (let j = 0; j <= Alterhaupt; j++) {

      const Datechart = String(Jahr + j);
      if (AHV_gesamt + BVG >= einkommen_max) {
        Differenz = (AHV_gesamt + BVG) - einkommen_max;
        //  this.barChartLabels.push(Datechart);
      } else {
        Differenz = 0;
        }
      if (Differenz <= 0) {
        Differenz = 0;
      }


     // this.TDKAHVData.push(Math.round(AHV_gesamt) > 0 ? Math.round(AHV_gesamt) : 0);
     if (BVG - Differenz < -10) {
       this.TDKAHVData.push(Math.round(AHV_gesamt) - ((AHV_gesamt) - (einkommen_max)));
     } else {
       this.TDKAHVData.push(Math.round(AHV_gesamt));
      }
      this.TDKchartData.labels.push(Number(Datechart));
      this.RedLineTDK.push(this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome);

      if (BVG - Differenz < 1) {

        this.TDKBVGData.push(0);

        this.LastTDKAHV.push(Math.round((AHV_gesamt) - ((AHV_gesamt) - (einkommen_max))) > 0 ?
        Math.round((AHV_gesamt) - ((AHV_gesamt) - (einkommen_max))) : 0);
        this.LabelsTDK = this.TDKchartData.labels.length;
      } else {

        this.TDKBVGData.push(Math.round(BVG - Differenz));
        this.LastTDKBVG.push(Math.round(BVG - Differenz));

      this.LastTDKAHV.push(Math.round(AHV_gesamt) > 0 ? Math.round(AHV_gesamt) : 0);
      this.LabelsTDK = this.TDKchartData.labels.length;
      }

      AHVChartTmp.push(AHV_gesamt);
      BVGChartTmp.push(BVG);

      for (let i = 0; i < this.data.children.filter(x => x.YearsTillAdult <= Alterhaupt).length; i++) {
        if (this.data.children[i].YearsTillAdult === j) {
          AHV_gesamt -= waisenrentejahr;
          BVG -= waisenrentejahrbvg;

        }
      }
    }

    if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
      for (let i = 0; i <= Alterhaupt; i++) {
        const Datechart = String(Jahr + i);
        EinkommenChartTmp.push(einkommen_p);

        this.TDKEinkommenData.push(Math.round(einkommen_p));
        this.LastTDKEinkommen.push(Math.round(einkommen_p));
      }
    }
  }

  compareTDK(i: any){
    // this.tod_durch_krankheit();


  this.result1TDK = this.TDKchartData.labels;



    this.namedatasets = {
      aktuellesname: this.TDKchartData.datasets[0].label,
      ahvname: this.TDKchartData.datasets[1].label,
      bvgname: this.TDKchartData.datasets[2].label,
      uvgname: this.TDKchartData.datasets[3].label,
      einkommen: this.TDKchartData.datasets[4].label,
      ktg: this.TDKchartData.datasets[5].label,
    }

    this.result2TDK  =
      {
        aktuellesinkomen: this.TDKchartData.datasets[0].data[i],
      ahv: this.TDKchartData.datasets[1].data[i],
      bvg: this.TDKchartData.datasets[2].data[i],
      uvg: this.TDKchartData.datasets[3].data[i],
      einkommen: this.TDKchartData.datasets[4].data[i],
      ktg: this.TDKchartData.datasets[5].data[i]

      };
    }
  // ----------------------------------------------------------------------------
  invalid_durch_unfall() {

    let KTG; // the value of KTG
    let UVG;
    let waisenrentejahr;
    let waisenrentejahr2;
    let witwerrentejahr;

    let rente;
    let kinderrente;
    let renteuvg;

    let einkommen_max;
    let einkommen_p;

    let kinderrente2;

    let AHV_gesamt;
    let UVG_gesamt;

    let Jahr;
    let Alters_differenz;
    let Alter;
    let Datechart;
    let Differenz;

    // let waisenrente;

    let AHVChart: any[] = [];
    let BVGChart: any[] = [];
    let UVGChart: any[] = [];
    let EinkommenChart: any[] = [];
    const KTGChart: any[] = [];

    const AHVChartTmp: any[] = [];
    const BVGChartTmp: any[] = [];
    const UVGChartTmp: any[] = [];
    const EinkommenChartTmp: any[] = [];
    const KTGChartTmp: any[] = [];

    //  this.randomize1(this.barChartData_invalid_durch_unfall);


    AHVChart = [];
    AHVChart.length = 0;
    BVGChart = [];
    BVGChart.length = 0;
    EinkommenChart = [];
    EinkommenChart.length = 0;
    UVGChart = [];
    UVGChart.length = 0;

    rente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
    [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].ahv_iv;
    kinderrente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
    [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].waisenrente;
    renteuvg = this.data.person.avgYearlyIncome * 0.8;

    Alter = this.data.person.age;
    Alters_differenz = 65 - Alter;

    einkommen_max = this.data.person.avgYearlyIncome * 0.9; // 90% of the yearly income will be the max income in the assicuration
    KTG = Number(this.KTGSelect) * this.data.person.avgYearlyIncome;

    if (einkommen_max >= 133380) { einkommen_max = 133380; }
    einkommen_p = this.data.partner.avgYearlyIncome;

    if (this.data.children.length > 0) {
      kinderrente2 = kinderrente * this.data.children.length;
    }
    for (let i = 0; i < this.data.children.length; i++) {
      this.data.children[i].YearsTillAdult = 18 - this.data.children[i].years;
    }

    waisenrentejahr = kinderrente * 12;
    waisenrentejahr2 = kinderrente2 * 12;

    witwerrentejahr = rente * 12;

    //  '--------------------new code------------------------------
    // waisenrentejahr = this.calc_ahv(waisenrentejahr, Alter)
    // waisenrentejahr2 = this.calc_ahv(waisenrentejahr2, Alter)
    // witwerrentejahr = this.calc_ahv(witwerrentejahr, Alter)
    //  '--------------------new code------------------------------

    if (this.data.children.length > 0) {
      AHV_gesamt = kinderrente2 + rente;
      UVG_gesamt = renteuvg;
    } else {
      AHV_gesamt = rente;
      UVG_gesamt = renteuvg;
    }

    //  '--------------------new code------------------------------
    // AHV_gesamt = this.calc_ahv(AHV_gesamt, Alter)
    //  '--------------------new code------------------------------
    AHV_gesamt = AHV_gesamt * 12;

    UVG = UVG_gesamt;


   Jahr = new Date().getFullYear() + 1;
    for (let j = 0; j <= Alters_differenz; j++) {
      Datechart = String(Jahr + j);
      if (AHV_gesamt + UVG >= einkommen_max) {

        Differenz = (AHV_gesamt + UVG) - einkommen_max;
      } else {
        Differenz = 0;
      }

      if (Differenz <= 0) {
        Differenz = 0;
      }

      // this.IDUAHVData.push(Math.round(AHV_gesamt));
      if (UVG - Differenz < -10) {
        this.IDUAHVData.push(Math.round(AHV_gesamt) - ((AHV_gesamt) - (einkommen_max)));
      } else {
        this.IDUAHVData.push(Math.round(AHV_gesamt));
      }
      this.IDUchartData.labels.push(Number(Datechart));
      this.RedLineIDU.push(this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome);


      if (UVG - Differenz < 1) {
        this.LastIDUAHV.push(Math.round( (AHV_gesamt) - ((AHV_gesamt) - (einkommen_max)) ));
        this.LabelsIDU = this.IDUchartData.labels.length;
        this.IDUUVGData.push(0);
        this.LastIDUUVG.push(0);
      } else {
        this.IDUUVGData.push(Math.round(UVG - Differenz));
        this.LastIDUUVG.push(Math.round(UVG - Differenz));
        this.LastIDUAHV.push(Math.round( (AHV_gesamt) ));
        this.LabelsIDU = this.IDUchartData.labels.length;
      }

      for (let i = 0;
        i < this.data.children.filter(x => x.YearsTillAdult <= Alters_differenz).length
        ; i++) {
        if (this.data.children[i].YearsTillAdult === j) {
          AHV_gesamt -= waisenrentejahr;
        }
      }
    }

    if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
      for (let i = 0; i <= Alters_differenz; i++) {
        Datechart = String(Jahr + i);
        EinkommenChartTmp.push(einkommen_p);
        this.IDUEinkommenData.push(Math.round(einkommen_p));
        this.LastIDUEinkommen.push(Math.round(einkommen_p));
      }
    }
  }
  compareIDU(i: any){
    // this.invalid_durch_unfall();


  this.result1IDU = this.IDUchartData.labels;

    this.namedatasets = {
      aktuellesname: this.IDUchartData.datasets[0].label,
      ahvname: this.IDUchartData.datasets[1].label,
      bvgname: this.IDUchartData.datasets[2].label,
      uvgname: this.IDUchartData.datasets[3].label,
      einkommen: this.IDUchartData.datasets[4].label,
      ktg: this.IDUchartData.datasets[5].label,
    }

    this.result2IDU  =
      {
        aktuellesinkomen: this.IDUchartData.datasets[0].data[i],
      ahv: this.IDUchartData.datasets[1].data[i],
      bvg: this.IDUchartData.datasets[2].data[i],
      uvg: this.IDUchartData.datasets[3].data[i],
      einkommen: this.IDUchartData.datasets[4].data[i],
      ktg: this.IDUchartData.datasets[5].data[i]

      };
    }

  // ----------------------------------------------------------------------------
  invalid_durch_krankheit() {
    let KTG; // the value of KTG
    // const UVG;
    let BVG;
    let waisenrentejahr;
    let waisenrentejahr2;
    let witwerrentejahr;

    let rente;
    let kinderrente;
    // const renteuvg;

    let einkommen_max;
    let einkommen_p;

    let kinderrente2;

    let AHV_gesamt;
    // const UVG_gesamt;
    let BVG_gesamt;

    let Jahr;
    let Alters_differenz;
    let Alter;
    let Datechart;
    let Differenz;

    let kinderrentebvg;
    let rentebvg;

    // let waisenrente;

    let waisenrentejahrbvg;
    let waisenrentejahrbvg2;
    let kinderrentebvg2;

    //  let KTGChartTmp = [];
    let AHVChart: any[] = [];
    let BVGChart: any[] = [];
    let UVGChart: any[] = [];
    let EinkommenChart: any[] = [];
    // let KTGChart: any[] = [];

    // let AHVChartTmp: any[] = [];
    const BVGChartTmp: any[] = [];
    // let UVGChartTmp: any[] = [];
    const EinkommenChartTmp: any[] = [];
    const KTGChartTmp: any[] = [];

    //  this.randomize1(this.barChartData_invalid_durch_krankheit);


    AHVChart = [];
    AHVChart.length = 0;
    BVGChart = [];
    BVGChart.length = 0;
    EinkommenChart = [];
    EinkommenChart.length = 0;
    UVGChart = [];
    UVGChart.length = 0;

    rente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
    [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].ahv_iv; // this.cbAHVIV

    kinderrente = skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome)
    [skala44.filter(x => x.durchschnitt_einkommen <= this.data.person.avgYearlyIncome).length - 1].waisenrente;

    this.BVG_Beginn(this.data.person.avgYearlyIncome, this.data.person.age);

    kinderrentebvg = this.BVGInvalidenrente * 0.2;
    rentebvg = this.BVGInvalidenrente;

    Alter = this.data.person.age;
    Alters_differenz = 65 - Alter;

    einkommen_max = this.data.person.avgYearlyIncome * 0.9; // 90% of the yearly income will be the max income in the assicuration

    einkommen_p = this.data.partner.avgYearlyIncome;

    KTG = this.data.person.avgYearlyIncome * Number(this.KTGSelect);

    kinderrente2 = kinderrente * this.data.children.length;
    kinderrentebvg2 = kinderrentebvg * this.data.children.length;
    for (let i = 0; i < this.data.children.length; i++) {
      //  kinderrentebvg2 = kinderrentebvg;
      this.data.children[i].YearsTillAdult = 18 - this.data.children[i].years;
    }

    waisenrentejahr = kinderrente * 12;
    waisenrentejahr2 = kinderrente2 * 12;
    waisenrentejahrbvg = kinderrentebvg;
    waisenrentejahrbvg2 = kinderrentebvg2;

    witwerrentejahr = rente * 12;

    //  '--------------------new code------------------------------
    // waisenrentejahr = this.calc_ahv(waisenrentejahr, Alter)
    // waisenrentejahr2 = this.calc_ahv(waisenrentejahr2, Alter)
    // witwerrentejahr = this.calc_ahv(witwerrentejahr, Alter)
    //  '--------------------new code------------------------------


    if (this.data.children.length > 0) {
      AHV_gesamt = kinderrente2 + rente;
      BVG_gesamt = kinderrentebvg2 + rentebvg;

    } else {
      AHV_gesamt = rente;
      BVG_gesamt = rentebvg;
    }

    //  '--------------------new code------------------------------
    // AHV_gesamt = this.calc_ahv(AHV_gesamt, Alter)
    //  '--------------------new code------------------------------

    AHV_gesamt = AHV_gesamt * 12;

    BVG = BVG_gesamt;

   Jahr = new Date().getFullYear() + 1;
    for (let j = 0; j <= Alters_differenz; j++) {
      Datechart = String(Jahr + j);
      if (AHV_gesamt + BVG >= einkommen_max) {
        Differenz = (AHV_gesamt + BVG) - einkommen_max;

      } else {
        Differenz = 0;
      }

        if (Differenz <= 0) {
          Differenz = 0;
        }

        if (BVG - Differenz < -10) { this.IDKAHVData.push(Math.round(AHV_gesamt) - ((AHV_gesamt) - (einkommen_max)));
        } else {this.IDKAHVData.push(Math.round(AHV_gesamt)); }
    // this.IDKAHVData.push(Math.round(AHV_gesamt));
    this.IDKchartData.labels.push(Number(Datechart));
    this.RedLineIDK.push(this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome);

    // this.LastIDKAHV.push(Math.round(AHV_gesamt) > 0 ? Math.round(AHV_gesamt) : 0);
    // this.LabelsIDK = this.IDKchartData.labels.length;

      if (BVG - Differenz < 1) {
        this.IDKBVGData.push(0);
        this.LastIDKAHV.push(Math.round((AHV_gesamt) - ((AHV_gesamt) - (einkommen_max))) > 0 ?
        Math.round((AHV_gesamt) - ((AHV_gesamt) - (einkommen_max))) : 0);
        this.LabelsIDK = this.IDKchartData.labels.length;

      } else {
        // BVGChartTmp.push(BVG - Differenz);
        this.IDKBVGData.push(Math.round(BVG - Differenz))
        this.LastIDKBVG.push(Math.round(BVG - Differenz))

       // this.IDKAHVData.push(Math.round(AHV_gesamt));
       // this.LastIDKAHV.push(Math.round(AHV_gesamt));
       this.LastIDKAHV.push(Math.round(AHV_gesamt) > 0 ? Math.round(AHV_gesamt) : 0);
       this.LabelsIDK = this.IDKchartData.labels.length;
      }


      BVGChartTmp.push(BVG);
      for (let i = 0;
        i < this.data.children.filter(x => x.YearsTillAdult <= Alters_differenz).length; i++) {
        if (this.data.children[i].YearsTillAdult === j) {
          AHV_gesamt = AHV_gesamt - waisenrentejahr;
          BVG = BVG - waisenrentejahrbvg;

        }
      }
    }

    if (this.data.person.maritalStatus === 'Verheiratet' || this.data.person.maritalStatus === 'Married' || this.data.person.maritalStatus === 'Marié' || this.data.person.maritalStatus === 'Sposato') {
      for (let i = 0; i <= Alters_differenz; i++) {
        Datechart = String(Jahr + i);
        EinkommenChartTmp.push(einkommen_p);

        this.IDKEinkommenData.push(Math.round(einkommen_p));
        this.LastIDKEinkommen.push(Math.round(einkommen_p));

      }
    }
  }
  compareIDK(i: any){
    // this.invalid_durch_krankheit();


  this.result1IDK = this.IDKchartData.labels;

    this.namedatasets = {
      aktuellesname: this.IDKchartData.datasets[0].label,
      ahvname: this.IDKchartData.datasets[1].label,
      bvgname: this.IDKchartData.datasets[2].label,
      uvgname: this.IDKchartData.datasets[3].label,
      einkommen: this.IDKchartData.datasets[4].label,
      ktg: this.IDKchartData.datasets[5].label,
    }

    this.result2IDK  =
      {
        aktuellesinkomen: this.IDKchartData.datasets[0].data[i],
      ahv: this.IDKchartData.datasets[1].data[i],
      bvg: this.IDKchartData.datasets[2].data[i],
      uvg: this.IDKchartData.datasets[3].data[i],
      einkommen: this.IDKchartData.datasets[4].data[i],
      ktg: this.IDKchartData.datasets[5].data[i]

      };
    }

  // ----------------------------------------------------------------------------


  Alterf(personAvgYearlyIncome: number, maritalStatus: string, personAge: number, partnerAge?: number, partnerAvgYearlyIncome?: number) {
    this.LastAlterBVG = []; this.LastAlterAHV = []; this.LastAlterEinkommen = []; this.LastAlterKTG = []; this.LastAlterUVG = [];
    let marriedPersonRente = 0;
    let totalChildPensions = 0;
    let yearlyTotalPensions = 0;
    let pensionStartYear = new Date().getFullYear() + (65 - personAge);
    let ageDiff: number;

    let BVG;
    let waisenrentejahr;

    let rente;
    let childPension;

    let einkommen_max;


    let AHV_gesamt;
    let BVG_gesamt;

    let Jahr;
    let Datechart;
    let Differenz;

    let childPensionBvg;
    let rentebvg;


    let childPensionBvg2;

    let AHVChart: any[] = [];
    let BVGChart: any[] = [];
    let UVGChart: any[] = [];
    let EinkommenChart: any[] = [];
    const BVGChartTmp: any[] = [];
    const EinkommenChartTmp: any[] = [];


    AHVChart = [];
    AHVChart.length = 0;
    BVGChart = [];
    BVGChart.length = 0;
    EinkommenChart = [];
    EinkommenChart.length = 0;
    UVGChart = [];
    UVGChart.length = 0;

    let AHV_gesamt_2;
    let rentebvg2;
    let rentebvg3;


    rente = skala44.filter(x => x.durchschnitt_einkommen <= personAvgYearlyIncome).pop().ahv_iv;
    childPension = skala44.filter(x => x.durchschnitt_einkommen <= personAvgYearlyIncome).pop().waisenrente;

    if (maritalStatus === 'Verheiratet' || maritalStatus === 'Married'|| maritalStatus === 'Sposato'|| maritalStatus === 'Marié') {
      marriedPersonRente = 0;
      if(skala44.filter(x => x.durchschnitt_einkommen <= partnerAvgYearlyIncome).pop()) {
        marriedPersonRente = skala44.filter(x => x.durchschnitt_einkommen <= partnerAvgYearlyIncome).pop().ahv_iv;
      }
      ageDiff = personAge - partnerAge
    }

    this.BVG_Beginn(personAvgYearlyIncome, personAge);
    this.Partner_BVGAltersrenteMitZins = this.BVG_BeginnPartner(partnerAvgYearlyIncome, partnerAvgYearlyIncome);
    childPensionBvg = this.BVGInvalidenrente * 0.2;

    rentebvg2 = this.Partner_BVGAltersrenteMitZins;
    einkommen_max = personAvgYearlyIncome * 0.9;
    totalChildPensions = childPension * this.data.children.length;
    childPensionBvg2 = childPensionBvg * this.data.children.length;

    for (let i = 0; i < this.data.children.length; i++) {
      this.data.children[i].YearsTillAdult = 18 - this.data.children[i].years;
    }

    yearlyTotalPensions = rente;

    if (maritalStatus === 'Verheiratet' || maritalStatus === 'Married' || maritalStatus === 'Sposato'|| maritalStatus === 'Marié') {
      rente = rente + marriedPersonRente;
    }

    if(rente >= 3525) { rente = 3525 }

    waisenrentejahr = childPension * 12;
    rentebvg = this.BVGAltersrenteMitZins;
    rentebvg3 = rentebvg;
    BVG_gesamt = rentebvg;

    if (this.data.children.length > 0) {
      BVG_gesamt = BVG_gesamt + childPensionBvg2;
      rentebvg3 = rentebvg3 + childPensionBvg2;
      yearlyTotalPensions = rente + childPension;
      rente = rente + totalChildPensions;
    }
    yearlyTotalPensions = 12 * yearlyTotalPensions;
    AHV_gesamt = rente * 12;
    AHV_gesamt_2 = AHV_gesamt;
    BVG = BVG_gesamt;


    // let bvgrentepartner = 0;
    for (let i = 0; i <= 40; i++) {
      Datechart = pensionStartYear + i;

      if (maritalStatus === 'Verheiratet' || maritalStatus === 'Married'|| maritalStatus === 'Sposato'|| maritalStatus === 'Marié') {
        if (i < ageDiff) {
          AHV_gesamt = yearlyTotalPensions;
          BVG = rentebvg3;
         // bvgrentepartner = 0;
        } else {
          AHV_gesamt = AHV_gesamt_2;
          BVG = BVG_gesamt;
         // bvgrentepartner = rentebvg2
        }
      } else {
        AHV_gesamt = AHV_gesamt_2;
        BVG = BVG_gesamt;
      }

      if (AHV_gesamt + BVG >= einkommen_max) {
        Differenz = AHV_gesamt + BVG;
        Differenz -= einkommen_max;

      } else { Differenz = 0; }
      if (BVG - Differenz < -10) { this.AlterAHVData.push(Math.round(AHV_gesamt) - ((AHV_gesamt) - (einkommen_max)));
      } else { this.AlterAHVData.push(Math.round(AHV_gesamt)); }

      this.AlterchartData.labels.push(Datechart);
      this.RedLineAlter.push(personAvgYearlyIncome + partnerAvgYearlyIncome);



      if (BVG - Differenz < 1) {

        this.LastAlterAHV.push(Math.round( (AHV_gesamt) - ((AHV_gesamt) - (einkommen_max)) ));
        if (i < ageDiff) {
          BVGChartTmp.push(0);
          this.AlterBVGData.push(0);
        }
        else  {
          BVGChartTmp.push(Math.round(rentebvg2));
          this.AlterBVGData.push(Math.round(rentebvg2));
          this.LastAlterBVG.push(Math.round(rentebvg2));
        }
        this.LabelsAlter = this.AlterchartData.labels.length;

        this.LastAlterBVG.push(0);
      }
      else {
        this.LastAlterAHV.push(Math.round(AHV_gesamt));
        this.LabelsAlter = this.AlterchartData.labels.length;
        if (i < ageDiff) {
          BVGChartTmp.push(Math.round(BVG - Differenz));
          this.AlterBVGData.push(Math.round(BVG - Differenz));
          this.LastAlterBVG.push(Math.round(BVG - Differenz));
        } else {
          BVGChartTmp.push(Math.round(BVG + rentebvg2 - Differenz));
          this.AlterBVGData.push(Math.round(BVG + rentebvg2 - Differenz));
          this.LastAlterBVG.push(Math.round(BVG + rentebvg2 - Differenz));
        }
      }
      for (let j = 0; j < this.data.children.filter(x => x.YearsTillAdult === i).length; j++) {
        if (i < ageDiff) {
          BVG_gesamt -= childPensionBvg;
          AHV_gesamt_2 -= waisenrentejahr;
          rentebvg3 -= childPensionBvg;
        } else {
          AHV_gesamt_2 -= waisenrentejahr;
          BVG_gesamt -= childPensionBvg;
          rentebvg3 -= childPensionBvg;
        }

      }

    }

    if (maritalStatus === 'Verheiratet' || maritalStatus === 'Married'|| maritalStatus === 'Sposato'|| maritalStatus === 'Marié') {
      for (let i = 0; i <= ageDiff - 1; i++) {
        Datechart = String(Jahr + i);
        EinkommenChartTmp.push(partnerAvgYearlyIncome);

        this.AlterEinkommenData.push(Math.round(partnerAvgYearlyIncome));
        this.LastAlterEinkommen.push(Math.round(partnerAvgYearlyIncome));

      }

    }
  }
  compareALTER(i: any){


  this.result1ALTER = this.AlterchartData.labels;

  this.namedatasets = {
    aktuellesname: this.AlterchartData.datasets[0].label,
    ahvname: this.AlterchartData.datasets[1].label,
    bvgname: this.AlterchartData.datasets[2].label,
    uvgname: this.AlterchartData.datasets[3].label,
    einkommen: this.AlterchartData.datasets[4].label,
    ktg: this.AlterchartData.datasets[5].label,
  }

    this.result2ALTER  =
      {
        aktuellesinkomen: this.AlterchartData.datasets[0].data[i],
      ahv: this.AlterchartData.datasets[1].data[i],
      bvg: this.AlterchartData.datasets[2].data[i],
      uvg: this.AlterchartData.datasets[3].data[i],
      einkommen: this.AlterchartData.datasets[4].data[i],
      ktg: this.AlterchartData.datasets[5].data[i]

      };
    }


  Execute() {
     if (!(this.data.person.maritalStatus == 'Verheiratet' || this.data.person.maritalStatus == 'Married'|| this.data.person.maritalStatus == 'Marié'|| this.data.person.maritalStatus == 'Sposato')) {
       this.data.partner.avgYearlyIncome = 0; }

    this.ShowOnlyBarCharts = true;
    this.ShowOnlyDoughnutCharts = false;

    // this.RedLineTDU=[];

    // check if select all is
    if (this.ExecuteFunctions.tod_durch_unfall === true &&
      this.ExecuteFunctions.tod_durch_krankheit === true &&
      this.ExecuteFunctions.invalid_durch_unfall === true &&
      this.ExecuteFunctions.invalid_durch_krankheit === true &&
      this.ExecuteFunctions.Alter === true) {
      this.SelectAllSelected = true;

      this.tod_durch_unfall();
      this.GroupTDU();
      this.compareTDU(0);
      this.Chart_TDUf();

      this.tod_durch_krankheit();
      this.GroupTDK();
      this.compareTDK(0);
      this.Chart_TDKf();

      this.invalid_durch_unfall();
      this.GroupIDU();
      this.compareIDU(0);
      this.Chart_IDUf();

      this.invalid_durch_krankheit();
      this.GroupIDK();
      this.compareIDK(0);
      this.Chart_IDKf();

      this.Alterf(this.data.person.avgYearlyIncome, this.data.person.maritalStatus, this.data.person.age, this.data.partner.age, this.data.partner.avgYearlyIncome);
      this.GroupAlter();
      this.compareALTER(0);
      this.Chart_Alterf();
    } else {
      this.SelectAllSelected = false;

      if (this.ExecuteFunctions.tod_durch_unfall === true) {
        this.tod_durch_unfall();
        this.DoughnutTDU = this.DoughnutTDUchartData;
        this.GroupTDU();
        this.compareTDU(0);
        this.Chart_TDUf();
      }

      if (this.ExecuteFunctions.tod_durch_krankheit === true) {
        this.tod_durch_krankheit();
        this.GroupTDK();
        this.compareTDK(0);
        this.Chart_TDKf();
      }

      if (this.ExecuteFunctions.invalid_durch_unfall === true) {
        this.invalid_durch_unfall();
        this.GroupIDU();
        this.compareIDU(0);
        this.Chart_IDUf();
      }

      if (this.ExecuteFunctions.invalid_durch_krankheit === true) {
        this.invalid_durch_krankheit();
        this.GroupIDK();
        this.compareIDK(0);
        this.Chart_IDKf();
      }

      if (this.ExecuteFunctions.Alter === true) {
        this.Alterf(this.data.person.avgYearlyIncome, this.data.person.maritalStatus, this.data.person.age, this.data.partner.age, this.data.partner.avgYearlyIncome);
        this.GroupAlter();
        this.compareALTER(0);
        this.Chart_Alterf();
      }

    }

    this.ShowRotateScreen = true;
  }

  CheckRequired1() {
    if (
      String(this.data.person.age) === 'null' ||
      String(this.data.person.avgYearlyIncome) === 'null' ||
      String(this.cbAHVIV) === 'null' ||
      String(this.data.person.maritalStatus) === 'null' ||
      String(this.BVGInvalidenrente) === 'null' ||
      //  String(this.BVGAltersrenteMitZins)==='null' ||
      String(this.PersonBirthplace) === 'null' ||
      String(this.KTGSelect) === 'null'
      //  ||
      //  String(this.data.person.maritalStatus)==='Single' //Skip the step 2 if the person is Single
    ) {
      this.GoTo2 = false;
    } else {
      this.GoTo2 = true;
    }

  }

  CheckRequired2() {
    if (
      String(this.Partner_BVGInvalidenrente) === 'null' ||
      String(this.Partner_cbAHVIV) === 'null' ||
      String(this.data.partner.avgYearlyIncome) === 'null' ||
      String(this.data.partner.age) === 'null'
    ) {
      this.GoTo3 = false;
    } else {
      this.GoTo3 = true;
    }
  }

  calc_bvg_savings(yearly_income, age) {
    let bvg_savings = 0;
    for (let i = 25; i <= age; i++) {
      if (i >= 25 && i <= 34) { bvg_savings += yearly_income * 0.07; }
      if (i >= 35 && i <= 44) { bvg_savings += yearly_income * 0.1; }
      if (i >= 45 && i <= 54) { bvg_savings += yearly_income * 0.15; }
      if (i >= 55 && i <= 65) { bvg_savings += yearly_income * 0.18; }
    }
    bvg_savings  *= 0.068;
    return Math.round(bvg_savings);
  }

  calc_bvg_rente(yearly_income) {
    let bvgincome = 0;
    bvgincome = yearly_income - 24885;
    if (bvgincome > 60435) {
      bvgincome = 60435;
    } else if (bvgincome < 3555) {
      bvgincome = 3555;
    }
    if (yearly_income < 21330) {
      bvgincome = 0;
    }
    return Math.round(bvgincome);
  }

  calc_bvg_savings_with_zins(yearly_income: number, age: number, maxAge: number) {
    let bvg_savings = yearly_income * 5;
    let zins: number;

    for (let i = 25; i <= maxAge; i++) {
      zins = this.searchZinsByAge(age);
      bvg_savings += bvg_savings * zins;
      age -= 1;
    }

    bvg_savings  *= 0.068;
    return Number(bvg_savings.toFixed(0));
  }

  searchZinsByAge(age: number): number {
    let year = (new Date().getFullYear() - age) + 25;
    let zins = 0.01;
    let zinsObject = AgeBasedZins.find(x => x.yearFrom <= year && x.yearTo > year);
    if (![undefined, null].includes(zinsObject)) {
      zins = zinsObject.zins;
    }
    return Number(zins.toFixed(4));
  }

  calc_ahv(pay, age) {

    let age_diff = age - 18;
    //  let ahv_invalid = Math.ceil(pay/44);
    if ( age_diff > 44) {
      age_diff = 44;
    }
    let ahv_invalid = Math.round(pay / 44);
    ahv_invalid = ahv_invalid * age_diff;

    return Number(ahv_invalid.toFixed(0));
  }

  BVG_Beginn(yearlyIncome: number, age: number) {
    let bvgIncome = this.bvgIncomeCheck(yearlyIncome);
      // this is needed when sex is female
      // result_bvg_alter = this.calc_bvg_savings_with_zins(bvgincome, age, 64);
    this.BVGInvalidenrente = bvgIncome * 5  * 0.068;
    this.BVGAltersrenteMitZins = this.calc_bvg_savings_with_zins(bvgIncome, age, 65);
  }
  bvgIncomeCheck(yearlyIncome: number): number {
    let bvgIncome = 0;
    // check if yearlyIncome is greater than 21330
    if (yearlyIncome < 21330) {
      bvgIncome = 0;
    }  else {
      bvgIncome = yearlyIncome - 24885;
      if (bvgIncome > 60435) {
        bvgIncome = 60435;
      } else if (bvgIncome < 3555) {
        bvgIncome = 3555;
      }
    }
    return bvgIncome;
  }

  BVG_BeginnPartner(yearlyIncome: number, age: number) {
    let bvgIncome = this.bvgIncomeCheck(yearlyIncome);
      // this is needed when sex is female
      // result_bvg_alter = this.calc_bvg_savings_with_zins(bvgincome, age, 64);
    return this.calc_bvg_savings_with_zins(bvgIncome, age, 65);
  }

  Chart_TDUf() {

    const chart1 = this.TDUrefChart.nativeElement;
    const ctx = chart1.getContext('2d');
    //  let ctx = document.getElementById('TDU');

    if (this.myChartTDU) {
      this.myChartTDU.destroy();
      }
    this.myChartTDU = new Chart(this.TDUrefChart.nativeElement,
      {
        type: 'bar',
        data: this.TDUchartData

        ,
        options: {
          responsive:true,
          legend: {
            labels: {
              filter: function (item, chart) {
                try {
                  return chart.datasets[item.datasetIndex].data.reduce(function( a, b) {
                    return a ? ( b ? ( a + b ) : a ) : ( b ? b : 0);
                  });
              } catch { return false; }
                //  Logic to remove a particular legend item goes here
                // return chart.datasets[item.datasetIndex].data.length > 0
              }
            }
          },
          // title: {
          //   display: true,
          //   text:this.vsrStep4.chartHeadline[this.selectedLanguage],//'Black',
          //   fontSize: 12,
          //   responsive: true,
          //   //  fontFamily:'UniviaPro-Book'
          // },
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{
              stacked: true
            }]
          },
        },

      }

    );


    try {
    this.myDoughnutChartTDU.destroy();
    } catch {}

  }
  Chart_TDKf() {
    const chart1 = this.TDKrefChart.nativeElement;
    const ctx1 = chart1.getContext('2d');
    //  let ctx1 = document.getElementById('TDK');

    if (this.myChartTDK) {
      this.myChartTDK.destroy();
      }

    this.myChartTDK = new Chart(ctx1, {
      type: 'bar',
      data: this.TDKchartData,
      options: {
        responsive:true,
        legend: {
          labels: {
            filter: function (item, chart) {
              //  Logic to remove a particular legend item goes here
              // return chart.datasets[item.datasetIndex].data.length > 0
              try {
                return chart.datasets[item.datasetIndex].data.reduce(function( a, b ) {
                  return a ? ( b ? ( a + b ) : a ) : ( b ? b : 0 );
                } );
            } catch { return false; }
            }
          }
        },
        // title: {
        //   display: true,
        //   text:this.vsrStep4.chartHeadline[this.selectedLanguage], // 'Tod durch unfall',
        //   fontColor: '#00254D',//'Black',
        //   fontSize: 12,
        //   responsive: true,
        // },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        },
      }
    });

    try {
    this.myDoughnutChartTDK.destroy();
    } catch {}

  }
  Chart_IDUf() {
    const chart1 = this.IDUrefChart.nativeElement;
    const ctx1 = chart1.getContext('2d');

    //  let ctx1 = document.getElementById('IDU');

    if (this.myChartIDU) {
      this.myChartIDU.destroy();
      }

    this.myChartIDU = new Chart(ctx1, {
      type: 'bar',
      data: this.IDUchartData,
      options: {
        responsive:true,
        legend: {
          labels: {
            filter: function (item, chart) {
              //  Logic to remove a particular legend item goes here
              // return chart.datasets[item.datasetIndex].data.length > 0
              try {
                return chart.datasets[item.datasetIndex].data.reduce(function( a, b ) {
                  return a ? ( b ? ( a + b ) : a ) : ( b ? b : 0 );
                });
            } catch { return false; }
            }
          }
        },
        // title: {
        //   display: true,
        //   text:this.vsrStep4.chartHeadline[this.selectedLanguage], // 'Tod durch unfall',
        //   fontColor: '#00254D',//'Black',
        //   fontSize: 12,
        //   responsive: true,
        //   //  fontFamily:'Arial'
        // },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        },
        //  annotation: {
        //    annotations: [{
        //      type: 'line',
        //      mode: 'horizontal',
        //      scaleID: 'y-axis-0',
        //      value: this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome,
        //      borderColor: 'red',
        //      borderWidth: 2
        //    },
        //    ]
        //  }
      }
    });

    try {
    this.myDoughnutChartIDU.destroy();
    } catch {}

  }
  Chart_IDKf() {
    const chart1 = this.IDKrefChart.nativeElement;
    const ctx1 = chart1.getContext('2d');
    //  let ctx1 = document.getElementById('IDK');

    if (this.myChartIDK) {
      this.myChartIDK.destroy();
      }

    this.myChartIDK = new Chart(ctx1, {
      type: 'bar',
      data: this.IDKchartData,
      options: {
        responsive:true,
        legend: {
          labels: {
            filter: function (item, chart) {
              //  Logic to remove a particular legend item goes here
              // return chart.datasets[item.datasetIndex].data.length > 0
              try {
                return chart.datasets[item.datasetIndex].data.reduce(function(a, b) {
                  return  a ? ( b ? ( a + b ) : a ) : ( b ? b : 0 );
                });
            } catch { return false; }
            }
          }
        },
        // title: {
        //   display: true,
        //   text:this.vsrStep4.chartHeadline[this.selectedLanguage],// 'Tod durch unfall',
        //   fontColor: '#00254D',//'Black',
        //   fontSize: 12,
        //   responsive: true,
        // },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        },
      }
    });

    try {
    this.myDoughnutChartIDK.destroy();
    } catch {}
  }
  Chart_Alterf() {

    const chart1 = this.AlterrefChart.nativeElement;
    const ctx1 = chart1.getContext('2d');

    //  let ctx1 = document.getElementById('Alter');

    if (this.myChartAlter) {
      this.myChartAlter.destroy();
      }

    this.myChartAlter = new Chart(ctx1, {
      type: 'bar',
      data: this.AlterchartData,
      options: {
        responsive:true,
        legend: {
          labels: {
            filter: function (item, chart) {
              //  Logic to remove a particular legend item goes here
              // return chart.datasets[item.datasetIndex].data.length > 0
              try {
                return chart.datasets[item.datasetIndex].data.reduce(function(a, b ) {
                  return a ? ( b ? ( a + b ) : a ) : ( b ? b : 0 );
                });
            } catch { return false; }
            }
          }
        },
        // title: {
        //   display: true,
        //   text:this.vsrStep4.chartHeadline[this.selectedLanguage], // 'Tod durch unfall',
        //   fontColor: '#00254D',//'Black',
        //   fontSize: 12,
        //   responsive: true,
        //   //  fontFamily:'Arial'
        // },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        },
        //  annotation: {
        //    annotations: [{
        //      type: 'line',
        //      mode: 'horizontal',
        //      scaleID: 'y-axis-0',
        //      value: this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome,
        //      borderColor: 'red',
        //      borderWidth: 2
        //    },
        //    ]
        //  }
        //  ,
        //  legend: {
        //    display: true,
        //    position: 'top',
        //    labels: {
        //      boxWidth: 80,
        //      fontColor: 'red'
        //    }
        //  }
      }
    });

    try {
      this.myDoughnutChartAlter.destroy();
      } catch {}
  }



  Chart_TDUf_Doughnut() {
    const TDUSum = Number(this.LastTDUAHV[this.LabelsTDU - 1] ? this.LastTDUAHV[this.LabelsTDU - 1] : 0) +
    Number(this.LastTDUBVG[this.LabelsTDU - 1] ? this.LastTDUBVG[this.LabelsTDU - 1] : 0) +
    Number(this.LastTDUEinkommen[this.LabelsTDU - 1] ? this.LastTDUEinkommen[this.LabelsTDU - 1] : 0) +
    Number(this.LastTDUKTG[this.LabelsTDU - 1] ? this.LastTDUKTG[this.LabelsTDU - 1] : 0) +
    Number(this.LastTDUUVG[this.LabelsTDU - 1] ? this.LastTDUUVG[this.LabelsTDU - 1] : 0);
    //  let ctx = document.getElementById('TDU');
    const chart1 = this.TDU_DrefChart.nativeElement;
    const ctx = chart1.getContext('2d');

    this.myDoughnutChartTDU = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [
            TDUSum
            ,

            (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)
            -
            TDUSum

          ],
          backgroundColor: [
            //  'rgba(255, 99, 132, 0.2)',
            'rgba(203,187,158,1)',
            Math.round((TDUSum) * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)) > 90 ?
            '#BCD056' :
            'rgba(0,37,77,1)'
          ],
          borderColor: [
            //  'rgba(255, 99, 132, 1)',
            'rgba(203,187,158,1)',
            Math.round((TDUSum) * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)) > 90 ?
            '#BCD056' :
            'rgba(0,37,77,1)'
          ],
          borderWidth: 0
        }],

        labels: [
          'Deckung im Schadenfall',
          'Deckungslücke'
        ]
      },
      plugins: [{
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            const ctx = chart.chart.ctx;
            // Get options from the center object in options
            const centerConfig = chart.config.options.elements.center;
            const fontStyle = 'UniviaPro-Bold' || 'Arial';
            const txt = centerConfig.text;
            const color = centerConfig.color || '#000';
            const sidePadding = centerConfig.sidePadding || 20;
            const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            // ctx.font = '28px '  + fontStyle;
            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            const stringWidth = ctx.measureText(txt).width;
            const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
            //  Find out how much the font can grow in width.
            const widthRatio = elementWidth / stringWidth;
            const newFontSize = Math.floor(15 * widthRatio);
            const elementHeight = (chart.innerRadius * 2);

            //  Pick a new font size so it will not be larger than the height of label.
            const fontSizeToUse = Math.min(newFontSize, elementHeight);

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            const centerY = ((chart.chartArea.bottom));
             ctx.font = '40px ' + fontStyle;
            ctx.fillStyle = color;

            // Draw text in center
            ctx.fillText(txt, centerX, centerY);
          }
        }
    }],
       options: {
        responsive:true,
        legend: {
          position: 'top',
      },
        cutoutPercentage: 90,
        elements: {
          center: {
            text: Math.round(
              (
                TDUSum
            )
             * 100
            / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)
            ) + '%',
            color: '#00254d', //  Default is #000000
            //  fontStyle: 'Arial', // Default is Arial
            sidePadding: 20 //  Defualt is 20 (as a percentage)
          }
        },
        maintainAspectRatio: false,
        rotation: 0.8 * Math.PI,
        circumference: 1.4 * Math.PI,
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: false
            }
          }]
        },
        // title: {
        //   display: true,
        //   // text:this.vsrStep4.overviewHeadline[this.selectedLanguage], // 'Tod durch unfall',
        //   fontColor: '#00254D', // 'Black'
        //   fontSize: 14,
        //   fontFamily: 'UniviaPro-Bold',
        //   responsive: true,
        //   //  fontFamily:'UniviaPro-Book'
        // }
      }
    });

    try {
    this.myChartTDU.destroy();
  } catch {}

  this.myDoughnutChartTDU.update();
  }

  Chart_TDKf_Doughnut() {
    //  let ctx = document.getElementById('TDK');
    const chart1 = this.TDK_DrefChart.nativeElement;
    const ctx = chart1.getContext('2d');

    const TDKSum = Number(this.LastTDKAHV[this.LabelsTDK - 1] ? this.LastTDKAHV[this.LabelsTDK - 1] : 0) +
    Number(this.LastTDKBVG[this.LabelsTDK - 1] ? this.LastTDKBVG[this.LabelsTDK - 1] : 0) +
    Number(this.LastTDKEinkommen[this.LabelsTDK - 1] ? this.LastTDKEinkommen[this.LabelsTDK - 1] : 0) +
    Number(this.LastTDKKTG[this.LabelsTDK - 1] ? this.LastTDKKTG[this.LabelsTDK - 1] : 0) +
    Number(this.LastTDKUVG[this.LabelsTDK - 1] ? this.LastTDKUVG[this.LabelsTDK - 1] : 0);
    this.myDoughnutChartTDK = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [
            TDKSum
            ,

            (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)
            -
            TDKSum

          ],
          backgroundColor: [
            //  'rgba(255, 99, 132, 0.2)',
            'rgba(203,187,158,1)',
            Math.round( TDKSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome) ) > 90 ?
            '#BCD056' :
             'rgba(0,37,77,1)'
          ],
          borderColor: [
            //  'rgba(255, 99, 132, 1)',
            'rgba(203,187,158,1)',
            Math.round( TDKSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome) ) > 90 ?
            '#BCD056' :
             'rgba(0,37,77,1)'
          ],
          borderWidth: 3
        }],

        labels: [
          'Deckung im Schadenfall',
          'Deckungslücke'
        ]
      },
      plugins: [{
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            const ctx = chart.chart.ctx;
            // Get options from the center object in options
            const centerConfig = chart.config.options.elements.center;
            const fontStyle = 'UniviaPro-Bold' || 'Arial';
            const txt = centerConfig.text;
            const color = centerConfig.color || '#000';
            const sidePadding = centerConfig.sidePadding || 20;
            const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            // ctx.font = '28px '  + fontStyle;
            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            const stringWidth = ctx.measureText(txt).width;
            const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
            //  Find out how much the font can grow in width.
            const widthRatio = elementWidth / stringWidth;
            const newFontSize = Math.floor(15 * widthRatio);
            const elementHeight = (chart.innerRadius * 2);

            //  Pick a new font size so it will not be larger than the height of label.
            const fontSizeToUse = Math.min(newFontSize, elementHeight);

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            const centerY = ((chart.chartArea.bottom));
             ctx.font = '40px ' + fontStyle;
            ctx.fillStyle = color;

            // Draw text in center
            ctx.fillText(txt, centerX, centerY);
          }
        }
    }],

      options: {
        responsive:true,
        legend: {
          position: 'top',
      },
      labels: {
        position:'top'
      },
        cutoutPercentage: 90,
        elements: {
          center: {
            text: Math.round( TDKSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome) ) + '%',
            color: '#00254d', //  Default is #000000
            //  fontStyle: 'Arial', // Default is Arial
            sidePadding: 20 //  Defualt is 20 (as a percentage)
          }
        },
        maintainAspectRatio: false,
        // rotation: 1 * Math.PI,
        // circumference: 1 * Math.PI,
        rotation: 0.8 * Math.PI,
        circumference: 1.4 * Math.PI,
        // title: {
        //   display: true,
        //   // text:this.vsrStep4.overviewHeadline[this.selectedLanguage], // 'Tod durch unfall',
        //   fontColor: '#00254D', // 'Black'
        //   fontSize: 14,
        //   fontFamily: 'UniviaPro-Bold',
        //   responsive: true,
        //   //  fontFamily:'UniviaPro-Book'
        // },
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
try {
    this.myChartTDK.destroy();
  } catch {}
  }

  Chart_IDUf_Doughnut() {
    this.MaxIDU = this.MaxValue([
      this.LastIDUBVG.length,
      this.LastIDUAHV.length,
      this.LastIDUEinkommen.length,
      this.LastIDUKTG, this.LastIDUUVG
    ]);
    //  let ctx = document.getElementById('IDU');
    const chart1 = this.IDU_DrefChart.nativeElement;
    const ctx = chart1.getContext('2d');


    // let IDUSum= Number(this.IDUAHVData[this.LabelsIDU - 1] ? this.IDUAHVData[this.LabelsIDU - 1] : 0) +
    // Number(this.IDUBVGData[this.LabelsIDU - 1] ? this.IDUBVGData[this.LabelsIDU - 1] : 0) +
    // Number(this.IDUEinkommenData[this.LabelsIDU - 1] ? this.IDUEinkommenData[this.LabelsIDU - 1] : 0) +
    // Number(this.IDUKTGData[this.LabelsIDU - 1] ? this.IDUKTGData[this.LabelsIDU - 1] : 0) +
    // Number(this.IDUUVGData[this.LabelsIDU - 1] ? this.IDUUVGData[this.LabelsIDU - 1] : 0)


    const IDUSum = Number(this.LastIDUAHV[this.MaxIDU - 1] ? this.LastIDUAHV[this.MaxIDU - 1] : 0) +
      Number(this.LastIDUBVG[this.MaxIDU - 1] ? this.LastIDUBVG[this.MaxIDU - 1] : 0) +
      Number(this.LastIDUEinkommen[this.MaxIDU - 1] ? this.LastIDUEinkommen[this.MaxIDU - 1] : 0) +
      Number(this.LastIDUKTG[this.MaxIDU - 1] ? this.LastIDUKTG[this.MaxIDU - 1] : 0) +
      Number(this.LastIDUUVG[this.MaxIDU - 1] ? this.LastIDUUVG[this.MaxIDU - 1] : 0);
    // let IDUSum= Number(this.LastIDUAHV[this.LabelsIDU - 1] ? this.LastIDUAHV[this.LabelsIDU - 1] : 0) +
    // Number(this.LastIDUBVG[this.LabelsIDU - 1] ? this.LastIDUBVG[this.LabelsIDU - 1] : 0) +
    // Number(this.LastIDUEinkommen[this.LabelsIDU - 1] ? this.LastIDUEinkommen[this.LabelsIDU - 1] : 0) +
    // Number(this.LastIDUKTG[this.LabelsIDU - 1] ? this.LastIDUKTG[this.LabelsIDU - 1] : 0) +
    // Number(this.LastIDUUVG[this.LabelsIDU - 1] ? this.LastIDUUVG[this.LabelsIDU - 1] : 0)

    // let TDUSum = Number(this.LastTDUAHV[this.LabelsTDU - 1] ? this.LastTDUAHV[this.LabelsTDU - 1] : 0) +
    // Number(this.LastTDUBVG[this.LabelsTDU - 1] ? this.LastTDUBVG[this.LabelsTDU - 1] : 0) +
    // Number(this.LastTDUEinkommen[this.LabelsTDU - 1] ? this.LastTDUEinkommen[this.LabelsTDU - 1] : 0) +
    // Number(this.LastTDUKTG[this.LabelsTDU - 1] ? this.LastTDUKTG[this.LabelsTDU - 1] : 0) +
    // Number(this.LastTDUUVG[this.LabelsTDU - 1] ? this.LastTDUUVG[this.LabelsTDU - 1] : 0)

    this.myDoughnutChartIDU = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [
            IDUSum,
            (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)
            -
            IDUSum

          ],
          backgroundColor: [
            //  'rgba(255, 99, 132, 0.2)',
            'rgba(203,187,158,1)',
            Math.round( IDUSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome) ) > 90 ?
            '#BCD056' :
            'rgba(0,37,77,1)'
          ],
          borderColor: [
            //  'rgba(255, 99, 132, 1)',
            'rgba(203,187,158,1)',
            Math.round( IDUSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome) ) > 90 ?
            '#BCD056' :
            'rgba(0,37,77,1)'
          ],
          borderWidth: 3
        }],

        labels: [
          'Deckung im Schadenfall',
          'Deckungslücke'
        ]
      },
      plugins: [{
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            const ctx = chart.chart.ctx;
            // Get options from the center object in options
            const centerConfig = chart.config.options.elements.center;
            const fontStyle = 'UniviaPro-Bold' || 'Arial';
            const txt = centerConfig.text;
            const color = centerConfig.color || '#000';
            const sidePadding = centerConfig.sidePadding || 20;
            const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            // ctx.font = '28px '  + fontStyle;
            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            const stringWidth = ctx.measureText(txt).width;
            const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
            //  Find out how much the font can grow in width.
            const widthRatio = elementWidth / stringWidth;
            const newFontSize = Math.floor(15 * widthRatio);
            const elementHeight = (chart.innerRadius * 2);

            //  Pick a new font size so it will not be larger than the height of label.
            const fontSizeToUse = Math.min(newFontSize, elementHeight);

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            const centerY = ((chart.chartArea.bottom));
             ctx.font = '40px ' + fontStyle;
            ctx.fillStyle = color;

            // Draw text in center
            ctx.fillText(txt, centerX, centerY);
          }
        }
    }],
      options: {
        responsive:true,
        legend: {
          position: 'top',
      },
        cutoutPercentage: 90,
        elements: {
          center: {
            text: Math.round( IDUSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome) ) + '%',
            color: '#00254d', //  Default is #000000
            //  fontStyle: 'Arial', // Default is Arial
            sidePadding: 20 //  Defualt is 20 (as a percentage)
          }
        },
        maintainAspectRatio: false,
        // rotation: 1 * Math.PI,
        // circumference: 1 * Math.PI,
        rotation: 0.8 * Math.PI,
        circumference: 1.4 * Math.PI,
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }]
        },
        // title: {
        //   display: true,
        //   text:this.vsrStep4.overviewHeadline[this.selectedLanguage], // 'Tod durch unfall',
        //   fontColor: '#00254D', // 'Black'
        //   fontSize: 14,
        //   fontFamily: 'UniviaPro-Bold',
        //   responsive: true,
        //   //  fontFamily:'UniviaPro-Book'
        // }
      }
    });

    try {
    this.myChartIDU.destroy();
  } catch {}
  }

  Chart_IDKf_Doughnut() {
    this.MaxIDK = this.MaxValue([
      this.LastIDKBVG.length, this.LastIDKAHV.length, this.LastIDKEinkommen.length, this.LastIDKKTG, this.LastIDKUVG
    ]);
    //  let ctx = document.getElementById('IDK');
    const chart1 = this.IDK_DrefChart.nativeElement;
    const ctx = chart1.getContext('2d');

    const IDKSum = Number(this.LastIDKAHV[this.MaxIDK - 1] ? this.LastIDKAHV[this.MaxIDK - 1] : 0) +
      Number(this.LastIDKBVG[this.MaxIDK - 1] ? this.LastIDKBVG[this.MaxIDK - 1] : 0) +
      Number(this.LastIDKEinkommen[this.MaxIDK - 1] ? this.LastIDKEinkommen[this.MaxIDK - 1] : 0) +
      Number(this.LastIDKKTG[this.MaxIDK - 1] ? this.LastIDKKTG[this.MaxIDK - 1] : 0) +
      Number(this.LastIDKUVG[this.MaxIDK - 1] ? this.LastIDKUVG[this.MaxIDK - 1] : 0);

    this.myDoughnutChartIDK = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [
            IDKSum
            ,

            (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)
            -
            IDKSum

          ],
          backgroundColor: [
            //  'rgba(255, 99, 132, 0.2)',
            'rgba(203,187,158,1)',
            Math.round(IDKSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)) > 90 ?
            '#BCD056' :
            'rgba(0,37,77,1)'
          ],
          borderColor: [
            //  'rgba(255, 99, 132, 1)',
            'rgba(203,187,158,1)',
            Math.round(IDKSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)) > 90 ?
            '#BCD056' :
            'rgba(0,37,77,1)'
          ],
          borderWidth: 3
        }],

        labels: [
          'Deckung im Schadenfall',
          'Deckungslücke'
        ]
      },
      plugins: [{
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            const ctx = chart.chart.ctx;
            // Get options from the center object in options
            const centerConfig = chart.config.options.elements.center;
            const fontStyle = 'UniviaPro-Bold' || 'Arial';
            const txt = centerConfig.text;
            const color = centerConfig.color || '#000';
            const sidePadding = centerConfig.sidePadding || 20;
            const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            // ctx.font = '28px '  + fontStyle;
            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            const stringWidth = ctx.measureText(txt).width;
            const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
            //  Find out how much the font can grow in width.
            const widthRatio = elementWidth / stringWidth;
            const newFontSize = Math.floor(15 * widthRatio);
            const elementHeight = (chart.innerRadius * 2);

            //  Pick a new font size so it will not be larger than the height of label.
            const fontSizeToUse = Math.min(newFontSize, elementHeight);

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            const centerY = ((chart.chartArea.bottom));
             ctx.font = '40px ' + fontStyle;
            ctx.fillStyle = color;

            // Draw text in center
            ctx.fillText(txt, centerX, centerY);
          }
        }
    }],
      options: {
        responsive:true,
        legend: {
          position: 'top',
      },
        cutoutPercentage: 90,
        elements: {
          center: {
            text: Math.round(IDKSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)) + '%',
            color: '#00254d', //  Default is #000000
            //  fontStyle: 'Arial', // Default is Arial
            sidePadding: 20 //  Defualt is 20 (as a percentage)
          }
        },
        maintainAspectRatio: false,
        // rotation: 1 * Math.PI,
        // circumference: 1 * Math.PI,
        rotation: 0.8 * Math.PI,
        circumference: 1.4 * Math.PI,
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }]
        },
        // title: {
        //   display: true,
        //   text:this.vsrStep4.overviewHeadline[this.selectedLanguage], // 'Tod durch unfall',
        //   fontColor: '#00254D', // 'Black'
        //   fontSize: 14,
        //   fontFamily: 'UniviaPro-Bold',
        //   responsive: true,
        //   //  fontFamily:'UniviaPro-Book'
        // }
      }
    });

    try {
    this.myChartIDK.destroy();
  } catch {}
  }

  Chart_Alterf_Doughnut() {

    this.MaxAlter = this.MaxValue([
      this.LastAlterBVG.length, this.LastAlterAHV.length, this.LastAlterEinkommen.length, this.LastAlterKTG, this.LastAlterUVG
    ]);

    //  let ctx = document.getElementById('Alter');

    const chart1 = this.Alter_DrefChart.nativeElement;
    const ctx = chart1.getContext('2d');

    const AlterSum = Number(this.LastAlterAHV[this.MaxAlter - 1] ? this.LastAlterAHV[this.MaxAlter - 1] : 0) +
    Number(this.LastAlterBVG[this.MaxAlter - 1] ? this.LastAlterBVG[this.MaxAlter - 1] : 0) +
    Number(this.LastAlterEinkommen[this.MaxAlter - 1] ? this.LastAlterEinkommen[this.MaxAlter - 1] : 0) +
    Number(this.LastAlterKTG[this.MaxAlter - 1] ? this.LastAlterKTG[this.MaxAlter - 1] : 0) +
    Number(this.LastAlterUVG[this.MaxAlter - 1] ? this.LastAlterUVG[this.MaxAlter - 1] : 0);

    this.myDoughnutChartAlter = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [{
          data:
            [
              AlterSum
              ,

              (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)
              -
              AlterSum

            ],
          backgroundColor: [
            //  'rgba(255, 99, 132, 0.2)',
            'rgba(203,187,158,1)',
            Math.round(AlterSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)) > 90 ?
            '#BCD056' :
            'rgba(0,37,77,1)'
          ],
          borderColor: [
            //  'rgba(255, 99, 132, 1)',
            'rgba(203,187,158,1)',
            Math.round(AlterSum * 100 / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)) > 90 ?
            '#BCD056' :
            'rgba(0,37,77,1)'
          ],
          borderWidth: 3
        }],

        labels: [
          'Deckung im Schadenfall',
          'Deckungslücke'
        ]
      },
      plugins: [{
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            const ctx = chart.chart.ctx;
            // Get options from the center object in options
            const centerConfig = chart.config.options.elements.center;
            const fontStyle = 'UniviaPro-Bold' || 'Arial';
            const txt = centerConfig.text;
            const color = centerConfig.color || '#000';
            const sidePadding = centerConfig.sidePadding || 20;
            const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            // ctx.font = '28px '  + fontStyle;
            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            const stringWidth = ctx.measureText(txt).width;
            const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
            //  Find out how much the font can grow in width.
            const widthRatio = elementWidth / stringWidth;
            const newFontSize = Math.floor(15 * widthRatio);
            const elementHeight = (chart.innerRadius * 2);

            //  Pick a new font size so it will not be larger than the height of label.
            const fontSizeToUse = Math.min(newFontSize, elementHeight);

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            const centerY = ((chart.chartArea.bottom));
             ctx.font = '40px ' + fontStyle;
            ctx.fillStyle = color;

            // Draw text in center
            ctx.fillText(txt, centerX, centerY);
          }
        }
    }],
      options: {
         responsive:true,
        legend: {
          position: 'top',
      },
        cutoutPercentage: 90,
        elements: {
          center: {
            text: Math.round(
              AlterSum
             * 100
            / (this.data.person.avgYearlyIncome + this.data.partner.avgYearlyIncome)
            ) + '%',
            color: '#00254d', //  Default is #000000
            //  fontStyle: 'Arial', // Default is Arial
            sidePadding: 20 //  Defualt is 20 (as a percentage)
          }
        },
        maintainAspectRatio: false,
        // rotation: 1 * Math.PI,
        // circumference: 1 * Math.PI,
        rotation: 0.8 * Math.PI,
        circumference: 1.4 * Math.PI,
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }]
        },
        // title: {
        //   display: true,
        //   text:this.vsrStep4.overviewHeadline[this.selectedLanguage], // 'Tod durch unfall',
        //   fontColor: '#00254D', // 'Black'
        //   fontSize: 14,
        //   fontFamily: 'UniviaPro-Bold',
        //   responsive: true,
        //   //  fontFamily:'UniviaPro-Book'
        // }
      }
    });

    try {
    this.myChartAlter.destroy();
  } catch {}

  }

  MaxValue(values: any[]): number {
    let MaxValue = 0;
    for (let i = 0; i < values.length; i++) {
      if (values[i] > MaxValue) { MaxValue = values[i]; }
    }
    return MaxValue;
  }


  GroupTDU() {

    let SameValues = 0;
    let ValuesToGroup = 0;
    const TDUGroupedData = {
      Years: [],
      AHV: [],
      BVG: [],
      UVG: [],
      Einkommen: [],
      KTG: []
    };


    let CurrentTDUData = {
      Years: this.TDUchartData.labels[0],
      AHV: this.TDUAHVData[0],
      BVG: this.TDUBVGData[0],
      UVG: this.TDUUVGData[0],
      Einkommen: this.TDUEinkommenData[0],
      KTG: this.TDUKTGData[0]
    };

    for (let i = 1; i <= this.TDUchartData.labels.length - 2; i++) {

      if (i === this.TDUchartData.labels.length - 2) {
        TDUGroupedData.Years.push(ValuesToGroup > 0 ? CurrentTDUData.Years + '-' +
        Number(CurrentTDUData.Years + ValuesToGroup) : CurrentTDUData.Years);
          TDUGroupedData.AHV.push(CurrentTDUData.AHV);
          TDUGroupedData.BVG.push(CurrentTDUData.BVG);
          TDUGroupedData.UVG.push(CurrentTDUData.UVG);
          TDUGroupedData.Einkommen.push(CurrentTDUData.Einkommen);
          TDUGroupedData.KTG.push(CurrentTDUData.KTG);


    this.TDUchartData.datasets[1].data = TDUGroupedData.AHV;
    this.TDUchartData.datasets[2].data = TDUGroupedData.BVG;
    this.TDUchartData.datasets[3].data = TDUGroupedData.UVG;
    this.TDUchartData.datasets[4].data = TDUGroupedData.Einkommen;
    this.TDUchartData.datasets[5].data = TDUGroupedData.KTG;
    this.TDUchartData.labels = TDUGroupedData.Years;

          // return;
      } else {

      if (CurrentTDUData.AHV === this.TDUAHVData[i] &&
        CurrentTDUData.BVG === this.TDUBVGData[i] &&
        CurrentTDUData.UVG === this.TDUUVGData[i] &&
        CurrentTDUData.Einkommen === this.TDUEinkommenData[i] &&
        CurrentTDUData.KTG === this.TDUKTGData[i]) {
        SameValues++;
        if (SameValues > this.GroupAfter) {
          ValuesToGroup++;
        } else {
          TDUGroupedData.Years.push(ValuesToGroup > 0 ? CurrentTDUData.Years + '-' +
          Number(CurrentTDUData.Years + ValuesToGroup) : CurrentTDUData.Years);
          TDUGroupedData.AHV.push(CurrentTDUData.AHV);
          TDUGroupedData.BVG.push(CurrentTDUData.BVG);
          TDUGroupedData.UVG.push(CurrentTDUData.UVG);
          TDUGroupedData.Einkommen.push(CurrentTDUData.Einkommen);
          TDUGroupedData.KTG.push(CurrentTDUData.KTG);

          CurrentTDUData = {
            Years: this.TDUchartData.labels[i],
            AHV: this.TDUAHVData[i],
            BVG: this.TDUBVGData[i],
            UVG: this.TDUUVGData[i],
            Einkommen: this.TDUEinkommenData[i],
            KTG: this.TDUKTGData[i]
          };

        }
      } else {
        SameValues = 0;

        TDUGroupedData.Years.push(ValuesToGroup > 0 ? CurrentTDUData.Years + '-' +
        Number(CurrentTDUData.Years + ValuesToGroup) : CurrentTDUData.Years); // add the year
        TDUGroupedData.AHV.push(CurrentTDUData.AHV);
        TDUGroupedData.BVG.push(CurrentTDUData.BVG);
        TDUGroupedData.UVG.push(CurrentTDUData.UVG);
        TDUGroupedData.Einkommen.push(CurrentTDUData.Einkommen);
        TDUGroupedData.KTG.push(CurrentTDUData.KTG);

        CurrentTDUData = {
          Years: this.TDUchartData.labels[i],
          AHV: this.TDUAHVData[i],
          BVG: this.TDUBVGData[i],
          UVG: this.TDUUVGData[i],
          Einkommen: this.TDUEinkommenData[i],
          KTG: this.TDUKTGData[i]
        };

        ValuesToGroup = 0;
      }
      }
    }

    this.TDUAHVData =  TDUGroupedData.AHV;
    this.TDUBVGData = TDUGroupedData.BVG;
    this.TDUUVGData = TDUGroupedData.UVG;
    this.TDUEinkommenData = TDUGroupedData.Einkommen;
    this.TDUKTGData = TDUGroupedData.KTG;

  }

  GroupTDK() {

    let SameValues = 0;
    let ValuesToGroup = 0;
    const TDKGroupedData = {
      Years: [],
      AHV: [],
      BVG: [],
      UVG: [],
      Einkommen: [],
      KTG: []
    };

    let CurrentTDKData = {
      Years: this.TDKchartData.labels[0],
      AHV: this.TDKAHVData[0],
      BVG: this.TDKBVGData[0],
      UVG: this.TDKUVGData[0],
      Einkommen: this.TDKEinkommenData[0],
      KTG: this.TDKKTGData[0]
    };


    for (let i = 1; i <= this.TDKchartData.labels.length - 2; i++) {

      if (i === this.TDKchartData.labels.length - 2) {
        TDKGroupedData.Years.push(ValuesToGroup > 0 ? CurrentTDKData.Years + '-' +
        Number(CurrentTDKData.Years + ValuesToGroup) : CurrentTDKData.Years); // add the year
          TDKGroupedData.AHV.push(CurrentTDKData.AHV);
          TDKGroupedData.BVG.push(CurrentTDKData.BVG);
          TDKGroupedData.UVG.push(CurrentTDKData.UVG);
          TDKGroupedData.Einkommen.push(CurrentTDKData.Einkommen);
          TDKGroupedData.KTG.push(CurrentTDKData.KTG);


    this.TDKchartData.datasets[1].data = TDKGroupedData.AHV;
    this.TDKchartData.datasets[2].data = TDKGroupedData.BVG;
    this.TDKchartData.datasets[3].data = TDKGroupedData.UVG;
    this.TDKchartData.datasets[4].data = TDKGroupedData.Einkommen;
    this.TDKchartData.datasets[5].data = TDKGroupedData.KTG;
    this.TDKchartData.labels = TDKGroupedData.Years;

          // return;
      } else {

      if (CurrentTDKData.AHV === this.TDKAHVData[i] &&
        CurrentTDKData.BVG === this.TDKBVGData[i] &&
        CurrentTDKData.UVG === this.TDKUVGData[i] &&
        CurrentTDKData.Einkommen === this.TDKEinkommenData[i] &&
        CurrentTDKData.KTG === this.TDKKTGData[i]) {
        SameValues++;
        if (SameValues > this.GroupAfter) {
          ValuesToGroup++;
        } else {
          TDKGroupedData.Years.push(ValuesToGroup > 0 ? CurrentTDKData.Years + '-' +
          Number(CurrentTDKData.Years + ValuesToGroup) : CurrentTDKData.Years);
          TDKGroupedData.AHV.push(CurrentTDKData.AHV);
          TDKGroupedData.BVG.push(CurrentTDKData.BVG);
          TDKGroupedData.UVG.push(CurrentTDKData.UVG);
          TDKGroupedData.Einkommen.push(CurrentTDKData.Einkommen);
          TDKGroupedData.KTG.push(CurrentTDKData.KTG);

          CurrentTDKData = {
            Years: this.TDKchartData.labels[i],
            AHV: this.TDKAHVData[i],
            BVG: this.TDKBVGData[i],
            UVG: this.TDKUVGData[i],
            Einkommen: this.TDKEinkommenData[i],
            KTG: this.TDKKTGData[i]
          };

        }
      } else {
        SameValues = 0;

        TDKGroupedData.Years.push(ValuesToGroup > 0 ? CurrentTDKData.Years + '-' +
        Number(CurrentTDKData.Years + ValuesToGroup) : CurrentTDKData.Years);
        TDKGroupedData.AHV.push(CurrentTDKData.AHV);
        TDKGroupedData.BVG.push(CurrentTDKData.BVG);
        TDKGroupedData.UVG.push(CurrentTDKData.UVG);
        TDKGroupedData.Einkommen.push(CurrentTDKData.Einkommen);
        TDKGroupedData.KTG.push(CurrentTDKData.KTG);

        CurrentTDKData = {
          Years: this.TDKchartData.labels[i],
          AHV: this.TDKAHVData[i],
          BVG: this.TDKBVGData[i],
          UVG: this.TDKUVGData[i],
          Einkommen: this.TDKEinkommenData[i],
          KTG: this.TDKKTGData[i]
        };

        ValuesToGroup = 0;
      }
      }
    }

    this.TDKAHVData =  TDKGroupedData.AHV;
    this.TDKBVGData = TDKGroupedData.BVG;
    this.TDKUVGData = TDKGroupedData.UVG;
    this.TDKEinkommenData = TDKGroupedData.Einkommen;
    this.TDKKTGData = TDKGroupedData.KTG;

  }

  GroupIDU() {

    let SameValues = 0;
    let ValuesToGroup = 0;
    const IDUGroupedData = {
      Years: [],
      AHV: [],
      BVG: [],
      UVG: [],
      Einkommen: [],
      KTG: []
    };

    let CurrentIDUData = {
      Years: this.IDUchartData.labels[0],
      AHV: this.IDUAHVData[0],
      BVG: this.IDUBVGData[0],
      UVG: this.IDUUVGData[0],
      Einkommen: this.IDUEinkommenData[0],
      KTG: this.IDUKTGData[0]
    };


    for (let i = 1; i <= this.IDUchartData.labels.length - 2; i++) {

      if (i === this.IDUchartData.labels.length - 2) {
        IDUGroupedData.Years.push(ValuesToGroup > 0 ? CurrentIDUData.Years + '-' +
        Number(CurrentIDUData.Years + ValuesToGroup) : CurrentIDUData.Years);
          IDUGroupedData.AHV.push(CurrentIDUData.AHV);
          IDUGroupedData.BVG.push(CurrentIDUData.BVG);
          IDUGroupedData.UVG.push(CurrentIDUData.UVG);
          IDUGroupedData.Einkommen.push(CurrentIDUData.Einkommen);
          IDUGroupedData.KTG.push(CurrentIDUData.KTG);


    this.IDUchartData.datasets[1].data = IDUGroupedData.AHV;
    this.IDUchartData.datasets[2].data = IDUGroupedData.BVG;
    this.IDUchartData.datasets[3].data = IDUGroupedData.UVG;
    this.IDUchartData.datasets[4].data = IDUGroupedData.Einkommen;
    this.IDUchartData.datasets[5].data = IDUGroupedData.KTG;
    this.IDUchartData.labels = IDUGroupedData.Years;

          // return;
      } else {

      if (CurrentIDUData.AHV === this.IDUAHVData[i] &&
        CurrentIDUData.BVG === this.IDUBVGData[i] &&
        CurrentIDUData.UVG === this.IDUUVGData[i] &&
        CurrentIDUData.Einkommen === this.IDUEinkommenData[i] &&
        CurrentIDUData.KTG === this.IDUKTGData[i]) {
        SameValues++;
        if (SameValues > this.GroupAfter) {
          ValuesToGroup++;
        } else {
          IDUGroupedData.Years.push(ValuesToGroup > 0 ? CurrentIDUData.Years + '-' +
          Number(CurrentIDUData.Years + ValuesToGroup) : CurrentIDUData.Years);
          IDUGroupedData.AHV.push(CurrentIDUData.AHV);
          IDUGroupedData.BVG.push(CurrentIDUData.BVG);
          IDUGroupedData.UVG.push(CurrentIDUData.UVG);
          IDUGroupedData.Einkommen.push(CurrentIDUData.Einkommen);
          IDUGroupedData.KTG.push(CurrentIDUData.KTG);

          CurrentIDUData = {
            Years: this.IDUchartData.labels[i],
            AHV: this.IDUAHVData[i],
            BVG: this.IDUBVGData[i],
            UVG: this.IDUUVGData[i],
            Einkommen: this.IDUEinkommenData[i],
            KTG: this.IDUKTGData[i]
          };

        }
      } else {
        SameValues = 0;

        IDUGroupedData.Years.push(ValuesToGroup > 0 ? CurrentIDUData.Years + '-' +
        Number(CurrentIDUData.Years + ValuesToGroup) : CurrentIDUData.Years);
        IDUGroupedData.AHV.push(CurrentIDUData.AHV);
        IDUGroupedData.BVG.push(CurrentIDUData.BVG);
        IDUGroupedData.UVG.push(CurrentIDUData.UVG);
        IDUGroupedData.Einkommen.push(CurrentIDUData.Einkommen);
        IDUGroupedData.KTG.push(CurrentIDUData.KTG);

        CurrentIDUData = {
          Years: this.IDUchartData.labels[i],
          AHV: this.IDUAHVData[i],
          BVG: this.IDUBVGData[i],
          UVG: this.IDUUVGData[i],
          Einkommen: this.IDUEinkommenData[i],
          KTG: this.IDUKTGData[i]
        };

        ValuesToGroup = 0;
      }
      }
    }

    this.IDUAHVData =  IDUGroupedData.AHV;
    this.IDUBVGData = IDUGroupedData.BVG;
    this.IDUUVGData = IDUGroupedData.UVG;
    this.IDUEinkommenData = IDUGroupedData.Einkommen;
    this.IDUKTGData = IDUGroupedData.KTG;

  }

  GroupIDK() {

    let SameValues = 0;
    let ValuesToGroup = 0;
    const IDKGroupedData = {
      Years: [],
      AHV: [],
      BVG: [],
      UVG: [],
      Einkommen: [],
      KTG: []
    };

    let CurrentIDKData = {
      Years: this.IDKchartData.labels[0],
      AHV: this.IDKAHVData[0],
      BVG: this.IDKBVGData[0],
      UVG: this.IDKUVGData[0],
      Einkommen: this.IDKEinkommenData[0],
      KTG: this.IDKKTGData[0]
    };


    for (let i = 1; i <= this.IDKchartData.labels.length - 2; i++) {

      if (i === this.IDKchartData.labels.length - 2) {
        IDKGroupedData.Years.push(ValuesToGroup > 0 ? CurrentIDKData.Years + '-' +
        Number(Number(CurrentIDKData.Years) + Number(ValuesToGroup)) : CurrentIDKData.Years);
          IDKGroupedData.AHV.push(CurrentIDKData.AHV);
          IDKGroupedData.BVG.push(CurrentIDKData.BVG);
          IDKGroupedData.UVG.push(CurrentIDKData.UVG);
          IDKGroupedData.Einkommen.push(CurrentIDKData.Einkommen);
          IDKGroupedData.KTG.push(CurrentIDKData.KTG);


    this.IDKchartData.datasets[1].data = IDKGroupedData.AHV;
    this.IDKchartData.datasets[2].data = IDKGroupedData.BVG;
    this.IDKchartData.datasets[3].data = IDKGroupedData.UVG;
    this.IDKchartData.datasets[4].data = IDKGroupedData.Einkommen;
    this.IDKchartData.datasets[5].data = IDKGroupedData.KTG;
    this.IDKchartData.labels = IDKGroupedData.Years;

          // return;
      } else {

      if (CurrentIDKData.AHV === this.IDKAHVData[i] &&
        CurrentIDKData.BVG === this.IDKBVGData[i] &&
        CurrentIDKData.UVG === this.IDKUVGData[i] &&
        CurrentIDKData.Einkommen === this.IDKEinkommenData[i] &&
        CurrentIDKData.KTG === this.IDKKTGData[i]) {
        SameValues++;
        if (SameValues > this.GroupAfter) {
          ValuesToGroup++;
        } else {
          IDKGroupedData.Years.push(ValuesToGroup > 0 ? CurrentIDKData.Years + '-' +
          Number(CurrentIDKData.Years + ValuesToGroup) : CurrentIDKData.Years);
          IDKGroupedData.AHV.push(CurrentIDKData.AHV);
          IDKGroupedData.BVG.push(CurrentIDKData.BVG);
          IDKGroupedData.UVG.push(CurrentIDKData.UVG);
          IDKGroupedData.Einkommen.push(CurrentIDKData.Einkommen);
          IDKGroupedData.KTG.push(CurrentIDKData.KTG);

          CurrentIDKData = {
            Years: this.IDKchartData.labels[i],
            AHV: this.IDKAHVData[i],
            BVG: this.IDKBVGData[i],
            UVG: this.IDKUVGData[i],
            Einkommen: this.IDKEinkommenData[i],
            KTG: this.IDKKTGData[i]
          };

        }
      } else {
        SameValues = 0;

        IDKGroupedData.Years.push(ValuesToGroup > 0 ? CurrentIDKData.Years + '-' +
        Number(CurrentIDKData.Years + ValuesToGroup) : CurrentIDKData.Years);
        IDKGroupedData.AHV.push(CurrentIDKData.AHV);
        IDKGroupedData.BVG.push(CurrentIDKData.BVG);
        IDKGroupedData.UVG.push(CurrentIDKData.UVG);
        IDKGroupedData.Einkommen.push(CurrentIDKData.Einkommen);
        IDKGroupedData.KTG.push(CurrentIDKData.KTG);

        CurrentIDKData = {
          Years: this.IDKchartData.labels[i],
          AHV: this.IDKAHVData[i],
          BVG: this.IDKBVGData[i],
          UVG: this.IDKUVGData[i],
          Einkommen: this.IDKEinkommenData[i],
          KTG: this.IDKKTGData[i]
        };

        ValuesToGroup = 0;
      }
      }
    }

    this.IDKAHVData =  IDKGroupedData.AHV;
    this.IDKBVGData = IDKGroupedData.BVG;
    this.IDKUVGData = IDKGroupedData.UVG;
    this.IDKEinkommenData = IDKGroupedData.Einkommen;
    this.IDKKTGData = IDKGroupedData.KTG;

  }

  GroupAlter() {

    let SameValues = 0;
    let ValuesToGroup = 0;
    const AlterGroupedData = {
      Years: [],
      AHV: [],
      BVG: [],
      UVG: [],
      Einkommen: [],
      KTG: []
    };
    let CurrentAlterData = {
      Years: this.AlterchartData.labels[0],
      AHV: this.AlterAHVData[0],
      BVG: this.AlterBVGData[0],
      UVG: this.AlterUVGData[0],
      Einkommen: this.AlterEinkommenData[0],
      KTG: this.AlterKTGData[0]
    };



    for (let i = 1; i <= this.AlterchartData.labels.length - 2; i++) {
      if (i === this.AlterchartData.labels.length - 2) {
        AlterGroupedData.Years.push(ValuesToGroup > 0 ? CurrentAlterData.Years + '-' + Number(CurrentAlterData.Years + ValuesToGroup) : CurrentAlterData.Years); // add the year
          AlterGroupedData.AHV.push(CurrentAlterData.AHV);
          AlterGroupedData.BVG.push(CurrentAlterData.BVG);
          AlterGroupedData.UVG.push(CurrentAlterData.UVG);
          AlterGroupedData.Einkommen.push(CurrentAlterData.Einkommen);
          AlterGroupedData.KTG.push(CurrentAlterData.KTG);


    this.AlterchartData.datasets[1].data = AlterGroupedData.AHV;
    this.AlterchartData.datasets[2].data = AlterGroupedData.BVG;
    this.AlterchartData.datasets[3].data = AlterGroupedData.UVG;
    this.AlterchartData.datasets[4].data = AlterGroupedData.Einkommen;
    this.AlterchartData.datasets[5].data = AlterGroupedData.KTG;
    this.AlterchartData.labels = AlterGroupedData.Years;

      } else {

      if (CurrentAlterData.AHV === this.AlterAHVData[i] &&
        CurrentAlterData.BVG === this.AlterBVGData[i] &&
        CurrentAlterData.UVG === this.AlterUVGData[i] &&
        CurrentAlterData.Einkommen === this.AlterEinkommenData[i] &&
        CurrentAlterData.KTG === this.AlterKTGData[i]) {
        SameValues++;
        if (SameValues > this.GroupAfter) {
          ValuesToGroup++;
        } else {
          AlterGroupedData.Years.push(ValuesToGroup > 0 ? CurrentAlterData.Years + '-' +
          Number(CurrentAlterData.Years + ValuesToGroup) : CurrentAlterData.Years); // add the year
          AlterGroupedData.AHV.push(CurrentAlterData.AHV);
          AlterGroupedData.BVG.push(CurrentAlterData.BVG);
          AlterGroupedData.UVG.push(CurrentAlterData.UVG);
          AlterGroupedData.Einkommen.push(CurrentAlterData.Einkommen);
          AlterGroupedData.KTG.push(CurrentAlterData.KTG);

          CurrentAlterData = {
            Years: this.AlterchartData.labels[i],
            AHV: this.AlterAHVData[i],
            BVG: this.AlterBVGData[i],
            UVG: this.AlterUVGData[i],
            Einkommen: this.AlterEinkommenData[i],
            KTG: this.AlterKTGData[i]
          };

        }
      } else {
        SameValues = 0;

        AlterGroupedData.Years.push(ValuesToGroup > 0 ? CurrentAlterData.Years + '-' +
        Number(CurrentAlterData.Years + ValuesToGroup) : CurrentAlterData.Years);
        AlterGroupedData.AHV.push(CurrentAlterData.AHV);
        AlterGroupedData.BVG.push(CurrentAlterData.BVG);
        AlterGroupedData.UVG.push(CurrentAlterData.UVG);
        AlterGroupedData.Einkommen.push(CurrentAlterData.Einkommen);
        AlterGroupedData.KTG.push(CurrentAlterData.KTG);

        CurrentAlterData = {
          Years: this.AlterchartData.labels[i],
          AHV: this.AlterAHVData[i],
          BVG: this.AlterBVGData[i],
          UVG: this.AlterUVGData[i],
          Einkommen: this.AlterEinkommenData[i],
          KTG: this.AlterKTGData[i]
        };

        ValuesToGroup = 0;
      }
      }
    }

    this.AlterAHVData =  AlterGroupedData.AHV;
    this.AlterBVGData = AlterGroupedData.BVG;
    this.AlterUVGData = AlterGroupedData.UVG;
    this.AlterEinkommenData = AlterGroupedData.Einkommen;
    this.AlterKTGData = AlterGroupedData.KTG;
  }
DoughnutButton() {
  if (!(this.data.person.maritalStatus == 'Verheiratet' || this.data.person.maritalStatus == 'Married'|| this.data.person.maritalStatus == 'Marié'|| this.data.person.maritalStatus == 'Sposato')) {
    this.data.partner.avgYearlyIncome = 0; }
this.ShowDoughnutChart = true;
this.ShowChart = false;

this.TDUDo = this.TDKDo = this.IDUDo = this.IDKDo = this.ALTERDo = this.TDUDCHART = this.TDKDCHART=this.IDUDCHART = this.IDKDCHART = this.ALTERDCHART = this.shouldShowTDUDoughnutTitle = this.shouldShowTDKDoughnutTitle = this.shouldShowIDUDoughnutTitle = this.shouldShowIDKDoughnutTitle = this.shouldShowALTERDoughnutTitle = this.shouldShowTDUDoughnutMD = this.shouldShowTDKDoughnutMD = this.shouldShowIDUDoughnutMD =this.shouldShowIDKDoughnutMD = this.shouldShowALTERDoughnutMD = true;
this.TDUD = false;
this.TDKD = false;
this.IDUD = false;
this.IDKD = false;
this.ALTERD = false;
this.HeightChart = '100%';
this.WidthChart = '100%';
this.DefineCharts();
this.Execute();
this.ExecuteDoughnut();
}

ExecuteDoughnut() {
  this.ShowChart = false;
  this.ShowDoughnutChart = true;
  this.ShowOnlyBarCharts = false;
  this.ShowOnlyDoughnutCharts = true;

  // check if select all is
  if (this.ExecuteFunctions.tod_durch_unfall === true &&
    this.ExecuteFunctions.tod_durch_krankheit === true &&
    this.ExecuteFunctions.invalid_durch_unfall === true &&
    this.ExecuteFunctions.invalid_durch_krankheit === true &&
    this.ExecuteFunctions.Alter === true) {

    this.SelectAllSelected = true;

    this.Chart_TDUf_Doughnut();
    this.Chart_TDKf_Doughnut();
    this.Chart_IDUf_Doughnut();
    this.Chart_IDKf_Doughnut();
    this.Chart_Alterf_Doughnut();
  } else {
    this.SelectAllSelected = false;

    if (this.ExecuteFunctions.tod_durch_unfall === true) {
            this.Chart_TDUf_Doughnut();
    }

    if (this.ExecuteFunctions.tod_durch_krankheit === true) {
      this.Chart_TDKf_Doughnut();
    }

    if (this.ExecuteFunctions.invalid_durch_unfall === true) {
      this.Chart_IDUf_Doughnut();
    }

    if (this.ExecuteFunctions.invalid_durch_krankheit === true) {
      this.Chart_IDKf_Doughnut();
    }

    if (this.ExecuteFunctions.Alter === true) {
      this.Chart_Alterf_Doughnut();
    }

  }
}
executeschrit4(){

}
ExecuteButton() {
  if (!(this.data.person.maritalStatus == 'Verheiratet' || this.data.person.maritalStatus == 'Married'|| this.data.person.maritalStatus == 'Marié'|| this.data.person.maritalStatus == 'Sposato')) {
    this.data.partner.avgYearlyIncome = 0; }
  this.shouldShow3 = !this.shouldShow3;
  this.Step1 = false;
  this.Step2 = false;
  this.Step3 = false;
  this.Step5 = false;
  this.Step4 = true;
  this.shouldShow = this.shouldShow1 = this.shouldShow2 = this.shouldShow4 = this.shouldShowTDUMD =this.shouldShowTDKMD = this.shouldShowIDUMD = this.shouldShowIDKMD =this.shouldShowALTERMD = this.shouldShowTDUTITLE = this.shouldShowTDKTITLE = this.shouldShowIDUTITLE = this.shouldShowIDKTITLE = this.shouldShowALTERTITLE = this.TDUCHART = this.TDKCHART =this.IDUCHART = this.IDKCHART = this.ALTERCHART = true;
  this.ShowDoughnutChart = false;
  this.ShowChart = true;
  this.TDUMEHR = false;
  this.TDUMD = true;
  this.TDKMEHR = false;
  this.TDKMD = true;
  this.IDUMEHR = false;
  this.IDUMD = true;
  this.IDKMEHR = false;
  this.IDKMD = true;
this.isToggled = false;

  this.ALTERMEHR = false;
  this.ALTERMD = true;
  this.TDUD = this.TDKD = this.IDUD = this.IDKD = this.ALTERD = true;
  this.HeightChart = '100%';
  this.WidthChart = '100%';
  this.ShowTwoButtons = true;
  this.DefineCharts();
  this.Execute();
}

alterChanged(Alter: number): void{
  if(Alter !== 0 && Alter !== null  ){
    this.data.person.age = Alter;
    this.CheckRequired1();
    this.updateUrl();
  }
}

alterpartnerChanged(partnerAlter: number): void{
  if(partnerAlter !== 0 && partnerAlter !== null  ){
    this.data.partner.age = partnerAlter;

 this.CheckRequired2();
 this.updateUrl();
  }
}

yearlyIncomeChanged(yearlyIncome: string): void {

    this.PersonYearlyIncomeTmp = yearlyIncome;
    this.CheckRequired1();
    this.setPersonYearlyIncome(yearlyIncome);
    this.updateUrl();
}

partnerYearylyIncomeChanged(partnerYearlyIncome: string):void{
  this.PartnerYearlyIncomeTmp = partnerYearlyIncome;
  this.CheckRequired2();
  this.setPartnerYearlyIncomeThousand(partnerYearlyIncome);
  this.updateUrl();
}

childChanged(childAlter: number, index: number):void{
  if(childAlter !== null  ){
    this.data.children[index].years = childAlter;
    this.updateUrl();
  }
}

ChartsButton() {
  if (!(this.data.person.maritalStatus == 'Verheiratet' || this.data.person.maritalStatus == 'Married'|| this.data.person.maritalStatus == 'Marié'|| this.data.person.maritalStatus == 'Sposato')) {
    this.data.partner.avgYearlyIncome = 0; }
this.ShowDoughnutChart = false;
this.ShowChart = true;
this.TDUD = this.TDKD = this.IDUD = this.IDKD = this.ALTERD = true;
this.HeightChart = '100%';
this.WidthChart = '100%';
this.DefineCharts();
this.Execute();
}

updateUrl() {
this.urlProvider.updateUrl(this.data, this.firstSegment);
}
translateStuff() {
  const temp = new VorsorgerechnerClass();
  for (const i in temp) {
    if (temp.hasOwnProperty(i)) {
      this.translations[i] = this.Dotranslate(temp[i]);
    }
  }
  this.localTranslationClass.setTranslations(this.translations);
}

Dotranslate(key: string): string {
  let result = 'UNKNOWN';
  this.translate.get(key, {value: 'world'}).subscribe((res: string) => {result = res; });
  return result;
}


toggleButton() {
  if(!this.isToggled){
    this.DoughnutButton();
  }else{
    this.ChartsButton();
  }
  this.isToggled = !this.isToggled;
}
//vsr-seo
updateMetaTags(description: string, keywords: string): void {
  this.seo.updateTags(
      [
          {
              name: 'description', content: description
          },
          {
              name: 'keywords', content: keywords
          }
      ]
  );
}


// filter()
// {
//   this.filteredToolsComponent = this.toolsComponent.filter(x => x.title.de != "Vorsorgerechner");
// }

// vsr-cms
getText(){
  this.subscriptions.push(this.apollo
  .watchQuery({
    query:  VSR_DATA
  })
  .valueChanges.subscribe((res:any) => {
    this.vsrText = res.data.vsr.data.attributes;
    // this.toolsComponent = res.data.tools;
    // this.toolsTitle = res.data.homePageText.Tools;
    // this.filter();
    //left text
    this.leftText = res.data.vsr.data.attributes.VsrLeftText;
    //step1
    this.vsrStep1 = res.data.vsr.data.attributes.VsrStep1;
    //step2
    this.vsrStep2 = res.data.vsr.data.attributes.VsrStep2;

    //step3
    this.vsrStep3 = res.data.vsr.data.attributes.VsrStep3;
    //vsrmatcard
    this.vsrMatCard = res.data.vsr.data.attributes.VsrMatCard;
    //step4
    this.vsrStep4 = res.data.vsr.data.attributes.VsrStep4;
    //seo
    this.seo.setTitle(this.vsrText.Seo.title[this.selectedLanguage]);
    this.updateMetaTags(this.vsrText.Seo.description[this.selectedLanguage],this.vsrText.Seo.keywords[this.selectedLanguage])


  })
  )
}
ngOnDestroy(): void {
  this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
}
}
