import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {NeoInputBase} from "./neo-input.abstract.component";
import {NeoInputButtonItem} from "../models/neo-input-button.item";

@Component({
    selector: "neo-input-button",
    templateUrl: "./neo-input-button.component.html",
    styleUrls: ["./neo-input-button.component.scss"]
})
export class NeoInputButtonComponent extends NeoInputBase<NeoInputButtonItem> implements OnInit, OnChanges {
  
    public neoToggleData = [];
  
    @Input()
    public isToggleButton = false;
    @Input()
    public neoToggleNr = 1;
    @Input()
    public neoToggleLabels: string[];
    @Input()
    public neoToggleValues: string[];
    @Input()
    public neoButtonCssClasses: string;
    @Input()
    public neoID: string;
    @Input()
    public neoName: string;
    @Input()
    public neoAriaLabel: string;
    @Input() 
    public customCssClass = '';

    constructor() {
        super(NeoInputButtonItem);
        this.calcToggleData();
    }

    ngOnInit() {
    }

    public neoIsValid(): boolean {
        return true;
    }

    public neoValueChangedToggle(event: string) {
        this.neoValue.toggleValue = event;
        this.neoValueEmitter.emit(this.neoValue);
        this.neoValueIsValid.emit(this.neoIsValid());
    }

    public neoButtonEmitter() {
        this.neoValueEmitter.emit();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                const change = changes[propName];
                if (propName === "neoToggleNr") {
                    if (change.previousValue !== change.currentValue) {
                        this.calcToggleData();
                    }
                    break;
                }
            }
        }
    }

    private calcToggleData() {
        let i = 0;
        this.neoToggleData = [];
        while (i < this.neoToggleNr) {
            this.neoToggleData.push(i);
            i++;
        }
    }
}
