@if(content){
  <div class="d-flex title py-30 mb-25">
    <app-title-section [content]="content?.reviewHeader" [useCenter]="false"></app-title-section>
    @if(!isMobile){

      <div class="buttons inline-flex gap-10">
        <div class="button-component" [style.--btnBgColor]="content?.buttonLeft.bgColor"
          [style.--color]="content?.buttonLeft.buttonTextColor">
            <button class="component-btn button-prev" aria-label="Previous slide">
              {{content?.buttonLeft.buttonText}}
              <img class="icon" src="{{  content?.buttonLeft.btnSvg.data.attributes.url }}"
                alt="{{ content?.buttonLeft.btnSvg.data.attributes?.alternativeText }}" />
            </button>
        </div>
        <div class="button-component" [style.--btnBgColor]="content?.buttonRight.bgColor"
          [style.--color]="content?.buttonRight.buttonTextColor">

          <button class="component-btn button-next" aria-label="Next slide">
            {{content?.buttonRight.buttonText}}
            <img class="icon" src="{{  content?.buttonRight.btnSvg.data.attributes.url }}"
              alt="{{ content?.buttonRight?.btnSvg?.data?.attributes?.alternativeText }}" />
          </button>
        </div>

      </div>
    }
  </div>

  <div class="py-30">
    <swiper-container swiperElement [config]="config" init="false" class="swiper">
      @for(review of content?.reviewCard; track review; let i = $index ){
      <swiper-slide class="swiper-slide">
        <div class="card-content">
          <div class="bg-image" style="--backgroundUrl: url({{ content?.bgImage.data.attributes?.url}})">
            <div class="description font-univia-pro-23 text-align-left" >
              {{ truncateDescription(review.description) }}
            </div>
            <div class="bottom">
              <div class="media-img" (click)="redirectToMediaLink(review.mediaRedirectLink)">
                <img [src]=" review.mediaSvg.data.attributes.url"
                  [alt]="review.mediaSvg.data.attributes.alternativeText">
              </div>
              <div class="name-star">
                <div class="font-univia-pro-20 text-align-left">
                  {{ review.fullName }}
                </div>
                <div class="star" [style.color]="review.starColor">
                  <span class="star-icon" [class.filled]="star <= review.starsNumber"
                    *ngFor="let star of getStarArray(review.starsNumber)">&#9733;</span>
                </div>
                <div class="underline"></div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      }
    </swiper-container>
  </div>

  @if(isMobile){
    <div class="buttons inline-flex gap-10">
      <div class="button-component" [style.--btnBgColor]="content?.buttonLeft.bgColor"
        [style.--color]="content?.buttonLeft.buttonTextColor">
          <button class="component-btn button-prev">
            {{content?.buttonLeft.buttonText}}
            <img class="icon" src="{{  content?.buttonLeft.btnSvg.data.attributes.url }}"
              alt="{{ content?.buttonLeft.btnSvg.data.attributes?.alternativeText }}" />
          </button>
      </div>
      <div class="button-component" [style.--btnBgColor]="content?.buttonRight.bgColor"
        [style.--color]="content?.buttonRight.buttonTextColor">

        <button class="component-btn button-next">
          {{content?.buttonRight.buttonText}}
          <img class="icon" src="{{  content?.buttonRight.btnSvg.data.attributes.url }}"
            alt="{{ content?.buttonRight?.btnSvg?.data?.attributes?.alternativeText }}" />
        </button>
      </div>

    </div>
  }
}
