import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
// import {
//   log,
//   NeoDialogComponent,
//   NeoRestProvider,
// } from "@neo/commons-ui";
// import { NeoContactFormData } from "@neo/commons-ui/lib/models/neo-contact-form.data";
import { environment } from "../../../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Apollo, ApolloModule } from "apollo-angular";
import FC_DIETIKON_QUERY from "../../../graphql/queries/fcDietikon.js";
// import { StaticDataProvider } from "@neo/vgr";
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { debounceTime, map, startWith } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { NeoRegion } from "../../../models/neo-region";
import { RegionenService } from "../../../services/regionen.service";
import { NeoDialogComponent } from "../../../components/neo-dialog/neo-dialog.component";
import { HttpClient } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { AngularMaterialModule } from "../../../modules/angularmaterial.module";
import { OldGraphQLModule } from "../../../graphql/oldcmsgraphql.module";

export class NeoFcDietikonFormData {
    salutation = "Herr";
    birthday: Date | undefined;
    lastName: string | undefined;
    firstName: string | undefined;
    street: string | undefined;
    houseNumber: string | undefined;
    zip: string | undefined;
    town: string | undefined;
    region: NeoRegion | undefined;
    email: string | undefined;
    phone: string | undefined;
    language = "de";
    interestedInHealth = true;
    interestedInMotor = false;
    interestedInHousehold = false;
    interestedInLife = false;
    interestedInOther = "";
    concern = "";
    news = "";
    contactKind = "neo_contactform_fcdietikon";
    time: string | undefined;
    data: any;
    genderImages: string | undefined;

    public mapContactToJob(data: any) {
            return {
                salutation: data.salutation,
                region: data.region,
                lastName: data.lastName,
                firstName: data.firstName,
                birthday: data.birthday,
                street: data.street,
                houseNumber: data.houseNumber,
                email: data.email,
                phone: data.phone,
                message: data.news
            };
    }
}

@Component({
  selector: 'app-contact-section',
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularMaterialModule, ReactiveFormsModule, FormsModule, ApolloModule, OldGraphQLModule
  ],
  templateUrl: "./fc-dietikon.component.html",
  styleUrl: "./fc-dietikon.component.scss"
})
export class FcDietikonComponent implements OnInit {
  private readonly MIN_REGION_COMPLETER_LENGTH = 3;
  private readonly containerTypeId = "neo_lead_fcdietikon";
  agbContent: any;
  selectedLanguage: string;
  cmsUrl = environment.OLD_CMS_URL;
  regionFC: FormControl = new FormControl("", [Validators.required, forbiddenObjectValidator()]);
  regionFCChild: FormControl = new FormControl("", [Validators.required, forbiddenObjectValidator()]);
  filteredOptions: any;
  filteredOptionsChild: any;
  fcDietikon: any;

  readAGB: boolean = false;

  contactForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    zip: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    birthday: new FormControl('', [Validators.required]),
    });

  childForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    zip: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    birthday: new FormControl('', [Validators.required]),
  });

  public regions: any[] | undefined;
  public subscriptions: Subscription[] = [];
  public successMessage: any =
  {
    dialogSuccessTitle: {
      de: "Das Kontaktformular wurde erfolgreich übermittelt!",
      en: "The contact form was submitted successfully!",
      fr: "Le formulaire de contact a été soumis avec succès!",
      it: "Il modulo di contatto è stato inviato con successo!"
    },
    dialogSuccessDescription: {
      de: "Ihre Anfrage wird schnellstmöglich von einem unserer Mitarbeiter bearbeitet.",
      en: "Your request will be processed as quickly as possible by one of our employees.",
      fr: "Votre demande sera traitée dans les plus brefs délais par l'un de nos collaborateurs.",
      it: "La tua richiesta sarà elaborata il più rapidamente possibile da un nostro dipendente."
    },
  };
  public errorMessage: any =
  {
    dialogErrorTitle: {
      de: "Kontaktformular konnte nicht zugestellt werden.",
      en: "Contact form could not be delivered.",
      fr: "Le formulaire de contact n'a pas pu être livré.",
      it: "Non è stato possibile consegnare il modulo di contatto."
    },
    dialogErrorDescription: {
      de: "Aus technischen Gründen haben wir Ihr Kontaktformular nicht erhalten. Sollte diese Fehlermeldung mehrmals erscheinen, so bitten wir Sie uns eine E-Mail an info@neosana.ch zu schreiben. Vielen Dank!",
      en: "For technical reasons we did not receive your contact form. If this error message appears several times, please send us an email to info@neosana.ch. Thanks very much!",
      fr: "Pour des raisons techniques, nous n'avons pas reçu votre formulaire de contact. Si ce message d'erreur apparaît plusieurs fois, veuillez nous envoyer un e-mail à info@neosana.ch. Merci beaucoup!",
      it: "Per motivi tecnici non abbiamo ricevuto il modulo di contatto. Se questo messaggio di errore viene visualizzato più volte, inviaci un'e-mail a info@neosana.ch. Grazie mille!"
    }
  };

  triedToSubmit: boolean = false;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    // private restProvider: NeoRestProvider<any>,
    private router: Router,
    private apollo: Apollo,
    // private staticData: StaticDataProvider,
    private sanitizer: DomSanitizer,
    private regionenService: RegionenService,
    private http: HttpClient
  ) {
    this.selectedLanguage = translate.currentLang;
  }

  ngOnInit(): void {
    this.getFcDietikon();
    this.filteredOptions = this.regionFC.valueChanges.pipe(
        debounceTime(200),
        startWith(''),
        map((value: string) => this._filter(value || '')),
      )
    this.filteredOptionsChild = this.regionFCChild.valueChanges.pipe(
        debounceTime(200),
        startWith(''),
        map((value: string) => this._filter(value || '')),
      )
    this.subscriptions.push(
      this.translate.onLangChange.subscribe((res: any) => {
        this.agbContent = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant("FC_DIETIKON.INPUTS.ACCEPT_AGB"))
        if (this.selectedLanguage !== res.lang) {
          this.selectedLanguage = res.lang;
          this.getFcDietikon();
        }
      })
    );
    this.agbContent =  this.sanitizer.bypassSecurityTrustHtml(this.translate.instant("FC_DIETIKON.INPUTS.ACCEPT_AGB"))

    if (this.regions === null || this.regions === undefined) {
      // this.obtainRegions();
      this.getRegionen();
    }
  }

  getFcDietikon() {
    this.subscriptions.push(
      this.apollo
        .watchQuery({
          query: FC_DIETIKON_QUERY,
        })
        .valueChanges.subscribe((result: any) => {
          this.fcDietikon = result.data.fcDietikon;
        })
    );
  }

  selectedRegion(ev: any): any {
    this.contactForm.controls['zip'].setValue(ev.option.value.PLZ)
    this.contactForm.controls['city'].setValue(ev.option.value.Ort + " (" + ev.option.value.Gemeinde + ")")
  }

  selectedRegionChild(ev: any): any {
    this.childForm.controls['zip'].setValue(ev.option.value.PLZ)
    this.childForm.controls['city'].setValue(ev.option.value.Ort + " (" + ev.option.value.Gemeinde + ")")
  }

  displayRegionFn(region: any) {
    if (!region) return '';
    return `${region.PLZ} - ${region.Ort} (${region.Gemeinde})`;
  }

  private _filter(value: string) {
    const filterValue = value.toString();
    if (value !== "" && filterValue.length >= this.MIN_REGION_COMPLETER_LENGTH)
      return this.regions!.filter(option => option.PLZ.toString().includes(filterValue));
    else return [];
  }

  // private obtainRegions() {
  //   this.staticData.loadRegions();
  //   this.subscriptions.push(
  //     this.staticData.onRegionsLoad.subscribe((v: any) => {
  //       this.regions = v;
  //     })
  //   );
  // }

  getRegionen() {
    this.regionenService.getRegionen().subscribe((data: any) => {
      this.regions = data;
    });
  }

  public POST(actionUrl: string, body: any, map: ((dataRest: any ) => any) | null = null, buildError = true) {
    return new Promise((resolve, reject) => {
        this.http.post(actionUrl, body)
            .toPromise()
            .then(
                res => { // Success
                    resolve( map ? map(res) : res);
                },
                err => { // Error
                    if (buildError) {
                        // this.errorProvider.buildError(err);
                    }
                    reject(err);
                }
            );
    });
}

  submitContactInfo(){
    this.triedToSubmit = true;
    if(this.readAGB){
      let fullNameValue= this.childForm.value.fullName.split(' ')
      let firstName = fullNameValue[0];
      let lastName = fullNameValue.slice(1).join(' ');
      let childContactData = `Kind: Geburtsdatum: ${this.childForm.value.birthday}, Vorname: ${firstName}, Nachname: ${lastName}`
      let contactData = this.newNeoFcDietikonFormData(this.contactForm, this.regionFC);
      contactData.concern = childContactData;
      this.sendMessage(contactData);
    } else {
    }
  }

  newNeoFcDietikonFormData(form: any, region: any){
    let contactData = new NeoFcDietikonFormData();
    let nameSplit = form.value.fullName.split(' ');
    contactData.birthday = form.value.birthday;
    contactData.lastName = nameSplit[nameSplit.length - 1];
    contactData.firstName = nameSplit[0];
    contactData.street = form.value.address;
    contactData.zip = form.value.zip;
    contactData.town = form.value.city;
    contactData.region = region.value;
    contactData.phone = form.value.phoneNumber;
    return contactData;
  }

  sendMessage(value: any) {
    if (this.contactForm.valid && this.childForm.valid) {
        const actionUrl = `${environment.VGR_BASE_URL}/contacts?containerTypeId=${this.containerTypeId}`;
        this.POST(actionUrl, value, null, false)
            .then(
                (res: any) => { this.openSendMessageConfirmDialog(res)},
                (err: any) => this.openSendMessageErrorDialog(err));
    }
  }

  redirectToAgb() {
    window.open('https://neo.swiss/datenschutz', "_blank");
  }

  onLabelClick(event: MouseEvent) {
    console.log(event);
    if ((event.target as HTMLElement).id === 'agb-click-span') {
      event.stopPropagation();
      this.redirectToAgb();
    }
  }

  private openSendMessageConfirmDialog(res: any) {
    let dialog = this.dialog.open(NeoDialogComponent, {
        width: "350px",
        panelClass: "neo-vvg-dialog",
        data: {
            "title": this.successMessage.dialogSuccessTitle[this.selectedLanguage],
            "text": this.successMessage.dialogSuccessDescription[this.selectedLanguage],
        },
    });
    dialog.afterClosed().subscribe( result => {
        this.redirectTo('home');
      });
}

redirectTo(page: string) {
  if (page) {
    window.location.href = `/${page}`;
  }
}

private openSendMessageErrorDialog(err: any) {
    this.dialog.open(NeoDialogComponent, {
        width: "350px",
        panelClass: "neo-vvg-dialog",
        data: {
            "title": this.errorMessage.dialogErrorTitle[this.selectedLanguage],
            "text": this.errorMessage.dialogErrorDescription[this.selectedLanguage]
        }
    });
  }

  checkAGB(checked: any){
    this.readAGB = checked.checked;
  }
}


export function forbiddenObjectValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return typeof control.value !== 'object' || control.value === null ? { forbiddenObject: true } : null;
    }
  }
