import { JobDetails } from "./job-details";
import { NeoRegion } from "./neo-region";

export class NeoContactFormData {
    salutation = "Herr";
    birthday: Date;
    lastName: string;
    firstName: string;
    street: string;
    houseNumber: string;
    zip: string;
    town: string;
    region: NeoRegion;
    email: string;
    phone: string;
    language = "de";
    interestedInHealth = true;
    interestedInMotor = false;
    interestedInHousehold = false;
    interestedInLife = false;
    interestedInOther = "";
    concern = "";
    news = "";
    contactKind = "neo_contactform";
    time: string;
    data: any;
    genderImages: string;

    public mapContactToJob(data: NeoContactFormData, jobDetails: JobDetails, files: any): IJobData {
            return {
                salutation: data.salutation,
                region: data.region,
                lastName: data.lastName,
                firstName: data.firstName,
                birthday: data.birthday,
                street: data.street,
                houseNumber: data.houseNumber,
                email: data.email,
                phone: data.phone,
                message: data.news,
                attachments: files,
                jobId: jobDetails.jobId,
                jobTitle: jobDetails.jobTitle,
                jobDescription: jobDetails.jobDescription,
                jobLocation: jobDetails.jobLocation
            };
    }
}



export interface IJobData {
    salutation: string;
    region: NeoRegion;
    lastName: string;
    firstName: string;
    birthday: Date;
    street: string;
    houseNumber: string;
    email: string
    phone: string;
    message: string;
    attachments: string[];
    jobId: string;
    jobTitle: string;
    jobDescription: string;
    jobLocation: string;
}