  //  skala44_old = [
  //    {
  //      'durchschnitt_einkommen' : 14100,
  //      'ahv_iv": 1175,
  //      "ahv_iv_witwen": 1410,
  //      "witwen_witwer": 940,
  //      "zusatzrente": 353,
  //      "waisenrente": 470,
  //      "waisenrente60": 705
  //    },
  //    {
  //      "durchschnitt_einkommen": 15510,
  //      "ahv_iv": 1206,
  //      "ahv_iv_witwen": 1447,
  //      "witwen_witwer": 964,
  //      "zusatzrente": 362,
  //      "waisenrente": 482,
  //      "waisenrente60": 723
  //    },
  //    {
  //      "durchschnitt_einkommen": 16920,
  //      "ahv_iv": 1236,
  //      "ahv_iv_witwen": 1483,
  //      "witwen_witwer": 989,
  //      "zusatzrente": 371,
  //      "waisenrente": 494,
  //      "waisenrente60": 742
  //    },
  //    {
  //      "durchschnitt_einkommen": 18330,
  //      "ahv_iv": 1267,
  //      "ahv_iv_witwen": 1520,
  //      "witwen_witwer": 1013,
  //      "zusatzrente": 380,
  //      "waisenrente": 507,
  //      "waisenrente60": 760
  //    },
  //    {
  //      "durchschnitt_einkommen": 19740,
  //      "ahv_iv": 1297,
  //      "ahv_iv_witwen": 1557,
  //      "witwen_witwer": 1038,
  //      "zusatzrente": 389,
  //      "waisenrente": 519,
  //      "waisenrente60": 778
  //    },
  //    {
  //      "durchschnitt_einkommen": 21150,
  //      "ahv_iv": 1328,
  //      "ahv_iv_witwen": 1593,
  //      "witwen_witwer": 1062,
  //      "zusatzrente": 398,
  //      "waisenrente": 531,
  //      "waisenrente60": 797
  //    },
  //    {
  //      "durchschnitt_einkommen": 22560,
  //      "ahv_iv": 1358,
  //      "ahv_iv_witwen": 1630,
  //      "witwen_witwer": 1087,
  //      "zusatzrente": 407,
  //      "waisenrente": 543,
  //      "waisenrente60": 815
  //    },
  //    {
  //      "durchschnitt_einkommen": 23970,
  //      "ahv_iv": 1389,
  //      "ahv_iv_witwen": 1667,
  //      "witwen_witwer": 1111,
  //      "zusatzrente": 417,
  //      "waisenrente": 556,
  //      "waisenrente60": 833
  //    },
  //    {
  //      "durchschnitt_einkommen": 25380,
  //      "ahv_iv": 1419,
  //      "ahv_iv_witwen": 1703,
  //      "witwen_witwer": 1136,
  //      "zusatzrente": 426,
  //      "waisenrente": 568,
  //      "waisenrente60": 852
  //    },
  //    {
  //      "durchschnitt_einkommen": 26790,
  //      "ahv_iv": 1450,
  //      "ahv_iv_witwen": 1740,
  //      "witwen_witwer": 1160,
  //      "zusatzrente": 435,
  //      "waisenrente": 580,
  //      "waisenrente60": 870
  //    },
  //    {
  //      "durchschnitt_einkommen": 28200,
  //      "ahv_iv": 1481,
  //      "ahv_iv_witwen": 1777,
  //      "witwen_witwer": 1184,
  //      "zusatzrente": 444,
  //      "waisenrente": 592,
  //      "waisenrente60": 888
  //    },
  //    {
  //      "durchschnitt_einkommen": 29610,
  //      "ahv_iv": 1511,
  //      "ahv_iv_witwen": 1813,
  //      "witwen_witwer": 1209,
  //      "zusatzrente": 453,
  //      "waisenrente": 604,
  //      "waisenrente60": 907
  //    },
  //    {
  //      "durchschnitt_einkommen": 31020,
  //      "ahv_iv": 1542,
  //      "ahv_iv_witwen": 1850,
  //      "witwen_witwer": 1233,
  //      "zusatzrente": 462,
  //      "waisenrente": 617,
  //      "waisenrente60": 925
  //    },
  //    {
  //      "durchschnitt_einkommen": 32430,
  //      "ahv_iv": 1572,
  //      "ahv_iv_witwen": 1887,
  //      "witwen_witwer": 1258,
  //      "zusatzrente": 472,
  //      "waisenrente": 629,
  //      "waisenrente60": 943
  //    },
  //    {
  //      "durchschnitt_einkommen": 33840,
  //      "ahv_iv": 1603,
  //      "ahv_iv_witwen": 1923,
  //      "witwen_witwer": 1282,
  //      "zusatzrente": 481,
  //      "waisenrente": 641,
  //      "waisenrente60": 962
  //    },
  //    {
  //      "durchschnitt_einkommen": 35250,
  //      "ahv_iv": 1633,
  //      "ahv_iv_witwen": 1960,
  //      "witwen_witwer": 1307,
  //      "zusatzrente": 490,
  //      "waisenrente": 653,
  //      "waisenrente60": 980
  //    },
  //    {
  //      "durchschnitt_einkommen": 36660,
  //      "ahv_iv": 1664,
  //      "ahv_iv_witwen": 1997,
  //      "witwen_witwer": 1331,
  //      "zusatzrente": 499,
  //      "waisenrente": 666,
  //      "waisenrente60": 998
  //    },
  //    {
  //      "durchschnitt_einkommen": 38070,
  //      "ahv_iv": 1694,
  //      "ahv_iv_witwen": 2033,
  //      "witwen_witwer": 1355,
  //      "zusatzrente": 508,
  //      "waisenrente": 678,
  //      "waisenrente60": 1017
  //    },
  //    {
  //      "durchschnitt_einkommen": 39480,
  //      "ahv_iv": 1725,
  //      "ahv_iv_witwen": 2070,
  //      "witwen_witwer": 1380,
  //      "zusatzrente": 517,
  //      "waisenrente": 690,
  //      "waisenrente60": 1035
  //    },
  //    {
  //      "durchschnitt_einkommen": 40890,
  //      "ahv_iv": 1755,
  //      "ahv_iv_witwen": 2106,
  //      "witwen_witwer": 1404,
  //      "zusatzrente": 527,
  //      "waisenrente": 702,
  //      "waisenrente60": 1053
  //    },
  //    {
  //      "durchschnitt_einkommen": 42300,
  //      "ahv_iv": 1786,
  //      "ahv_iv_witwen": 2143,
  //      "witwen_witwer": 1429,
  //      "zusatzrente": 536,
  //      "waisenrente": 714,
  //      "waisenrente60": 1072
  //    },
  //    {
  //      "durchschnitt_einkommen": 43710,
  //      "ahv_iv": 1805,
  //      "ahv_iv_witwen": 2166,
  //      "witwen_witwer": 1444,
  //      "zusatzrente": 541,
  //      "waisenrente": 722,
  //      "waisenrente60": 1083
  //    },
  //    {
  //      "durchschnitt_einkommen": 45120,
  //      "ahv_iv": 1824,
  //      "ahv_iv_witwen": 2188,
  //      "witwen_witwer": 1459,
  //      "zusatzrente": 547,
  //      "waisenrente": 729,
  //      "waisenrente60": 1094
  //    },
  //    {
  //      "durchschnitt_einkommen": 46530,
  //      "ahv_iv": 1842,
  //      "ahv_iv_witwen": 2211,
  //      "witwen_witwer": 1474,
  //      "zusatzrente": 553,
  //      "waisenrente": 737,
  //      "waisenrente60": 1105
  //    },
  //    {
  //      "durchschnitt_einkommen": 47940,
  //      "ahv_iv": 1861,
  //      "ahv_iv_witwen": 2233,
  //      "witwen_witwer": 1489,
  //      "zusatzrente": 558,
  //      "waisenrente": 744,
  //      "waisenrente60": 1117
  //    },
  //    {
  //      "durchschnitt_einkommen": 49350,
  //      "ahv_iv": 1880,
  //      "ahv_iv_witwen": 2256,
  //      "witwen_witwer": 1504,
  //      "zusatzrente": 564,
  //      "waisenrente": 752,
  //      "waisenrente60": 1128
  //    },
  //    {
  //      "durchschnitt_einkommen": 50760,
  //      "ahv_iv": 1899,
  //      "ahv_iv_witwen": 2279,
  //      "witwen_witwer": 1519,
  //      "zusatzrente": 570,
  //      "waisenrente": 760,
  //      "waisenrente60": 1139
  //    },
  //    {
  //      "durchschnitt_einkommen": 52170,
  //      "ahv_iv": 1918,
  //      "ahv_iv_witwen": 2301,
  //      "witwen_witwer": 1534,
  //      "zusatzrente": 575,
  //      "waisenrente": 767,
  //      "waisenrente60": 1151
  //    },
  //    {
  //      "durchschnitt_einkommen": 53580,
  //      "ahv_iv": 1936,
  //      "ahv_iv_witwen": 2324,
  //      "witwen_witwer": 1549,
  //      "zusatzrente": 581,
  //      "waisenrente": 775,
  //      "waisenrente60": 1162
  //    },
  //    {
  //      "durchschnitt_einkommen": 54990,
  //      "ahv_iv": 1955,
  //      "ahv_iv_witwen": 2346,
  //      "witwen_witwer": 1564,
  //      "zusatzrente": 587,
  //      "waisenrente": 782,
  //      "waisenrente60": 1173
  //    },
  //    {
  //      "durchschnitt_einkommen": 56400,
  //      "ahv_iv": 1974,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1579,
  //      "zusatzrente": 592,
  //      "waisenrente": 790,
  //      "waisenrente60": 1184
  //    },
  //    {
  //      "durchschnitt_einkommen": 57810,
  //      "ahv_iv": 1993,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1594,
  //      "zusatzrente": 598,
  //      "waisenrente": 797,
  //      "waisenrente60": 1196
  //    },
  //    {
  //      "durchschnitt_einkommen": 59220,
  //      "ahv_iv": 2012,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1609,
  //      "zusatzrente": 603,
  //      "waisenrente": 805,
  //      "waisenrente60": 1207
  //    },
  //    {
  //      "durchschnitt_einkommen": 60630,
  //      "ahv_iv": 2030,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1624,
  //      "zusatzrente": 609,
  //      "waisenrente": 812,
  //      "waisenrente60": 1218
  //    },
  //    {
  //      "durchschnitt_einkommen": 62040,
  //      "ahv_iv": 2049,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1639,
  //      "zusatzrente": 615,
  //      "waisenrente": 820,
  //      "waisenrente60": 1230
  //    },
  //    {
  //      "durchschnitt_einkommen": 63450,
  //      "ahv_iv": 2068,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1654,
  //      "zusatzrente": 620,
  //      "waisenrente": 827,
  //      "waisenrente60": 1241
  //    },
  //    {
  //      "durchschnitt_einkommen": 64860,
  //      "ahv_iv": 2087,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1669,
  //      "zusatzrente": 626,
  //      "waisenrente": 835,
  //      "waisenrente60": 1252
  //    },
  //    {
  //      "durchschnitt_einkommen": 66270,
  //      "ahv_iv": 2106,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1684,
  //      "zusatzrente": 632,
  //      "waisenrente": 842,
  //      "waisenrente60": 1263
  //    },
  //    {
  //      "durchschnitt_einkommen": 67680,
  //      "ahv_iv": 2124,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1700,
  //      "zusatzrente": 637,
  //      "waisenrente": 850,
  //      "waisenrente60": 1275
  //    },
  //    {
  //      "durchschnitt_einkommen": 69090,
  //      "ahv_iv": 2143,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1715,
  //      "zusatzrente": 643,
  //      "waisenrente": 857,
  //      "waisenrente60": 1286
  //    },
  //    {
  //      "durchschnitt_einkommen": 70500,
  //      "ahv_iv": 2162,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1730,
  //      "zusatzrente": 649,
  //      "waisenrente": 865,
  //      "waisenrente60": 1297
  //    },
  //    {
  //      "durchschnitt_einkommen": 71910,
  //      "ahv_iv": 2181,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1745,
  //      "zusatzrente": 654,
  //      "waisenrente": 872,
  //      "waisenrente60": 1308
  //    },
  //    {
  //      "durchschnitt_einkommen": 73320,
  //      "ahv_iv": 2200,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1760,
  //      "zusatzrente": 660,
  //      "waisenrente": 880,
  //      "waisenrente60": 1320
  //    },
  //    {
  //      "durchschnitt_einkommen": 74730,
  //      "ahv_iv": 2218,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1775,
  //      "zusatzrente": 666,
  //      "waisenrente": 887,
  //      "waisenrente60": 1331
  //    },
  //    {
  //      "durchschnitt_einkommen": 76140,
  //      "ahv_iv": 2237,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1790,
  //      "zusatzrente": 671,
  //      "waisenrente": 895,
  //      "waisenrente60": 1342
  //    },
  //    {
  //      "durchschnitt_einkommen": 77550,
  //      "ahv_iv": 2256,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1805,
  //      "zusatzrente": 677,
  //      "waisenrente": 902,
  //      "waisenrente60": 1354
  //    },
  //    {
  //      "durchschnitt_einkommen": 78960,
  //      "ahv_iv": 2275,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1820,
  //      "zusatzrente": 682,
  //      "waisenrente": 910,
  //      "waisenrente60": 1365
  //    },
  //    {
  //      "durchschnitt_einkommen": 80370,
  //      "ahv_iv": 2294,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1835,
  //      "zusatzrente": 688,
  //      "waisenrente": 917,
  //      "waisenrente60": 1376
  //    },
  //    {
  //      "durchschnitt_einkommen": 81780,
  //      "ahv_iv": 2312,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1850,
  //      "zusatzrente": 694,
  //      "waisenrente": 925,
  //      "waisenrente60": 1387
  //    },
  //    {
  //      "durchschnitt_einkommen": 83190,
  //      "ahv_iv": 2331,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1865,
  //      "zusatzrente": 699,
  //      "waisenrente": 932,
  //      "waisenrente60": 1399
  //    },
  //    {
  //      "durchschnitt_einkommen": 84600,
  //      "ahv_iv": 2350,
  //      "ahv_iv_witwen": 2350,
  //      "witwen_witwer": 1880,
  //      "zusatzrente": 705,
  //      "waisenrente": 940,
  //      "waisenrente60": 1410
  //    }
  //  ];

export  const skala44 = [
  {
    'durchschnitt_einkommen' : 14220,
    'ahv_iv' : 1185,
    'ahv_iv_witwen' : 1422,
    'witwen_witwer' : 948,
    'zusatzrente' : 356,
    'waisenrente' : 474,
    'waisenrente60' : 711
  },
  {
    'durchschnitt_einkommen' : 15642,
    'ahv_iv' : 1216,
    'ahv_iv_witwen' : 1459,
    'witwen_witwer' : 973,
    'zusatzrente' : 365,
    'waisenrente' : 486,
    'waisenrente60' : 729
  },
  {
    'durchschnitt_einkommen' : 17064,
    'ahv_iv' : 1247,
    'ahv_iv_witwen' : 1496,
    'witwen_witwer' : 997,
    'zusatzrente' : 374,
    'waisenrente' : 499,
    'waisenrente60' : 748
  },
  {
    'durchschnitt_einkommen' : 18486,
    'ahv_iv' : 1277,
    'ahv_iv_witwen' : 1533,
    'witwen_witwer' : 1022,
    'zusatzrente' : 383,
    'waisenrente' : 511,
    'waisenrente60' : 766
  },
  {
    'durchschnitt_einkommen' : 19908,
    'ahv_iv' : 1308,
    'ahv_iv_witwen' : 1570,
    'witwen_witwer' : 1047,
    'zusatzrente' : 392,
    'waisenrente' : 523,
    'waisenrente60' : 785
  },
  {
    'durchschnitt_einkommen' : 21330,
    'ahv_iv' : 1339,
    'ahv_iv_witwen' : 1607,
    'witwen_witwer' : 1071,
    'zusatzrente' : 402,
    'waisenrente' : 536,
    'waisenrente60' : 803
  },
  {
    'durchschnitt_einkommen' : 22752,
    'ahv_iv' : 1370,
    'ahv_iv_witwen' : 1644,
    'witwen_witwer' : 1096,
    'zusatzrente' : 411,
    'waisenrente' : 548,
    'waisenrente60' : 822
  },
  {
    'durchschnitt_einkommen' : 24174,
    'ahv_iv' : 1401,
    'ahv_iv_witwen' : 1681,
    'witwen_witwer' : 1120,
    'zusatzrente' : 420,
    'waisenrente' : 560,
    'waisenrente60' : 840
  },
  {
    'durchschnitt_einkommen' : 25596,
    'ahv_iv' : 1431,
    'ahv_iv_witwen' : 1718,
    'witwen_witwer' : 1145,
    'zusatzrente' : 429,
    'waisenrente' : 573,
    'waisenrente60' : 859
  },
  {
    'durchschnitt_einkommen' : 27018,
    'ahv_iv' : 1462,
    'ahv_iv_witwen' : 1755,
    'witwen_witwer' : 1170,
    'zusatzrente' : 439,
    'waisenrente' : 585,
    'waisenrente60' : 877
  },
  {
    'durchschnitt_einkommen' : 28440,
    'ahv_iv' : 1493,
    'ahv_iv_witwen' : 1792,
    'witwen_witwer' : 1194,
    'zusatzrente' : 448,
    'waisenrente' : 597,
    'waisenrente60' : 896
  },
  {
    'durchschnitt_einkommen' : 29862,
    'ahv_iv' : 1524,
    'ahv_iv_witwen' : 1829,
    'witwen_witwer' : 1219,
    'zusatzrente' : 457,
    'waisenrente' : 610,
    'waisenrente60' : 914
  },
  {
    'durchschnitt_einkommen' : 31284,
    'ahv_iv' : 1555,
    'ahv_iv_witwen' : 1866,
    'witwen_witwer' : 1244,
    'zusatzrente' : 466,
    'waisenrente' : 622,
    'waisenrente60' : 933
  },
  {
    'durchschnitt_einkommen' : 32706,
    'ahv_iv' : 1586,
    'ahv_iv_witwen' : 1903,
    'witwen_witwer' : 1268,
    'zusatzrente' : 476,
    'waisenrente' : 634,
    'waisenrente60' : 951
  },
  {
    'durchschnitt_einkommen' : 34128,
    'ahv_iv' : 1616,
    'ahv_iv_witwen' : 1940,
    'witwen_witwer' : 1293,
    'zusatzrente' : 485,
    'waisenrente' : 647,
    'waisenrente60' : 970
  },
  {
    'durchschnitt_einkommen' : 35550,
    'ahv_iv' : 1647,
    'ahv_iv_witwen' : 1977,
    'witwen_witwer' : 1318,
    'zusatzrente' : 494,
    'waisenrente' : 659,
    'waisenrente60' : 988
  },
  {
    'durchschnitt_einkommen' : 36972,
    'ahv_iv' : 1678,
    'ahv_iv_witwen' : 2013,
    'witwen_witwer' : 1342,
    'zusatzrente' : 503,
    'waisenrente' : 671,
    'waisenrente60' : 1007
  },
  {
    'durchschnitt_einkommen' : 38394,
    'ahv_iv' : 1709,
    'ahv_iv_witwen' : 2050,
    'witwen_witwer' : 1367,
    'zusatzrente' : 513,
    'waisenrente' : 683,
    'waisenrente60' : 1025
  },
  {
    'durchschnitt_einkommen' : 39816,
    'ahv_iv' : 1740,
    'ahv_iv_witwen' : 2087,
    'witwen_witwer' : 1392,
    'zusatzrente' : 522,
    'waisenrente' : 696,
    'waisenrente60' : 1044
  },
  {
    'durchschnitt_einkommen' : 41238,
    'ahv_iv' : 1770,
    'ahv_iv_witwen' : 2124,
    'witwen_witwer' : 1416,
    'zusatzrente' : 531,
    'waisenrente' : 708,
    'waisenrente60' : 1062
  },
  {
    'durchschnitt_einkommen' : 42660,
    'ahv_iv' : 1801,
    'ahv_iv_witwen' : 2161,
    'witwen_witwer' : 1441,
    'zusatzrente' : 540,
    'waisenrente' : 720,
    'waisenrente60' : 1081
  },
  {
    'durchschnitt_einkommen' : 44082,
    'ahv_iv' : 1820,
    'ahv_iv_witwen' : 2184,
    'witwen_witwer' : 1456,
    'zusatzrente' : 546,
    'waisenrente' : 728,
    'waisenrente60' : 1092
  },
  {
    'durchschnitt_einkommen' : 45504,
    'ahv_iv' : 1839,
    'ahv_iv_witwen' : 2207,
    'witwen_witwer' : 1471,
    'zusatzrente' : 552,
    'waisenrente' : 736,
    'waisenrente60' : 1103
  },
  {
    'durchschnitt_einkommen' : 46926,
    'ahv_iv' : 1858,
    'ahv_iv_witwen' : 2230,
    'witwen_witwer' : 1486,
    'zusatzrente' : 557,
    'waisenrente' : 743,
    'waisenrente60' : 1115
  },
  {
    'durchschnitt_einkommen' : 48348,
    'ahv_iv' : 1877,
    'ahv_iv_witwen' : 2252,
    'witwen_witwer' : 1502,
    'zusatzrente' : 563,
    'waisenrente' : 751,
    'waisenrente60' : 1126
  },
  {
    'durchschnitt_einkommen' : 49770,
    'ahv_iv' : 1896,
    'ahv_iv_witwen' : 2275,
    'witwen_witwer' : 1517,
    'zusatzrente' : 569,
    'waisenrente' : 758,
    'waisenrente60' : 1138
  },
  {
    'durchschnitt_einkommen' : 51192,
    'ahv_iv' : 1915,
    'ahv_iv_witwen' : 2298,
    'witwen_witwer' : 1532,
    'zusatzrente' : 574,
    'waisenrente' : 766,
    'waisenrente60' : 1149
  },
  {
    'durchschnitt_einkommen' : 52614,
    'ahv_iv' : 1934,
    'ahv_iv_witwen' : 2321,
    'witwen_witwer' : 1547,
    'zusatzrente' : 580,
    'waisenrente' : 774,
    'waisenrente60' : 1160
  },
  {
    'durchschnitt_einkommen' : 54036,
    'ahv_iv' : 1953,
    'ahv_iv_witwen' : 2343,
    'witwen_witwer' : 1562,
    'zusatzrente' : 586,
    'waisenrente' : 781,
    'waisenrente60' : 1172
  },
  {
    'durchschnitt_einkommen' : 55458,
    'ahv_iv' : 1972,
    'ahv_iv_witwen' : 2366,
    'witwen_witwer' : 1577,
    'zusatzrente' : 592,
    'waisenrente' : 789,
    'waisenrente60' : 1183
  },
  {
    'durchschnitt_einkommen' : 56880,
    'ahv_iv' : 1991,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1593,
    'zusatzrente' : 597,
    'waisenrente' : 796,
    'waisenrente60' : 1194
  },
  {
    'durchschnitt_einkommen' : 58302,
    'ahv_iv' : 2010,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1608,
    'zusatzrente' : 603,
    'waisenrente' : 804,
    'waisenrente60' : 1206
  },
  {
    'durchschnitt_einkommen' : 59724,
    'ahv_iv' : 2029,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1623,
    'zusatzrente' : 609,
    'waisenrente' : 811,
    'waisenrente60' : 1217
  },
  {
    'durchschnitt_einkommen' : 61146,
    'ahv_iv' : 2048,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1638,
    'zusatzrente' : 614,
    'waisenrente' : 819,
    'waisenrente60' : 1229
  },
  {
    'durchschnitt_einkommen' : 62568,
    'ahv_iv' : 2067,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1653,
    'zusatzrente' : 620,
    'waisenrente' : 827,
    'waisenrente60' : 1240
  },
  {
    'durchschnitt_einkommen' : 63990,
    'ahv_iv' : 2086,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1668,
    'zusatzrente' : 626,
    'waisenrente' : 834,
    'waisenrente60' : 1251
  },
  {
    'durchschnitt_einkommen' : 65412,
    'ahv_iv' : 2105,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1684,
    'zusatzrente' : 631,
    'waisenrente' : 842,
    'waisenrente60' : 1263
  },
  {
    'durchschnitt_einkommen' : 66834,
    'ahv_iv' : 2124,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1699,
    'zusatzrente' : 637,
    'waisenrente' : 849,
    'waisenrente60' : 1274
  },
  {
    'durchschnitt_einkommen' : 68256,
    'ahv_iv' : 2142,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1714,
    'zusatzrente' : 643,
    'waisenrente' : 857,
    'waisenrente60' : 1285
  },
  {
    'durchschnitt_einkommen' : 69678,
    'ahv_iv' : 2161,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1729,
    'zusatzrente' : 648,
    'waisenrente' : 865,
    'waisenrente60' : 1297
  },
  {
    'durchschnitt_einkommen' : 71100,
    'ahv_iv' : 2180,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1744,
    'zusatzrente' : 654,
    'waisenrente' : 872,
    'waisenrente60' : 1308
  },
  {
    'durchschnitt_einkommen' : 72522,
    'ahv_iv' : 2199,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1759,
    'zusatzrente' : 660,
    'waisenrente' : 880,
    'waisenrente60' : 1320
  },
  {
    'durchschnitt_einkommen' : 73944,
    'ahv_iv' : 2218,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1775,
    'zusatzrente' : 665,
    'waisenrente' : 887,
    'waisenrente60' : 1331
  },
  {
    'durchschnitt_einkommen' : 75366,
    'ahv_iv' : 2237,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1790,
    'zusatzrente' : 671,
    'waisenrente' : 895,
    'waisenrente60' : 1342
  },
  {
    'durchschnitt_einkommen' : 76788,
    'ahv_iv' : 2256,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1805,
    'zusatzrente' : 677,
    'waisenrente' : 902,
    'waisenrente60' : 1354
  },
  {
    'durchschnitt_einkommen' : 78210,
    'ahv_iv' : 2275,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1820,
    'zusatzrente' : 683,
    'waisenrente' : 910,
    'waisenrente60' : 1365
  },
  {
    'durchschnitt_einkommen' : 79632,
    'ahv_iv' : 2294,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1835,
    'zusatzrente' : 688,
    'waisenrente' : 918,
    'waisenrente60' : 1376
  },
  {
    'durchschnitt_einkommen' : 81054,
    'ahv_iv' : 2313,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1850,
    'zusatzrente' : 694,
    'waisenrente' : 925,
    'waisenrente60' : 1388
  },
  {
    'durchschnitt_einkommen' : 82476,
    'ahv_iv' : 2332,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1866,
    'zusatzrente' : 700,
    'waisenrente' : 933,
    'waisenrente60' : 1399
  },
  {
    'durchschnitt_einkommen' : 83898,
    'ahv_iv' : 2351,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1881,
    'zusatzrente' : 705,
    'waisenrente' : 940,
    'waisenrente60' : 1411
  },
  {
    'durchschnitt_einkommen' : 85320,
    'ahv_iv' : 2370,
    'ahv_iv_witwen' : 2370,
    'witwen_witwer' : 1896,
    'zusatzrente' : 711,
    'waisenrente' : 948,
    'waisenrente60' : 1422
  }
];
