import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
  standalone: true
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number | null | undefined): string {
    if (value === null || value === undefined) {
      return '';
    }
    if (value >= 10000) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }
    return value.toString();
  }
}
