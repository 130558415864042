<div *ngIf="neoLabel && neoLabel.length > 0"
     id="radio_label"
     class="neo-vgr-input-margin ">
    {{neoLabel}} <span *ngIf="showInfoButton" class="mdi mdi-information-outline" (click)="showInfoDialog($event)"></span>
</div>
<mat-radio-group class="neo-vgr-input-margin" aria-labelledby="radio_label"
                 [(ngModel)]="neoValue"
                 (change)="neoValueChanged($event)">
    <mat-radio-button *ngFor="let t of neoRadioLabels; let i = index;"
                      value="{{neoRadioValues[i]}}"
                      class="neo-vgr-input-margin">
        {{t}}
    </mat-radio-button>
</mat-radio-group>
