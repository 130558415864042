<div class="overflow-x-hidden">
    @if(introduction && introduction.showSection){
        <app-introduction [content]="introduction" [useCenter]="false"></app-introduction>
    }
    
    @if(kvgSection && kvgSection.showSection){
        <app-kvg-card [content]="kvgSection"></app-kvg-card>
    }
    
    @if (insuranceAdditional && insuranceAdditional.showSection) {
        <app-insurance [content]="insuranceAdditional" [reverse]="false"></app-insurance>
    }
    
    @if(benefitSection && benefitSection.benefitSectionHeader.showSection){
        <app-benefit-section [content]="benefitSection" [back]="backArrow" [forward]="forwardArrow" [useCenter]="false"></app-benefit-section>
    }
    
    @if (insuranceCompanies && insuranceCompanies.showSection) {
        <app-insurance [content]="insuranceCompanies" [reverse]="true"></app-insurance>
    }
    
    @if (franchise && franchise.showSection) {
        <app-franchise [content]="franchise"></app-franchise>
    }
    
    @if (insuranceExpert && insuranceExpert.showSection) {
        <app-insurance-expert [content]="insuranceExpert"></app-insurance-expert>
    }
    
    @if(faq && faq.showSection){
        <app-faq [content]="faq"></app-faq>
    }
    
    <app-contact-section [id]="insuranceAdditional?.button.redirectFragment" [taglineColor]="kvgSection?.headerSection?.underLineColor" [useCenter]="false"></app-contact-section>
</div>