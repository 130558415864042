import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, debounceTime, map, startWith } from 'rxjs';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RegionenService } from '../../services/regionen.service';
import { MAT_DATE_FORMATS, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { Region } from '../../models/regions';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { NeoContactMail, NeoRegionMail } from '../../models/contact-mail.model';
import { ContactService } from '../../services/contact.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import { environment } from '../../../environments/environment';
import { CmsService } from '../../services/cms.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-contact-section',
  standalone: true,
  imports: [
    FormsModule, 
    ReactiveFormsModule, 
    MatAutocompleteModule, 
    AsyncPipe, 
    MatOptionModule, 
    CommonModule, 
    MatSelectModule, 
    MatDatepickerModule, 
    MatNativeDateModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MomentDateModule,
    NgxSpinnerModule
  ],
  templateUrl: './contact-section.component.html',
  styleUrl: './contact-section.component.scss',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ContactSectionComponent implements OnInit {
  private readonly MIN_REGION_COMPLETER_LENGTH = 3;
  @Output() viewInitialized: EventEmitter<void> = new EventEmitter<void>();

  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;
  @Input('taglineColor') taglineColor?: string;

  contactForm: FormGroup;
  regions: Region[] = [];
  regionSelected: Region = {
    PLZ: 0,
    Ort: '',
    Kanton: '',
    Bfs: 0,
    Region: 0,
    Gemeinde: ''
};
  filteredRegions: Observable<Region[]> = new Observable<Region[]>();
  regionFC: FormControl = new FormControl("", [Validators.required, forbiddenObjectValidator()]);

  sanitizedMapAddress: any;
  showOptions: any;
  showSelect: boolean = false;

  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  currentPage: string | undefined = '';
  constructor(
    private sanitizer: DomSanitizer, 
    private http: HttpClient, 
    private formBuilder: FormBuilder, 
    private regionenService: RegionenService, 
    private contactService: ContactService, 
    private spinner: NgxSpinnerService,
    private cmsService: CmsService, 
    private translate: TranslateService,
    private route: ActivatedRoute) {
      
    this.contactForm = this.formBuilder.group({
      salutation: [''],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      birthdate: ['', Validators.required],
      address: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      language: ['', Validators.required],
      message: ['', Validators.required]
    });
    this.currentPage = this.route.snapshot.routeConfig?.path;
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
        this.selectedLanguage = res.lang;
      this.getCmsContent();

        // }
      })

      // this.getCmsContent();
  
    this.getRegionen();

    this.filteredRegions = this.regionFC.valueChanges.pipe(
      debounceTime(200),
      startWith(''),
      map((value: string) => this._filter(value || '')),
    )

    this.sanitizedMapAddress = this.sanitizer.bypassSecurityTrustResourceUrl(this.content?.Address?.MapAddress);
  }

  getCmsContent(){

    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;
    this.cmsService.singlePageContent('contact-form', populateString).subscribe((res: any) => {
      this.content = res.contactSection;
    });
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  toggleSelectVisibility() {
    this.showSelect = !this.showSelect;
  }


  private _filter(value: string): Region[] {
    const filterValue = value.toString();
    if (value !== "" && filterValue.length >= this.MIN_REGION_COMPLETER_LENGTH)
      return this.regions.filter(region => region.PLZ.toString().includes(filterValue));
    else return [];
  }

  selectedRegion(ev: any): void {
    this.regionSelected = ev.option.value;
    this.contactForm.controls['zip'].setValue(ev.option.value);
    this.contactForm.controls['city'].setValue(ev.option.value.Ort + " (" + ev.option.value.Gemeinde + ")");
  }

  displayRegionFn(region: Region) {
    if (!region) return '';
    return `${region.PLZ} `;
  }

  sendMail() {
    if (this.contactForm.valid) {
      const formData = this.contactForm.value;
      const regionData = this.regionSelected;

      const mappedRegion: NeoRegionMail = {
        Region: regionData.Region,
        PLZ: regionData.PLZ,
        Kanton: regionData.Kanton,
        Ort: regionData.Ort,
        Gemeinde: regionData.Gemeinde,
        key: ''
      };

      const mailData: NeoContactMail = {
        salutation: formData.salutation?.Gender,
        lastName: formData.lastName,
        firstName: formData.firstName,
        birthday: formData.birthdate,
        street: formData.address,
        houseNumber: formData.address,
        region: mappedRegion,
        email: formData.email,
        phone: formData.phoneNumber,
        language: formData.language?.Language,
        interestedInHealth: true,
        interestedInMotor: false,
        interestedInHousehold: false,
        interestedInLife: false,
        interestedInOther: '',
        concern: formData.message,
        news: '',
        contactKind: 'neo_contactform'
      };

      this.sendContactForm(mailData);
    } else {
    }
  }


  getRegionen() {
    this.regionenService.getRegionen().subscribe(data => {
      this.regions = data;
    });
  }

  sendContactForm(mailData: NeoContactMail) {
    this.spinner.show();
    this.contactService.postContactData(mailData).subscribe({
      next: (response) => {
        this.contactForm.reset();
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        console.error("Error:", error);
      }
    });
  }

  ngAfterViewInit(): void {
    this.viewInitialized.emit();
  }
}

export function forbiddenObjectValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return typeof control.value !== 'object' || control.value === null ? { forbiddenObject: true } : null;
  }
}

