import { Component } from '@angular/core';
import { CmsService } from '../../services/cms.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { IntroductionComponent } from "../../components/introduction/introduction.component";
import { LocationComponent } from "../../components/location/location.component";
import { HistorySectionComponent } from "../../components/history-section/history-section.component";
import { ContactSectionComponent } from "../../components/contact-section/contact-section.component";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-agencies',
  standalone: true,
  templateUrl: './agencies.component.html',
  styleUrl: './agencies.component.scss',
  imports: [IntroductionComponent, LocationComponent, HistorySectionComponent, ContactSectionComponent]
})
export class AgenciesComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  content: any;
  childViewInitialized: boolean = false;
  allMenuItems: any;
  currentPage: string | undefined;

  constructor(
    private cmsService: CmsService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private seo: SeoService) {
    this.currentPage = this.route.snapshot.routeConfig?.path
  }
  onChildViewInitialized(): void {
    this.childViewInitialized = true;
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      const element = document.getElementById(fragment);
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
      this.selectedLanguage = res.lang;
      this.getCmsContent();
      // }
    })
    this.getMenuItems();
    // this.getCmsContent();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const fragment = this.route.snapshot.fragment;
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          element.scrollIntoView();
        }
      }
    });
  }
  fragment: string[] = [];
  getMenuItems() {
    this.cmsService.allMenuItems.subscribe(menuItems => {
      if (menuItems.length > 0) {

        this.allMenuItems = menuItems;
        const aboutMenuItems = this.allMenuItems.filter((item: any) => item.redirectLink === this.currentPage);
        aboutMenuItems.forEach((aboutMenuItem: any) => {
          const subMenuItem = aboutMenuItem.subMenuItem;
          subMenuItem.forEach((item: any) => {
            const fragment = item.fragment;

            this.fragment.push(fragment);
          });
        });
      }
    });
  }

  getCmsContent() {
    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;
    this.cmsService.singlePageContent('agencie', populateString).subscribe((res: any) => {
      this.content = res;

      const seoData = res?.pluginSeo;
      this.seo.initializeSeo(seoData);
    })
  }

}
