import {Component, Input, OnInit} from "@angular/core";
import {NeoInputBase} from "./neo-input.abstract.component";
import {NeoDialogComponent} from "./neo-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: "neo-input-radio-group",
    templateUrl: "./neo-input-radio-group.component.html",
    styleUrls: ["./neo-input-radio-group.component.scss"]
})
export class NeoInputRadioGroupComponent extends NeoInputBase<String> implements OnInit {
    @Input()
    public neoRadioLabels: string[];
    @Input()
    public neoRadioValues: string[];
    @Input()
    public showInfoButton = false;
    @Input()
    public neoInfoButtonTitle: string;
    @Input()
    public neoInfoButtonText: string;

    constructor(public dialog: MatDialog) {
        super(String);
    }

    ngOnInit() {
    }

    public neoIsValid(): boolean {
        return true;
    }

    public neoValueChanged(event: Event) {
        this.neoValueEmitter.emit(this.neoValue);
        this.neoValueIsValid.emit(this.neoIsValid());
    }
    public showInfoDialog(event: Event) {
        event.stopPropagation();
        this.dialog.open(NeoDialogComponent, {
            maxWidth: "100%",
            minWidth: "20%",
            panelClass: "neo-vvg-dialog",
            data: {
                "title": this.neoInfoButtonText,
                "text": this.neoInfoButtonTitle
            }
        });
    }
}
