<div class="row px-1 my-5">
    <div class="title-lg p-4 pt-md-0 pt-xl-5">
        <app-title-section [content]="content?.insuranceHeader[0]"></app-title-section>
    </div>
    <div class="col-12 col-md-5 px-4 px-md-0 py-md-4 {{reverse ? 'order-md-2':''}}">
        <img src="{{image}}" class="img-size" />
    </div>
    <div
        class="col-12 col-md-6 mt-xl-5 p-4 pt-xl-4 pos-relative {{reverse ? 'order-md-1 col-md-7 px-md-5':'mx-md-4 mx-xxl-5'}}">
        <app-title-section class="title-sm" [content]="content?.insuranceHeader[0]"></app-title-section>
        @if(fullText){
        <p class="pt-md-4 pl-md-4 font-17 col-grey">{{description}}</p>
        }
        @if(!fullText) {
        <p class="pt-md-4 pl-md-4 font-17 col-grey">{{description | slice:0:250}}...</p>
        }
        <div class="pl-md-4 read-more pointer" [style.--color]="underLineColor" (click)="showMore()">
            {{readMore}}
            <div class="mtb-5 underline"></div>
        </div>
        <app-button class="pl-md-4 mb-25 btn-position" [content]="button" [hideButton]="false"></app-button>
    </div>
</div>