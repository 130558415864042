import {Inject, Injectable, InjectionToken} from "@angular/core";
import {NeoEnvironment} from "../models/neo-environment";

export let envVariables = new InjectionToken<NeoEnvironment>("env.variables");

@Injectable()
export class NeoEnvironmentProvider {

    constructor(@Inject(envVariables) public env: NeoEnvironment) {
    }

    public get name(): string {
        return this.env.name;
    }

    public get version(): string {
        return this.env.version;
    }

    public get logLevel(): string {
        return this.env.logLevel;
    }

    public get production(): boolean {
        return this.env.production;
    }

    public get tariffYear(): number {
        return this.env.tariffYear;
    }

    public get urlVGR(): string {
        return this.env.urlVGR;
    }

    public get urlAPI(): string {
        return this.env.urlAPI;
    }
}
