import { Injectable } from "@angular/core";
@Injectable()
export class VorsorgerechnerClass {
  public VorsorgerechnerTITLE = 'VORSORGERECHNER.TITLE';
  public VorsorgerechnerSUBTITLE = 'VORSORGERECHNER.SUBTITLE';
  public VorsorgerechnerDESCRIPTION = 'VORSORGERECHNER.DESCRIPTION';
    public VorsorgerechnerPFLICHTFELD = 'VORSORGERECHNER.PFLICHTFELD';
    public VorsorgerechnerNACHSTER = 'VORSORGERECHNER.NACHSTER';
    public VorsorgerechnerAUSFUHREN = 'VORSORGERECHNER.AUSFUHREN';
    public VorsorgerechnerZURUCK = 'VORSORGERECHNER.ZURUCK';
    public VorsorgerechnerPERSON = 'VORSORGERECHNER.PERSON';
    public VorsorgerechnerPARTNER = 'VORSORGERECHNER.PARTNER';
    public VorsorgerechnerJAHRE = 'VORSORGERECHNER.JAHRE';
    public VorsorgerechnerJAHRESEINKOMMEN = 'VORSORGERECHNER.JAHRESEINKOMMEN';
    public VorsorgerechnerJAHRESEINKOMMENPROBLEM = 'VORSORGERECHNER.JAHRESEINKOMMENPROBLEM';
    public VorsorgerechnerFAMILIENSTAND = 'VORSORGERECHNER.FAMILIENSTAND';
    public VorsorgerechnerBVGINVALIDENRENTE = 'VORSORGERECHNER.BVGINVALIDENRENTE';
    public VorsorgerechnerBVGALTERSRENTEMITZINS = 'VORSORGERECHNER.BVGALTERSRENTEMITZINS';
    public VorsorgerechnerGEBORENIN = 'VORSORGERECHNER.GEBORENIN';
    public VorsorgerechnerLOHNFORTZAHLUNGKRANKENTAGEGELD = 'VORSORGERECHNER.LOHNFORTZAHLUNG-KRANKENTAGEGELD';
    public VorsorgerechnerJAHREDERBEITRAGE = 'VORSORGERECHNER.JAHREDERBEITRAGE';
    public VorsorgerechnerKINDER = 'VORSORGERECHNER.KINDER';
    public VorsorgerechnerGESCHLECHT = 'VORSORGERECHNER.GESCHLECHT';
    public VorsorgerechnerMANNLICH = 'VORSORGERECHNER.MANNLICH';
    public VorsorgerechnerWEIBLICH = 'VORSORGERECHNER.WEIBLICH';
    public VorsorgerechnerTODDURCHUNFALL = 'VORSORGERECHNER.TODDURCHUNFALL';
    public VorsorgerechnerTODDURCHKRANKHEIT = 'VORSORGERECHNER.TODDURCHKRANKHEIT';
    public VorsorgerechnerINVALIDDURCHUNFALL = 'VORSORGERECHNER.INVALIDDURCHUNFALL';
    public VorsorgerechnerINVALIDDURCH = 'VORSORGERECHNER.INVALIDDURCH';
    public VorsorgerechnerALTER = 'VORSORGERECHNER.ALTER';
    public VorsorgerechnerSELECTYEAR = 'VORSORGERECHNER.SELECTYEAR';
    public VorsorgerechnerSINGLE = 'VORSORGERECHNER.SINGLE';
    public VorsorgerechnerVERHEIRATET = 'VORSORGERECHNER.VERHEIRATET';
    public VorsorgerechnerGESCHIEDEN = 'VORSORGERECHNER.GESCHIEDEN';
    public VorsorgerechnerWITWER = 'VORSORGERECHNER.WITWER';
    public VorsorgerechnerSCHWEIZ = 'VORSORGERECHNER.SCHWEIZ';
    public VorsorgerechnerANDERE = 'VORSORGERECHNER.ANDERE';
    public VorsorgerechnerINVALIDDURCHKRANKHEIT = 'VORSORGERECHNER.INVALIDDURCHKRANKHEIT';
    public SELECTEDDATA = 'VORSORGERECHNER.SELECTEDDATA';
    public VorsorgerechnerSTEP1 = 'VORSORGERECHNER.STEP1';
    public VorsorgerechnerSTEP2 = 'VORSORGERECHNER.STEP2';
    public VorsorgerechnerSTEP3 = 'VORSORGERECHNER.STEP3';
    public VorsorgerechnerTITLESCHRITT = 'VORSORGERECHNER.TITLESCHRITT';
    public VorsorgerechnerINFOTITLETODDURCHUNFALL = 'VORSORGERECHNER.INFOTITLETODDURCHUNFALL';
    public VorsorgerechnerINFOTODDURCHUNFALL = 'VORSORGERECHNER.INFOTODDURCHUNFALL';
    public VorsorgerechnerINFOTITLETODDURCHKRANKHEIT = 'VORSORGERECHNER.INFOTITLETODDURCHKRANKHEIT';
    public VorsorgerechnerINFOTODDURCHKRANKHEIT = 'VORSORGERECHNER.INFOTODDURCHKRANKHEIT';   
    public VorsorgerechnerINFOTITLEINVALIDITATDURCHUNFALL = 'VORSORGERECHNER.INFOTITLEINVALIDITATDURCHUNFALL';
    public VorsorgerechnerINFOINVALIDITATDURCHUNFALL = 'VORSORGERECHNER.INFOINVALIDITATDURCHUNFALL';
    public VorsorgerechnerINFOTITLEINVALIDITATDURCHKRANKHEIT = 'VORSORGERECHNER.INFOTITLEINVALIDITATDURCHKRANKHEIT';
    public VorsorgerechnerINFOINVALIDITATDURCHKRANKHEIT = 'VORSORGERECHNER.INFOINVALIDITATDURCHKRANKHEIT';
    public VorsorgerechnerINFOTITLESCHRITTALTER = 'VORSORGERECHNER.INFOTITLESCHRITTALTER';
    public VorsorgerechnerINFOTITLEALLEAUSWAHLEN = 'VORSORGERECHNER.INFOTITLEALLEAUSWAHLEN';
    public VorsorgerechnerINFOALLEAUSWAHLEN = 'VORSORGERECHNER.INFOALLEAUSWAHLEN';
    public VorsorgerechnerWAHLEN = 'VORSORGERECHNER.WAHLEN';
    public VorsorgerechnerSCHRITTALTER = 'VORSORGERECHNER.INFOSCHRITTALTER';
    public VorsorgerechnerSTEP4 = 'VORSORGERECHNER.STEP4';
    public VorsorgerechnerSTEP5 = 'VORSORGERECHNER.STEP5';

    public VorsorgerechnerKINDHINZUFUGEN = 'VORSORGERECHNER.KINDHINZUFUGEN';
    public VorsorgerechnerKIND = 'VORSORGERECHNER.KIND';
    public VorsorgerechnerINFO = 'VORSORGERECHNER.INFO';
    public VorsorgerechnerINFOTITLE = 'VORSORGERECHNER.INFOTITLE';
    public SendMessage = 'KONTAKTFORMULAR.BUTTON.ABSENDEN';
    public neoVgrContactTitle = 'KONTAKTFORMULAR.TITEL.TITEL';
    public VorsorgerechnerSELECTALL = 'VORSORGERECHNER.SELECTALL';
    public VorsorgerechnerUNSELECTALL = 'VORSORGERECHNER.UNSELECTALL';

    public VorsorgerechnerDOUGHNUTCHART = 'VORSORGERECHNER.DOUGHNUTCHART';

    public VorsorgerechnerMEHRINFORMATIONEN = 'VORSORGERECHNER.MEHRINFORMATIONEN';
    public VorsorgerechnerROTATE = 'VORSORGERECHNER.ROTATE';
    public VorsorgerechnerCURRENTINCOME = 'VORSORGERECHNER.CURRENTINCOME';
    public VorsorgerechnerINCOMEPARTNER = 'VORSORGERECHNER.INCOMEPARTNER';
    public VorsorgerechnerAHV = 'VORSORGERECHNER.AHV';
    public VorsorgerechnerBVG = 'VORSORGERECHNER.BVG';
    public VorsorgerechnerUVG = 'VORSORGERECHNER.UVG';
    public neoVsrContactModalErrTitel = "KONTAKTFORMULAR.MODAL_ERR.TITEL";
    public neoVsrContactModalErrText = "KONTAKTFORMULAR.MODAL_ERR.BESCHREIBUNG";

  }
