 <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

@if(introduction && introduction.showSection){
    <app-introduction 
    [content]="introduction" 
    [useCenter]="false"
    ></app-introduction>
}

@if(contactSection) {

<neo-vorsorgerechner-lib 
[translations]="VorsorgerechnerTranslationTexts" 
[firstSegment]="firstSegment"
[VGR_API]="VGR_BASE_URL"
[production]="production"
></neo-vorsorgerechner-lib>
}
<div class="contact-section">
@if(contactSection) {
    <app-contact-section [content]="contactSection" [useCenter]="false" id="contact"></app-contact-section>
}
</div>