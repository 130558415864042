<div *ngIf="neoLabel && neoLabel.length > 0" class="neo-vgr-input-margin neo-input-text-height">
    {{neoLabel}}
    <span *ngIf="neoRequired === true && neoShowRequired === true" class="neo-contact-form-required">
        *
    </span>
</div>
<input *ngIf="!useTextarea" type="text"
       ngClass="{{ showErrorCss ? 'neo-message-error-input':'' }}"
       class="neo-vgr-input-field neo-vgr-input-margin neo-vgr-input-field-text neo-helper-user-select-auto" [ngStyle]="customCss"
       placeholder="{{neoPlaceholder}}"
       [(ngModel)]="neoValue"
       (ngModelChange)="neoValueChange($event)"
       matTooltip="{{neoErrorTooltipText}}"
       matTooltipClass="neo-message-error-tooltip"
       [matTooltipDisabled]=[!showErrorCss]
       [matTooltipPosition]="'right'"
       [disabled]="disabled"
/>
<textarea *ngIf="useTextarea" type="text"
          ngClass="{{ showErrorCss ? 'neo-message-error-input':'' }}"
          class="neo-vgr-input-field neo-vgr-input-margin neo-vgr-input-field-text"
          [rows]="rows"
          placeholder="{{neoPlaceholder}}"
          [(ngModel)]="neoValue"
          (ngModelChange)="neoValueChange($event)"
          matTooltip="{{neoErrorTooltipText}}"
          matTooltipClass="neo-message-error-tooltip"
          [matTooltipDisabled]=[!showErrorCss]
          [matTooltipPosition]="'right'"
          [disabled]="disabled"
></textarea>
