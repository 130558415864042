import { NeoRegion } from "@neo/commons-ui/lib/models/neo-region";

export class ContactForm {
    salutation: string;
    lastName: string;
    firstName: string;
    region: NeoRegion;
    email: string;
    phone: string;
    contactKind: string;
    language: string;
    url?: string;
    time: string;
    data: any;
}
