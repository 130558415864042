@if(content && content.showSection ){
    <div class="title" [class.use-center]="useCenter">
        <div class="label font-univia-pro-14" [style.--color]="content?.underLineColor">
            {{content?.label}}
            <div class="mtb-5 underline"></div>
        </div>
        <div>
            <div class="title font-univia-pro-59">{{content?.title}}</div>
        </div>
        @if(content.text){
        <div class="text font-calibre-18">{{content?.text}}</div>
        }
    </div>
}