import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {NeoErrorProvider} from "./neo-error-provider";

@Injectable()
export class NeoRestProvider<T> {

    constructor(public http: HttpClient, public errorProvider: NeoErrorProvider) {
    }

    private _result: T = null;

    get result(): T {
        return this._result;
    }

    public GET(actionUrl: string, map: (dataRest: any) => T = null, buildError = true, showLoading = false): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.http.get<T>(actionUrl)
                .toPromise()
                .then(
                    res => { // Success
                        this._result = map ? map(res) : res;
                        resolve(this._result);
                    },
                    err => { // Error
                        if (buildError) {
                            this.errorProvider.buildError(err);
                        }
                        reject(err);
                    }
                );
        });
    }

    public POST(actionUrl: string, body: any, map: (dataRest: any) => T = null, buildError = true): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.http.post<T>(actionUrl, body)
                .toPromise()
                .then(
                    res => { // Success
                        this._result = map ? map(res) : res;
                        resolve(this._result);
                    },
                    err => { // Error
                        if (buildError) {
                            this.errorProvider.buildError(err);
                        }
                        reject(err);
                    }
                );
        });
    }
}
