import { Component } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { NeoHypothekenrechnerLibModule } from 'hypothekenrechner-lib';
import { GraphQLModule } from '../../graphql/graphql.module';
import { CmsService } from '../../services/cms.service';
import { TranslateService } from '@ngx-translate/core';
import { IntroductionComponent } from '../introduction/introduction.component';
import { ContactSectionComponent } from '../contact-section/contact-section.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-hypothekenrechner',
  standalone: true,
  imports: [ CommonModule, IntroductionComponent, NeoHypothekenrechnerLibModule, ApolloModule, GraphQLModule, ContactSectionComponent],
  templateUrl: './hypothekenrechner.component.html',
  styleUrl: './hypothekenrechner.component.scss'
})
export class HypothekenrechnerComponent {
  calculatorData: any;
  introduction: any;
  calculatorsInfo: any;
  historySection: any;
  contactSection: any;
  selectedLanguage!: string;
  bmiCalculator: any;

  constructor(private cmsService: CmsService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;

    this.translate.onLangChange.subscribe((res: any) => {
      this.selectedLanguage = res.lang;
      this.getCalculatorData();
    });
  }
  getCalculatorData() {
    const page = 'hypothekenrechner';
    const populate = 'deep';
    const locale = this.selectedLanguage;;
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;

    this.cmsService.singlePageContent(page, populateAndLocale).subscribe((data: any) => {
      this.calculatorData = data;
      this.introduction = data.Introduction;
      // this.calculatorsInfo = data.CalculatorsInfoSection;
      // this.historySection = data.NeoHistory;
      this.contactSection = data.ContactSection;
    });
  }
}
