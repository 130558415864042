import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperDirective } from '../../../lib/swiper.directive';
import { ButtonComponent } from '../button/button.component';
import { TitleSectionComponent } from '../title-section/title-section.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-review-section',
  standalone: true,
  templateUrl: './review-section.component.html',
  styleUrl: './review-section.component.scss',
  imports: [TitleSectionComponent, ButtonComponent, FormsModule, CommonModule, SwiperDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReviewSectionComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  isMobile: boolean = false;
  public config: SwiperOptions = {}

  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;
  @ViewChild('swiper', { static: true }) swiperContainer: any;
  constructor(private translate: TranslateService, private router: Router) {

  }
  ngOnInit(): void {

    this.config = {
      navigation: {
        prevEl: '.button-prev',
        nextEl: '.button-next'
      },
      slidesPerView: 'auto',
      spaceBetween: 50,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
          grid: {
            rows: 1,
            fill: 'row'
          },
        },
        650: {
          slidesPerView: 2,
          spaceBetween: 20,
          grid: {
            rows: 1,
            fill: 'row'
          },
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 30,
          grid: {
            rows: 1,
            fill: 'row'
          },
        }
      },
      injectStyles: [
        `
        `
      ],
    };
    this.isMobile = window.innerWidth <= 649;
  }
  redirectTo(link: string) {
    if (link) {
      this.router.navigate([link]);
    }
  }

  getStarArray(starsNumber: string): number[] {
    const numberOfStars = parseInt(starsNumber, 10);
    return Array.from({ length: numberOfStars }, (_, index) => index + 1);
  }

  redirectToMediaLink(redirectLink: string): void {
    if (redirectLink) {
      window.open(redirectLink, '_blank');
    }
  }

  truncateDescription(description: string): string {
    if (description.length > 150) {
      return description.slice(0, 150) + '...';
    }
    return description;
  }

  ngAfterViewChecked(): void {
    this.isMobile = window.innerWidth <= 649;
  }

}
