import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  @Input('content') content: any;
  @Input('hideButton') hideButton!: boolean;

  constructor(private translate: TranslateService, private router: Router) {
  }
  
  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
        this.selectedLanguage = res.lang;
      // }
    })

  }

  redirectTo(link: string, fragment:string) {
    // this.router.navigate([link]);
    let url = `${link}#${fragment}`;
    if(fragment){
      window.location.href=url;

    }
    else if(link){

      window.location.href=link;
    }
    else{
      return;
    }

  }
}
