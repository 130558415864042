<button *ngIf="!isToggleButton" type="button"
        class="{{neoButtonCssClasses}}" [ngClass]="customCssClass"
        (click)="neoButtonEmitter()">
    {{neoLabel}}
</button>
<mat-button-toggle-group *ngIf="isToggleButton"
                         id="{{neoID}}"
                         name="{{neoName}}"
                         aria-label="{{neoAriaLabel}}"
                         class="neo-btn-input-group"
                         [(ngModel)]="neoValue.toggleValue"
                         (ngModelChange)="neoValueChangedToggle($event)">
    <mat-button-toggle *ngFor="let nr of neoToggleData; let i = index;"
                       value="{{neoToggleValues[i]}}"
                       class="{{neoButtonCssClasses}}">{{neoToggleLabels[i]}}
    </mat-button-toggle>
</mat-button-toggle-group>