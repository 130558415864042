import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CmsService {
  header = {
    'authorization': `bearer ${environment.API_TOKEN}`
  }

  HomeData = new BehaviorSubject<any[]>([]);
    data$ = this.HomeData.asObservable();

  constructor(private http: HttpClient, private meta: Meta, private titleService: Title) { }

  singlePageContent(page: string, populate?: string) {
    let population = populate || '';
    return this.http.get(`${environment.CMS_URL}/api/${page}${population}`).pipe(
      map(this.mapResponseToAttributes)
    )
  }

  collectionTypesContent(page: string, populate?: string) {
    let population = populate || ''
    return this.http.get(`${environment.CMS_URL}/api/${page}${population}`).pipe(
      map(this.mapResponseCollectionTypesToAttributes)
    )
  }

  collectionService(page: string, populate?: string) {
    let population = populate || ''
    return this.http.get(`${environment.CMS_URL}/api/${page}${population}`)
  }

  mapResponseToAttributes(res: any) {
    return res.data.attributes
  }

  mapResponseCollectionTypesToAttributes(res: any) {
    return res.data.map((x: any) => {
      x.attributes.id = x.id;
      return x.attributes;
    });
  }

  writePopulateFunction(...cmsComponents: string[]) {
    let string = '?'
    cmsComponents.forEach(element => {
      string += `populate[${element}][populate]=*&`
    });
    string += 'populate=*';
    return string;
  }


  //is used to fetch menu data from header to use on footer
  private allMenuItemsSubject = new BehaviorSubject<any[]>([]);
  allMenuItems = this.allMenuItemsSubject.asObservable();


  setAllMenuItems(menuItems: any[]) {
    this.allMenuItemsSubject.next(menuItems);
  }


  setHomeData(data:any){
    data = Array.isArray(data)
    ? data.reduce((acc: any[], val: any[]) => acc.concat(val), [])
    : data;
    this.HomeData.next(data);
  }

  getHomeData(){
    return this.HomeData.getValue();
  }

}
