import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-card-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card-modal.component.html',
  styleUrl: './card-modal.component.scss'
})
export class CardModalComponent implements OnInit {
  @Input() public cardContentDetails: any;
  cmsUrl = environment.CMS_URL;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
      //console.log('modal', this.cardContentDetails);
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
