import {VvgLeistungJson} from "../jsons/vvg-leistung.json";
import {VvgLeistungDataJson} from "../jsons/vvg-leistung-data.json";
import {VvgLeistungDetailJson} from "../jsons/vvg-leistung-detail.json";

export class VvgLeistung {
    id: number;
    key: string;
    data: { [lang: string]: VvgLeistungDataJson; } = {};
    feedback: number;
    selected = false;
    open = false;
    show = true;
    details: Array<VvgLeistungDetailJson>;

    constructor() {
    }

    public static fromJson(value: VvgLeistungJson): VvgLeistung {
        const result = new VvgLeistung();
        result.id = value.id;
        result.key = value.key;
        result.details = value.details;
        result.show = value.show;
        value.data.forEach(v => {
            result.data[v.key] = v.value;
        });
        return result;
    }
}
