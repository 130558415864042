@if(introduction && introduction.showSection){
<app-introduction [content]="introduction" [useCenter]="false"></app-introduction>
}

@if(visionSection && visionSection.showSection){
<app-vision-section [id]="fragment[0]" (viewInitialized)="onChildViewInitialized()" [content]="visionSection" [useCenter]="false"></app-vision-section>
}

@if(historySection && historySection.showSection){
<app-history-section [id]="fragment[1]" (viewInitialized)="onChildViewInitialized()" [content]="historySection" [useCenter]="false" [marginBottom]="true" [bgColor]="true"></app-history-section>
}

@if(teamSection && teamSection.showSection){
<app-team-section [id]="fragment[2]" [content]="teamSection" [useCenter]="false"></app-team-section>
}

<app-contact-section (viewInitialized)="onChildViewInitialized()" [useCenter]="false"></app-contact-section>

