<div class="calculators-main-container">
    <div class="calculators-section-title">{{content?.SectionTitle}}</div>
    <div class="row cards">
        <div class="col-12 " [ngClass]="screenWidth >= 1500 ? 'col-md-4' : 'col-md-5'">
            <div class="calculators-card-column green-card">
                <div class="calculators-card-content">
                    <div class="calculators-card-title">
                        {{content?.FirstCardTitle}}</div>
                    <div class="calculators-card-text">{{content?.FirstCardText}}</div>
                </div>
                <div (click)="navigateToHypothekenrechner()" class="icon-container">
                    <img class="vision-vector-first" src="../../../assets/icons/arrow-right-m.svg" alt="Right arrow">
                </div>
            </div>
        </div>
        <div class="col-12" [ngClass]="screenWidth >= 1500 ? 'col-md-4' : 'col-md-5'">
            <div class="calculators-card-column blue-card">
                <div class="calculators-card-content">
                    <div class="calculators-card-title">
                        {{content?.SecondCardTitle}}</div>
                    <div class="calculators-card-text">
                        {{content?.SecondCardText}}
                    </div>
                </div>
                <div class="icon-container">
                    <img class="vision-vector-first" src="../../../assets/icons/arrow-right-m.svg" alt="Right arrow">
                </div>
            </div>
        </div>
    </div>
</div>