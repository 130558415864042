import {Inject, Injectable} from "@angular/core";
import { Location } from '@angular/common';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent} from 'lz-string';
import { VsrData } from '../model/VsrData';
import { DOCUMENT } from '@angular/common';

// @dynamic
@Injectable()
export class UrlProvider {
  constructor(@Inject(DOCUMENT) private document: Document, private location: Location) {}
  private compressedData: string;

  updateUrl(data: VsrData, firstSegment: string = "") {
    this.compressedData = this.compressData(data);
    this.location.replaceState(`${firstSegment}/${this.compressedData}`);
  }
  compressData(data: VsrData): string {
    return compressToEncodedURIComponent(JSON.stringify(data));
  }
  decompressData(data: string): Promise<VsrData> {
    return new Promise((resolve, reject) => {
        var decompressedData = decompressFromEncodedURIComponent(data);
        if (decompressedData) {
          resolve( JSON.parse(decompressedData) as VsrData);
        } else {
          reject();
        }
    });
  }
  getFullUrl(): string {
    return this.document.location.href;
  }
  getCompressedData(): string {
    return this.compressedData;
  }
}
