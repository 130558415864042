import { Agency } from '../models/agency-find';

export class AgencyJson {
    city: string;
    postalCode: number;
    key: string;

    constructor(data?: Agency) {
        if (data) {
            this.city = data.city;
            this.postalCode = data.postalCode;
            this.key = data.key;
        }
    }
}
