import { Injectable } from "@angular/core";

@Injectable()
export class HypothekenrechnerClass{
  public HypothekenrechnerKAUFPREIS  = "HYPOTHEKENRECHNER.KAUFPREIS";
  public HypothekenrechnerEINKOMMEN  = "HYPOTHEKENRECHNER.EINKOMMEN";
  public HypothekenrechnerEIGENMITTEL  = "HYPOTHEKENRECHNER.EIGENMITTEL";
  public HypothekenrechnerZINSEN  = "HYPOTHEKENRECHNER.ZINSEN";
  public HypothekenrechnerAMORTISATION  = "HYPOTHEKENRECHNER.AMORTISATION";
  public HypothekenrechnerUNTERHALTNEBENKOSTEN  = "HYPOTHEKENRECHNER.UNTERHALTNEBENKOSTEN";
  public HypothekenrechnerKOSTENMONATLICH  = "HYPOTHEKENRECHNER.KOSTENMONATLICH";
  public HypothekenrechnerHYPOTHEK  = "HYPOTHEKENRECHNER.HYPOTHEK";
  public HypothekenrechnerBELEHNUNG  = "HYPOTHEKENRECHNER.BELEHNUNG";
  public HypothekenrechnerTRAGBARKEIT  = "HYPOTHEKENRECHNER.TRAGBARKEIT";
  public HypothekenrechnerINFO = "HYPOTHEKENRECHNER.INFO";
  public HypothekenrechnerINFOTITLE = "HYPOTHEKENRECHNER.INFOTITLE";
}
