<div class="mdc-layout-grid neo-vsr-right-contact-grid">
  <div class="mdc-layout-grid__inner">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
    >
      <h1>{{localTranslationClass.getTranslationTexts().SELECTEDDATA}}</h1>
      <button
        (click)="showPersonData = !showPersonData"
        [ngClass]="showPersonData ? 'neo_vsr_accordion_active' : 'neo_vsr_accordion'"
        class="neo_vsr_accordion"
        *ngIf="vsrData?.person"
      >
        Person
      </button>

      <!-- Person content -->
      <div *ngIf="showPersonData">
        <div class="mdc-layout-grid__inner neo-padding-20 neo-vsr-content-step">
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-8-phone"
          >
            {{
              localTranslationClass.getTranslationTexts().VorsorgerechnerJAHRE
            }}
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone"
          >
            {{ vsrData.person.age }}
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-8-phone"
          >
            {{
              localTranslationClass.getTranslationTexts()
                .VorsorgerechnerJAHRESEINKOMMEN
            }}
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone"
          >
            {{ vsrData.person.avgYearlyIncome | thousand }}
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-8-phone"
          >
            {{
              localTranslationClass.getTranslationTexts()
                .VorsorgerechnerFAMILIENSTAND
            }}
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone"
          >
            {{ vsrData.person.maritalStatus }}
          </div>
        </div>
      </div>
      <!--Partner info-->
      <button
        (click)="showPartnerData = !showPartnerData"
        [ngClass]="showPartnerData ? 'neo_vsr_accordion_active' : 'neo_vsr_accordion'"
        class="neo_vsr_accordion"
        *ngIf="
          vsrData?.partner && (vsrData.person.maritalStatus === 'Verheiratet' ||vsrData.person.maritalStatus === 'Married' || vsrData.person.maritalStatus === 'Sposato' || vsrData.person.maritalStatus === 'Marié' )
        "
      >
        Partner
      </button>
      <div *ngIf="showPartnerData">
        <div class="mdc-layout-grid__inner neo-padding-20 neo-vsr-content-step">
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-8-phone"
          >
            {{
              localTranslationClass.getTranslationTexts().VorsorgerechnerJAHRE
            }}
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone"
          >
            {{ vsrData.partner.age }}
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-8-phone"
          >
            {{
              localTranslationClass.getTranslationTexts()
                .VorsorgerechnerJAHRESEINKOMMEN
            }}
          </div>
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone"
          >
            {{ vsrData.person.avgYearlyIncome | thousand }}
          </div>
        </div>
      </div>

      <!--Child info-->

      <button
        (click)="showChildData = !showChildData"
        [ngClass]="showChildData ? 'neo_vsr_accordion_active' : 'neo_vsr_accordion'"
        class="neo_vsr_accordion"
        *ngIf="vsrData?.children.length"
      >
        {{ localTranslationClass.getTranslationTexts().VorsorgerechnerKINDER }}
      </button>
      <div *ngIf="showChildData">
        <div class="mdc-layout-grid__inner neo-padding-20 neo-vsr-content-step">
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone"
            *ngFor="let child of vsrData.children"
          >
            <div class="child neo-padding-10">
              <div class="neo-padding-bottom-20">
                <b
                  >{{
                    localTranslationClass.getTranslationTexts()
                      .VorsorgerechnerKIND
                  }}
                  {{ child.id }}</b
                >
              </div>
              <div>
                {{
                  localTranslationClass.getTranslationTexts()
                    .VorsorgerechnerJAHRE
                }}: {{ child.years }}
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
