import { Component, Input } from "@angular/core";
import { NeoInputBase } from "./neo-input.abstract.component";

@Component({
    selector:"neo-input-gender-select",
    templateUrl: "./neo-gender-select.component.html",
    styleUrls: ["./neo-gender-select.component.scss"]
})
export class NeoGenderSelectComponent extends NeoInputBase<String>  {
    @Input()
    width = '55px';
    @Input()
    height = '60px';
    @Input()
    public neoRadioLabels: string[];
    @Input()
    public neoRadioValues: string[];
    @Input()
    public images: [{alt: string, img: string}];

    constructor() { 
        super(String);
    }


    public neoIsValid(): boolean{
        return true;
    }

    public neoValueChanged(event: Event){
        this.neoValueEmitter.emit(this.neoValue);
        this.neoValueIsValid.emit(this.neoIsValid());
    }
    
}
