<div class="neo-hypothekenrechner-main-left-side">
  <div class="neo-hypothekenrechner-left-side-height">
    <div class="neo-hypothekenrechner-content-img">
      <h1 class="neo-hypothekenrechner-intro" *ngIf="this.leftText.introTitle">
        {{ this.leftText.introTitle[this.selectedLanguage] }}
      </h1>
      <p class="neo-hypothekenrechner-display-flex">
        <span class="neo-hypothekenrechner-span-content" *ngIf="this.leftText.introContent">
          <markdown class="neo-markdown" [data]="this.leftText.introContent[this.selectedLanguage]"></markdown>
        </span>
      </p>
    </div>
  </div>
</div>

<div class="mdc-layout-grid neo-right-side">
  <div class="mdc-layout-grid__inner">
    <div class="
        mdc-layout-grid__cell
        mdc-layout-grid__cell--span-6-desktop
        mdc-layout-grid__cell--span-12-tablet
        mdc-layout-grid__cell--span-12-phone
        neo-sliders-text
      ">
      <div class="mdc-layout-grid__cell
    mdc-layout-grid__cell--span-6-desktop
    mdc-layout-grid__cell--span-6-tablet
    mdc-layout-grid__cell--span-12-phone  neo-hypotek-sliders">
        <!-- Calculate button -->
        <div class="
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-2-desktop
          mdc-layout-grid__cell--span-2-tablet
          mdc-layout-grid__cell--span-2-phone
        ">
          <div class="neo-btn-calc neo-float-right" (click)="Calculate()" *ngIf="!status">
          </div>
          <div class="neo-btn-reset neo-float-right" mat-button (click)="onReset()" *ngIf="status">

          </div>
        </div>
        <!-- Kaufpreis CARD -->
        <div class="
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-12-desktop
          mdc-layout-grid__cell--span-12-tablet
          mdc-layout-grid__cell--span-12-phone
          neo-htk-card
        ">
          <div class="mdc-layout-grid neo-padding-top-5">
            <div [ngClass]="{
              neo_added_selected_class: Kaufpreis != 0,
              neo_border_img: Kaufpreis === 0
            }" class="mdc-layout-grid__inner">
              <div class="
                neo-padding-top-20
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-12-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
              ">
                <i class="
                  material-icons
                  neo-recommendation-info neo-recommendation-info-position neo-hypotek-info-icon
                " (click)="
                  openDialog(
                    firstSlider.dialogInfoDescription[this.selectedLanguage],
                    firstSlider.dialogInfoTitle[this.selectedLanguage]
                  )
                ">info_outline</i>

                <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-6-tablet
                  mdc-layout-grid__cell--span-12-phone
                " *ngIf="this.firstSlider.sliderTitle">
                  <label class="neo-font-regular neo-padding-15">{{
                    this.firstSlider.sliderTitle[this.selectedLanguage]
                    }}</label>
                </div>

                <div class="
                  neo-left-right
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                ">
                  <input class="
                    neo-input
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-7-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                  " type="text" min="0" [(ngModel)]="KaufpreisTmp" (input)="
                    CheckIfNegative();
                    CheckRequired();
                    KaufpreisThousand();
                    CalculateOnChange()
                  " (change)="
                    CheckIfNegative();
                    CheckIfNegative();
                    CheckRequired();
                    KaufpreisThousand();
                    CalculateOnChange()
                  " (keyup)="CheckIfNegative()" required type="text" />

                  <div class="
                    neo-plus-minus
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-2-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                  ">
                    <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-6-desktop
                      mdc-layout-grid__cell--span-4-tablet
                      mdc-layout-grid__cell--span-2-phone
                      neo-align-right
                    ">
                      <button (click)="Decrease('Kaufpreis', 500)" class="
                        neo-button neo-js-button
                        neo-button--fab neo-button--mini-fab
                      ">
                        <div class="neo-minus"></div>
                      </button>
                    </div>
                    <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-6-desktop
                      mdc-layout-grid__cell--span-4-tablet
                      mdc-layout-grid__cell--span-2-phone
                    ">
                      <button (click)="Increase('Kaufpreis', 500)" class="
                        neo-button neo-js-button
                        neo-button--fab neo-button--mini-fab
                      ">
                        <div class="neo-plus"></div>
                      </button>
                    </div>
                  </div>

                  <label *ngIf="ClickableKaufpreis" class="neo-color-red">The field cannot be empty</label>
                </div>
                <div class="neo-hypotek-mat-slider">
                  <mat-slider class="
                    neo-service-slider-track neo-mat-slider
                    mat-slider mat-accent" [min]="KaufpreisMin" [max]="KaufpreisMax" [(ngModel)]="Kaufpreis"
                    ngDefaultControl>
                    <input matSliderThumb [value]="Kaufpreis" (input)="onKaufpreisInputChange(Kaufpreis)"
                      (change)="onKaufpreisChange()" />
                  </mat-slider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Einkomen CARD -->
        <div class="
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-12-desktop
          mdc-layout-grid__cell--span-12-tablet
          mdc-layout-grid__cell--span-12-phone
          neo-htk-card
        ">
          <div class="mdc-layout-grid neo-padding-top-5">
            <div [ngClass]="{
              neo_added_selected_class: einkommen != 0,
              neo_border_img: einkommen === 0
            }" class="mdc-layout-grid__inner">
              <div class="
                neo-padding-top-20
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-12-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
              ">
                <i class="
                  material-icons
                  neo-recommendation-info neo-recommendation-info-position neo-hypotek-info-icon
                " (click)="
                  openDialog(
                    secondSlider.dialogInfoDescription[this.selectedLanguage],
                    secondSlider.dialogInfoTitle[this.selectedLanguage]
                  )
                ">info_outline</i>

                <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-6-tablet
                  mdc-layout-grid__cell--span-12-phone
                " *ngIf="this.secondSlider.sliderTitle">
                  <label class="neo-font-regular neo-padding-15 neo-hypotek-long-text">{{
                    this.secondSlider.sliderTitle[this.selectedLanguage]
                    }}</label>
                </div>

                <div class="
                  neo-left-right
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                ">
                  <input class="
                    neo-input
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-7-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                  " min="0" [(ngModel)]="einkommenTmp" (input)="
                    CheckIfNegative();
                    CheckRequired();
                    einkommenThousand();
                    CalculateOnChange()
                  " (change)="
                    CheckIfNegative();
                    CheckRequired();
                    einkommenThousand();
                    CalculateOnChange()
                  " (keyup)="CheckIfNegative()" required type="text" />

                  <div class="
                    neo-plus-minus
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-2-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                  ">
                    <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-6-desktop
                      mdc-layout-grid__cell--span-4-tablet
                      mdc-layout-grid__cell--span-2-phone
                      neo-align-right
                    ">
                      <button (click)="Decrease('Einkommen', 500)" class="
                        neo-button neo-js-button
                        neo-button--fab neo-button--mini-fab
                      ">
                        <div class="neo-minus"></div>
                      </button>
                    </div>
                    <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-6-desktop
                      mdc-layout-grid__cell--span-4-tablet
                      mdc-layout-grid__cell--span-2-phone
                    ">
                      <button (click)="Increase('Einkommen', 500)" class="
                        neo-button neo-js-button
                        neo-button--fab neo-button--mini-fab
                      ">
                        <div class="neo-plus"></div>
                      </button>
                    </div>
                  </div>

                  <label class="neo-color-red" *ngIf="Clickableeinkommen">The field cannot be empty</label>
                </div>
                <div class="neo-hypotek-mat-slider">
                  <mat-slider type="range" [(ngModel)]="einkommen" [min]="EinkommenMin" [max]="EinkommenMax"
                    ngDefaultControl class="neo-service-slider-track neo-mat-slider
                  mat-slider mat-accent" id="myRange">
                    <input matSliderThumb [value]="einkommen" (input)="onEinkommenInputChange(einkommen)"
                      (change)="onEinkommenChange()" />
                  </mat-slider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Eigenmittel CARD -->
        <div class="
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-12-desktop
          mdc-layout-grid__cell--span-12-tablet
          mdc-layout-grid__cell--span-12-phone
          neo-htk-card
        ">
          <div class="mdc-layout-grid neo-padding-top-5">
            <div [ngClass]="{
              neo_added_selected_class: eigenmittel != 0,
              neo_border_img: eigenmittel === 0
            }" class="mdc-layout-grid__inner">
              <div class="
                neo-padding-top-20
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-12-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
              ">
                <i class="
                  material-icons
                  neo-recommendation-info neo-recommendation-info-position neo-hypotek-info-icon
                " (click)="
                  openDialog(
                    thirdSlider.dialogInfoDescription[this.selectedLanguage],
                    thirdSlider.dialogInfoTitle[this.selectedLanguage]
                  )
                ">info_outline</i>

                <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-6-tablet
                  mdc-layout-grid__cell--span-12-phone
                " *ngIf="this.thirdSlider.sliderTitle">
                  <label class="neo-font-regular neo-padding-15">{{
                    this.thirdSlider.sliderTitle[this.selectedLanguage]
                    }}</label>
                </div>

                <div class="
                  neo-left-right
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                ">
                  <input class="
                    neo-input
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-7-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                  " type="text" min="0" [(ngModel)]="EigenmittelTmp" (input)="
                    CheckIfNegative();
                    CheckRequired();
                    EigenmittelThousand();
                    CalculateOnChange()
                  " (change)="
                    CheckIfNegative();
                    CheckIfNegative();
                    CheckRequired();
                    EigenmittelThousand();
                    CalculateOnChange()
                  " (keyup)="CheckIfNegative()" required />

                  <div class="
                    neo-plus-minus
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-2-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                  ">
                    <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-6-desktop
                      mdc-layout-grid__cell--span-4-tablet
                      mdc-layout-grid__cell--span-2-phone
                      neo-align-right
                    ">
                      <button (click)="Decrease('Eigenmittel', 500)" class="
                        neo-button neo-js-button
                        neo-button--fab neo-button--mini-fab
                      ">
                        <div class="neo-minus"></div>
                      </button>
                    </div>
                    <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-6-desktop
                      mdc-layout-grid__cell--span-4-tablet
                      mdc-layout-grid__cell--span-2-phone
                    ">
                      <button (click)="Increase('Eigenmittel', 500)" class="
                        neo-button neo-js-button
                        neo-button--fab neo-button--mini-fab
                      ">
                        <div class="neo-plus"></div>
                      </button>
                    </div>
                  </div>

                  <label *ngIf="Clickableeigenmittel" class="neo-color-red">The field cannot be empty</label>
                </div>
                <div class="neo-hypotek-mat-slider">
                  <mat-slider class="
                    neo-service-slider-track neo-mat-slider
                    mat-slider mat-accent" [min]="EigenmittelMin" [max]="EigenmittelMax" [(ngModel)]="eigenmittel"
                    ngDefaultControl>
                    <input matSliderThumb [value]="eigenmittel" (input)="onEigenmittelInputChange(eigenmittel)"
                      (change)="onEigenmittelChange()" />
                  </mat-slider>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Zinsen CARD -->
        <div class="
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-12-desktop
          mdc-layout-grid__cell--span-12-tablet
          mdc-layout-grid__cell--span-12-phone
          neo-htk-card
        ">
          <div class="mdc-layout-grid neo-padding-top-5">
            <div [ngClass]="{
              neo_added_selected_class: zinsen != 0,
              neo_border_img: zinsen === 0
            }" class="mdc-layout-grid__inner">
              <div class="
                neo-padding-top-20
                mdc-layout-grid__cell
                mdc-layout-grid__cell--span-12-desktop
                mdc-layout-grid__cell--span-12-tablet
                mdc-layout-grid__cell--span-12-phone
              ">
                <i class="
                  material-icons
                  neo-recommendation-info neo-recommendation-info-position neo-hypotek-info-icon
                " (click)="
                  openDialog(
                    fourthSlider.dialogInfoDescription[this.selectedLanguage],
                    fourthSlider.dialogInfoTitle[this.selectedLanguage]
                  )
                ">info_outline</i>

                <div class="
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-6-tablet
                  mdc-layout-grid__cell--span-12-phone
                " *ngIf="this.fourthSlider.sliderTitle">
                  <label class="neo-font-regular neo-padding-15">{{
                    this.fourthSlider.sliderTitle[this.selectedLanguage]
                    }}</label>
                </div>

                <div class="
                  neo-left-right
                  mdc-layout-grid__cell
                  mdc-layout-grid__cell--span-12-desktop
                  mdc-layout-grid__cell--span-12-tablet
                  mdc-layout-grid__cell--span-12-phone
                ">
                  <input class="
                    neo-input
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-7-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                  " type="text" min="0" [(ngModel)]="ZinsenTmp" (input)="
                    CheckRequired(); ZinsenThousand(); CalculateOnChange()
                  " (change)="
                    CheckIfNegative();
                    CheckRequired();
                    ZinsenThousand();
                    CalculateOnChange()
                  " (keyup)="CheckIfNegative()" required />

                  <div class="
                    neo-plus-minus
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-2-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                  ">
                    <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-6-desktop
                      mdc-layout-grid__cell--span-4-tablet
                      mdc-layout-grid__cell--span-2-phone
                      neo-align-right
                    ">
                      <button (click)="Decrease('Zinsen', 0.1)" class="
                        neo-button neo-js-button
                        neo-button--fab neo-button--mini-fab
                      ">
                        <div class="neo-minus"></div>
                      </button>
                    </div>
                    <div class="
                      mdc-layout-grid__cell
                      mdc-layout-grid__cell--span-6-desktop
                      mdc-layout-grid__cell--span-4-tablet
                      mdc-layout-grid__cell--span-2-phone
                    ">
                      <button (click)="Increase('Zinsen', 0.1)" class="
                        neo-button neo-js-button
                        neo-button--fab neo-button--mini-fab
                      ">
                        <div class="neo-plus"></div>
                      </button>
                    </div>
                  </div>
                  <label *ngIf="Clickableeigenmittel" class="neo-color-red">The field cannot be empty</label>
                </div>
                <div class="neo-hypotek-mat-slider">
                  <mat-slider class="
                    neo-service-slider-track neo-mat-slider
                    mat-slider mat-accent" [min]="ZinsenMin" [max]="ZinsenMax" [(ngModel)]="zinsen" ngDefaultControl>
                    <input matSliderThumb [value]="zinsen" (input)="onZinsenInputChange(zinsen)"
                      (change)="onZinsenChange()" />
                  </mat-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="{ neo_table_show: status, neo_table_hidden: !status }" class="
        mdc-layout-grid__cell
        mdc-layout-grid__cell--span-6-desktop
        mdc-layout-grid__cell--span-6-tablet
        mdc-layout-grid__cell--span-12-phone
      ">
        <div class="mdc-layout-grid__inner neo-column-gap">
          <div class="
          neo-input-calc
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-8-desktop
          mdc-layout-grid__cell--span-8-tablet
          mdc-layout-grid__cell--span-7-phone
        ">
            <label class="textcolor" *ngIf="this.resultsText.hypothek">{{
              this.resultsText.hypothek[this.selectedLanguage]
              }}</label>
          </div>
          <div class="
          neo-input-res
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-4-desktop
          mdc-layout-grid__cell--span-4-tablet
          mdc-layout-grid__cell--span-5-phone
        ">
            <label [ngStyle]="{ color: Hypothek < 0 ? '#D8000C' : '' }">
              {{ Hypothek | number: "1.0-0" }}</label>
          </div>
          <div class="
            neo-input-calc
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-8-desktop
            mdc-layout-grid__cell--span-8-tablet
            mdc-layout-grid__cell--span-7-phone
          ">
            <label class="textcolor" *ngIf="this.resultsText.zinsen">{{
              this.resultsText.zinsen[this.selectedLanguage]
              }}</label>
          </div>
          <div class="
            neo-input-res
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-4-desktop
            mdc-layout-grid__cell--span-4-tablet
            mdc-layout-grid__cell--span-5-phone
          ">
            <label [ngStyle]="{ color: Zinsen < 0 ? '#D8000C' : '' }">
              {{ Zinsen | number: "1.0-0" }} </label>
          </div>

          <div class="
            neo-input-calc
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-8-desktop
            mdc-layout-grid__cell--span-8-tablet
            mdc-layout-grid__cell--span-7-phone
          ">
            <label class="textcolor" *ngIf="this.resultsText.amortisation">{{
              this.resultsText.amortisation[this.selectedLanguage]
              }}</label>
          </div>
          <div class="
            neo-input-res
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-4-desktop
            mdc-layout-grid__cell--span-4-tablet
            mdc-layout-grid__cell--span-5-phone
          ">
            <label [ngStyle]="{ color: Amortisation < 0 ? '#D8000C' : '' }">
              {{ Amortisation | number: "1.0-0" }}</label>
          </div>
          <div class="
            neo-input-calc
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-8-desktop
            mdc-layout-grid__cell--span-8-tablet
            mdc-layout-grid__cell--span-7-phone
            neo-word-break
          ">
            <label class="textcolor" *ngIf="this.resultsText.unterhaltnebenkosten">{{
              this.resultsText.unterhaltnebenkosten[
              this.selectedLanguage
              ]
              }}</label>
          </div>
          <div class="
            neo-input-res
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-4-desktop
            mdc-layout-grid__cell--span-4-tablet
            mdc-layout-grid__cell--span-5-phone
          ">
            <label [ngStyle]="{
              color: UnterhaltNebenkosten < 0 ? '#D8000C' : ''
            }">
              {{ UnterhaltNebenkosten | number: "1.0-0" }}</label>
          </div>
          <div class="
            neo-input-calc
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-8-desktop
            mdc-layout-grid__cell--span-8-tablet
            mdc-layout-grid__cell--span-7-phone
          ">
            <label class="textcolor" *ngIf="this.resultsText.kostenmonatlich">{{
              this.resultsText.kostenmonatlich[this.selectedLanguage]
              }}</label>
          </div>
          <div class="
            neo-input-res
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-4-desktop
            mdc-layout-grid__cell--span-4-tablet
            mdc-layout-grid__cell--span-5-phone
          ">
            <label [ngStyle]="{
              color: KostenMonatlich < 0 ? '#D8000C' : ''
            }">
              {{ KostenMonatlich | number: "1.0-0" }}</label>
          </div>
        </div>
      </div>
    </div>
    <!--  6  -->
    <div class="
        mdc-layout-grid__cell
        mdc-layout-grid__cell--span-6-desktop
        mdc-layout-grid__cell--span-12-tablet
        mdc-layout-grid__cell--span-12-phone
        neo-results
      ">
      <!-- Calculate button -->


      <div class="
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-6-desktop
          mdc-layout-grid__cell--span-12-tablet
          mdc-layout-grid__cell--span-12-phone
        " [ngClass]="{ neo_show: status, neo_hidden: !status }">
        <div class="
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-10-desktop
            mdc-layout-grid__cell--span-12-tablet
            mdc-layout-grid__cell--span-12-phone
            neo-padding-bottom-30
          ">
          <div class="lending-horizontal-chart-content">
            <canvas #LendingHorizontalChartBelehnung class="horizontal-chart-belehnung"></canvas>
            <canvas #LendingHorizontalChartTragbarkeit class="horizontal-chart-tragbarkeit"></canvas>
          </div>
        </div>
        <div class="
            mdc-layout-grid__cell
            mdc-layout-grid__cell--span-10-desktop
            mdc-layout-grid__cell--span-12-tablet
            mdc-layout-grid__cell--span-12-phone
            base-chart-content
          ">
          <canvas #BaseChart width="400px" height="100%" class="neo-hypotek-base-chart"></canvas>
        </div>
      </div>
    </div>
  </div>
  <div class="neo-banner-flex">
    <div class="
        neo-first-banners
        mdc-layout-grid__cell
        mdc-layout-grid__cell--span-6-desktop
        mdc-layout-grid__cell--span-12-tablet
        mdc-layout-grid__cell--span-12-phone
      ">
      <markdown class="neo-markdown" *ngIf="firstTwoBanners.firstBannerDescription"
        [data]="firstTwoBanners.firstBannerDescription[selectedLanguage]"></markdown>
    </div>
    <div class="
        neo-second-banners
        mdc-layout-grid__cell
        mdc-layout-grid__cell--span-6-desktop
        mdc-layout-grid__cell--span-12-tablet
        mdc-layout-grid__cell--span-12-phone
      ">
      <markdown class="neo-markdown" *ngIf="firstTwoBanners.secondBannerDescription"
        [data]="firstTwoBanners.secondBannerDescription[selectedLanguage]"></markdown>
    </div>
  </div>
  <div class="swiper-bmi">
    <h2 class="header-title-swiper font-univia">{{toolsTitle?.headline[selectedLanguage]}}</h2>
    <!-- E5A swiper previously working with v. 9.0.1 -->

    <!-- <swiper [config]="neoSwiperCareerConfig" class="swiper-el">
      <neo-grid-component 
      class="neo-grid-flex"
      customCssClass = "bmi-tools-swiper"
      *ngFor="let items of filteredToolsComponent"
      [tinyRepresentation]="true"
      [color]="items?.colorSchema"
      [elementAsLink]="true"
      [title]="items?.title[selectedLanguage]"
      [link]="items?.toolsLink[selectedLanguage]"
      [description]="(items?.description[selectedLanguage].length>100)?(items?.description[selectedLanguage] | slice: 0:100)+'...':(items?.description[selectedLanguage])">
      </neo-grid-component>
      
      
    </swiper> -->
  </div>
</div>