import {Directive, ElementRef, HostListener, Input} from "@angular/core";

@Directive({
    selector: "[neoNumbersOnly]"
})
export class NumbersOnlyDirective {

    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private readonly specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "-"];
    DIGITS_REGEXP = new RegExp(/\D/g);

    constructor(public el: ElementRef) {

        this.el.nativeElement.onpaste = (e: any) => {
            e.preventDefault();
            let text;
            const clp = (e.originalEvent || e).clipboardData;
            if (clp === undefined || clp === null) {
                text = (window as any).clipboardData.getData("text") || "";
                if (text !== "") {
                    text = text.replace(this.DIGITS_REGEXP, "");
                    if (window.getSelection) {
                        const newNode = document.createElement("span");
                        newNode.innerHTML = text;
                        window.getSelection().getRangeAt(0).insertNode(newNode);
                    } else {
                        (window as any).selection.createRange().pasteHTML(text);
                    }
                }
            } else {
                text = clp.getData("text/plain") || "";
                if (text !== "") {
                    text = text.replace(this.DIGITS_REGEXP, "");
                    document.execCommand("insertText", false, text);
                }
            }
        };
    }

    @Input() neoNumbersOnly: boolean;

    @HostListener("keydown", ["$event"]) onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        // Prüft ob Command (MAC), STRG oder ALT gedrückt wurde
        const isModifierkeyPressed: boolean = (event.metaKey || event.ctrlKey || event.shiftKey);

        // Prüft ob folgende Tasten gedrückt wurden:
        // 46  --> Delete
        // 8   --> Backspace
        // 37  --> Pfeiltaste links
        // 38  --> Pfeiltaste hoch
        // 39  --> Pfeiltaste rechts
        // 40  --> Pfeiltaste runter
        const isCursorMoveOrDeleteAction: boolean = ([46, 8, 37, 38, 39, 40].indexOf(event.keyCode) !== -1);

        // Prüft ob eine Nummerntaste gedrückt wurde:
        // 48-57
        // 96-105 Numblock
        const isNumKeyPressed: boolean = (event.keyCode >= 48 && event.keyCode <= 58) || (event.keyCode >= 96 && event.keyCode <= 105);

        // Tastencodes für v, c, a,
        const vKey = 86, cKey = 67, aKey = 65;

        switch (true) {
            case isCursorMoveOrDeleteAction:
            case isModifierkeyPressed === false && isNumKeyPressed:
            case (event.metaKey || event.ctrlKey) && ([vKey, cKey, aKey].indexOf(event.keyCode) !== -1):
                break;
            default:
                event.preventDefault();
        }
    }
}
