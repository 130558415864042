import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild} from "@angular/core";
import {Suchparameter} from "../models/suchparameter";
import {StaticDataProvider} from "../provider/static-data-provider";
import {SuchmaskeProvider} from "../provider/suchmaske-provider";
import {Router} from "@angular/router";
import {NeoCommonsUiConstants} from "../models/neo-ui.constants";
import { NeoInputCompleterItem } from "../models/neo-input-completer.item";
import {NeoRegion} from "../models/neo-region";
import { BreakpointObserver } from "@angular/cdk/layout";
@Component({
    selector: "neo-search-small",
    templateUrl: "./search-small.component.html",
    styleUrls: ["./search-small.component.css"]
})
export class SearchSmallComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    description: string;
    @Input()
    suchdatenstring: Suchparameter;
    @Input() customClass = '';
    @Output()
    openVGR = new EventEmitter<Suchparameter>();
    @Input()
    genderImages: [{alt: string; img: string}] | any = [];
    @ViewChild("search_inner")
    searchInner: ElementRef;
    @ViewChild("search_outer")
    searchOuter: ElementRef;

    isSmallScreen = false;

    public regionen: NeoInputCompleterItem[];
    contentHeight;
    public readonly genderMale = NeoCommonsUiConstants.GENDER_MALE;
    public readonly genderFemale = NeoCommonsUiConstants.GENDER_FEMALE;

    constructor(public staticData: StaticDataProvider, public router: Router,
        public breakpointObserver: BreakpointObserver) {
        this.staticData.onRegionsLoad.subscribe(v => this.regionen = v);
        breakpointObserver.observe('(max-width: 599px)').subscribe(res => {
            if (res.matches) {
              this.isSmallScreen = true;
            } else {
              this.isSmallScreen = false;
            }
          })
    }

    ngOnInit() {
        this.suchdatenstring = new Suchparameter();
        setTimeout(() => {
            this.initHeight();
        }, 10);
        this.regionen = this.staticData.regions;
        if (this.regionen.length <= 0) {
            this.staticData.ladeAlleDaten();
        }
    }

    changeSex(value: string) {
        this.suchdatenstring.hauptPerson.geschlecht = value;
    }

    changeYear(value: number) {
        if (SuchmaskeProvider.isJahrgangValide(value)) {
            this.suchdatenstring.hauptPerson.jahrgang = value;
        }
    }

    changeRegion(data: NeoRegion) {
        this.suchdatenstring.region = data;
    }

    toggleDisplay() {
        if (this.contentHeight === 0) {
            this.contentHeight = this.getInnerHeight();
        } else {
            this.contentHeight = 0;
        }
    }

    private initHeight() {
        if (this.getOuterHeight() > 0) {
            this.contentHeight = this.getInnerHeight();
        } else {
            this.contentHeight = 0;
        }
    }


    getInnerHeight(): number {
        return this.searchInner.nativeElement.offsetHeight + 10;
    }

    getOuterHeight(): number {
        return this.searchOuter.nativeElement.offsetHeight;
    }

    emitChange() {
        this.openVGR.emit(this.suchdatenstring);
    }
}
