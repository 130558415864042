import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountingService {
  constructor() { }

  countSmooth(start: number, end: number, duration: number, delay: number): Observable<number> {
    return new Observable(observer => {
      const stepTime = 20;
      const steps = duration / stepTime;
      const stepValue = (end - start) / steps;
      let current = start;
      const timer = setTimeout(() => {
        const interval = setInterval(() => {
          current += stepValue;
          if ((stepValue > 0 && current >= end) || (stepValue < 0 && current <= end)) {
            current = end;
            clearInterval(interval);
          }
          observer.next(Math.round(current));
        }, stepTime);
        return () => clearInterval(interval);
      }, delay);
      return () => clearTimeout(timer);
    });
  }
}
