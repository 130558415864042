import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Doctor} from "../models/doctor";
import {VvgLeistung} from "../models/vvg-leistung";
import {NeoVgrTranslation} from "../models/neo-vgr-translation.model";
import {SuchmaskeProvider} from "./suchmaske-provider";
import {NeoVgrTranslationLanguage} from "../models/neo-vgr-translation-language.model";
import {StandardBelegungJson} from "../jsons/standard-belegung.json";
import {FranchiseStatistikJson} from "../jsons/franchise-statistik.json";
import {ConfigDataJson} from "../jsons/config-data.json";
import {TarifartDescription} from "../models/tarifart-description";
import {
    compress,
    compressToBase64,
    compressToEncodedURIComponent,
    compressToUTF16,
    decompress,
    decompressFromBase64,
    decompressFromEncodedURIComponent,
    decompressFromUTF16
} from "lz-string";
import {Suchparameter} from "../models/suchparameter";
import {TarifJson} from "../jsons/tarif.json";
import {PersonData} from "../models/person-data";
import {ServiceVersionJson} from "../jsons/service-version.json";
import {log} from "../components/neo.logger";
import {NeoCommonsUiConstants} from "../models/neo-ui.constants";
import {NeoInputButtonItem} from "../models/neo-input-button.item";
import {NeoRegion} from "../models/neo-region";
import {NeoVgrConstants} from "../models/neo-vgr-constants";

@Injectable()
export class StaticDataProvider {

    static readonly MIN_AGE = 1;
    static readonly MAX_AGE = 80;

    public TAG: string = this.constructor.name;
    public regions: NeoRegion[] = [];
    public doctors: Doctor[] = [];
    public vvgLeistungen: VvgLeistung[] = [];
    public tariffKinds: { [kind: string]: TarifartDescription } = {};

    public tariffKindsLoaded: EventEmitter<any> = new EventEmitter<TarifartDescription[]>();
    public onVvgLeistungenLoad: EventEmitter<VvgLeistung[]> = new EventEmitter<VvgLeistung[]>();
    public onRegionsLoad: EventEmitter<NeoRegion[]> = new EventEmitter<NeoRegion[]>();
    public onDoctorLoad: EventEmitter<Doctor[]> = new EventEmitter<Doctor[]>();
    public onServiceVersionLoad: EventEmitter<ServiceVersionJson> = new EventEmitter<ServiceVersionJson>();
    public onFranchiseDataLoad: EventEmitter<any> = new EventEmitter<any>();
    public onStandardBelegungDataLoad: EventEmitter<any> = new EventEmitter<any>();
    public onFranchiseStatistikDataLoad: EventEmitter<any> = new EventEmitter<any>();
    public onTranslationChange: EventEmitter<any> = new EventEmitter<any>();
    public onConfigDataLoad: EventEmitter<boolean> = new EventEmitter<boolean>();

    private readonly franchiseData: { [altersklasse: number]: Array<String>; } = {};
    private readonly standardBelegungData: { [alter: number]: StandardBelegungJson; } = {};
    private readonly franchiseStatistikData: { [alter: number]: FranchiseStatistikJson; } = {};

    public translationTexts: NeoVgrTranslation = new NeoVgrTranslation();
    public translationLang: NeoVgrTranslationLanguage = new NeoVgrTranslationLanguage(NeoCommonsUiConstants.LANGUAGE_GERMAN);

    private static correctAge(alter: number): number {
        if (alter < StaticDataProvider.MIN_AGE) {
            return StaticDataProvider.MIN_AGE;
        } else if (alter > StaticDataProvider.MAX_AGE) {
            return StaticDataProvider.MAX_AGE;
        }
        return alter;
    }


    public static compress(s: string): string {
        return compressToEncodedURIComponent(s);
    }

    public static decompress(s: string): string {
        return decompressFromEncodedURIComponent(s);
    }

    public static compressString(s: any): string {
        return StaticDataProvider.compress(JSON.stringify(s.toJson()));
    }

    public static decompressCheck(s: string): boolean {
        const data = StaticDataProvider.decompressString(s);
        if (data !== null) {
            return true;
        }
        return false;
    }

    public static decompressString(s: string): Suchparameter {
        const json: string = StaticDataProvider.decompress(s);
        if (json !== null) {
            return Suchparameter.fromJson(JSON.parse(json) as Suchparameter);
        }
        return null;
    }

    public static getDoctorSelectionValue(value: string[], doctor: Doctor): NeoInputButtonItem {
        if (value) {
            log.debug(`getDoctorSelectionValue - `, value, doctor);
            const returnValue = new NeoInputButtonItem();
            returnValue.toggleValue = NeoVgrConstants.DOCTOR_VALUE_NO_SELECTION;
            if (value.indexOf(NeoVgrConstants.TARIFF_TYPE_BASE) > -1 && value.indexOf(NeoVgrConstants.TARIFF_TYPE_DIV) > -1) {
                returnValue.toggleValue = NeoVgrConstants.DOCTOR_VALUE_FREE_CHOICE;
            } else  if (value.indexOf(NeoVgrConstants.TARIFF_TYPE_BASE) > -1 && value.length === 1) {
                returnValue.toggleValue = NeoVgrConstants.DOCTOR_VALUE_STANDARD;
            } else {
                if (doctor && doctor.GPV === 1 && doctor.HAV === 1) {
                    returnValue.toggleValue = NeoVgrConstants.DOCTOR_VALUE_SPECIFIED;
                } else if (doctor && doctor.HAV === 1) {
                    returnValue.toggleValue = NeoVgrConstants.DOCTOR_VALUE_SPECIFIED;
                } else {
                    returnValue.toggleValue = NeoVgrConstants.DOCTOR_VALUE_NO_SELECTION;
                }
            }
            log.debug(`getDoctorSelectionValue `, returnValue);
            return returnValue;
        }
        log.error(`gotDoctorSelectionValue: value is empty!`);
        return null;
    }

    public static getTariffKindForDoctor(value: string): string[] {
        let returnValue = [NeoVgrConstants.TARIFF_TYPE_HMO];
        if (value === NeoVgrConstants.DOCTOR_VALUE_NO_SELECTION) {
            returnValue = [NeoVgrConstants.TARIFF_TYPE_HMO, NeoVgrConstants.TARIFF_TYPE_HAM];
        } else if (value === NeoVgrConstants.DOCTOR_VALUE_FREE_CHOICE) {
            returnValue = [NeoVgrConstants.TARIFF_TYPE_BASE, NeoVgrConstants.TARIFF_TYPE_DIV];
        } else if (value === NeoVgrConstants.DOCTOR_VALUE_SPECIFIED) {
            returnValue = [NeoVgrConstants.TARIFF_TYPE_HMO];
        } else if (value === NeoVgrConstants.DOCTOR_VALUE_STANDARD) {
            returnValue = [NeoVgrConstants.TARIFF_TYPE_BASE];
        }
        return returnValue;
    }

    constructor(public http: HttpClient) {
    }

    public ladeAlleDaten() {
        Promise.all([
            this.loadConfigData(),
            this.loadRegions(),
            this.loadDoctors(),
        ]).then(values => {
            // all loading done
            this.onConfigDataLoad.emit(true);
            log.debug(`${this.TAG}: all static config data loaded!`);
        }, err => {
            log.error(`${this.TAG}: ERROR loading static config data.`);
            log.error(err);
        });
    }

    public setTranslationTexts(data: NeoVgrTranslation) {
        this.translationTexts = data;
        this.onTranslationChange.emit();
    }

    public setTranslationLang(data: NeoVgrTranslationLanguage) {
        this.translationLang = data;
        this.onTranslationChange.emit();
    }

    public getTranslationTexts(): NeoVgrTranslation {
        return this.translationTexts;
    }

    public getTranslationLang(): NeoVgrTranslationLanguage {
        return this.translationLang;
    }

    public getTariffKind(value: string): string {
        if (this.tariffKinds && this.tariffKinds[value] !== undefined &&
            this.getTranslationLang().language !== undefined &&
            this.tariffKinds[value].data[this.getTranslationLang().language] !== undefined) {
            return this.tariffKinds[value].data[this.getTranslationLang().language];
        }
        return undefined;
    }

    public getFranchisen(altersklasse: number): Array<String> {
        return this.franchiseData[altersklasse];
    }

    public getVVGRecommendation(age: number): StandardBelegungJson {
        return this.standardBelegungData[StaticDataProvider.correctAge(age)];
    }

    public getVVGRecommendationForYear(year: number): StandardBelegungJson {
        return this.getVVGRecommendation(SuchmaskeProvider.getAgeForYear(year));
    }

    public getFranchiseStatisticForYear(year: number): FranchiseStatistikJson {
        return this.getFranchiseStatistik(SuchmaskeProvider.getAgeForYear(year));
    }

    public getFranchiseStatistik(alter: number): FranchiseStatistikJson {
        return this.franchiseStatistikData[alter] == null ? null : this.franchiseStatistikData[alter];
    }

    private loadConfigData(): Promise<any> {
        return new Promise((resolve, reject) => {
            const actionUrl = `${environment.settings.BASE_URL}/${NeoVgrConstants.URL_PART_CONFIGS}`;
            this.http.get<any>(actionUrl)
                .toPromise()
                .then(
                    res => { // Success
                        const configDaten: ConfigDataJson = res;

                        // Franchise-Combobox-Inhalte parsen
                        configDaten.franchiseData.forEach(v => {
                            const values: Array<String> = [];
                            v.Franchise.forEach(f => values.push(String(f)));
                            this.franchiseData[v.AK] = values;
                        });
                        this.onFranchiseDataLoad.emit(this.franchiseData);

                        // Standardbelegung speichern
                        configDaten.standardBelegung.forEach(v => {
                            this.standardBelegungData[v.alter] = v;
                        });
                        this.onStandardBelegungDataLoad.emit(this.standardBelegungData);

                        // Franchise-Statistik laden
                        configDaten.franchiseStatistik.forEach(v => {
                            this.franchiseStatistikData[v.alter] = v;
                        });
                        this.onFranchiseStatistikDataLoad.emit(this.franchiseStatistikData);

                        // VVG-Leistungen laden
                        this.vvgLeistungen = [];
                        for (let i = 0; i < configDaten.vvgLeistungen.length; i++) {
                            this.vvgLeistungen.push(VvgLeistung.fromJson(configDaten.vvgLeistungen[i]));
                        }
                        this.onVvgLeistungenLoad.emit(this.vvgLeistungen);

                        // Tarifarten laden
                        configDaten.tarifarten.forEach(v => {
                            this.tariffKinds[v.key] = TarifartDescription.fromJson(v);
                        });
                        this.tariffKindsLoaded.emit(this.tariffKinds);

                        // ConfigVersion laden
                        this.onServiceVersionLoad.emit(configDaten.serviceVersion);

                        resolve(configDaten);
                    },
                    err => { // Error
                        reject(err);
                    }
                );
        });
    }

    public loadRegions(): Promise<any> {
        this.regions = [];
        return new Promise((resolve, reject) => {
            const actionUrl = `${environment.settings.BASE_URL}/${NeoVgrConstants.URL_PART_REGIONS}`;
            this.http.get<any>(actionUrl).toPromise()
                .then(res => { // Success
                        let counter = 0;
                        res.forEach(v => {
                            const region = NeoRegion.fromRegion(v);
                            region.key = counter.toString();
                            this.regions.push(region);
                            counter++;
                        });
                        this.onRegionsLoad.emit(this.regions);
                        resolve(this.regions);
                    },
                    err => { // Error
                        reject(err);
                    }
                );
        });
    }

    private loadDoctors(): Promise<any> {
        this.doctors = [];
        return new Promise((resolve, reject) => {
            const actionUrl = `${environment.settings.BASE_URL}/${NeoVgrConstants.URL_PART_DOCTORS}`;
            this.http.get<any>(actionUrl).toPromise()
                .then(res => { // Success
                        let counter = 0;
                        res.forEach(v => {
                            const doctor = Doctor.fromDoctor(v);
                            doctor.key = counter.toString();
                            this.doctors.push(doctor);
                            counter++;
                        });
                        this.onDoctorLoad.emit(this.doctors);
                        resolve(this.doctors);
                    },
                    err => { // Error
                        reject(err);
                    }
                );
        });
    }

    public createPersonDataForTariff(tariff: TarifJson, translateBaseTariff: boolean, preselectAll: boolean): Array<PersonData> {
        log.debug(`${this.TAG}: createPersonDataForTarif `, tariff);
        let personData: Array<PersonData>;
        personData = [];
        if (tariff && tariff.personen) {
            for (let i = 0; i < tariff.personen.length; i++) {
                if (personData.length < (i + 1)) {
                    const pPrefix = this.getTranslationTexts().neoVgrPersonenEingabeTitle;
                    const pGenderBirthYear = SuchmaskeProvider.getTranslatedPersonBirthdayGenderShortened(this,
                        tariff.personen[i].jahrgang, tariff.personen[i].geschlecht);
                    const personName = `${pPrefix} ${i + 1} ${pGenderBirthYear}`;

                    personData.push({
                        name: personName,
                        premiumCategories: [],
                        premiumData: [],
                        premiumNames: [],
                        selected: false
                    });
                }
                for (let j = 0; j < tariff.personen[i].produkte.length; j++) {
                    if (translateBaseTariff && j === 0) {
                        const temp = tariff.personen[i].produkte[j].tarifart;
                        personData[i].premiumNames.push(this.getTariffKind(temp) + " (" + tariff.personen[i].produkte[j].bezeichnung + ")");
                    } else {
                        personData[i].premiumNames.push(tariff.personen[i].produkte[j].bezeichnung);
                    }
                    personData[i].premiumCategories.push(tariff.personen[i].produkte[j].tarifart.substr(0, 2));
                    personData[i].premiumData.push(tariff.personen[i].produkte[j].praemie);
                }
                if (i === 0 || preselectAll === true) {
                    personData[i].selected = true;
                }
            }
        }
        log.debug(`${this.TAG}: personData created `, personData);
        return personData;
    }
}
