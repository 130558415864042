import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  selectedLanguage!: string;
  errorPageData: any;

  constructor(private translate: TranslateService, private cmsService: CmsService) { }

  ngOnInit() {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.selectedLanguage = res.lang;
      this.getCmsContent();
    })
    this.getCmsContent();
  }

  getCmsContent() {
    const page = 'error-page';
    const populate = 'deep';
    const locale = this.selectedLanguage;;
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;

    this.cmsService.singlePageContent(page, populateAndLocale).subscribe((r: any) => {
      this.errorPageData = r;
    })
  }

  redirectTo() {

  }

}
