<div class="neo-message-content">
    <div class="neo-message-bottom">
       <img class="like-image" src="../../../assets/images/Erfolgsorientiert.png">
        <div class="message-text">
           <h1>{{'VGR.MESSAGE-SUCCESS.TITLE' | translate}}</h1>
           <p>{{'VGR.MESSAGE-SUCCESS.SHORT-TEXT' | translate}}</p>
           <p>{{'VGR.MESSAGE-SUCCESS.TEXT' | translate}}</p>
           <a href="/home" class="redirect-link">
            <svg xmlns="http://www.w3.org/2000/svg" 
            width="2em" 
            height="2em" 
            viewBox="0 0 24 24">
            <path fill="currentColor" d="M5.59 7.41L7 6l6 6l-6 6l-1.41-1.41L10.17 12zm6 0L13 6l6 6l-6 6l-1.41-1.41L16.17 12z"/></svg>
        
           {{'VGR.MESSAGE-SUCCESS.LINK' | translate}}</a>
         </div>
    </div>
 </div>