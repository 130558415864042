import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormControl} from "@angular/forms";
import {NeoInputBase} from "./neo-input.abstract.component";
import {NeoInputCompleterItem} from "../models/neo-input-completer.item";
import {Observable} from "rxjs";
import {map, startWith, debounceTime} from "rxjs/operators";

@Component({
    selector: "neo-input-completer",
    templateUrl: "./neo-input-completer.component.html",
    styleUrls: ["./neo-input-completer.component.scss"]
})
export class NeoInputCompleterComponent extends NeoInputBase<NeoInputCompleterItem> implements OnInit {
    @Input()
    set neoAutoValue(v: NeoInputCompleterItem) {
        this.neoValue = v;
        this.setValue();
    }
    @Input()
    public minLength = 4;
    @Input()
    public neoCompleterItems: NeoInputCompleterItem[];

    myControl = new UntypedFormControl();
    filteredOptions: Observable<NeoInputCompleterItem[]>;

    constructor() {
        super(NeoInputCompleterItem);
    }

    setValue() {
        this.myControl.setValue(this.neoValue);
        this.neoValueEmitter.emit(this.neoValue);
    }

    ngOnInit() {
        this.filteredOptions = this.myControl.valueChanges.pipe(debounceTime(400))
            .pipe(startWith(""), map(value => this._filter(value)));
        this.myControl.valueChanges.pipe(debounceTime(400)).subscribe(data => {
            if (data === undefined || data === "" || typeof data === "string") {
                this.showErrorCss = true;
                this.neoValueEmitter.emit(undefined);
            } else {
                this.neoValueEmitter.emit(data);
                this.showErrorCss = false;
            }
        });
    }

    public neoIsValid(): boolean {
        if (this.neoRequired && this.neoValue && this.neoValue.value && this.neoValue.value.length > 0) {
            return true;
        } else if (this.neoRequired) {
            return false;
        }
        return true;
    }

    private _filter(value: string): NeoInputCompleterItem[] {
        if (value !== undefined) {
            const filterValue = value.toString().toLowerCase();
            if (this.neoCompleterItems && filterValue && filterValue.length >= this.minLength) {
                return this.neoCompleterItems.filter(option => option.value.toLowerCase().includes(filterValue));
            }
        }
        return [];
    }
}
