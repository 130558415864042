<div class="mdc-layout-grid" [ngClass]="customCssClass">
    <div class="mdc-layout-grid__inner neo-helper-grid-gap-10">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <h1 *ngIf="showHeader === true">{{formTexts.titleTitle}}</h1>
        </div>
        <div *ngIf="showText === true"
             class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            {{formTexts.titleText}}
        </div>
        <div *ngIf="showRequired === true"
             class="neo-contact-form-required mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            * {{formTexts.required}}
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-contact-form-padding-b-t">
            <neo-input-gender-select class="neo-vgr-input-margin "
                                   [neoValue]="contactData.salutation"
                                   [neoLabel]="formTexts.salutation"
                                   [neoRadioLabels]="[formTexts.salutationMale,formTexts.salutationFemale]"
                                   [neoRadioValues]="[formOfAddressMale,formOfAddressFemale]"
                                   [images]="genderImages"
                                   (neoValueEmitter)="sexChanged($event)">
            </neo-input-gender-select>
        </div>

       
     
        <div 
             class="mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-12-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid__inner neo-helper-grid-gap-10">
                         <div class="neo-contact-form-inputs mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputLastName [neoValue]="contactData.lastName"
                            [neoLabel]="formTexts.surname"
                            [neoRequired]="true"
                            [neoShowRequired]="true"
                            (neoValueEmitter)="lastNameChanged($event)">
            </neo-input-text>
        </div>
        <div class="neo-contact-form-inputs mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputFirstName [neoValue]="contactData.firstName"
                            [neoLabel]="formTexts.name"
                            [neoRequired]="true"
                            [neoShowRequired]="true"
                            (neoValueEmitter)="firstNameChanged($event)">
            </neo-input-text>
        </div>

        <div *ngIf="showBirthday"
        class="mdc-layout-grid__cell
        mdc-layout-grid__cell--span-4-desktop 
        mdc-layout-grid__cell--span-6-tablet 
        mdc-layout-grid__cell--span-12-phone">
            <neo-input-datepicker #inputDate class="neo-input-datepicker"
                                          [neoValue]="contactData.birthday"
                                          [neoLabel]="formTexts.birthday"
                                          [neoPlaceholder]="datepickerPlaceholder[selectedLanguage]"
                                          [selectedLanguage]="selectedLanguage"
                                          (neoValueEmitter)="birthdayChanged($event)">
                    </neo-input-datepicker>
                   </div>
             
                <div class="neo-contact-form-inputs
                            mdc-layout-grid__cell
                            mdc-layout-grid__cell--span-4-desktop
                            mdc-layout-grid__cell--span-6-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <!--empty column-->
                </div>
            </div>
        </div>

        <div  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid__inner neo-helper-grid-gap-10">
                <div *ngIf="showStreetAndNumber" class="neo-contact-form-inputs
                 mdc-layout-grid__cell
                 mdc-layout-grid__cell--span-4-desktop
                 mdc-layout-grid__cell--span-6-tablet
                 mdc-layout-grid__cell--span-12-phone">
                    <neo-input-text #inputStreet
                                    [neoValue]="contactData.street"
                                    [neoLabel]="formTexts.street"
                                    (neoValueEmitter)="streetChanged($event)">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      </neo-input-text>
                </div>
                <div *ngIf="showStreetAndNumber" class="neo-contact-form-inputs
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-4-desktop
                    mdc-layout-grid__cell--span-6-tablet
                    mdc-layout-grid__cell--span-12-phone">
                    <neo-input-text #inputHouseNo
                                    [neoValue]="contactData.houseNumber"
                                    [neoLabel]="formTexts.number"
                                    (neoValueEmitter)="houseNumberChanged($event)">
                    </neo-input-text>
                </div>
           

        <!-- zip/town or region selector start -->
        <div *ngIf="useRegion === true"
             class="neo-contact-form-inputs
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-4-desktop
                    mdc-layout-grid__cell--span-6-tablet
                    mdc-layout-grid__cell--span-12-phone">
            <neo-input-completer #inputRegion
                                 [minLength]="3"
                                 [neoAutoValue]="contactData.region"
                                 [neoLabel]="formTexts.region"
                                 [neoRequired]="true"
                                 [neoShowRequired]="true"
                                 [neoCompleterItems]="regionData"
                                 (neoValueEmitter)="regionChanged($event)">
            </neo-input-completer>
        </div>
        <div class="neo-contact-form-inputs
        mdc-layout-grid__cell--span-4-desktop
                    mdc-layout-grid__cell--span-6-tablet
                    mdc-layout-grid__cell--span-12-phone">
                    <!-- empty column -->
    </div>
</div>
        <div *ngIf="useRegion === false || !useRegion"
             class="neo-contact-form-inputs
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-4-desktop
                    mdc-layout-grid__cell--span-6-tablet
                    mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputZipCode
                            [neoValue]="contactData.zip"
                            [neoLabel]="formTexts.zip"
                            (neoValueEmitter)="zipChanged($event)">
            </neo-input-text>
        </div>
        <div *ngIf="useRegion === false || !useRegion"
             class="neo-contact-form-inputs
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-4-desktop
                    mdc-layout-grid__cell--span-6-tablet
                    mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputCity
                            [neoValue]="contactData.town"
                            [neoLabel]="formTexts.city"
                            [neoRequired]="true"
                            [neoShowRequired]="true"
                            (neoValueEmitter)="townChanged($event)">
            </neo-input-text>
        </div>
        <!-- zip/town or region selector end -->
        <div  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid__inner neo-helper-grid-gap-10 neo-contact-form-padding-b-t">
        <div class="mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-4-desktop
                    mdc-layout-grid__cell--span-6-tablet
                    mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputEmail
                            [neoValue]="contactData.email"
                            [neoLabel]="formTexts.mail"
                            [neoRequired]="true"
                            [neoShowRequired]="true"
                            (neoValueEmitter)="emailChanged($event)">
            </neo-input-text>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-text #inputPhone
                            [neoValue]="contactData.phone"
                            [neoLabel]="formTexts.telefon"
                            [neoRequired]="true"
                            [neoShowRequired]="true"
                            (neoValueEmitter)="phoneChanged($event)">
            </neo-input-text>
        </div>
        <div class="neo-contact-form-inputs
        mdc-layout-grid__cell--span-4-desktop
                    mdc-layout-grid__cell--span-6-tablet
                    mdc-layout-grid__cell--span-12-phone">
                    <!-- empty column -->
    </div>
    </div>
    </div>


        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-contact-form-padding-b-t" *ngIf="showLanguage">
            <h2>{{formTexts.language}}</h2>
            <neo-input-radio-group class="neo-vgr-input-margin  neo-contact-form-radios"
                                   [neoValue]="contactData.language"
                                   [neoLabel]=""
                                   [neoRadioLabels]="[formTexts.languageDe,
                                                      formTexts.languageEn,
                                                      formTexts.languageFr,
                                                      formTexts.languageIt]"
                                   [neoRadioValues]="[languageGerman, languageEnglish, languageFrench, languageItalian]"
                                   (neoValueEmitter)="languageChanged($event)">
            </neo-input-radio-group>
        </div>
        <div *ngIf="showInterestedIn"
             class="mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-12-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone">
            <h2>{{formTexts.interest}}</h2>
            <div class="neo-checkbox-group">
                <neo-input-checkbox class="neo-text"
                                    [labelPosition]="'after'"
                                    [neoValue]="contactData.interestedInHealth"
                                    [neoLabel]="formTexts.interestHealth"
                                    (neoValueEmitter)="interestedInChanged(0, $event)">
                </neo-input-checkbox>
                <neo-input-checkbox class="neo-text"
                                    [labelPosition]="'after'"
                                    [neoValue]="contactData.interestedInMotor"
                                    [neoLabel]="formTexts.interestMotor"
                                    (neoValueEmitter)="interestedInChanged(1, $event)">
                </neo-input-checkbox>
                <neo-input-checkbox class="neo-text"
                                    [labelPosition]="'after'"
                                    [neoValue]="contactData.interestedInHousehold"
                                    [neoLabel]="formTexts.interestHousehold"
                                    (neoValueEmitter)="interestedInChanged(2, $event)">
                </neo-input-checkbox>
                <neo-input-checkbox class="neo-text"
                                    [labelPosition]="'after'"
                                    [neoValue]="contactData.interestedInLife"
                                    [neoLabel]="formTexts.interestLife"
                                    (neoValueEmitter)="interestedInChanged(3, $event)">
                </neo-input-checkbox>
            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                <neo-input-text #inputInterest
                                [neoValue]="contactData.interestedInOther"
                                [neoLabel]="formTexts.interestOther"
                                [useTextarea]="true"
                                (neoValueEmitter)="interestOtherChanged($event)">
                </neo-input-text>
            </div>
        </div>
        <div *ngIf="showConcern === true"
             class="mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-12-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone neo-contact-form-padding-b-t">
            <h2>{{formTexts.concern}}</h2>
            <neo-input-text #inputConcern
                            [neoValue]="contactData.concern"
                            [neoLabel]="formTexts.concernText"
                            [useTextarea]="true"
                            (neoValueEmitter)="concernChanged($event)">
            </neo-input-text>
        </div>
        <div *ngIf="showNews === true"
        class="mdc-layout-grid__cell
               mdc-layout-grid__cell--span-12-desktop
               mdc-layout-grid__cell--span-12-tablet
               mdc-layout-grid__cell--span-12-phone neo-contact-form-padding-b-t">
       <h2>{{formTexts.news}}</h2>
       <neo-input-text #inputNews
                       [neoValue]="contactData.news"
                       [useTextarea]="true"
                       (neoValueEmitter)="newsChanged($event)">
       </neo-input-text>
   </div>
        <div *ngIf="showContactPlatform" class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-contact-form-padding-b">
            <h2>{{formTexts.contact}}</h2>
            <neo-input-radio-group class="neo-vgr-input-margin  neo-contact-form-radios"
                                   [neoValue]="contactData.contactKind"
                                   [neoLabel]=""
                                   [neoRadioLabels]="[formTexts.contactMail,
                                                      formTexts.contactWhatsapp,
                                                      formTexts.contactTelefon]"
                                   [neoRadioValues]="[contactMail, contactWhatsApp, contactPhone]"
                                   (neoValueEmitter)="contactChanged($event)">
            </neo-input-radio-group>
        </div>
        <div *ngIf="showTime" class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-contact-form-padding-b-t">
            <h2>{{formTexts.contactTime}}</h2>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid__inner">
        <div *ngIf="showTime" class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-time #inputTime
                            [neoValue]="contactData.time"
                            [neoLabel]=""
                            [neoRequired]="true"
                            (neoValueEmitter)="timeChanged($event)">
            </neo-input-time>
        </div>
        </div>
        </div>
        <div class="neo-contact-form-inputs mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
            <!--empty column-->
        </div>
        <div class="neo-careerform-attach-documents" *ngIf="showAttachment">
            <h1>{{formTexts.attachmentTitle}}</h1>
            <div class="neo-dragfile-container" appDragfile (fileDropped)="onFileDropped($event)">
              <input type="file" accept=".pdf, .jpg, .png" data-filetype="pdf|jpg|png" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />    
              <p>{{formTexts.attachmentDescription1}}</p>
              <p>{{formTexts.attachmentDescription2}}</p>
              <p>{{formTexts.attachmentDescription3}}</p>
            </div>
            <div class="files-list">
              <div class="single-file" *ngFor="let file of files; let i = index">
                <div class="file-icon" style="width: 50px">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
                    <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                    <g>
                      <path style="fill:#CEC9AE;"
                        d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                      <path style="fill:#CEC9AE;"
                        d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                      <path style="fill:#CEC9AE;"
                        d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                      <path style="fill:#CEC9AE;"
                        d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                      <path style="fill:#CEC9AE;"
                        d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                    </g>
                    <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                  </svg>
                </div>
                <div class="info">
                  <h4 class="name">
                    {{ file?.name }}
                  </h4>
                  <p class="size">
                    {{ formatBytes(file?.size, 2) }}
                  </p>
                </div>
            
                <div class="delete" (click)="deleteFile(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                    <path fill="#B1B1B1" fill-rule="nonzero"
                      d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                  </svg>
                </div>
              </div>
            </div>
        </div>

        <div class="neo-privacy-policy" *ngIf="showPrivacyPolicy">
            <p>
                {{formTexts.privacyPolicy}}
            </p>
        </div>
        <div *ngIf="showErrorDiv"
             class="neo-message-error neo-contact-form-error mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            {{formTexts.errorRequiredMissing}}
        </div>
        <div *ngIf="showSubmit"
             class="neo-contact-form-submit mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <neo-input-button [neoLabel]="formTexts.send"
                              (neoValueEmitter)="sendMessage()"
                              [neoButtonCssClasses]="'neo-btn-input neo-btn-input-primary neo-btn-input-end'">
            </neo-input-button>
        </div>
    </div>
</div>
