import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-neo-calculators-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './neo-calculators-info.component.html',
  styleUrl: './neo-calculators-info.component.scss'
})
export class NeoCalculatorsInfoComponent {
  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;
  screenWidth: number = window.innerWidth;

  cmsUrl = environment.CMS_URL;
  constructor(private router: Router) {
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  }

  navigateToHypothekenrechner(): void {
    this.router.navigate(['/hypothekenrechner']);
  }
}
