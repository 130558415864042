export const environment = {
    name: "lib",
    settings: {
        production: true,
        USE_GOOGLE_ANALYTICS: false,
        LOG_LEVEL: "WARNING",
        BASE_URL: "https://dev.vgr.neo.swiss",
        TARIFJAHR: 2022
    }
};
