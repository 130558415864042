import {Component, Input, OnInit} from "@angular/core";
import {NeoInputBase} from "./neo-input.abstract.component";

@Component({
    selector: "neo-input-checkbox",
    templateUrl: "./neo-input-checkbox.component.html",
    styleUrls: ["./neo-input-checkbox.component.scss"]
})
export class NeoInputCheckboxComponent extends NeoInputBase<Boolean> implements OnInit {
    @Input()
    public labelPosition = "before";

    constructor() {
        super(Boolean);
    }

    ngOnInit() {
    }

    public neoIsValid(): boolean {
        return true;
    }

    public neoValueChanged(event: Event) {
        this.neoValueEmitter.emit(this.neoValue);
        this.neoValueIsValid.emit(this.neoIsValid());
    }
}
