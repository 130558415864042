import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { TitleSectionComponent } from '../title-section/title-section.component';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [CommonModule, TitleSectionComponent, NgbAccordionModule],
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  @Input('content') content: any;

  faqs:any = [];
  faqLabel: string = '';
  faqTitle: string = '';
  faqDescription: string = '';
  env = environment.CMS_URL;

  ngOnInit(): void {
    this.faqLabel = this.content.label;
    this.faqTitle = this.content.title;
    this.faqDescription =  this.content.description;
    this.faqs = this.content.faqs;

  }
}
