<div [ngClass]="customCssClass" class="{{elementAsLink === true ? 'neo-isquare neo-isquare-link' : 'neo-isquare'}} neo-isquare{{getColor()}}"
     (click)="openLink($event)">
      <div class="neo-isquare-center" (mouseover)="changeImg=true" (mouseout)="changeImg=false">
        <div class="neo-isquare-tiny neo-isquare-wrapper">
            <div *ngIf="icon" class="neo-isquare-wrapper-img">
                <img *ngIf="!changeImg" [src]="icon" [attr.width]="width" [attr.height]="height" class="neo-icon" alt="{{alt}}">
                <img *ngIf="changeImg"  [src]="iconHover" [attr.width]="width" [attr.height]="height"  class="neo-icon" alt="{{alt}}" />
            </div>
        </div>
        <div *ngIf="description" class="neo-isquare-wrapper-txt">
            <p>{{description}}</p>
        </div>  
       </div>
</div>