import gql from "graphql-tag";

export const HYPOTHEKENRECHNER_QUERY = gql`
query hypothekenrechner {
	hypothekenrechner{
    data {
      id
      attributes {
        firstTwoBanners{
          firstBannerDescription{de en fr it}
          secondBannerDescription{de en fr it}
        }
        rightTopChart{
          topTitle {de en fr it}
          bottomTitle {de en fr it}
        }
        rightBottomChart{
          firstTitle {de en fr it}
          secondTitle {de en fr it}
          thirdTitle {de en fr it}
        }
        leftText{
          introTitle{de en fr it}
          introContent{de en fr it}
          backgroundImg{ data {
            attributes {
              url
            }
          }}
          backgroundImgAlt{de en fr it}
        }
        firstSlider{
          sliderTitle{de en fr it}
          dialogInfoTitle{de en fr it}
          dialogInfoDescription{de en fr it}
        }
        secondSlider{
          sliderTitle{de en fr it}
          dialogInfoTitle{de en fr it}
          dialogInfoDescription{de en fr it}
        }
        thirdSlider{
          sliderTitle{de en fr it}
          dialogInfoTitle{de en fr it}
          dialogInfoDescription{de en fr it}
        }
        fourthSlider{
          sliderTitle{de en fr it}
          dialogInfoTitle{de en fr it}
          dialogInfoDescription{de en fr it}
        }
        result{
          zinsen{de en fr it}
          amortisation{de en fr it}
          unterhaltnebenkosten{de en fr it}
          hypothek{de en fr it}
          kostenmonatlich{de en fr it}
        }
        seo{
          title{de en fr it}
          description{de en fr it}
          keywords{de en fr it}
        }
      }
    }
  }
}
`;


// tools{
//   colorSchema
//   toolsLink{ de en fr it }
//   description{de en fr it}
//   title{de en fr it}
// }
// homePageText{
//   Tools{
//     headline{de en fr it}
//     textContent{de en fr it}
//   }
// }

