import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperDirective } from '../../../lib/swiper.directive';

@Component({
  selector: 'app-team-section',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [FormsModule, CommonModule, SwiperDirective],
  templateUrl: './team-section.component.html',
  styleUrl: './team-section.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class TeamSectionComponent implements OnInit {
  cmsUrl = environment.CMS_URL;
  @Output() viewInitialized: EventEmitter<void> = new EventEmitter<void>();

  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;

  @ViewChild('swiper', { static: true }) swiperContainer: any;
  activeIndex: any = 0;

  public config: SwiperOptions = {}

  constructor() { }

  ngOnInit(): void {
    this.config = {
      navigation: {
        prevEl: '.button-prev',
        nextEl: '.button-next'
      },

      slidesPerView: 'auto',
      spaceBetween: 50,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
          grid: {
            rows: 1,
            fill: 'row'
          }
        },
        650: {
          slidesPerView: 2,
          spaceBetween: 20,
          grid: {
            rows: 2,
            fill: 'row'
          }
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 30,
          grid: {
            rows: 2,
            fill: 'row'
          }
        }
      },
      injectStyles: [
        `
        `,
      ],
      on: { slideChange: index => { this.activeIndex = index.snapIndex; console.log('index', this.activeIndex) }, }
    };

  }
  ngAfterViewInit(): void {
    this.viewInitialized.emit();
  }
}