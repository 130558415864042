import {Injectable} from "@angular/core";
import {Suchparameter} from "../models/suchparameter";
import {Persondaten} from "../models/persondaten";
import {StaticDataProvider} from "./static-data-provider";
import {NeoCommonsUiConstants} from "../models/neo-ui.constants";

@Injectable()
export class SuchmaskeProvider {

    public static neoYearMinValue = 1900;
    public static neoYearMaxValue = new Date().getFullYear();
    public suchmaskeDaten: Suchparameter;

    public static isJahrgangValide(jahrgang: number): boolean {
        return jahrgang >= SuchmaskeProvider.neoYearMinValue && jahrgang <= SuchmaskeProvider.neoYearMaxValue;
    }

    public static getAgeForYear(year: number): number {
        return (new Date().getFullYear()) - year;
    }

    public static getAltersklasseForYear(year: number): number {
        const alter = SuchmaskeProvider.getAgeForYear(year);
        if (alter >= 0 && alter <= 18) {
            return 0;
        } else if (alter >= 19 && alter <= 25) {
            return 1;
        } else if (alter >= 26) {
            return 2;
        } else {
            return -1;
        }
    }

    public static getAccidentCoverageForYear(year): boolean {
        const alter = SuchmaskeProvider.getAgeForYear(year);
        return (alter >= 0 && alter <= 15);
    }

    public static getAltersklasse(person: Persondaten): number {
        if (person && person.jahrgang !== undefined) {
            const year = SuchmaskeProvider.getAltersklasseForYear(person.jahrgang);
            if (year >= 0) {
                return year;
            } else {
                throw new Error(`Jahrgang ${person.jahrgang} wird nicht unterstützt!`);
            }
        }
    }

    public static calcNrPersons(data: Suchparameter): Array<number> {
        const nrPersons = [];
        nrPersons.push(0);
        if (data && data.weitereVersichertePersonen) {
            for (let i = 0; i < data.weitereVersichertePersonen.length; i++) {
                nrPersons.push((i + 1));
            }
        }
        return nrPersons;
    }

    public static obtainPersonLabelsAndValues(source: Suchparameter, provider: StaticDataProvider, prefixToUse: string,
                                              personNumbers: number[], out: (
                                                  outLabels: string[], outValues: string[]) => void): void {
        const labels: string[] = [];
        const values: string[] = [];
        for (let _i = 0; _i < personNumbers.length; _i++) {
            const person = source.alleVersichertePersonen[_i];
            const pPrefix = provider.getTranslationTexts().neoVgrPersonenEingabeTitle;
            const pGenderBirthYear = SuchmaskeProvider.getTranslatedPersonBirthdayGenderShortened(provider,
                person.jahrgang, person.geschlecht);
            const personFmt = `${pPrefix} ${_i + 1} ${pGenderBirthYear}`;
            labels.push(personFmt);
            values.push(`${prefixToUse}_${_i}`);
        }
        out(labels, values);
    }


    public static getTranslatedGenderShortcutString(staticData: StaticDataProvider, fullGender: string): string {
        switch (fullGender) {
            case NeoCommonsUiConstants.GENDER_MALE: {
                return staticData.getTranslationTexts().neoVgrCommonShortcutMale;
            }
            case NeoCommonsUiConstants.GENDER_FEMALE: {
                return staticData.getTranslationTexts().neoVgrCommonShortcutFemale;
            }
            case NeoCommonsUiConstants.GENDER_UNBORN: {
                    return staticData.getTranslationTexts().neoVgrCommonShortcutUnborn;
            }
            default: {
                return "?";
            }
        }
    }

    public static getTranslatedPersonBirthdayGenderShortened(provider: StaticDataProvider, birthYear: number, gender: string): string {
        const pGenderShort = SuchmaskeProvider.getTranslatedGenderShortcutString(provider, gender);
        let retVal = `(${pGenderShort})`;
        if (gender !== NeoCommonsUiConstants.GENDER_UNBORN) {
            retVal = `${retVal} ${birthYear}`;
        }
        return retVal;
    }

    public updateSearchParameter(data: Suchparameter) {
        if (this.suchmaskeDaten === undefined ) {
            this.suchmaskeDaten = data;
        } else {
            this.suchmaskeDaten.tarifJahr = data.tarifJahr;
            this.suchmaskeDaten.nurKVG = data.nurKVG;
            this.suchmaskeDaten.alleVersichertePersonen = data.alleVersichertePersonen;
            this.suchmaskeDaten.region = data.region;
            this.suchmaskeDaten.tariftypen = data.tariftypen;
        }
    }
}
