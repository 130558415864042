import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
// import { ContactFormTranslation, log, NeoContactFormComponent,  NeoDialogComponent, NeoInputCompleterItem, NeoRegion } from '@neo/commons-ui';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ContactForm } from '../model/ContactForm';
import { VsrData } from '../model/VsrData';
import { LocalTranslateService } from '../providers/local-translate.service';
import { UrlProvider } from '../providers/url-provider';
import { SeoService } from '../static/seo.service';
import  VSR_DATA from "./query/vsr.js"
import { NeoDialogComponent } from '@neo/commons-ui';
import { NeoRegion } from '@neo/commons-ui';
import { ContactFormTranslation } from '@neo/commons-ui';
import { NeoInputCompleterItem } from '@neo/commons-ui';
import { NeoContactFormComponent } from '@neo/commons-ui';
@Component({
  selector: 'app-vsr-contact-form',
  templateUrl: './vsr-contact-form.component.html',
  styleUrls: ['./vsr-contact-form.component.scss']
})
export class VsrContactFormComponent implements OnInit {
  formData: ContactFormTranslation;
  submitInvalid = false;
  public regions: NeoInputCompleterItem[];
  public contactData = new ContactForm();
  vsrData: VsrData;
  @ViewChild(NeoContactFormComponent)
    contactForm: NeoContactFormComponent;
  vsrText: any = [];
  leftText: any = [];
  selectedLanguage = 'de';

  public images: [{alt: string;img: string}] | any =[];
  cmsUrl = environment.CMS_URL;
  private subscriptions: Subscription[] = [];
  constructor(
    private urlProvider: UrlProvider,
    public translate: TranslateService,
    public localTranslateService: LocalTranslateService,
    private http: HttpClient,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private seo: SeoService) {
    this.loadRegions().then((res) => {
      this.regions = res;
    });
   }
  ngOnInit(): void {

    this.getText();
    this.route.params.subscribe((param: {data: string}) => {
      if(param.data) {
      this.urlProvider.decompressData(param.data).then((vsrData: VsrData) => {
        this.vsrData = vsrData;
      }).catch(err => {this.router.navigate(['/']);});
    }
    });
    this.formData = new ContactFormTranslation();
    this.translateForm();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateForm();
  });
  this.selectedLanguage = this.translate.currentLang;
  this.subscriptions.push(this.translate.onLangChange.subscribe(res => {
      if(this.selectedLanguage !== res.lang){
          this.selectedLanguage = res.lang;
          this.getText();
       }
   }));
  }
  private translateForm() {
    const tempData = new ContactFormTranslation();
    for (const i in tempData) {
        if (tempData.hasOwnProperty(i)) {
            this.formData[i] = this.doTranslate(tempData[i]);
        }
    }
}
private doTranslate(key: string): string {
  let result = "UNKNOWN";
  this.translate.get(key, {value: "world"}).subscribe((res: string) => {
          result = res;
      }
  );
  return result;
}
public dataChanged() {
  if (this.submitInvalid) {
      this.contactForm.showError(true);
      if (this.checkInputComponentsValid()) {
          this.contactForm.showError(false);
      } else {
          this.contactForm.showError(true);
      }
  }
}
private checkInputComponentsValid() {
  const cf = this.contactForm;
  return cf.inputFirstName.neoIsValid() &&
      cf.inputLastName.neoIsValid() &&
      cf.inputPhone.neoIsValid() &&
      cf.inputEmail.neoIsValid() &&
      cf.inputRegion.neoIsValid();
}
sendMessage() {
  const cf = this.contactForm;
  if (cf.neoIsValid()) {
      cf.showError(false);
      const contactData: ContactForm = cf.getContactData();
      contactData.url = this.urlProvider.getFullUrl();
      contactData.contactKind = "neo_vsr_contactform";
      contactData.data = [this.vsrData];
      const actionUrl = `${environment.VGR_API}/vsr`;
    this.http.post(actionUrl, contactData, {observe: 'response'}).subscribe((res) => {
      if(res && res.status === 200) {
       this.router.navigate(['/message-success']);
      } else {
        this.openSendMessageErrorDialog();
      }
    }, err => this.openSendMessageErrorDialog());
  } else {
      cf.showError(true);
  }
}
private openSendMessageErrorDialog() {
  this.dialog.open(NeoDialogComponent, {
      maxWidth: "100%",
      minWidth: "20%",
      panelClass: "neo-vvg-dialog",
      data: {
          "title": this.localTranslateService.getTranslationTexts().neoVsrContactModalErrTitel,
          "text": this.localTranslateService.getTranslationTexts().neoVsrContactModalErrText
      }
  });
}
private loadRegions(): Promise<any> {
  this.regions = [];
  return new Promise((resolve, reject) => {
      const actionUrl = `${environment.VGR_API}/regionen`;
      this.http.get<any>(actionUrl).toPromise()
          .then(res => { // Success
                  let counter = 0;
                  res.forEach(v => {
                      const region = NeoRegion.fromRegion(v);
                      region.key = counter.toString();
                      this.regions.push(region);
                      counter++;
                  });
                  resolve(this.regions);
              },
              err => { // Error
                  reject(err);
              }
          );
  });
}
getText(){
  this.subscriptions.push(this.apollo
  .watchQuery({
    query:  VSR_DATA
  })
  .valueChanges.subscribe((res:any) => {
    this.vsrText = res.data.vsr.data.attributes;
    //left text
    this.leftText = res.data.vsr.data.attributes.VsrLeftText;
    this.images = res.data.selectGender.data.attributes.images.map(x => {
      return {
      alt: x.alt[this.selectedLanguage],
      img: `${this.cmsUrl}${x.img.data.attributes.url}`
      }
  });
    //seo
    this.seo.setTitle(this.vsrText.Seo.title[this.selectedLanguage]);
    this.updateMetaTags(this.vsrText.Seo.description[this.selectedLanguage],this.vsrText.Seo.keywords[this.selectedLanguage])
  })
  )
}
//vsr-seo
updateMetaTags(description: string, keywords: string): void {
  this.seo.updateTags(
      [
          {
              name: 'description', content: description
          },
          {
              name: 'keywords', content: keywords
          }
      ]
  );
}
ngOnDestroy(): void {
  this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
}
}
