import { Component, Input, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { VsrData } from '../model/VsrData';
import { LocalTranslateService } from '../providers/local-translate.service';
import { VorsorgerechnerClass } from './VorsorgerechnerClass';

@Component({
  selector: 'app-vsr-selected-data',
  templateUrl: './vsr-selected-data.component.html',
  styleUrls: ['./vsr-selected-data.component.scss']
})
export class VsrSelectedDataComponent implements OnInit {
  @Input()
  vsrData: VsrData;
  translations;
  showPersonData = false;
  showPartnerData = false;
  showChildData = false;
  constructor(private translate: TranslateService, public localTranslationClass: LocalTranslateService) {
    this.translations = new VorsorgerechnerClass();
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => this.translateStuff());

  }

  translateStuff() {
    const temp = new VorsorgerechnerClass();
    for (const i in temp) {
      if (temp.hasOwnProperty(i)) {
        this.translations[i] = this.Dotranslate(temp[i]);
      }
    }
    this.localTranslationClass.setTranslations(this.translations);
  }

  Dotranslate(key: string): string {
    let result = 'UNKNOWN';
    this.translate.get(key, {value: 'world'}).subscribe((res: string) => {result = res; });
    return result;
  }

}
