import {LeistungFilter} from "../models/leistung-filter";

export class LeistungFilterJson {
    key: string;
    wertung: number;
    constructor(data?: LeistungFilter) {
        if (data) {
            this.key = data.key;
            this.wertung = data.wertung;
        }
    }
}


