import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import { NeoInputBase } from "./neo-input.abstract.component";

@Component({
    selector: "neo-input-integer",
    templateUrl: "neo-input-integer.component.html",
    styleUrls: ["neo-input-integer.component.css"],
    encapsulation: ViewEncapsulation.None

})
export class NeoInputIntegerComponent extends NeoInputBase<Number> implements OnInit {
    @Input()
    public neoMinValue?: number;
    @Input()
    public neoMaxValue?: number;
    @Input()
    public disabled = false;
    constructor() {
        super(Number);
    }

    ngOnInit() {
    }

    public neoValueChange(value: Number) {
        this.neoValueEmitter.emit(value);
        this.showErrorCss = !this.neoIsValid();
        this.neoValueIsValid.emit(this.neoIsValid());
    }

    public neoIsValid(): boolean {
        if (!this.neoValue || this.neoValue.toString().length === 0) {
            if (this.neoRequired) {
                return false;
            }
            return true;
        } else {
            const value = Number.parseInt(this.neoValue.toString());
            if (this.neoMinValue && this.neoMaxValue) {
                return value >= this.neoMinValue && value <= this.neoMaxValue;
            } else if (this.neoMinValue && !this.neoMaxValue) {
                return value >= this.neoMinValue;
            } else if (!this.neoMinValue && this.neoMaxValue) {
                return value <= this.neoMaxValue;
            }
            return true;
        }
    }
}
