import { Component, Input, OnInit } from '@angular/core';
import { NeoInputBase } from "./neo-input.abstract.component";

@Component({
  selector: "neo-select-option",
  templateUrl: "./neo-select-option.component.html",
  styleUrls: ["./neo-select-option.component.scss"]
})
export class NeoSelectOptionComponent extends  NeoInputBase<String> implements OnInit {
  selected = "Default";

  @Input()
  public neoSelectOptions: string[];

  @Input()
  public neoSelectValues: string[];
  
  constructor() {
    super(String);
}

ngOnInit() {
}

public neoValueChange(value: string) {
    this.neoValueEmitter.emit(value);
    this.showErrorCss = !this.neoIsValid();
    this.neoValueIsValid.emit(this.neoIsValid());
}

public neoIsValid(): boolean {
    if (!this.neoValue || this.neoValue.toString().length === 0) {
        if (this.neoRequired) {
            return false;
        }
        return true;
    }
    return true;
}
}
