import {Router} from "@angular/router";
import {Injector} from "@angular/core";
import {
    compress,
    compressToBase64,
    compressToEncodedURIComponent,
    compressToUTF16,
    decompress,
    decompressFromBase64,
    decompressFromEncodedURIComponent,
    decompressFromUTF16
} from "lz-string";
import {log} from "./neo.logger";
import {NeoErrorProvider} from "../provider/neo-error-provider";

export class NeoComponent {
    protected router: Router = null;
    protected errorProvider: NeoErrorProvider = null;

    constructor(injector: Injector, public errorPageRoutingOnError = true) {

        this.router = injector.get(Router);
        this.errorProvider = injector.get(NeoErrorProvider);

        this.errorProvider.onError.subscribe({
            next: (event: string) => {
                log.error(`Received error-message #${event}`);
                if (this.errorPageRoutingOnError) {
                    this.router.navigate(["error"]);
                }
            }
        });
    }

    public static compressObject(obj: any): string {
        return NeoComponent.compress(JSON.stringify(obj));
    }

    public static decompressObject(s: string): any {
        return JSON.parse(NeoComponent.decompress(s));
    }

    public static compress(s: string): string {
        return compressToEncodedURIComponent(s);
    }

    public static decompress(s: string): string {
        return decompressFromEncodedURIComponent(s);
    }

    public static formatMoney(c: any, showCurrency = true): string {
        return (showCurrency ? "CHF " : "") + c.toFixed(2);
    }

}
