export class ContactFormTranslation {
    required = "KONTAKTFORMULAR.TITEL.REQUIRED";
    titleText = "KONTAKTFORMULAR.TITEL.TEXT";
    titleTitle = "KONTAKTFORMULAR.TITEL.TITEL";
    salutation = "KONTAKTFORMULAR.ANREDE.TITLE";
    salutationMale = "KONTAKTFORMULAR.ANREDE.OPTION.HERR";
    salutationFemale = "KONTAKTFORMULAR.ANREDE.OPTION.FRAU";
    birthday = "KONTAKTFORMULAR.GEBURTSDATUM";
    supervisor = "KONTAKTFORMULAR.VORGESETZTER";
    address = "KONTAKTFORMULAR.ADDRESS";
    name = "KONTAKTFORMULAR.VORNAME";
    surname = "KONTAKTFORMULAR.NAME";
    street = "KONTAKTFORMULAR.ADRESSE.STRASSE";
    zip = "KONTAKTFORMULAR.ADRESSE.PLZ";
    city = "KONTAKTFORMULAR.ADRESSE.ORT";
    number = "KONTAKTFORMULAR.ADRESSE.NUMMER";
    region = "KONTAKTFORMULAR.ADRESSE.REGION";
    mail = "KONTAKTFORMULAR.MAIL";
    telefon = "KONTAKTFORMULAR.TEL";
    language = "KONTAKTFORMULAR.SPRACHE.LABEL";
    languageDe = "KONTAKTFORMULAR.SPRACHE.OPTION.DE";
    languageIt = "KONTAKTFORMULAR.SPRACHE.OPTION.IT";
    languageFr = "KONTAKTFORMULAR.SPRACHE.OPTION.FR";
    languageEn = "KONTAKTFORMULAR.SPRACHE.OPTION.EN";
    interest = "KONTAKTFORMULAR.INTERESSE.LABEL";
    interestMotor = "KONTAKTFORMULAR.INTERESSE.OPTION.MOTOR";
    interestLife = "KONTAKTFORMULAR.INTERESSE.OPTION.LEBEN";
    interestHousehold = "KONTAKTFORMULAR.INTERESSE.OPTION.HAUSHALT";
    interestHealth = "KONTAKTFORMULAR.INTERESSE.OPTION.KRANKEN";
    interestOther = "KONTAKTFORMULAR.INTERESSE.OPTION.OTHER";
    concern = "KONTAKTFORMULAR.CONCERN.TITLE";
    concernText = "KONTAKTFORMULAR.CONCERN.TEXT";
    news = "KONTAKTFORMULAR.NEWS.TITLE";
    attachmentTitle = "KONTAKTFORMULAR.ATTACHMENT.TITLE";
    attachmentDescription1 = "KONTAKTFORMULAR.ATTACHMENT.DESCRIPTION";
    attachmentDescription2 = "KONTAKTFORMULAR.ATTACHMENT.DESCRIPTION2";
    attachmentDescription3 = "KONTAKTFORMULAR.ATTACHMENT.DESCRIPTION3";
    attachmentError = "KONTAKTFORMULAR.ATTACHMENT.ERROR";
    contact = "KONTAKTFORMULAR.KONTAKT.LABEL";
    employer = "KONTAKTFORMULAR.KONTAKT.EMPLOYER";
    billingAddress = "KONTAKTFORMULAR.KONTAKT.BILLING_ADDRESS";
    supervisorEmailAddress = "KONTAKTFORMULAR.KONTAKT.SUPERVISOR_EMAIL_ADDRESS";
    supervisorPhone = "KONTAKTFORMULAR.KONTAKT.SUPERVISOR_PHONE";
    experience = "KONTAKTFORMULAR.KONTAKT.EXPERIENCE";
    contactMail = "KONTAKTFORMULAR.KONTAKT.OPTION.MAIL";
    contactWhatsapp = "KONTAKTFORMULAR.KONTAKT.OPTION.WA";
    contactTelefon = "KONTAKTFORMULAR.KONTAKT.OPTION.TEL";
    contactTime = "KONTAKTFORMULAR.ZEIT";
    privacyPolicy = "KONTAKTFORMULAR.PRIVACY.TEXT";
    send = "KONTAKTFORMULAR.BUTTON.ABSENDEN";
    errorRequiredMissing = "KONTAKTFORMULAR.ERROR.REQUIRED";
    yes = "KONTAKTFORMULAR.KONTAKT.YES";
    no = "KONTAKTFORMULAR.KONTAKT.NO";
}
