import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { animate, AUTO_STYLE, style, transition, trigger } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';

const grow = trigger("grow", [
  transition(':enter', [
    style({width: "0px", padding: "0px", 'padding-right': '0px'}),
    animate('200ms ease-in-out', style({width: AUTO_STYLE, padding: '0.5em',  'padding-right': '1em'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in-out', style({width: 0, padding: "0px"}))
  ]),
])

const TABLET_MAX_SCREEN_WIDTH = 768;

@Component({
  selector: 'neo-consult-appointment-tool',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './consult-appointment-tool.component.html',
  styleUrl: './consult-appointment-tool.component.scss',
  animations: [grow]
})
export class ConsultAppointmentToolComponent {
  @ViewChild('tool') tool: ElementRef;

  consultAppointmentToolUrl = environment.CONSULT_APPOINTMENT_TOOL_URL;

  extendedFlag = false;
  innerWidth: number;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(private renderer: Renderer2) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.extendedFlag === true && !this.tool.nativeElement.contains(e.target)) {
        this.extendedFlag = false;
      }
    })
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  setExtendedFlag(value: boolean) {
    if (this.innerWidth > TABLET_MAX_SCREEN_WIDTH)
      this.extendedFlag = value;
  }

  extendedFlagEvent() {
    if (this.extendedFlag === false) {
      this.extendedFlag = true;
    } else {
      this.navigateToConsultAppointmentTool();
    }
  }

  navigateToConsultAppointmentTool() {
    window.location.href = this.consultAppointmentToolUrl;
  }

}
