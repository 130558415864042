import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { TitleSectionComponent } from '../title-section/title-section.component';

@Component({
  selector: 'app-franchise',
  standalone: true,
  imports: [CommonModule, TitleSectionComponent],
  templateUrl: './franchise.component.html',
  styleUrl: './franchise.component.scss'
})
export class FranchiseComponent implements OnInit {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;

  header: any = '';
  description: string = '';
  cardContent: any;

  //@Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.selectedLanguage = res.lang;
    })

    this.header = this.content.franchiseHeader
    this.description = this.content.description;

    this.cardContent = this.content.franchiseCard.map((card: any) => {
      return {
        title: card?.title,
        description: card?.description,
        image: card?.cardIcon?.data?.attributes?.url,
        bgColor: card?.bgColor
      }
    })
  }
}
