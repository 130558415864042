import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { ButtonComponent } from '../button/button.component';
import { TitleSectionComponent } from '../title-section/title-section.component';
import { CountingService } from '../../services/counting.service';
import { CommonModule } from '@angular/common';
import { NumberFormatPipe } from '../../pipes/number-format.pipe';

@Component({
  selector: 'app-discover-section',
  standalone: true,
  templateUrl: './discover-section.component.html',
  styleUrls: ['./discover-section.component.scss'],
  imports: [CommonModule, ButtonComponent, TitleSectionComponent, NumberFormatPipe]
})
export class DiscoverSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  isMobile: boolean = false;
  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;
  counters: { [key: string]: number } = {};
  countingStarted: boolean = false;

  @ViewChild('discoverSection', { static: false }) discoverSectionRef: ElementRef;

  private observer: IntersectionObserver | undefined;

  constructor(private translate: TranslateService, private router: Router, private countingService: CountingService) { }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.selectedLanguage = res.lang;
    });
  }

  ngAfterViewInit(): void {
    this.setupIntersectionObserver();
  }

  ngOnDestroy(): void {
    this.disconnectObserver();
  }

  private setupIntersectionObserver(): void {
    if (this.discoverSectionRef && this.discoverSectionRef.nativeElement) {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (!this.countingStarted) {
              this.startCounting();
              this.countingStarted = true;
            }
          } else {
            this.countingStarted = false;
          }
        });
      });

      this.observer.observe(this.discoverSectionRef.nativeElement);
    }
  }

  private disconnectObserver(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 880;
  }

  private startCounting() {
    this.content.discoverNumber.forEach((item: any) => {
      if (item.label === 'Gründungsjahr') {
        this.counters[item.label] = 2024;
        this.countingService.countSmooth(2024, parseInt(item.number), 3000, 1000).subscribe(value => {
          this.counters[item.label] = value;
        });
      } else if (item.label === 'Kunden') {
        const customers = parseInt(item.number.replace("'", ""));
        const step = 10000;
        const duration = 100;
        let currentCount = 0;
        const delay = 500;
        this.counters[item.label] = 0;
        setTimeout(() => {
          const interval = setInterval(() => {
            currentCount += step;
            if (currentCount > customers) {
              currentCount = customers;
              clearInterval(interval);
            }
            this.counters[item.label] = currentCount;
          }, duration);
        }, delay);
      } else {
        this.counters[item.label] = 0;
        this.countingService.countSmooth(0, parseInt(item.number), 3000, 1000).subscribe(value => {
          this.counters[item.label] = value;
        });
      }
    });
  }

  private resetCounters() {
    for (const label in this.counters) {
      if (Object.prototype.hasOwnProperty.call(this.counters, label)) {
        this.counters[label] = 0;
      }
    }
  }
}