
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VorsorgerechnerComponent } from './vorsorgerechner.component';


import {TranslateModule, TranslatePipe, TranslateService} from '@ngx-translate/core';


import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';

import {CommonsUiModule} from '@neo/commons-ui';
import { LocalTranslateService } from '../providers/local-translate.service';
import { UrlProvider } from '../providers/url-provider';
import { VsrContactFormComponent } from './vsr-contact-form.component';
import { RouterModule } from '@angular/router';
import { VsrSelectedDataComponent } from './vsr-selected-data.component';
import { ThousandPipe } from '../pipes/thousand.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MarkdownModule } from 'ngx-markdown';
// import {SwiperModule} from "ngx-swiper-wrapper";

@NgModule({
  declarations: [VorsorgerechnerComponent, VsrContactFormComponent, VsrSelectedDataComponent, ThousandPipe],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    // SwiperModule,
    MatRadioModule,
    MatCardModule,
    MatButtonModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule ,
    MatSliderModule,
    MatSelectModule,
    MatDialogModule,
    RouterModule,
    CommonsUiModule,
    MatSlideToggleModule,
    MarkdownModule.forRoot()
  ],
  exports: [
    VorsorgerechnerComponent,
  ],
  providers: [
    TranslateService, LocalTranslateService, UrlProvider
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VorsorgerechnerModule { }
