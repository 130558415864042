import { Component, HostListener, OnInit } from '@angular/core';
import { Observable, Subscription, catchError, map, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CmsService } from '../../services/cms.service';
import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from '../../pipes/filter.pipe';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-standort',
  standalone: true,
  imports: [GoogleMapsModule, FormsModule, FilterPipe, TranslateModule, CommonModule],
  templateUrl: './standort.component.html',
  styleUrl: './standort.component.scss'
})
export class StandortComponent {
  apiLoaded: Observable<boolean>;
  private subscriptions: Subscription[] = [];
  public standortPlaces: any;
  public standortDetails: any;
  standortContent: any;
  seo: any;
  isScreenLarge = window.innerWidth > 880;


  public FindPlaces: any = [];
  markers: Array<any> = [];

  selectedLanguage = "de";
  cmsUrl = environment.CMS_URL;

  showDetails = false;
  selectedAgency: any  = null;

  options!: google.maps.MapOptions;

  searchAgency: any;
  constructor(
    httpClient: HttpClient,
    private cmsService: CmsService,
    private translate: TranslateService
    ) {
      this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      if (this.selectedLanguage !== res.lang) {
        this.selectedLanguage = res.lang;
      }
    })
    this.defaultOption();
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyD4oNTgLz_2S2Ca4maI_Y30iJXoyAJ1PMg', 'callback')
    .pipe(
      map((res) => {
        return true
      }),
      catchError(() => of(false)),
    );
    this.apiLoaded.subscribe()


  }


  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isScreenLarge = (event.target as Window).innerWidth > 880;
  }

  ngOnInit() {
    this.getAgencyData();
  }



  toggleDetails() {
    this.showDetails = !this.showDetails;
    if (!this.showDetails) {
      this.defaultOption();
    }
  }
  agencySearchClear(){
    this.searchAgency = '';
  }

  setSelectedAgencyDetails(agency: any): void {
    this.selectedAgency = this.FindPlaces.find((x: { PostalCode: any; }) => x.PostalCode === agency.PostalCode || x.PostalCode === agency.PostalCode);
    if (this.selectedAgency) {

      this.showDetails = true;

      if (agency.position) {
        this.options = {
          center: { lat: agency.position.lat, lng: agency.position.lng },
          zoom: 15
        }
      } else {
        this.options = {
          center: { lat: Number(agency.StandortDetails.PositionLatitude), lng: Number(agency.StandortDetails.PositionLongitude) },
          zoom: 15
        }
      }

    } else {
      this.showDetails = false;
    }
  }
  clearSelectedAgency(): void {
    this.selectedAgency = null;
  }
  getCmsContent(){
    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;
    this.cmsService.singlePageContent('standort', populateString).subscribe(res => {

      this.standortContent = res;
      this.standortPlaces = res;
      this.FindPlaces = res?.standortPlace;
      this.addMarkers();
      // this.seo.seoSetProps(this.standortContent?.seo);
    })
  }
  getAgencyData() {
    this.getCmsContent();
    this.subscriptions.push(
    );
  }
  addMarkers(){
    this.markers = this.FindPlaces.map((x: { StandortDetails: { PositionLatitude: any; PositionLongitude: any; }; PlaceName: any; PostalCode: any; }) => {
      return {
        position: {
          lat: Number(x.StandortDetails.PositionLatitude),
          lng: Number(x.StandortDetails.PositionLongitude)
        },
        title: x.PlaceName,
        PostalCode: x.PostalCode,
        options: {
          icon: {
            url: "assets/images/neo-pin2.svg",
            scaledSize: {
              h: undefined,
              height: 40,
              j: undefined,
              width: 40
            }
          }
        }
      }
    });
  }
  public trackByFn(index: any, item: { id: any; }) {
    if (!item) return null;
    return item.id;
  }

  defaultOption(): void {
    this.options = {
      fullscreenControl: false,
      center: { lat: 47.06985193430931, lng: 7.566308049889968 },
      zoom: 7,
      mapTypeControl: false,
      streetViewControl: false,
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#00254d"
            },
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#00254d"
            },
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#00254d"
            },
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#f83535"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#98acc3"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#4a6480"
            }
          ]
        }
      ]
    };
  }
}
