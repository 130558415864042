import { Component } from '@angular/core';
import { IntroductionComponent } from '../../components/introduction/introduction.component';
import { environment } from '../../../environments/environment';
import { CmsService } from '../../services/cms.service';
import { TranslateService } from '@ngx-translate/core';
import { DiscoverSectionComponent } from "../../components/discover-section/discover-section.component";
import { ServicesSectionComponent } from "../../components/services-section/services-section.component";
import { ReviewSectionComponent } from "../../components/review-section/review-section.component";
import { CalculatorSectionComponent } from "../../components/calculator-section/calculator-section.component";
import { LocationComponent } from "../../components/location/location.component";
import { BlogsComponent } from "../../components/blogs/blogs.component";
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [IntroductionComponent, DiscoverSectionComponent, ServicesSectionComponent, ReviewSectionComponent, CalculatorSectionComponent, LocationComponent, BlogsComponent]
})
export class HomeComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  content: any;
  introduction: any;
  aboutUS: any;
  services: any;
  review: any;
  calculator: any;

  constructor(private cmsService: CmsService, private translate: TranslateService,private seo: SeoService) { }

  ngOnInit(): void {
    this.preloadDataOnHomePage();
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
      this.selectedLanguage = res.lang;
      this.getCmsContent();
      // }
    })
  }

  preloadDataOnHomePage(){
    this.cmsService.data$.subscribe((res:any) => {
      this.content = res;
      this.introduction = res.introduction;
      this.aboutUS = res.discover;
      this.services = res.serviceSection;
      this.review = res.reviewSection;
      this.calculator = res.calculatorSection;
    })
  }

  getCmsContent() {
    
    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;
    this.cmsService.singlePageContent('home-page', populateString).subscribe((res: any) => {
      this.content = res;
      this.introduction = res.introduction;
      this.aboutUS = res.discover;
      this.services = res.serviceSection;
      this.review = res.reviewSection;
      this.calculator = res.calculatorSection;

      const seoData = res?.pluginSeo;
      this.seo.initializeSeo(seoData);
    });
  }
}
