import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
// import {SwiperComponent, SwiperConfigInterface} from "ngx-swiper-wrapper";
import {log} from "./neo.logger";

@Component({
    selector: "neo-swiper-icon",
    templateUrl: "neo-swiper-icon.component.html",
    styleUrls: ["neo-swiper-icon.component.scss"]
})
export class NeoSwiperIconComponent implements OnInit, AfterViewInit {
//     @ViewChild(SwiperComponent)
//     swiperVC: SwiperComponent;
//     @Input()
//     public swipeables: Array<NeoSwiperComponent> = [];
//     @Input()
//     public swipeableSpaceBetweenInPx = 0;
//     @Output()
//     public swipeableLinkClicked = new EventEmitter<string>();
//     @Output()
//     public swipedCallback = new EventEmitter<number>();

//     public neoSwiperConfig: SwiperConfigInterface = {
//         observer: true,
//         observeParents: true, 
//         direction: "horizontal",
//         slidesPerView:"auto",
//         slidesPerGroup: 3,
//         initialSlide: 0,
//         spaceBetween: 0,
//         watchOverflow: true,
//         setWrapperSize: true,
//         centeredSlides: false,
//         slideToClickedSlide: false,
//         freeModeSticky: true,
//         keyboard: true,
//         mousewheel: true,
//         loop: false,
//         navigation: false,
//         scrollbar: false,
//         pagination: {
//             el: ".swiper-pagination",
//             type: "bullets"
//         },
//         breakpoints: {
//             1024: {
//               slidesPerView: 3,
//               slidesPerGroup: 3,
//               spaceBetween: 15,
//               pagination: {
//                 type: "bullets",
//                 clickable: true,
//               },
//             },
//             542: {
//               slidesPerView: 3,
//               slidesPerGroup: 3,
//               spaceBetween: 10,
//               pagination: {
//                 type: "bullets",
//                 clickable: true,
//               },
//             },
//             375: {
//               slidesPerView: 1,
//               slidesPerGroup: 1,
//               spaceBetween: 15,
//               pagination: {
//                 type: "bullets",
//                 clickable: true,
//               },
//             },
//             320: {
//               slidesPerView: 1,
//               slidesPerGroup: 1,
//               spaceBetween: 10,
//               pagination: {
//                 type: "bullets",
//                 clickable: true,
//               },
//             }
//         }
//     };

    ngOnInit() {
//         log.debug(`${this.constructor.name}: ngOnInit`);
    }

    ngAfterViewInit(): void {
//         log.debug(`${this.constructor.name}: ngAfterViewInit`);

//         // apply custom configuration to swiper
//         this.swiperVC.config.spaceBetween = this.swipeableSpaceBetweenInPx;
    }

//     indexChange(event: number) {
//         log.debug(`${this.constructor.name}: indexChange=`, event);
//         this.swipedCallback.emit(event);
//     }

//     linkClicked(event: string) {
//         log.debug(`${this.constructor.name}: linkClicked=`, event);
//         this.swipeableLinkClicked.emit(event);
    // }
}

export interface NeoSwiperComponent {
    colorSchema: string;
    iconPath: {url:string;}
    iconPathHover: {url: string};
    description: string;
    linkTarget: string;
    alt: string;
}