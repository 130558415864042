<button mat-icon-button mat-dialog-close class="neo-vvg-dialog-close">
  <mat-icon mat-dialog-close>close</mat-icon>
</button>
<div class="neo-dialog-title">
  <i class="material-icons md-light">info</i>
  <span class="neo-dialog-title-text neo-vgr-vvg-infobox-titel">
      {{data.INFOTITLE}}
  </span>
</div>
<div class="neo-dialog-text neo-vgr-vvg-infobox-text">
  {{data.INFO}}
</div>
