@if(content?.introduction && content.introduction.showSection){
<app-introduction [content]="content?.introduction" [useCenter]="false"></app-introduction>
}

@if(content?.header){
<div class="header">
    <app-title-section [content]="content?.header" [useCenter]="true"></app-title-section>
</div>
}

@if(content?.description ){
<div class="description-container">
    <div class="description font-calibre-18">
        <div class="description-content">
            <div class="text">

                @for(description of content.description; track description){
                <span [innerHTML]="description.children[0].text"></span>
                }
            </div>
        </div>
    </div>
</div>
}

<app-contact-section [useCenter]="false"></app-contact-section>