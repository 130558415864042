@if (!onlyRouter) {
<div class="wrapper">
  <header class="app-header">
    <app-header [currentPage]="currentPage" [currentFragment]="currentFragment"></app-header>
  </header>
  <main class="page-body">
    @if( isVsrContactPage) {
      <app-introduction *ngIf="introduction && introduction.showSection" [content]="introduction" [useCenter]="false"></app-introduction>
    }
    <router-outlet></router-outlet>
  </main>
  <footer class="app-footer">
    <app-footer></app-footer>
  </footer>
</div>
} @else {
  <router-outlet />
}

<neo-consult-appointment-tool />
