import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NeoInputCompleterItem } from "../models/neo-input-completer.item";
import { Agency } from "../models/agency-find";

@Component({
  selector: "app-agency-find",
  templateUrl: "./agency-find.component.html",
  styleUrls: ["./agency-find.component.scss"],
})
export class AgencyFindComponent {

  @Input() agencyButtonText: string;
  @Input() customCssClass = "";
  @Input() agencyFindTitle: string;
  @Input() selectedAgency: Agency;
  @Input() inputCompleterAgencyFindItems: NeoInputCompleterItem[];

  @Output() selectedAgencyChanged: EventEmitter<any> = new EventEmitter<any>();

  inputButtonEmitter() {
    this.selectedAgencyChanged.emit(this.selectedAgency);
  }

  public inputCompleterEmitter(value: Agency) {
    if (value !== undefined) {
      this.selectedAgency = value;
      this.selectedAgencyChanged.emit(this.selectedAgency);
    }
  }
}
