import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: 'app-services-section',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './services-section.component.html',
  styleUrl: './services-section.component.scss'
})
export class ServicesSectionComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  color:any;
  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;
  // @Input('color') color: any;

  constructor(private translate: TranslateService, private router: Router) {
  }
  
  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
        this.selectedLanguage = res.lang;
      // }
    })

  }
}
