<div class="neo-vgr-input-margin ">{{neoLabel}}</div>
       <input type="text"
       ngClass="{{ showErrorCss ? 'neo-message-error-input':'' }}"
       class="neo-vgr-input-field neo-vgr-input-margin neo-vgr-input-field-text neo-helper-user-select-auto neo-input-focus"
       placeholder="{{neoPlaceholder}}"
       neoNumbersOnly="true"
       [(ngModel)]="neoValue"
       (ngModelChange)="neoValueChange($event)"
       matTooltip="{{neoErrorTooltipText}}"
       matTooltipClass="neo-message-error-tooltip"
       [matTooltipDisabled]=[!showErrorCss]
       [matTooltipPosition]="'above'"
       [disabled]="disabled"
/>
