<div class="vision-section col-12" id="management">
    <div class="vision-title-container col-12 col-sm-12 col-md-12">
        <div class="vision-title-content col-12" >
            <div class="vision-tagline">
                <div class="vision-tagline-text">{{content?.VisionTitle}} | {{content?.MissionTitle}} | {{content?.ValuesTitle}}</div>
            </div>
            <div class="vision-title-content-title">{{content?.SectionTitle}}</div>
        </div>
        <div class="vision-title-container-paragraph col-12 col-md-7">{{content?.SectionParagraph}}</div>
    </div>
    <div class="services-container col-sm-12 col-md-12">
        <div class="row justify-content-evenly cards-container">
        <div class="vision-service col-12 col-md-3">
            <div class="vision-service-title">
                <div class="vision-check-circle">
                    <img class="vision-vector-first" src="../../../assets/icons/general-check-circle.svg" alt="General Check Circle">
                </div>
                <div class="vision-service-title-text">{{content?.VisionTitle}}</div>
            </div>
            <div class="vision-service-content-first">
                <div class="vision-content-text-grey">{{content?.VisionFirstParagraph}}</div>
                <div class="vision-content-text-navy">{{content?.VisionParagraphTitle}}</div>
                <div class="vision-content-text-grey">{{content?.VisionSecondParagraph}}</div>
            </div>
        </div>
        <div class="vision-service col-12 col-md-3">
            <div class="vision-service-title">
                <div class="vision-check-circle">
                    <img class="vision-vector-first" src="../../../assets/icons/general-check-circle.svg" alt="General Check Circle">
                </div>
                <div class="vision-service-title-text">{{content?.MissionTitle}}</div>
            </div>
            <div class="vision-service-content-second">
                <div class="vision-content-text-navy">{{content?.MissionParagraphTitle}}</div>
                <div class="vision-content-text-grey ">{{content?.MissionListTitle}}</div>
                <div class="vision-service-content-list">
                    <div class="vision-list-container">
                        <div class="vision-list-bullet"></div>
                        <div class="vision-list-text">{{content?.MissionListElement1}}</div>
                    </div>
                    <div class="vision-list-container">
                        <div class="vision-list-bullet"></div>
                        <div class="vision-list-text">{{content?.MissionListElement2}}</div>
                    </div>
                    <div class="vision-list-container">
                        <div class="vision-list-bullet"></div>
                        <div class="vision-list-text">{{content?.MissionListElement3}}</div>
                    </div>
                </div>
                <div class="vision-content-text-grey">{{content?.MissionFirstParagraph}}</div>
            </div>
        </div>
        <div class="vision-service col-12 col-md-3">
            <div class="vision-service-title">
                <div class="vision-check-circle">
                    <img class="vision-vector-first" src="../../../assets/icons/general-check-circle.svg" alt="General Check Circle">
                </div>
                <div class="vision-service-title-text">{{content?.ValuesTitle}}</div>
            </div>
            <div class="vision-service-content-third">
                <div class="vision-content-text-navy ">{{content?.ValuesParagraphTitle}}</div>
                <div class="vision-content-text-grey ">{{content?.ValuesFirstParagraph}}</div>
                <div class="vision-content-text-grey ">{{content?.ValuesSecondParagraph}}</div>
                <div class="vision-content-text-grey ">{{content?.ValuesListTitle}}</div>
                <div class="vision-service-content-list">
                    <div class="vision-list-container">
                        <div class="vision-list-bullet"></div>
                        <div class="vision-list-text">{{content?.ValuesListElement1}}</div>
                    </div>
                    <div class="vision-list-container">
                        <div class="vision-list-bullet"></div>
                        <div class="vision-list-text">{{content?.ValuesListElement2}}</div>
                    </div>
                    <div class="vision-list-container">
                        <div class="vision-list-bullet"></div>
                        <div class="vision-list-text">{{content?.ValuesListElement3}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>