@if(content){
    <div class="mt-5 pt-5 mb-3">
        <app-title-section [content]="content?.headerSection" [useCenter]="true"></app-title-section>
    </div>
    <div class="row mx-1 mb-md-5 pb-md-5">
        @for(card of cardContent.slice(0,3); track card){
        <div class="col-12 col-sm-6 col-lg-4 mt-4">
            <div class="card h-100 pointer bg-hover" (click)="openModal(card?.id, card?.cardBg)"
                [ngStyle]="{'--border-color': card?.cardBg, '--background-color': card?.cardBg}">
                <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                    <img class="mt-3 img-size" [src]=" card?.image"/>
                    <h5 class="h-card my-3">{{card?.title}}</h5>
                    <p class="p-card">{{card?.description}}</p>
                </div>
            </div>
        </div>
        }
        @for(card of cardContent.slice(3, 5); track card){
        <div class="col-12 col-sm-6 col-lg-6 mt-4">
            <div class="card h-100 pointer bg-hover" (click)="openModal(card?.id, card?.cardBg)"
                [ngStyle]="{'--border-color': card?.cardBg, '--background-color': card?.cardBg}">
                <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                    <img class="img-size" [src]=" card?.image"/>
                    <h5 class="h-card my-3">{{card?.title}}</h5>
                    <p class="p-card">{{card?.description}}</p>
                </div>
            </div>
        </div>
        }
    </div>
}
