export namespace NeoCommonsUiConstants {
    export const GENDER_MALE            = "Mann";
    export const GENDER_FEMALE          = "Frau";
    export const GENDER_UNBORN          = "Vorgeburt";
    export const ANSWER_YES             = "yes";
    export const ANSWER_NO              = "no";
    export const FORM_OF_ADDRESS_MALE   = "Herr";
    export const FORM_OF_ADDRESS_FEMALE = "Frau";
    export const LANGUAGE_GERMAN        = "de";
    export const LANGUAGE_ENGLISH       = "en";
    export const LANGUAGE_FRENCH        = "fr";
    export const LANGUAGE_ITALIAN       = "it";
    export const CONTACT_MAIL           = "contact_mail";
    export const CONTACT_WHATSAPP       = "contact_wa";
    export const CONTACT_PHONE          = "contact_phone";
    export const SORTING_PREMIUM        = "premium";
    export const SORTING_COVERAGE       = "coverage";
}
