<div class="team-section">
    <div class="team-section-header">
        <app-title-section [content]="content?.benefitSectionHeader"></app-title-section>
        <div class="team-navigation-buttons">
            <div class="team-navigation-icon" class="button-prev">
                <div class="team-navigation-icon prev-next" [class.index-zero]="activeIndex == 0"
                    [style.--btnBgColor]="content?.benefitSectionHeader?.underLineColor">
                    <img class="arrow" [src]="back" alt="Left arrow">
                </div>
            </div>
            <div class="team-navigation-icon" class="button-next">
                <div class="team-navigation-icon prev-next"
                    [class.index-zero]="activeIndex == content?.benefitCard?.length-1"
                    [style.--btnBgColor]="content?.benefitSectionHeader?.underLineColor">
                    <img class="arrow" [src]="forward" alt="Right arrow">
                </div>
            </div>
        </div>
    </div>
    <div class="w-100 h-70">
        <swiper-container swiperElement [config]="config" init="false" class="swiper">
            @for (card of content?.benefitCard; track $index) {
            <swiper-slide>
                <div class="card border-0 mt-4 custom-border h-480"
                    [ngStyle]="{'background-color' : ''+ card?.bgColor +''}">
                    <div class="card-body text-start p-4">
                        <h1 class="f-3" [ngStyle]="{'color' : ''+ card?.numberColor +''}">0{{$index + 1}}</h1>
                        <h5 class="h-benefit mt-5 mb-4" [ngStyle]="{'color' : ''+ card?.titleColor +''}">
                            {{card?.cardTitle}}</h5>
                        @for(benefit of card?.benefitsList; track $index){
                        <div class="d-flex justify-content-start align-items-baseline">
                            <div class="mx-2 vision-list-bullet" [style.--bullet-color]="card?.numberColor"></div>
                            <p class="p-benefit" [ngStyle]="{'color' : ''+ card?.textColor +''}">{{benefit?.benefit}}
                            </p>
                        </div>
                        }
                    </div>
                </div>
            </swiper-slide>
            }
        </swiper-container>
    </div>
</div>