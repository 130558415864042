import { HypothekenrechnerClass } from "./HypothekenrechnerClass";
import { DialogOverviewExampleDialogComponent } from "./dialog-overview-example-dialog";
import { MatDialog } from "@angular/material/dialog";
import { environment } from '../environments/environment';
import {HYPOTHEKENRECHNER_QUERY } from '../apollo/queries/hypothekenrechner';
import { Subscription } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from './services/seo.service';
// import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { Apollo } from "apollo-angular";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Chart } from "chart.js";
import Swiper from "swiper";

@Component({
  selector: "neo-hypothekenrechner-lib",
  templateUrl: "./neo-hypothekenrechner-lib.component.html",
  styleUrls: ["./neo-hypothekenrechner-lib.component.scss"],
})
export class HypothekenrechnerLibraryComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private apollo: Apollo,
    private seo: SeoService
  ) {
    Chart.defaults.global.defaultFontColor = "#00254D"; // 'black';
    Chart.defaults.global.defaultFontFamily = "UniviaPro-Bold"; // 'UniviaPro-Book'; // 'arial black';
  }

  @Input() data1: HypothekenrechnerClass;

  @ViewChild("LendingHorizontalChartBelehnung")
  LendingHorizontalChartBelehnungRef: ElementRef;

  @ViewChild("LendingHorizontalChartTragbarkeit")
  LendingHorizontalChartTragbarkeitRef: ElementRef;

  @ViewChild("BaseChart")
  BaseChartRef: ElementRef;

  public shouldShow = false;
  max = 100000;

  Kaufpreis = 500000;
  KaufpreisMin = 300000;
  KaufpreisMax = 5000000;
  KaufpreisTmp = this.Kaufpreis.toString();

  einkommen = 100000;
  EinkommenMin = 50000;
  EinkommenMax = 300000;
  einkommenTmp = this.einkommen.toString();

  eigenmittel = 100000;
  EigenmittelMin = 50000;
  EigenmittelMax = 1000000;
  EigenmittelTmp = this.eigenmittel.toString();

  zinsen = 1.2;
  zinsenNew = 0;
  ZinsenMin = 0.1;
  ZinsenMax = 5;
  ZinsenTmp = this.zinsen.toString();

  Zinsen = 0;
  Amortisation = 0;
  UnterhaltNebenkosten = 0;
  KostenMonatlich = 0;
  Hypothek = 0;
  Belehnung = 0;
  Tragbarkeit = 0;

  Clickable = true;

  ClickableKaufpreis = false;
  Clickableeinkommen = false;
  Clickableeigenmittel = false;
  Clickablezinsen = false;

  status = false;

  subscriptions: Subscription[] = [];
  selectedLanguage = '';
  leftText:any=[];
  firstSlider:any=[];
  secondSlider:any=[];
  thirdSlider:any=[];
  fourthSlider:any=[];
  calculateButton:any=[];
  resetButton:any=[];
  firstTwoBanners:any=[];
  rightBottomChart:any=[];
  rightTopChart:any=[];
  seoHypothekenrechner:any=[];
  resultsText:any=[];
  cmsUrl = environment.settings.CMS_URL;

  // required to destroy chart on data change
  lendingChartBelehnung: any;
  lendingChartTragbarkeit: any;
  baseChart: any;

  //swiper
  toolsComponent: any;
  toolsTitle: any;
  filteredToolsComponent : Array<any> = [];

ngOnInit() {
  this.selectedLanguage = this.translate.currentLang;
  this.getHypothekenrechnerData();
  this.subscriptions.push(
  this.translate.onLangChange.subscribe(res => {
      if(this.selectedLanguage !== res.lang){
          this.selectedLanguage = res.lang;
          this.getHypothekenrechnerData();
       }
   }));

  this.KaufpreisThousand();
  this.einkommenThousand();
  this.EigenmittelThousand();
  this.ZinsenThousand();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => { s.unsubscribe()});

  }

  onKaufpreisInputChange(value: number) {
    this.CheckIfNegative();
    this.KaufpreisTmp = value.toString();
    this.KaufpreisThousand();
    this.CalculateOnChange();
  }

  onKaufpreisChange() {
    this.CheckIfNegative();
    this.CheckRequired();
    this.KaufpreisThousand();
    this.CalculateOnChange();
  }

  onEinkommenInputChange(value: number) {
    this.CheckIfNegative();
    this.einkommenTmp = value.toString();
    this.einkommenThousand();
    this.CalculateOnChange();
  }

  onEinkommenChange() {
    this.CheckIfNegative();
    this.CheckRequired();
    this.einkommenThousand();
    this.CalculateOnChange();
  }

  onEigenmittelInputChange(value: number) {
    this.CheckIfNegative();
    this.EigenmittelTmp = value.toString();
    this.EigenmittelThousand();
    this.CalculateOnChange();
  }

  onEigenmittelChange() {
    this.CheckIfNegative();
    this.CheckRequired();
    this.EigenmittelThousand();
    this.CalculateOnChange();
  }

  onZinsenInputChange(value: number) {
    this.CheckIfNegative();

    this.ZinsenTmp = value.toString();
    this.ZinsenThousand();
    this.CalculateOnChange()
  }

  onZinsenChange() {
    this.CheckIfNegative();
    this.CheckRequired();
    this.ZinsenThousand();
    this.CalculateOnChange();
  }

  Calculate() {
    this.status = true;
    // Zinsen: ((k-E)*Z)/12
    // Amortisation: ((k-E) - (k * 0.6666)) /15 /12
    // Unterholt-Nebenkosten: (k * 0.01) / 12
    // Kosten Monatlich: Zinsen + Amortisation + Unterholt-Nebenkosten
    // Hypothek: (k-E)
    // Belehnung: (1-(E/k)) * 100
    // Trogbikuit: ((Hypothek * 0.05) / 12 ) + Unterholt-Nebenkosten
    this.zinsenNew = this.zinsen / 100;

    this.Zinsen = ((this.Kaufpreis - this.eigenmittel) * this.zinsenNew) / 12;
    this.Amortisation =
      (this.Kaufpreis - this.eigenmittel - this.Kaufpreis * 0.6666) / 15 / 12;

      // Amortisation cannot be less than 0
      this.Amortisation < 0 ? this.Amortisation = 0 : this.Amortisation;

      this.UnterhaltNebenkosten = (this.Kaufpreis * 0.01) / 12;
      this.KostenMonatlich =
      this.Zinsen + this.Amortisation + this.UnterhaltNebenkosten;
      this.Hypothek = this.Kaufpreis - this.eigenmittel;
      this.Belehnung = (1 - this.eigenmittel / this.Kaufpreis) * 100;
    // OLD //this.Tragbarkeit = ((this.Hypothek * 0.05) / 12) + this.UnterhaltNebenkosten;
    // NEW //(hypothek * 0.05)/12 + (Unterhaltskosten + Amortisation)/(Einkommen/12)
    // this.Tragbarkeit = (this.Hypothek * 0.05) / 12 + (this.UnterhaltNebenkosten + this.Amortisation) / (this.einkommen / 12 );
    this.Tragbarkeit =
      (((this.Hypothek * 0.05) / 12 +
        this.UnterhaltNebenkosten +
        this.Amortisation) /
        (this.einkommen / 12)) *
      100;
    //(((J5*0.05)/12)+H5+G5)/(B5/12)
    this.lendingHorizontalBarChartBelehnung();
    this.lendingHorizontalBarChartTragbarkeit();
    this.baseDoughnutChart();
  }

  getHypothekenrechnerData(){
    this.subscriptions.push(
      this.apollo
     .watchQuery({
       query: HYPOTHEKENRECHNER_QUERY,
     })
     .valueChanges.subscribe((result: any) => {
      let data = result.data.hypothekenrechner.data.attributes;
    //left side sticky
    this.leftText = data.leftText;

    //sliders
    this.firstSlider = data.firstSlider;
    this.secondSlider = data.secondSlider;
    this.thirdSlider = data.thirdSlider;
    this.fourthSlider = data.fourthSlider;
    // buttons
    this.calculateButton = data.calculateButton;
    this.resetButton = data.resetButton;
    // results text
    this.resultsText = data.result;
    // first two banners
     this.firstTwoBanners = data.firstTwoBanners;
    // seo
    this.seoHypothekenrechner = data.seo;
    this.seo.setTitle(this.seoHypothekenrechner.title[this.selectedLanguage]);
    this.updateMetaTags(this.seoHypothekenrechner.description[this.selectedLanguage], this.seoHypothekenrechner.keywords[this.selectedLanguage])
    // Charts
    this.rightTopChart = data.rightTopChart
    this.rightBottomChart = data.rightBottomChart
    
    //swiper
    // this.toolsComponent = result.data.tools;
    // this.toolsTitle = result.data.homePageText.Tools;

    this.nulllendingHorizontalBarChartBelehnung();
    this.nulllendingHorizontalBarChartTragbarkeit();
    this.nullbaseDoughnutChart();
    
    this.filter();
     }));
  }

  baseDoughnutChart() {
    if (this.baseChart) {
      this.baseChart.destroy();
    }
    this.baseChart = new Chart(this.BaseChartRef.nativeElement, {
      type: "doughnut",
      data: {
        labels: [this.rightBottomChart.firstTitle[this.selectedLanguage],
        this.rightBottomChart.secondTitle[this.selectedLanguage],
        this.rightBottomChart.thirdTitle[this.selectedLanguage]
          ],
        datasets: [
          {
            borderColor: "#FFF",
            data: [
              this.Zinsen.toFixed(2),
              this.Amortisation.toFixed(2),
              this.UnterhaltNebenkosten.toFixed(2),
            ],
            backgroundColor: ["#69C0BB", "#BCD056", "#00254D"],
          },
        ],
      },
      plugins: [ {
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            var ctx = chart.chart.ctx;
  
            // Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || "Arial";
            var txt = centerConfig.text;
            var color = centerConfig.color || "#000";
            var maxFontSize = centerConfig.maxFontSize || 75;
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated =
              (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            ctx.font = "30px " + fontStyle;
  
            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
  
            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2;
  
            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
            var minFontSize = centerConfig.minFontSize;
            var wrapText = false;
  
            if (minFontSize === undefined) {
              minFontSize = 20;
            }
  
            if (minFontSize && fontSizeToUse < minFontSize) {
              fontSizeToUse = minFontSize;
              wrapText = true;
            }
  
            // Set font settings to draw it correctly.
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;
  
            if (!wrapText) {
              ctx.fillText(txt, centerX, centerY);
              return;
            }
          }
        },
      }
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          Axes: [{
            type: 'linear'
          }]
        },
        elements: {
          center: {
            text: this.toThousandString(this.KostenMonatlich.toFixed(2)),
            color: "#00254d", // Default is #000000
            fontStyle: "UniviaPro-Bold", // Default is Arial
            lineHeight: 20, // Default is 25 (in px), used for when text wraps
          },
        },
      },
    });
  }

  nullbaseDoughnutChart() {
    if (this.baseChart) {
      this.baseChart.destroy();
    }
    this.baseChart = new Chart(this.BaseChartRef.nativeElement, {
      type: "doughnut",
      data: {
        labels: [this.rightBottomChart.firstTitle[this.selectedLanguage], 
        this.rightBottomChart.secondTitle[this.selectedLanguage],
        this.rightBottomChart.thirdTitle[this.selectedLanguage]
      ],
        datasets: [
          {
            borderColor: "#FFF",
            data: [
              this.Zinsen = 0.1,
              this.Amortisation = 0.1,
              this.UnterhaltNebenkosten = 0.1,
            ],
            backgroundColor: ["#69C0BB", "#69C0BB", "#69C0BB"],
          },
        ],
      },
      plugins: [ {
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            var ctx = chart.chart.ctx;
  
            // Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || "Arial";
            var txt = centerConfig.text;
            var color = centerConfig.color || "#000";
            var maxFontSize = centerConfig.maxFontSize || 75;
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated =
              (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            ctx.font = "30px " + fontStyle;
  
            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;
  
            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2;
  
            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
            var minFontSize = centerConfig.minFontSize;
            var wrapText = false;
  
            if (minFontSize === undefined) {
              minFontSize = 20;
            }
  
            if (minFontSize && fontSizeToUse < minFontSize) {
              fontSizeToUse = minFontSize;
              wrapText = true;
            }
  
            // Set font settings to draw it correctly.
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;
  
            if (!wrapText) {
              ctx.fillText(txt, centerX, centerY);
              return;
            }
          }
        },
      }
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          Axes: [{
            type: 'linear'
          }]
        },
        elements: {
          center: {
            text: this.toThousandString(this.KostenMonatlich.toFixed(0)),
            color: "#00254d", // Default is #000000
            fontStyle: "UniviaPro-Bold", // Default is Arial
            lineHeight: 20, // Default is 25 (in px), used for when text wraps
          },
        },
      },
    });
  }

  //Belehnung
  lendingHorizontalBarChartBelehnung() {
    var color =
      this.Belehnung < 15
        ? "#f5a623"
        : this.Belehnung >= 15 && this.Belehnung < 80
        ? "#8EFF06"
        : "#DA3209";
    var horizontalBarChartOptionsBelehnung = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        fontSize: 18,
        text: this.rightTopChart.topTitle[this.selectedLanguage],
        fontColor: "#fff"
        
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 10,
              max: 100,
              stepSize: 20,
              // Include a percent sign in the ticks
              callback: function (value) {
                return value + "%";
              },
              fontColor: "#fff"
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
            },
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],

      },
    };
    if (this.lendingChartBelehnung) {
      this.lendingChartBelehnung.destroy();
    }
    this.lendingChartBelehnung = new Chart(
      this.LendingHorizontalChartBelehnungRef.nativeElement,
      {
        type: "horizontalBar",
        scaleFontColor: "#FFFFFF",
        data: {
          labels: [this.rightTopChart.topTitle[this.selectedLanguage]],
          datasets: [
            {
              data: [this.Belehnung.toFixed(2)],
              backgroundColor: color,
              borderColor: color
            },
          ],
        },
        options: horizontalBarChartOptionsBelehnung,
      }
    );
  };
  nulllendingHorizontalBarChartBelehnung() {
    var color =
      this.Belehnung < 15
        ? "#f5a623"
        : this.Belehnung >= 15 && this.Belehnung < 80
        ? "#8EFF06"
        : "#DA3209";
    var horizontalBarChartOptionsBelehnung = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        fontSize: 18,
        text: this.rightTopChart.topTitle[this.selectedLanguage],
        fontColor: "#fff"
        
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 10,
              max: 100,
              stepSize: 20,
              // Include a percent sign in the ticks
              callback: function (value) {
                return value + "%";
              },
              fontColor: "#fff"
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
            },
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],

      },
    };
    if (this.lendingChartBelehnung) {
      this.lendingChartBelehnung.destroy();
    }
    this.lendingChartBelehnung = new Chart(
      this.LendingHorizontalChartBelehnungRef.nativeElement,
      {
        type: "horizontalBar",
        scaleFontColor: "#FFFFFF",
        data: {
          labels: [this.rightTopChart.topTitle[this.selectedLanguage]],
          datasets: [
            {
              data: [this.Belehnung = 100],
              backgroundColor: color,
              borderColor: color
            },
          ],
        },
        options: horizontalBarChartOptionsBelehnung,
      }
    );
  };

  //Tragbarkeit
  lendingHorizontalBarChartTragbarkeit() {
    var color =
      this.Tragbarkeit < 33
        ? "#8eff06"
        : this.Tragbarkeit >= 34 ? "#da3209" : "#da3209";

    var horizontalBarChartOptionsTragbarkeit = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        fontSize: 18,
        text: this.rightTopChart.bottomTitle[this.selectedLanguage],
        fontColor: "#fff"
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 10,
              max: 100,
              stepSize: 20,
              // Include a percent sign in the ticks
              callback: function (value) {
                return value + "%";
              },
              fontColor: "#fff"
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
            },
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };
    if (this.lendingChartTragbarkeit) {
      this.lendingChartTragbarkeit.destroy();
    }
    this.lendingChartTragbarkeit = new Chart(
      this.LendingHorizontalChartTragbarkeitRef.nativeElement,
      {
        type: "horizontalBar",
        data: {
          labels: [this.rightTopChart.bottomTitle[this.selectedLanguage]],
          datasets: [
            {
              data: [this.Tragbarkeit.toFixed(2)],
              backgroundColor: color,
              borderColor: color
            },
          ],
        },
        options: horizontalBarChartOptionsTragbarkeit,
      }
    );
  };
  nulllendingHorizontalBarChartTragbarkeit() {
    var color =
      this.Tragbarkeit < 33
        ? "#8eff06"
        : this.Tragbarkeit >= 34 ? "#da3209" : "#da3209";

    var horizontalBarChartOptionsTragbarkeit = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        fontSize: 18,
        text: this.rightTopChart.bottomTitle[this.selectedLanguage],
        fontColor: "#fff"
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: 10,
              max: 100,
              stepSize: 20,
              // Include a percent sign in the ticks
              callback: function (value) {
                return value + "%";
              },
              fontColor: "#fff"
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: false,
            },
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };
    if (this.lendingChartTragbarkeit) {
      this.lendingChartTragbarkeit.destroy();
    }
    this.lendingChartTragbarkeit = new Chart(
      this.LendingHorizontalChartTragbarkeitRef.nativeElement,
      {
        type: "horizontalBar",
        data: {
          labels: [this.rightTopChart.bottomTitle[this.selectedLanguage]],
          datasets: [
            {
              data: [this.Tragbarkeit = 100],
              backgroundColor: color,
              borderColor: color
            },
          ],
        },
        options: horizontalBarChartOptionsTragbarkeit,
      }
    );
  };

  CalculateOnChange() {
    if (this.zinsen !== 0) {
      this.Calculate();
    }
  }

  CheckIfNegative() {
    if (Number(this.Kaufpreis) < 0) {
      this.KaufpreisTmp = "0";
    }
    if (Number(this.einkommen) < 0) {
      this.einkommenTmp = "0";
    }
    if (Number(this.eigenmittel) < 0) {
      this.EigenmittelTmp = "0";
    }
    if (Number(this.zinsen) < 0) {
      this.ZinsenTmp = "0";
    }
  }

  CheckRequired() {
    if (this.Kaufpreis == null) {
      this.ClickableKaufpreis = true;
      this.Clickable = false;
      return;
    } else {
      this.ClickableKaufpreis = false;
      this.Clickable = true;
    }
    if (this.einkommen == null) {
      this.Clickableeinkommen = true;
      this.Clickable = false;
      return;
    } else {
      this.Clickableeinkommen = false;
      this.Clickable = true;
    }
    if (this.eigenmittel == null) {
      this.Clickableeigenmittel = true;
      this.Clickable = false;
      return;
    } else {
      this.Clickableeigenmittel = false;
      this.Clickable = true;
    }
    if (this.zinsen == null) {
      this.Clickablezinsen = true;
      this.Clickable = false;
      return;
    } else {
      this.Clickablezinsen = false;
      this.Clickable = true;
    }
  }

  //swiper 
  // public neoSwiperCareerConfig: SwiperConfigInterface = {
  //   observer: false,
  //   observeParents: false,
  //   direction: "horizontal",
  //   watchOverflow: false,
  //   setWrapperSize: false,
  //   centeredSlides: false,
  //   slideToClickedSlide: true,
  //   freeModeSticky: true,
  //   keyboard: true,
  //   mousewheel: false,
  //   loop: false,
  //   navigation: false,
  //   scrollbar: false, 
  //   autoHeight: true,
  //   pagination: {
  //     el: ".swiper-pagination",
  //     type: "bullets",
  //     clickable: true
  //   },
    
    
    // if there are more then 3 in the future remove Media query from neo.component.bmi.scss 
    // This the code that sshould be removed
    // @media (min-width: 1204px)
    // {
    //   .swiper-el .s-wrapper .swiper-pagination{
    //     display: none;
    //   }
    // }
    
    // @media screen and (max-width: 959px) and (min-width: 842px) 
    // {
    //   .swiper-el .s-wrapper .swiper-pagination{
    //     display: none;
    //   }
    // }



  //   breakpoints: {
     
  //     1204: {
  //       slidesPerView: 3,
  //       slidesPerGroup: 3,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
      
  //     959: {
  //       slidesPerView: 2,
  //       slidesPerGroup: 2,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
  //     842: {
  //       slidesPerView: 3,
  //       slidesPerGroup: 3,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
  //     588: {
  //       slidesPerView: 2,
  //       slidesPerGroup: 2,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
  //     375: {
  //       slidesPerView: 1,
  //       slidesPerGroup: 1,
  //       spaceBetween: 15,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     },
  //     300: {
  //       slidesPerView: 1,
  //       slidesPerGroup: 1,
  //       spaceBetween: 10,
  //       pagination: {
  //         el: ".swiper-pagination",
  //         type: "bullets",
  //         clickable: true,
  //       },
  //     }
  //   }
  // };

  filter()
  { 
    // this.filteredToolsComponent = this.toolsComponent.filter(x => x.title.de != "Hypothekenrechner");
    
  }

  toThousandString(value: any): string {
  return value ? value.toString().replace(/`/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1`') : "0";
  }

  einkommenThousand() {
    this.einkommenTmp = this.einkommenTmp
      .toString()
      .replace(/`/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1`");
    this.einkommen = Number(this.einkommenTmp.replace(/`/g, ""));
    return this.einkommenTmp;
    //     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  KaufpreisThousand() {
    // The value of Eigenmittel must  be less than the value of Kaufpreis
    if(this.Kaufpreis <= this.eigenmittel) {
      this.eigenmittel = this.Kaufpreis - 1;
      this.EigenmittelTmp = this.eigenmittel.toString()
      .replace(/`/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1`");
    }
    this.KaufpreisTmp = this.KaufpreisTmp.toString()
      .replace(/`/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1`");
    this.Kaufpreis = Number(this.KaufpreisTmp.replace(/`/g, ""));
    return this.KaufpreisTmp;
    //     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  EigenmittelThousand() {
        // The value of Eigenmittel must  be less than the value of Kaufpreis
    if (this.eigenmittel >= this.Kaufpreis) {
      this.eigenmittel = this.Kaufpreis - 1;
      this.EigenmittelTmp = this.eigenmittel.toString()
      .replace(/`/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1`");
    } else {
      this.EigenmittelTmp = this.EigenmittelTmp.toString()
      .replace(/`/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1`");
    this.eigenmittel = Number(this.EigenmittelTmp.replace(/`/g, ""));
    return this.einkommenTmp;
    }

    //     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  ZinsenThousand() {
    this.ZinsenTmp = this.ZinsenTmp.toString()
      .replace(/`/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1`");
    this.zinsen = Number(this.ZinsenTmp.replace(/`/g, ""));
    return this.ZinsenTmp;
    //     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  openDialog(INFO, INFOTITLE): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialogComponent, {
      maxWidth: "100%",
      minWidth: "50%",
      panelClass: "neo-vvg-dialog",
      data: { INFO: INFO, INFOTITLE: INFOTITLE },
      // panelClass: 'my-class',
    });
    dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      dialogRef.close();
    });
  }

  Increase(type, value) {
    if (value === 500) {
      if (type === "Kaufpreis") {
        this.Kaufpreis = Number(
          this.CheckIfValueIsValid(type, this.Kaufpreis + value)
        );
        this.KaufpreisTmp = this.Kaufpreis.toString();
        this.KaufpreisThousand();
      }
      if (type === "Einkommen") {
        this.einkommen = Number(
          this.CheckIfValueIsValid(type, this.einkommen + value)
        );
        this.einkommenTmp = this.einkommen.toString();
        this.einkommenThousand();
      }
      if (type === "Eigenmittel") {
        this.eigenmittel = Number(
          this.CheckIfValueIsValid(type, this.eigenmittel + value)
        );
        this.EigenmittelTmp = this.eigenmittel.toString();
        this.EigenmittelThousand();
      }
    } else {
      if (type === "Zinsen") {
        this.zinsen = Number(
          this.CheckIfValueIsValid(
            type,
            (parseFloat(this.zinsen.toString()) + parseFloat(value)).toFixed(1)
          )
        );
        this.ZinsenTmp = this.zinsen.toString();
        this.ZinsenThousand();
      }
    }
    this.CheckIfNegative();
    this.CheckRequired();
    this.CalculateOnChange();
  }

  Decrease(type, value) {
    if (value === 500) {
      if (type === "Kaufpreis") {
        this.Kaufpreis = Number(
          this.CheckIfValueIsValid(type, this.Kaufpreis - value)
        );
        this.KaufpreisTmp = this.Kaufpreis.toString();
        this.KaufpreisThousand();
      }
      if (type === "Einkommen") {
        this.einkommen = Number(
          this.CheckIfValueIsValid(type, this.einkommen - value)
        );
        this.einkommenTmp = this.einkommen.toString();
        this.einkommenThousand();
      }
      if (type === "Eigenmittel") {
        this.eigenmittel = Number(
          this.CheckIfValueIsValid(type, this.eigenmittel - value)
        );
        this.EigenmittelTmp = this.eigenmittel.toString();
        this.EigenmittelThousand();
      }
    } else {
      if (type === "Zinsen") {
        this.zinsen = Number(
          this.CheckIfValueIsValid(
            type,
            (parseFloat(this.zinsen.toString()) - parseFloat(value)).toFixed(1)
          )
        );
        this.ZinsenTmp = this.zinsen.toString();
        this.ZinsenThousand();
      }
    }
    this.CheckIfNegative();
    this.CheckRequired();
    this.CalculateOnChange();
  }

  CheckIfValueIsValid(type, value): Number {
    if (type === "Kaufpreis") {
      return value < 300000 ? 300000 : value < 5000000 ? value : 5000000;
    }
    if (type === "Einkommen") {
      return value < 50000 ? 50000 : value < 300000 ? value : 300000;
    }
    if (type === "Eigenmittel") {
      if (value <= this.Kaufpreis) {
      return value < 50000 ? 50000 : value < 1000000 ? value : 1000000;
    } else {
      return this.Kaufpreis;
    }
    }
    if (type === "Zinsen") {
      return value < 0.1 ? 0.1 : value < 5 ? value : 5;
    }
    return 0;
  }

 

  clearInputs() {
    this.Kaufpreis = null;
    this.KaufpreisTmp = null;
    this.einkommen = null;
    this.einkommenTmp = null;
    this.eigenmittel = null;
    this.EigenmittelTmp = null;
    this.Calculate();
  }

  onReset(){
    this.status = false;
    this.Zinsen = 0;
    this.Amortisation = 0;
    this.UnterhaltNebenkosten = 0;
    this.KostenMonatlich = 0;
    this.Hypothek = 0;
    this.Belehnung = 0;
    this.Tragbarkeit = 0;
    this.nullbaseDoughnutChart();
    this.nulllendingHorizontalBarChartBelehnung();
    this.nulllendingHorizontalBarChartTragbarkeit();
  }

  updateMetaTags(description: string, keywords: string): void {
    this.seo.updateTags(
        [
            {
                name: 'description', content: description
            },
            {
                name: 'keywords', content: keywords
            }
        ]
    );
}
}
