<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

@if(introduction && introduction.showSection){
<app-introduction [content]="introduction" [useCenter]="false"></app-introduction>
}

@if(introduction){
<neo-hypothekenrechner-lib></neo-hypothekenrechner-lib>
}

@if(contactSection) {
<app-contact-section [content]="contactSection" [useCenter]="false" id="contact"></app-contact-section>
}