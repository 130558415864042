import {Injectable} from "@angular/core";

@Injectable()
export class NeoGoogleAnalyticsProvider {
    private readonly GA_ID = "UA-125267780-1";
    constructor() {
    }

    public sendEvent(action: string, label: string, cat = "site_event") {
        (window as any).gtag("event", action, {
            "event_category": cat,
            "event_label": label
        });
    }

    public sendPageHit(title: string, url: string) {
        (window as any).gtag("config", this.GA_ID, {
            "page_title" : title,
            "page_path": url
        });
    }
}
