import { NeoInputCompleterItem } from './neo-input-completer.item';
import {DoctorJson} from "../jsons/doctor.json";

export class Doctor implements NeoInputCompleterItem {
    public GPV: number;
    public HAV: number;
    public Name: string;
    public Strasse: string;
    public PLZ: number;
    public Ort: string;
    public Kanton: string;
    public Neu: number;
    public GPVName: string;
    public key: string;

    constructor(GPV?: number, HAV?: number, Name?: string, Strasse?: string, PLZ?: number, Ort?: string,
                Kanton?: string, Neu?: number, GPVName?: string) {
        this.GPV = GPV;
        this.HAV = HAV;
        this.Name = Name;
        this.Strasse = Strasse;
        this.PLZ = PLZ;
        this.Ort = Ort;
        this.Kanton = Kanton;
        this.Neu = Neu;
        this.GPVName = GPVName;
    }

    public static fromDoctor(v: Doctor) {
        return new Doctor(v.GPV, v.HAV, v.Name, v.Strasse, v.PLZ, v.Ort, v.Kanton, v.Neu, v.GPVName);
    }

    public static fromJson(value: DoctorJson): Doctor {
        const result = new Doctor();
        result.GPV = value.GPV;
        result.GPVName = value.GPVName;
        result.HAV = value.HAV;
        result.Kanton = value.Kanton;
        result.key = value.key;
        result.Name = value.Name;
        result.Ort = value.Ort;
        result.Strasse = value.Strasse;
        result.PLZ = value.PLZ;
        result.Neu = value.Neu;
        return result;
    }

    public toJson(): DoctorJson {
        const result = new DoctorJson();
        result.GPV = this.GPV;
        result.GPVName = this.GPVName;
        result.HAV = this.HAV;
        result.Kanton = this.Kanton;
        result.key = this.key;
        result.Name = this.Name;
        result.Neu = this.Neu;
        result.Ort = this.Ort;
        result.PLZ = this.PLZ;
        result.Strasse = this.Strasse;
        return result;
    }

    public get value(): string {
        return `${this.Name} in ${this.PLZ} ${this.Ort} (${this.Strasse})`;
    }

    public toString(): string {
        return this.value;
    }
}
