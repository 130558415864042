import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-history-section',
    standalone: true,
    templateUrl: './history-section.component.html',
    styleUrl: './history-section.component.scss',
    imports: [CommonModule]
})
export class HistorySectionComponent {
  @Output() viewInitialized: EventEmitter<void> = new EventEmitter<void>();
  
  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;
  @Input('marginBottom') marginBottom!: boolean;
  @Input('bgColor') bgColor!: boolean;
  
  screenWidth: number = window.innerWidth;

  cmsUrl = environment.CMS_URL;
  constructor() {
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  }

  ngAfterViewInit(): void {
    this.viewInitialized.emit();
  }
  
}
