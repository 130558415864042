<div class="bmi-section row" id="bmi">
    <div class="bmi-left-container col-12" [ngClass]="screenWidth >= 1500 ? 'col-md-7' : 'col-md-6'">
        <div class="bmi-left-information">
            <div class="bmi-information-title">
                {{content?.CalculatorSectionTitle}}</div>
            <button class="bmi-information-button" (click)="resetTallWeightAge()">
                <div class="bmi-information-button-text">
                    {{content?.CalculateAgainButton}}</div>
            </button>
        </div>
        <div class="bmi-left-range">
            <div class="bmi-gender-section">
                <label class="bmi-gender">
                    <input type="radio" name="gender" value="male" [(ngModel)]="isMaleSelected"
                        (change)="toggleGenderSelection(true)" style="display: none;">

                    <div class="bmi-gender-icon" [style.opacity]="isMaleSelected ? '1' : '0.4'">
                        <img src="../../../assets/icons/man-icon.svg" alt="Man" />
                    </div>
                    <div class="bmi-gender-name" [style.opacity]="isMaleSelected ? '1' : '0.4'">
                        {{content?.ManGenderIcon}}</div>
                </label>
                <label class="bmi-gender">
                    <input type="radio" name="gender" value="female" [(ngModel)]="isMaleSelected"
                        (change)="toggleGenderSelection(false)" style="display: none;">

                    <div class="bmi-gender-icon" [style.opacity]="!isMaleSelected ? '1' : '0.4'">
                        <img src="../../../assets/icons/woman-icon.svg" alt="Woman">
                    </div>
                    <div class="bmi-gender-name" [style.opacity]="!isMaleSelected ? '1' : '0.4'">
                        {{content?.WomanGenderIcon}}</div>
                </label>
            </div>
            <div class="bmi-range-section">
                <div class="bmi-range">
                    <div class="bmi-range-name"><span>{{content?.HeightSlider}}</span><span> *</span></div>
                    <div class="bmi-range-slider">
                        <input type="range" class="centimeter-slider" [min]="minCm" [max]="maxCm" [(ngModel)]="cm"
                            name="cm" [value]="cm" (input)="cmChanged(cm)" [style.background]="heightGradient()">
                        <output name="result" for="cm" [style.margin-left.%]="heightThumbPosition "
                            style="font-size: clamp(20px, 1.5vw, 26px); margin-top: 30px; max-width: 100%;">{{cm}}
                            {{content.Cm}}</output>
                    </div>
                </div>
                <div class="bmi-range">
                    <div class="bmi-range-name"><span>{{content?.WeightSlider}}</span><span> *</span>
                    </div>
                    <div class="bmi-range-slider">
                        <input type="range" class="centimeter-slider" [min]="minKg" [max]="maxKg" [(ngModel)]="kg"
                            (input)="kgChanged(kg)" [style.background]="weightGradient()">
                        <output name="result" for="cm" [style.margin-left.%]="weightThumbPosition "
                            style="font-size: clamp(20px, 1.5vw, 26px); margin-top: 30px; max-width: 100%;">{{kg}}
                            {{content.Kg}}</output>
                    </div>
                </div>
                <div class="bmi-range">
                    <div class="bmi-range-name"><span>{{content?.AgeSlider}}</span><span> *</span></div>
                    <div class="bmi-range-slider">
                        <input type="range" class="centimeter-slider" [min]="minAge" [max]="maxAge" [(ngModel)]="age"
                            (input)="ageChanged(age)" [style.background]="ageGradient()">
                        <output name="result" for="cm" [style.margin-left.%]="ageThumbPosition "
                            style="font-size: clamp(20px, 1.5vw, 26px); margin-top: 30px; max-width: 100%;">{{age}}
                            {{content.Age}}</output>
                    </div>
                </div>
            </div>
        </div>
        <div class="bmi-left-button-container">
            <div class="bmi-left-container-title">{{content?.SelectEverythingText}}</div>
            <button class="second-button"
                (click)="redirectTo(content?.CompareButtonRedirectLink, content?.CompareButtonFragment)">
                <div class="second-button-text">{{content?.CompareButton}}</div>
                <img src="../../../assets/icons/right-arrow-vector.svg" alt="Right arrow">
            </button>
        </div>
    </div>
    <div class="bmi-right-container col-12" [ngClass]="screenWidth >= 1500 ? 'col-md-5' : 'col-md-6'">
        <div class="bmi-human-card-container">
            <div class="bmi-output">
                <div class="bmi-output-text">
                    {{content?.YourBMIText}}</div>
                <div class="bmi-output-number">
                    {{BMI| number:'1.2-2'}}</div>
            </div>
            <div class="bmi-human-vector">
                <img [src]="isMaleSelected ? '../../../assets/images/man-body.png' : '../../../assets/images/woman-body.png'"
                    alt="Human body">
            </div>
            <div class="bmi-slider">
                <div class="neo-bmi-range-slider" [ngStyle]="isMaleSelected ? maleGradient : femaleGradient">
                    <input type="range" [disabled]="age==null || cm==null || kg==null" min="{{MinSlider}}"
                        max="{{MaxSlider}}" step="0.1" [(ngModel)]="BMI" value="{{BMI}}"
                        (change)="GetKg(BMI);calculateBMI();" class="neo-bmi-slider" id="myRange" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bmi-text-section row">
    <div class="col-12"
        [ngClass]="(screenWidth >= 1700 && !getHealthTextSecondCard(subdivisionID)) ? 'col-md-8' : (screenWidth >= 1700 && getHealthTextSecondCard(subdivisionID)) ? 'col-md-5' : 'col-md-12'">
        <div class="bmi-normal-container">
            <div class="bmi-normal-title">
                {{ getContentForSubdivisionID(subdivisionID)?.attributes?.BMIWeightTexts?.Title }}</div>
            <div class="bmi-normal-text">
                {{ getContentForSubdivisionID(subdivisionID)?.attributes?.BMIWeightTexts?.Content }}</div>
        </div>
    </div>
    <div class="col-12 bmi-weight-cards"
        [ngClass]="(screenWidth >= 1700 && !getHealthTextSecondCard(subdivisionID)) ? 'col-md-4' : (screenWidth >= 1700 && getHealthTextSecondCard(subdivisionID)) ? 'col-md-7' : 'col-md-12'">
        <div class="bmi-weight-cards-container">
            <div class="row"
                [ngClass]="(screenWidth <= 1700 && !getHealthTextSecondCard(subdivisionID)) ? 'justify-content-center' : ''">
                <div class="col-12" *ngIf="getHealthTextFirstCard(subdivisionID)"
                    [ngClass]="(screenWidth >= 1700 && !getHealthTextSecondCard(subdivisionID)) ? 'col-md-12' : 'col-md-6'">
                    <div class="bmi-motivation-container">
                        <div class="bmi-motivation-title">
                            {{ getHealthTextFirstCard(subdivisionID)?.Header }}</div>
                        <div class="bmi-motivation-text">
                            {{ getHealthTextFirstCard(subdivisionID)?.Content }}</div>
                    </div>
                </div>
                <div class="col-12 col-md-6" *ngIf="getHealthTextSecondCard(subdivisionID)">
                    <div class="bmi-motivation-container">
                        <div class="bmi-motivation-title">
                            {{ getHealthTextSecondCard(subdivisionID)?.Header }}</div>
                        <div class="bmi-motivation-text">
                            {{ getHealthTextSecondCard(subdivisionID)?.Content }} <br />{{content?.MoreFitnessTips}}
                        </div>
                        <button class="bmi-tips-btn">
                            <div class="bmi-tips-btn-text">
                                {{content?.MoreFitnesTipsButton}}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>