<div class="neo-x-entry-search" [ngClass]="customClass">
  <div class="neo-x-entry-search-wrapper">
      <div #search_outer class="mdc-layout-grid neo-x-entry-search-content-outer">
          <div #search_inner class="mdc-layout-grid__inner align-items-center">
              <div class="neo-x-entry-search-wrapper-title" *ngIf="!isSmallScreen">
                <!-- <button
                mat-raised-button
                aria-label="Button that displays a tooltip when focused or hovered over"
                customToolTip [contentTemplate]="template"
              >
                Action
              </button> -->
              <i 
              mat-raised-button
              aria-label="Button that displays a tooltip when focused or hovered over"
              customToolTip [contentTemplate]="template"
              class="material-icons neo-tooltip-icon">info_outline</i>
              
              <ng-template #template>
                <div style="display: flex; flex-direction: column">
                  <span>{{title}}</span>
                  <span>{{description}}</span>
                 </div>
              </ng-template>
              </div>
              <div class="neo-tooltip-icon-smallscreen" *ngIf="isSmallScreen">
                <span>{{title}}</span>
                <span>{{description}}</span>
              </div>
              <neo-input-gender-select *ngIf="genderImages"
                                     class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-phone neo-contact-form-radios"
                                     [neoValue]="suchdatenstring.hauptPerson.geschlecht"
                                     [neoLabel]="staticData.getTranslationTexts().neoVgrPersonenEingabeSex"
                                     [neoRadioLabels]="[staticData.getTranslationTexts().neoVgrCommonMale,
                                                        staticData.getTranslationTexts().neoVgrCommonFemale]"
                                     [neoRadioValues]="[genderMale, genderFemale]"
                                     [images]="genderImages"
                                     (neoValueEmitter)="changeSex($event)">
              </neo-input-gender-select>
              <neo-input-integer class="mdc-layout-grid__cell mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-12-phone"
                                 [neoValue]="suchdatenstring.hauptPerson.jahrgang"
                                 (neoValueEmitter)="changeYear($event)"
                                 [neoPlaceholder]="staticData.getTranslationTexts().neoVgrSearchYear"
                                 [neoRequired]="false">
              </neo-input-integer>
              <neo-input-completer class="mdc-layout-grid__cell mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-12-phone neo-input-completer-label"
                                   [minLength]="3"
                                   [neoAutoValue]="suchdatenstring.region"
                                   [neoPlaceholder]="staticData.getTranslationTexts().neoVgrSearchPLZ"
                                   [neoCompleterItems]="regionen"
                                   (neoValueEmitter)="changeRegion($event)">
              </neo-input-completer>
                  <button class=" mdc-layout-grid__cell pramieBerechnen-btn mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone mdc-layout-grid--align-right" (click)="emitChange()">
                      {{staticData.getTranslationTexts().neoVgrSmallSearchPramieBerechnen}}
                          <!-- <img src="assets/images/vgr/arrow-white.svg" class="small-search-button"/> -->
                          <mat-icon width="15%" class="small-search-button">exit_to_app</mat-icon>
                  </button>
          </div>
      </div>

  </div>
</div>