import {Injectable} from "@angular/core";
import { VorsorgerechnerClass } from "../vorsorgerechner/VorsorgerechnerClass";

@Injectable()
export class LocalTranslateService {
  translationTexts = new VorsorgerechnerClass();
  setTranslations(data: VorsorgerechnerClass) {
    this.translationTexts = data;
  }


public getTranslationTexts(): VorsorgerechnerClass {
    return this.translationTexts;
}

}
