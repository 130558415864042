<div class="mdc-layout-grid neo-agency-search-grid">
  <div [ngClass]="customCssClass">
    <div class="mdc-layout-grid__inner">
      <div
        class="
          mdc-layout-grid__cell
          mdc-layout-grid__cell--span-12-desktop
          mdc-layout-grid__cell--span-12-tablet
          mdc-layout-grid__cell--span-12-phone
          neo-agency-card
        "
      >
        <h3 class="neo-agency-search-title">{{agencyFindTitle}}</h3>
      </div>
    </div>
    <div class="mdc-layout-grid neo-agency-search-grid">
      <div class="mdc-layout-grid__inner neo-agency-search-grid-inner">

        <div
          class="
            mdc-layout-grid__cell--span-12-desktop
            mdc-layout-grid__cell--span-12-tablet
            mdc-layout-grid__cell--span-12-phone
            neo-agency-search-button
          "
        >
          <neo-input-button
            [neoLabel]="agencyButtonText"
            (neoValueEmitter)="inputButtonEmitter()"
            [neoButtonCssClasses]="
              'neo-btn-input neo-btn-input-primary neo-btn-input-end'
            "
            routerLink="/standort" routerLinkActive="router-link-active" 
            customCssClass="neo-input-button"
            >
          </neo-input-button>
        </div>
      </div>
    </div>
  </div>
</div>
