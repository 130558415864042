import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'neo-banner',
  templateUrl: './neo-banner.component.html',
  styleUrls: ['./neo-banner.component.scss']
})
export class NeoBannerComponent implements OnInit {
  @Input() public showInnerGrid = false;
  @Input() public innerGridData:any = [];
  @Input() public bannerTitle: string;
  @Input() public bannerTitleDescription: string;
  @Input() public bannerDescription: string;
  @Input() public image: string;
  @Input() public alt:string;
  @Input() public customCssClass = '';
  constructor() { }
  ngOnInit() {
  }
}


export interface NeoBannerComponentItems {
  bannerTitle: string;
  bannerTitleDescription: string;
  bannerDescription: string;
  customCss: {};
  image: string;
  alt: string;
  innerContent: string;
}
