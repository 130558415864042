import {Inject, Injectable, InjectionToken} from "@angular/core";

export const LOCATION_TOKEN = new InjectionToken<Location>("Manually constructed token of window.location", {
    factory: () => getWindowLocation()
});

function getWindowLocation(): Location {
    if (typeof window !== 'undefined') {
        return window.location;
    } else {
        // Return a default object or handle the case when running in a non-browser environment
        return {} as Location;
    }
}

@Injectable({providedIn: 'root'})
export class DomLocationProvider {

    constructor(@Inject(LOCATION_TOKEN) private location: Location) {
    }

    get(): Location {
        return this.location;
    }

    getPathName(): string {
        return this.location.pathname;
    }

    getPort(): string {
        return this.location.port;
    }

    getProtocol(): string {
        return this.location.protocol;
    }

    getHostName(): string {
        return this.location.hostname;
    }

    getHost(): string {
        return this.location.host;
    }

    getOrigin(): string {
        return this.location.origin;
    }

    setHref(href: string) {
        this.location.href = href;
    }
}
