import { EventEmitter, Input, Output, Directive } from "@angular/core";

export interface NoParamConstructor<T> {
    new(): T;
}

@Directive()
export abstract class NeoInputBase<T> {
    @Input()
    public neoValue: T;
    @Output()
    public neoValueEmitter = new EventEmitter<T>(true);
    @Output()
    public neoValueIsValid = new EventEmitter<boolean>(true);
    @Input()
    public neoLabel: string;
    @Input()
    public neoPlaceholder: any;
    @Input()
    public neoRequired = false;
    @Input()
    public neoShowRequired = false;
    @Input()
    public neoErrorTooltipText: string;
    @Input()
    set neoShowError(doShowError: boolean) {
        this.showErrorCss = doShowError;
    }

    showErrorCss = false;

    protected constructor(ctor: NoParamConstructor<T>) {
        this.neoValue = new ctor();
    }

    public abstract neoIsValid(): boolean;
}
