<div *ngIf="neoLabel && neoLabel.length > 0" class="neo-vgr-input-margin ">
    {{neoLabel}}
    <span *ngIf="neoRequired === true && neoShowRequired === true" class="neo-contact-form-required">
        *
    </span>
</div>
<input type="number"
       ngClass="{{ showErrorCss ? 'neo-message-error-input':'' }}"
       class="neo-vgr-input-number neo-vgr-input-margin neo-vgr-input-field-number neo-helper-user-select-auto"
       placeholder="{{neoPlaceholder}}"
       [(ngModel)]="neoValue"
       (ngModelChange)="neoValueChange($event)"
       matTooltip="{{neoErrorTooltipText}}"
       matTooltipClass="neo-message-error-tooltip"
       [matTooltipDisabled]=[!showErrorCss]
       [matTooltipPosition]="'right'"
       [disabled]="disabled"
       [min]="min"
       [max]="max"
       (keyup)="onKeyup()"
/>